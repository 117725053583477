import EditorJs from 'react-editor-js';
import { EDITOR_JS_TOOLS } from './tools';

const MainEditor = ({ onUpdate, data }: any) => {
  if (data && data.loading) return <p>Loading...</p>;
  return (
    <div style={{ backgroundColor: '#ffffff' }}>
      <EditorJs
        tools={EDITOR_JS_TOOLS as any}
        onChange={onUpdate}
        data={data} /*instanceRef={instance => setEditorInstance(instance)}*/
      />
    </div>
  );
};

export default MainEditor;
