import { HoverShinyEffect } from '@components';
import { Progress } from 'antd';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';

type Props = {
  style?: any;
  data: any;
  clickDisabled: boolean;
};

const IndividualCourseButton = ({ style, data, clickDisabled }: Props) => {
  const [redirect, setRedirect] = useState<any>(false);

  if (redirect) return <Redirect to={redirect} />;

  return (
    <div
      className="course-button"
      style={{ backgroundColor: data.color, ...style }}
      onClick={() => {
        if (clickDisabled) return;
        setRedirect(`/course/${data.id}`);
      }}
    >
      <Progress
        type="circle"
        percent={data.completedPercent}
        trailColor="rgba(0,0,0,0.2)"
        strokeColor="white"
        style={{ float: 'left' }}
      />
      <div className="course-details">
        <h2>{data.title}</h2>
        <p>{data.subtitle}</p>
        <small>Next up: {data.nextLesson}</small>
      </div>
      <HoverShinyEffect />
    </div>
  );
};

export default IndividualCourseButton;
