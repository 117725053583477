import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useState } from 'react';
import Tab from './Tab';

interface TabDefinition<TabValue extends string> {
  label: string;
  value: TabValue;
}

interface TabsProps<TabValue extends string> {
  tabs: TabDefinition<TabValue>[];
  initialTab?: TabValue;
  className?: string;
  contentWrapperClassName?: string;
  onTabChange?: (tab: TabValue) => void;
  renderContent: (tab: TabValue) => React.ReactNode;
}

const Tabs = <TabValue extends string>(props: TabsProps<TabValue>) => {
  const {
    tabs,
    initialTab,
    className,
    contentWrapperClassName,
    onTabChange,
    renderContent,
  } = props;
  const [currentTab, setCurrentTab] = useState<TabValue>(
    initialTab || tabs[0].value
  );

  const handleTabClick = (tabValue: TabValue) => {
    setCurrentTab(tabValue);
    onTabChange?.(tabValue);
  };

  return (
    <div className={className}>
      <div className="flex gap-2 laptop:gap-4 flex-wrap">
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            text={tab.label}
            isActive={tab.value === currentTab}
            onClick={() => handleTabClick(tab.value)}
          />
        ))}
      </div>
      <div className={mergeClassNames('mt-6', contentWrapperClassName)}>
        {renderContent(currentTab)}
      </div>
    </div>
  );
};

export default Tabs;
