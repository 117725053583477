import React, { ReactNode, ButtonHTMLAttributes } from 'react';
import { COLORS } from '../../../../v3/global';
import './secondary-button.styles.scss';

type Props = {
  children?: ReactNode;
  disabled?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  borderRadius?: string;
  borderWidth?: string;
  height?: string;
  padding?: string;
  margin?: string;
  width?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const SecondaryButton = ({
  children,
  disabled,
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  height,
  padding,
  margin,
  width,
  ...buttonProps
}: Props) => {
  return (
    <button
      {...buttonProps}
      style={{
        height: height ?? 'auto',
        backgroundColor: backgroundColor ?? COLORS.TRANSPARENT,
        borderColor: borderColor ?? COLORS.DARK_WHITE,
        borderRadius: borderRadius ?? '4x',
        borderWidth: borderWidth ?? '1.2px',
        color: COLORS.DARK_WHITE,
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? '0.5' : '',
        padding: padding ?? '5px 16px',
        margin: margin ?? '',
        width: width ?? '',
      }}
      type="button"
      className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 secondary-button"
    >
      <span className="secondary-button-text">{children}</span>
    </button>
  );
};

export default SecondaryButton;
