import {
  ApiData,
  ApiDescription,
  GetNpsDiagramDataReturnType,
  NPSCategoriesType,
  NPSProblems,
  NPSReasons,
  NpsStatsType,
  RequestsMethod,
  UserPopulatedNPS,
} from '@shared/types';

export const checkPendingNPS: ApiDescription = {
  method: RequestsMethod.Get,
  endpoint: '/nps/check-pending',
};
export type CheckPendingNPS = ApiData<
  unknown,
  {
    type: 'monthly' | 'long';
    courseTitle: string;
    mentorName: string;
  } | null
>;

export const checkParentPendingNPS: ApiDescription = {
  method: RequestsMethod.Get,
  endpoint: '/nps/check-parent-pending/:id',
};

export type CheckParentPendingNPS = ApiData<
  unknown,
  {
    type: 'monthly' | 'long';
  } | null
>;

export const submitNPS: ApiDescription = {
  method: RequestsMethod.Post,
  endpoint: '/nps/submit',
};
export type SubmitNPS = ApiData<
  { rating: number; platformRating?: number; reasons?: NPSReasons },
  unknown
>;

export const submitParentNPS: ApiDescription = {
  method: RequestsMethod.Post,
  endpoint: '/nps/submit-parent',
};
export type SubmitParentNPS = ApiData<
  { rating: number; parentOf: string; reasons?: NPSReasons },
  unknown
>;

export type GetNpsDataType = (
  NPSType: 'platform' | 'education',
  userRole?: 'student' | 'mentor' | 'parent'
) => Promise<NpsStatsType>;

export const getCurrentNPS: ApiDescription = {
  method: RequestsMethod.Get,
  endpoint: '/nps/current',
};
export type GetCurrentNPS = ApiData<
  { userRole?: 'student' | 'mentor' | 'parent' },
  NpsStatsType
>;

export const getCurrentPlatformNPS: ApiDescription = {
  method: RequestsMethod.Get,
  endpoint: '/nps/current-platform',
};
export type GetCurrentPlatformNPS = ApiData<
  { userRole?: 'student' | 'mentor' },
  NpsStatsType
>;

export const generateCSVforNPS: ApiDescription = {
  method: RequestsMethod.Post,
  endpoint: '/nps/generate-csv',
};

export type GenerateCSVforNPS = ApiData<
  { filters?: any; date: string; filterByInflluencers: string },
  unknown
>;

export const getTrendDiagramData: ApiDescription = {
  method: RequestsMethod.Get,
  endpoint: '/nps/diagram-data',
};
export type GetTrendDiagramData = ApiData<
  {
    NPSType: 'platform' | 'education';
    userRole?: 'student' | 'mentor' | 'parent';
  },
  GetNpsDiagramDataReturnType
>;

export const computeAndStorePastNPS: ApiDescription = {
  method: RequestsMethod.Post,
  endpoint: '/nps/compute-past-nps',
};
export type ComputeAndStorePastNPS = ApiData<unknown, unknown>;

export const getDetractorsAndPassivesNPS: ApiDescription = {
  method: RequestsMethod.Get,
  endpoint: '/nps/detractors-and-passives-nps',
};

export type GetDetractorsAndPassivesNPS = ApiData<{ date: string }, unknown>;

export const getEducationAndPlatformComputedNps: ApiDescription = {
  method: RequestsMethod.Get,
  endpoint: '/nps/education-and-platform-computed-nps',
};

export type GetEducationAndPlatformComputedNps = ApiData<unknown, unknown>;

export const getPaginatedNPS: ApiDescription = {
  method: RequestsMethod.Get,
  endpoint: '/nps/paginated',
};

export type GetPaginatedNPS = ApiData<
  {
    date: string;
    NPSType: 'platform' | 'education';
    userRole: 'student' | 'mentor' | 'parent';
    category?: NPSCategoriesType[] | undefined;
    page?: number;
    limit?: number;
    reason?: string;
  },
  {
    nps: UserPopulatedNPS[];
    page: number;
    limit: number;
    total: number;
  }
>;

export const getNPSProblems: ApiDescription = {
  method: RequestsMethod.Get,
  endpoint: '/nps/by-problems',
};

export type GetNPSProblems = ApiData<
  { date: string },
  {
    studentEdu: {
      currMonthProblems: NPSProblems;
      lastMonthProblems: NPSProblems;
    };
    mentorEdu: {
      currMonthProblems: NPSProblems;
      lastMonthProblems: NPSProblems;
    };
    parentEdu: {
      currMonthProblems: NPSProblems;
      lastMonthProblems: NPSProblems;
    };

    studentPlatform: {
      currMonthProblems: NPSProblems;
      lastMonthProblems: NPSProblems;
    };

    mentorPlatform: {
      currMonthProblems: NPSProblems;
      lastMonthProblems: NPSProblems;
    };
  }
>;

export const getNPSScores: ApiDescription = {
  method: RequestsMethod.Get,
  endpoint: '/nps/score-line-chart',
};

export type GetNPSScores = ApiData<
  { date: string },
  {
    eduTrendPoints: {
      name: string;
      studentEdu: number;
      mentorEdu: number;
      parentEdu: number;
    }[];
    platformTrendPoints: {
      name: string;
      studentPlatform: number;
      mentorPlatform: number;
    }[];
  }
>;

export const getNPSDistribution: ApiDescription = {
  method: RequestsMethod.Get,
  endpoint: '/nps/distribution',
};

export type GetNPSDistribution = ApiData<{ date: string }, unknown>;

export const clearMonthNPSAndPopulate: ApiDescription = {
  method: RequestsMethod.Put,
  endpoint: '/nps/clear-month-nps',
};

export type ClearMonthNPSAndPopulate = ApiData<unknown, unknown>;
