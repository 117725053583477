import React from 'react';
import { COLORS } from '../../../v3/global';
import './enrollment-icon.styles.scss';

type Props = {
  enrollmentTitle: string;
  enrollmentColor: string;
  courseId: string;
  size?: number | string;
  fontSize?: number | string;
  margin?: string;
  abbreviation?: string;
};

const getFontSize = (
  abbreviation: string | undefined,
  enrollmentTitle: string | undefined,
  fontSize: number | string | undefined
): string | number | undefined => {
  if (abbreviation) {
    return abbreviation.length > 4 ? '12px' : '16px';
  }

  if (enrollmentTitle) {
    const [firstWord, secondWord] = enrollmentTitle.split(' ');
    if (firstWord === 'CoachMe') {
      return secondWord && secondWord.length <= 4 ? '16px' : '12px';
    }

    const titleAbbreviation = enrollmentTitle
      .split(' ')
      .map((word) => word[0])
      .join('');
    return titleAbbreviation.length <= 4 ? '16px' : '12px';
  }

  return fontSize;
};

const getTitleAbbreviation = (enrollmentTitle: string | undefined): string => {
  if (!enrollmentTitle) return '';
  const [firstWord, secondWord] = enrollmentTitle.split(' ');
  return firstWord === 'CoachMe' && secondWord
    ? secondWord
    : enrollmentTitle
        .split(' ')
        .map((word) => word[0])
        .join('');
};

const EnrollmentIcon: React.FC<Props> = ({
  enrollmentTitle,
  enrollmentColor,
  courseId,
  size = 40,
  fontSize,
  margin,
  abbreviation,
}) => {
  return (
    <div
      className="icon"
      style={{
        backgroundColor: enrollmentColor || '#9900EF',
        width: size,
        height: size,
        fontSize: getFontSize(abbreviation, enrollmentTitle, fontSize),
        borderRadius: '16px',
        color: COLORS.BLACK,
        margin: margin || '0px',
      }}
    >
      {abbreviation || getTitleAbbreviation(enrollmentTitle)}
    </div>
  );
};

export default EnrollmentIcon;
