import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import React from 'react';
import { COLORS } from '../../../v3/global';
import './table-column-sort-buttons.styles.scss';

type Props = {
  sortType?: string;
};

const TableColumnSortButtons = ({ sortType }: Props) => {
  return (
    <div className="sort-buttons-container">
      {sortType === 'asc' ? (
        <CaretUpOutlined
          style={{
            color: COLORS.TEXT_PRIMARY,
          }}
          className="sort-buttons-caret-icon"
        />
      ) : sortType === 'desc' ? (
        <CaretDownOutlined
          style={{
            color: COLORS.TEXT_PRIMARY,
          }}
          className="sort-buttons-caret-icon"
        />
      ) : (
        <>
          <CaretUpOutlined
            style={{
              color: COLORS.TEXT_PRIMARY,
            }}
            className="sort-buttons-caret-icon"
          />
          <CaretDownOutlined
            style={{
              color: COLORS.TEXT_PRIMARY,
            }}
            className="sort-buttons-caret-icon"
          />
        </>
      )}
    </div>
  );
};

export default TableColumnSortButtons;
