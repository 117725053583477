export const applicationStatuses = [
  { label: 'Application Not Started', value: 'notStarted' },
  { label: 'Application In Progress', value: 'inProgress' },
  { label: 'Application Sent', value: 'sent' },
  { label: 'Offer Received', value: 'received' },
  { label: 'Offer Accepted', value: 'accepted' },
  { label: 'Offer Rejected', value: 'rejected' },
];

export const degreeTypes = [
  'Professional Degree',
  "Bachelor's Degree",
  "Master's Degree",
  'Doctorate Degree',
];
