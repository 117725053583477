import { LoadingOutlined } from '@ant-design/icons';
import { HookFormInput, HookFormSelect } from '@components/Form/HookCustomForm';
import {
  admissionExamPrep,
  interestsOptions,
  learningStyles,
  standardisedTests,
  subjectsOptions,
} from '@shared/constants';
import { useGetAllCourses } from '@shared/react';
import { NewUserType } from '@shared/types';
import styles from '@styles/pages/sales-onboarding.module.scss';
import { Spin } from 'antd';
import { UseFormReturn } from 'react-hook-form';

const CreateUserForm = ({
  parentForm,
  isMentor,
  loading,
}: {
  parentForm: UseFormReturn<NewUserType, any>;
  isMentor: boolean;
  loading?: boolean;
}) => {
  const { reset } = parentForm;

  const required = { value: true, message: 'This field is required!' };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div>
      <div className={styles.formHeader}>
        Add user details
        <button
          className={styles.formButton}
          type="reset"
          onClick={() => reset()}
        >
          Clear all fields
        </button>
      </div>

      <div className={styles.formItems}>
        <HookFormInput
          form={parentForm}
          title="Full Name"
          label="fullName"
          validation={{
            required,
          }}
        />

        <HookFormInput
          form={parentForm}
          title="Phone Number"
          label="phoneNumber"
          validation={{
            required,
            pattern: {
              value: /^\d*$/,
              message: 'Invalid phone number format!',
            },
          }}
          type="tel"
        />

        <HookFormInput
          form={parentForm}
          title="High School"
          label="highSchool"
          validation={{ required }}
        />

        <HookFormInput
          form={parentForm}
          title="Graduation Year"
          label="graduationYear"
          validation={{
            required,
            pattern: {
              value: /^\d{4}$/,
              message: 'Invalid year format!',
            },
          }}
        />

        <HookFormInput
          form={parentForm}
          title="City"
          label="city"
          validation={{ required }}
        />

        <HookFormInput
          form={parentForm}
          title="Country"
          label="country"
          validation={{ required }}
        />

        <HookFormInput
          form={parentForm}
          title="ECL"
          label="ecl"
          validation={{ required }}
        />

        <HookFormSelect
          form={parentForm}
          title="Contact Owner"
          label="contactOwner"
          options={[
            {
              label: 'Amira',
              value: '335410129',
            },
            {
              label: 'Adriana',
              value: '260366526',
            },
            {
              label: 'Gabi',
              value: '467537870',
            },
          ]}
          validation={{ required }}
          type="single"
        />

        <HookFormInput
          form={parentForm}
          title="GPA"
          label="gpa"
          validation={{ required }}
          type="number"
          step="0.01"
        />

        <HookFormSelect
          title="Standardised Tests"
          label="standardisedTests"
          form={parentForm}
          options={standardisedTests.map((item) => ({
            value: item,
            text: item,
          }))}
          validation={{ required }}
        />

        <HookFormInput
          form={parentForm}
          title="Universities"
          label="universities"
          validation={{ required }}
        />

        <HookFormSelect
          title="Admissions Exam Prep"
          label="admissionExamPrep"
          form={parentForm}
          options={admissionExamPrep?.map((item) => ({
            value: item,
            text: item,
          }))}
        />

        <HookFormSelect
          title="Targeted University Programs"
          label="studySubjects"
          form={parentForm}
          options={subjectsOptions.map((item) => ({
            value: item,
            text: item,
          }))}
          validation={{ required }}
        />

        <HookFormInput
          form={parentForm}
          title="Subjects Student Likes"
          label="subjectsStudentLikes"
          validation={{ required }}
        />

        <HookFormInput
          form={parentForm}
          title="Subjects Student Doesn't Like"
          label="subjectsStudentDoesntLike"
          validation={{ required }}
        />

        <HookFormSelect
          form={parentForm}
          title="Learning Style"
          label="learningStyle"
          options={learningStyles.map((item: string) => ({
            value: item,
            text: item,
          }))}
          validation={{ required }}
        />

        <HookFormSelect
          form={parentForm}
          title={isMentor ? 'Countries to teach in' : 'Countries to study in'}
          label="countries"
          options={[
            { value: 'US', text: 'US' },
            { value: 'UK', text: 'UK' },
            { value: 'EU', text: 'EU' },
          ]}
          validation={{ required }}
        />

        <HookFormSelect
          form={parentForm}
          title="Academic Fields"
          label="interests"
          options={interestsOptions}
          validation={{ required }}
        />

        {!isMentor && (
          <>
            <HookFormInput
              form={parentForm}
              title="Parent Name"
              label="parentFullName"
              validation={{ required }}
            />

            <HookFormInput
              form={parentForm}
              title="Parent Phone Number"
              label="parentPhoneNumber"
              validation={{
                required,
                pattern: {
                  value: /^\d*$/,
                  message: 'Invalid phone number format!',
                },
              }}
            />

            <HookFormInput
              form={parentForm}
              title="Parent Email"
              label="parentEmail"
              validation={{
                required,
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: 'Invalid email address!',
                },
              }}
            />
          </>
        )}
      </div>

      <div style={{ marginRight: '80px' }} className={styles.formFooter}>
        {!loading ? (
          <button className={styles.formButton} type="submit">
            Save
          </button>
        ) : (
          <Spin style={{ marginRight: 10 }} size="large" indicator={antIcon} />
        )}
      </div>
    </div>
  );
};

export default CreateUserForm;
