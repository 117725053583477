import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import ReactMarkdown from 'react-markdown';
import styles from './Markdown.module.scss';

interface MarkdownProps {
  text: string;
  className?: string;
}

const Markdown = (props: MarkdownProps) => {
  const { text, className } = props;

  return (
    <ReactMarkdown
      className={mergeClassNames(styles['markdown-wrapper'], className)}
    >
      {text}
    </ReactMarkdown>
  );
};

export default Markdown;
