import { ReactComponent as BinIcon } from '@assets/icons/bin-scaled-icon.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg';
import { DropdownItem } from '@components/V4/Dropdown';
import { AppliedUniversity } from '@shared/common';
import React, { useState } from 'react';
import UniversitiesSectionCardDesktop from './UniversitiesSectionCardDesktop';
import UniversitiesSectionCardMobile from './UniversitiesSectionCardMobile';

export interface CardProps {
  dropdownItems: DropdownItem[];
  arrLength?: number;
  isShowMore: boolean;
  isEditMode: boolean;
  isDropdownOpen: boolean;
  setIsShowMore: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userId: string;
  universityGoal: AppliedUniversity;
  isUpdatingUserProfile: boolean;
  onCancelEdit: () => void;
  incrementOrderHandler: () => void;
  decrementOrderHandler: () => void;
}

interface UniversitiesSectionCardProps {
  userId: string;
  arrLength?: number;
  universityGoal: AppliedUniversity;
  isUpdatingUserProfile: boolean;
  onDeleteEntry: (id?: string) => void;
  onModifyGoalOrder: (newOrder?: number, id?: string) => void;
}

const UniversitiesSectionCard = (props: UniversitiesSectionCardProps) => {
  const {
    userId,
    arrLength,
    universityGoal,
    isUpdatingUserProfile,
    onDeleteEntry,
    onModifyGoalOrder,
  } = props;

  const [isEditMode, setIsEditMode] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const cancelEditHandler = () => {
    setIsEditMode(false);
  };

  const dropdownItems: DropdownItem[] = [
    {
      icon: <EditIcon className="w-3 h-3" />,
      text: 'Edit',
      onClick: () => setIsEditMode(true),
    },
    {
      icon: <BinIcon className="w-3 text-customRed" />,
      text: 'Delete',
      onClick: () => onDeleteEntry(universityGoal._id),
    },
  ];

  const incrementOrderHandler = () => {
    if (!universityGoal.order || isUpdatingUserProfile) return;
    onModifyGoalOrder(universityGoal.order + 1, universityGoal._id);
  };

  const decrementOrderHandler = () => {
    if (!universityGoal.order || isUpdatingUserProfile) return;
    onModifyGoalOrder(universityGoal.order - 1, universityGoal._id);
  };

  return (
    <>
      <UniversitiesSectionCardMobile
        userId={userId}
        arrLength={arrLength}
        dropdownItems={dropdownItems}
        isShowMore={isShowMore}
        isEditMode={isEditMode}
        isDropdownOpen={isDropdownOpen}
        setIsShowMore={setIsShowMore}
        setIsEditMode={setIsEditMode}
        setIsDropdownOpen={setIsDropdownOpen}
        onCancelEdit={cancelEditHandler}
        universityGoal={universityGoal}
        incrementOrderHandler={incrementOrderHandler}
        decrementOrderHandler={decrementOrderHandler}
        isUpdatingUserProfile={isUpdatingUserProfile}
      />
      <UniversitiesSectionCardDesktop
        dropdownItems={dropdownItems}
        arrLength={arrLength}
        isShowMore={isShowMore}
        userId={userId}
        isEditMode={isEditMode}
        isDropdownOpen={isDropdownOpen}
        setIsShowMore={setIsShowMore}
        setIsEditMode={setIsEditMode}
        setIsDropdownOpen={setIsDropdownOpen}
        onCancelEdit={cancelEditHandler}
        universityGoal={universityGoal}
        incrementOrderHandler={incrementOrderHandler}
        decrementOrderHandler={decrementOrderHandler}
        isUpdatingUserProfile={isUpdatingUserProfile}
      />
    </>
  );
};

export default UniversitiesSectionCard;
