import styles from '@styles/views/courses.module.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { COLORS } from '../../../v3/global';

interface BreadcrumbsProps {
  crumbs: Array<{ path: string; name: string }>;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ crumbs }) => {
  return (
    <div className={styles.breadcrumbs}>
      {crumbs.map((crumb, index: number) => (
        <span style={{ color: COLORS.TEXT_PRIMARY }} key={crumb.path}>
          <Link
            to={crumb.path}
            style={{ color: COLORS.TEXT_PRIMARY }}
            className={`${styles.breadcrumbsItem} ${
              index === crumbs.length - 1 ? styles.breadcrumbsItemLast : ''
            }`}
          >
            {crumb.name}
          </Link>
          {index !== crumbs.length - 1 && ' / '}
        </span>
      ))}
    </div>
  );
};

export default Breadcrumbs;
