import {
  ApiData,
  ApiDescription,
  RequestsMethod,
  Payment,
  PaymentItem,
} from '../common';

export const createPayment: ApiDescription = {
  endpoint: '/payment/new',
  method: RequestsMethod.Post,
};
export type CreatePayment = ApiData<
  {
    items: PaymentItem[];
    userId: string;
  },
  Partial<Payment>
>;

export const paymentWebhook: ApiDescription = {
  endpoint: '/payment/webhook',
  method: RequestsMethod.Post,
};
export type PaymentWebhook = ApiData<{ id: string }, Partial<Payment> | string>;

export const sendPaymentEmail: ApiDescription = {
  endpoint: '/payment/send-payment-email/:id',
  method: RequestsMethod.Get,
};
export type SendPaymentEmail = ApiData<{ id: string }, string>;
