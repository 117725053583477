import { StarOutlined } from '@ant-design/icons';
import { useMentorPerformace, useMentorPerformaceTrends } from '@shared/react';
import { Col, Row, Statistic, Tabs } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  Line,
} from 'recharts';

const { TabPane } = Tabs;

const POSITIVE_COLOR = '#3f8600';
const NEGATIVE_COLOR = '#cf1322';

const MentorPerformance = () => {
  const { data } = useMentorPerformace();
  const { data: historicData } = useMentorPerformaceTrends();

  const monthlyData = historicData?.lastSixMonths.map((ratings, index) => {
    const month: string = moment()
      .subtract(6 - index, 'months')
      .format('MMM');
    return {
      month,
      ratings,
    };
  });

  const weeklyData = historicData?.lastSixWeeks.map((ratings, index) => {
    // const week = `W${moment()
    //   .subtract(6 - index, 'weeks')
    //   .week()}`;
    const week = `${moment()
      .subtract(6 - index, 'weeks')
      .format('D MMM')}`;
    return {
      week,
      ratings,
    };
  });

  const percentageChange =
    ((Number(data?.feedbackAverage) - Number(data?.feedbackLastPeriodAverage)) /
      Number(data?.feedbackLastPeriodAverage)) *
    100;

  const trendText = () => {
    if (!data || !data.feedbackAverage || !data.feedbackLastPeriodAverage)
      return 'No data';

    if (
      data?.feedbackAverage === 'n/a' ||
      data?.feedbackLastPeriodAverage === 'n/a'
    ) {
      return 'Cannot compare with last period.';
    }

    if (data?.feedbackAverage > data?.feedbackLastPeriodAverage) {
      return `${percentageChange?.toFixed(2)}% increase`;
    }
    if (data?.feedbackAverage < data?.feedbackLastPeriodAverage) {
      return `${percentageChange?.toFixed(2)}% decrease`;
    }

    return 'No change';
  };

  const ratings = data?.ratings.map((rating, index) => ({
    name: `${index + 1}`,
    value: rating,
  }));

  return (
    <Row>
      <Col span={8}>
        <Link to="/admin/meeting-management">
          <Statistic
            style={{ textAlign: 'center' }}
            title="Avg Rating This Week"
            value={data?.feedbackAverage}
            prefix={<StarOutlined />}
          />
          <div
            style={{
              textAlign: 'center',
              color: percentageChange > 0 ? POSITIVE_COLOR : NEGATIVE_COLOR,
            }}
          >
            {trendText()}
          </div>
        </Link>
      </Col>
      <Col span={16}>
        <Tabs defaultActiveKey="this_week">
          <TabPane tab="This Week" key="this_week">
            <BarChart width={600} height={300} data={ratings}>
              <Bar dataKey="value" />
              <XAxis dataKey="name" />
              <YAxis dataKey="value" />
              <Tooltip
                formatter={(value: number) => [
                  `${value} meeting${value === 1 ? '' : 's'} got this rating`,
                  null,
                ]}
              />
            </BarChart>
          </TabPane>
          <TabPane tab="Monthly" key="monthly">
            {!!monthlyData && (
              <LineChart width={500} height={300} data={monthlyData}>
                <XAxis dataKey="month" />
                <YAxis domain={[0, 5]} />
                <Tooltip formatter={(value: string) => [`${value}`, null]} />
                <Line type="monotone" dataKey="ratings" stroke="#82ca9d" />
              </LineChart>
            )}
          </TabPane>
          <TabPane tab="Weekly" key="weekly">
            {!!weeklyData && (
              <LineChart width={500} height={300} data={weeklyData}>
                <XAxis dataKey="week" />
                <YAxis domain={[0, 5]} />
                <Tooltip formatter={(value: string) => [`${value}`, null]} />
                <Line type="monotone" dataKey="ratings" stroke="#82ca9d" />
              </LineChart>
            )}
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default MentorPerformance;
