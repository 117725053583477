import { diacriticsApplyMap } from '@shared/constants';
import { BASE_URL } from '@shared/env';
import moment from 'moment';
import mongoose from 'mongoose';

export const getRandomString = (length = 24) => {
  // Declare all characters
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  // Pick characers randomly
  let str = '';
  for (let i = 0; i < length; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return str;
};

export const millisToMinutesAndSeconds = (millis: number) => {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return `${minutes} mins, ${seconds} seconds`;
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getRandomColor = () => {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
};

export const getRandomPassword = (length: number) => {
  if (length < 6) {
    throw new Error(
      'Password too short, we refuse to generate passwords shorter than 6 characters'
    );
  }
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.toLowerCase();
  const specialChars = '[]{}|/,.<>?_+-=!~';
  const numbers = '01234567890';
  let hasCapital = false;
  let str = '';
  for (let i = 0; i < length - 4; i++) {
    const pos = Math.floor(Math.random() * chars.length);
    let char: string;
    if (Math.random() > 0.5) {
      char = chars[pos].toUpperCase();
      hasCapital = true;
    } else {
      char = chars[pos];
    }
    // if we were unlucky and have no capitals until the last character, make that a capital
    if (i === length - 5 && !hasCapital) {
      char = char.toUpperCase();
    }
    str += char;
  }
  for (let i = 0; i < 2; i++) {
    str += specialChars[Math.floor(Math.random() * specialChars.length)];
    str += numbers[Math.floor(Math.random() * numbers.length)];
  }
  return str;
};

export const sanitizeHTML = function (html: string) {
  return html.replaceAll('script', 'span').replaceAll('img', 'span');
};

diacriticsApplyMap.regex = [];
for (let i = 0; i < diacriticsApplyMap.default.length; i++) {
  const item = diacriticsApplyMap.default[i];
  const base = '(?:' + item.base + '|' + item.letters.source + ')';
  diacriticsApplyMap.regex.push({
    base: '$1' + base,
    letters: new RegExp('([^\\[\\\\])' + base + '|^' + base, 'g'),
  });
}

export const removeDiacritics = (str: string, which?: string) => {
  which = which || 'default';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changes: any = diacriticsApplyMap[which];
  for (let i = 0; i < changes.length; i++) {
    str = str.replace(changes[i].letters, changes[i].base);
  }
  return str;
};

export const documentUrl = (id: string) =>
  `https://docs.google.com/document/d/${id}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const YTDurationToSeconds = (duration: any) => {
  let match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);

  // eslint-disable-next-line
  match = match.slice(1).map((x: any) => {
    if (x != null) {
      return x.replace(/\D/, '');
    }
  });

  const hours = parseInt(match[0]) || 0;
  const minutes = parseInt(match[1]) || 0;
  const seconds = parseInt(match[2]) || 0;

  return hours * 3600 + minutes * 60 + seconds;
};

type FeedbackItem = {
  mentor: string;
  course: string;
  startDate: string;
  feedback: {
    sessionSummary: string;
  };
};
export const feedbackEmailItem = ({
  mentor,
  course,
  startDate,
  feedback,
}: FeedbackItem) => {
  const date = moment(startDate).format('DD MMM YYYY');
  return `<div style="border: 1px solid #e3e3e3; display: block; padding: 40px 50px; margin: 10px 0;">${course} - ${mentor} - ${date}<br /><br /><q style='white-space: pre-wrap'>${feedback.sessionSummary}</q></div>`;
};

export const isValidMeetingURL = (url: string) => {
  url = url.trim();
  if (url.startsWith('http://') || url.startsWith('https://')) {
    try {
      new URL(url);
    } catch (e) {
      return false;
    }
    return true;
  }
  return false;
};

export const detectDeviceType = () =>
  /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? 'Mobile'
    : 'Web';

export const buildSessionkey = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

export const buildMeetingURL = (sessionId: mongoose.Types.ObjectId) =>
  `${BASE_URL}/video-meeting/${sessionId?.toString()}`;

export const buildMeetingTitle = (
  title: string,
  mentor: string,
  students: string[]
) => `${title} - ${mentor} / ${students.join(' & ')}`;
