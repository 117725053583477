import { storage } from '../UniversalStorage';
import { useAuthState } from '../contexts';

const useHeaders = () => {
  const { token, auth0token } = useAuthState();
  return {
    'x-access-token': token ?? localStorage.getItem('authToken'),
    Authorization: `Bearer ${auth0token ?? localStorage.getItem('auth0token')}`,
    'impersonate-user-id': storage.getItem('impersonate-user-id') ?? '',
  };
};

export default useHeaders;
