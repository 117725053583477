import { useModal } from '@hooks';
import { User } from '@shared/common';
import { useCurrentDiagramNPS, useCurrentPlatformNPS } from '@shared/react';
import { Alert, Col, Row, Tabs, Tooltip as AntTooltip } from 'antd';
import React, { useState } from 'react';
import { Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import NPSUsersModal from './NPSModals/NPSUsersModal';

const { TabPane } = Tabs;

const PlatformNPS: React.FC = () => {
  const [userRole, setUserRole] = useState<'student' | 'mentor'>('student');
  const { data, isLoading } = useCurrentPlatformNPS({ userRole });
  const { data: trendPoints } = useCurrentDiagramNPS({
    NPSType: 'platform',
    userRole,
  });

  const [NPSUsersM, showNPSUsersM] = useModal(NPSUsersModal);

  const openUsersModal = (
    npsLevel: 'passives' | 'detractors' | 'promoters',
    data: Partial<User>[],
    userRates?: any
  ) => {
    const allowedUserRoles = ['student', 'mentor'];
    if (!allowedUserRoles.includes(userRole)) return;

    showNPSUsersM({
      fieldType: 'platformRating',
      userRole,
      npsLevel,
      data,
      userRates,
    });
  };

  if (isLoading) return <p>Loading...</p>;
  if (!data) return <p>No data</p>;

  const { totalNps, responsesNumber } = data;

  const commas2Digits = (nr?: number) => {
    return (nr ?? 0).toLocaleString('en', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  const percent2Digits = (nr?: number) => {
    return (
      ((nr ?? 0) * 100).toLocaleString('en', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }) + ' %'
    );
  };

  const chartPoints = (trendPoints?.trendPoints || []).map(
    ({ y, ...point }) => {
      return { ...point, y: y * 100 };
    }
  );

  return (
    <>
      <Tabs
        defaultActiveKey="student"
        onChange={(key) => {
          if (key === 'student' || key === 'mentor') {
            setUserRole(key);
          }
        }}
        activeKey={userRole}
      >
        <TabPane tab="Students" key="student" />
        <TabPane tab="Mentors" key="mentor" />
      </Tabs>

      <Row>
        <Col span={6}>
          <h3>Current NPS</h3>
          <span style={{ fontSize: '3rem' }}>{percent2Digits(totalNps)}</span>
        </Col>

        <Col span={6}>
          <h3>Number of responses</h3>
          <span style={{ fontSize: '3rem' }}>{responsesNumber}</span>
        </Col>

        <Col span={6}>
          <h3>Distribution</h3>
          <ul>
            {(['detractors', 'passives', 'promoters'] as const).map((field) => {
              const { users, percentage } = data[field];

              return (
                <li key={field}>
                  <AntTooltip title={`${users.length} responses`}>
                    <span
                      className="main-green-link"
                      onClick={() =>
                        openUsersModal(field, users, data?.userRates)
                      }
                    >
                      {field.charAt(0).toUpperCase() + field.slice(1)}
                    </span>
                    : {percent2Digits(percentage)}
                  </AntTooltip>
                </li>
              );
            })}
          </ul>
        </Col>

        <Col span={6}>
          <h3>Trend</h3>
          {chartPoints.length ? (
            <LineChart width={300} height={200} data={chartPoints}>
              <XAxis dataKey="name" />
              <YAxis domain={[-100, 100]} />
              <Tooltip
                formatter={(value: number) => `${commas2Digits(value)} %`}
              />
              <Line type="monotone" dataKey="y" stroke="#82ca9d" />
            </LineChart>
          ) : (
            <p>Loading...</p>
          )}
        </Col>
      </Row>

      {responsesNumber < 50 && (
        <Alert
          message="Data is not yet conclusive (less than 50 points)."
          type="warning"
          showIcon
        />
      )}

      {NPSUsersM}
    </>
  );
};

export default PlatformNPS;
