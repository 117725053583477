import { CheckOutlined, CrownOutlined, RedoOutlined } from '@ant-design/icons';
import { useConfetti, useQueryParams } from '@hooks';
import { COURSES } from '@routes';
import { Quiz } from '@shared/common';
import { Button, Space, Statistic } from 'antd';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import QuestionFeedback from './QuestionFeedback';

type Props = {
  retake: () => void;
  rank: number;
  results: { answer: string; question: string; correct: boolean }[];
};

const QuizFinish = ({ retake, rank, results }: Props) => {
  const { id } = useParams<{ [key: string]: string }>();
  const history = useHistory();

  const { data: quiz } = useQuery<Quiz>(['page/$?refType=Quiz', id]);

  const params = useQueryParams();
  const chapter = params.get('m');
  const moduleUrl = `${COURSES}/${quiz?.parentCourse}`;

  const correctAnswers = results.filter((r) => r.correct);
  const wrongAnswers = results.filter((r) => !r.correct);

  const passed = rank > -1;
  useConfetti(2, quiz?.isGraded && passed);

  if (!quiz) return <p>loading...</p>;

  return (
    <Space direction="vertical">
      <Space>
        <Statistic
          title="Correct answers"
          value={correctAnswers.length}
          suffix={`/ ${quiz.questions.length}`}
        />
        <CrownOutlined style={{ fontSize: 40, color: 'gold' }} />
      </Space>
      {!!wrongAnswers.length && (
        <div>
          <h4>What you've got wrong</h4>
          <hr />
          {wrongAnswers.map((ans) => (
            <QuestionFeedback
              question={quiz.questions.find((q) => q._id === ans.question)}
            />
          ))}
        </div>
      )}

      <Space>
        <Button
          style={{ marginTop: 15, marginRight: 15 }}
          shape="round"
          icon={<CheckOutlined />}
          size="large"
          type="primary"
          onClick={() => {
            window.location.replace(`${moduleUrl}/chapters/${chapter}`);
          }}
        >
          Back to Module
        </Button>

        <Button
          style={{ marginTop: 15, marginRight: 15 }}
          shape="round"
          icon={<RedoOutlined />}
          size="large"
          onClick={retake}
        >
          Retake Quiz
        </Button>
      </Space>
    </Space>
  );
};

export default QuizFinish;
