import NextMeeting, { NextMeetingDateToShow } from '@components/V3/NextMeeting';
import { useModal } from '@hooks';
import StudentDetailsModal from '@modules/Mentor/MyStudents/StudentDetailsModal';
import { MeetingWithHomework } from '@shared/common';
import React from 'react';
import HomeworkButton from '../../../views/UserView/Home/Common/Buttons/HomeworkButton';
import JoinButtonMentor from '../../../views/UserView/Home/Common/Buttons/JoinButtonMentor';

interface Props {
  meeting: MeetingWithHomework;
  refetch?: () => void;
  isMentor?: boolean;
  dateToShow?: NextMeetingDateToShow;
}

const NextMeetingMentor: React.FC<Props> = (props: Props) => {
  const { meeting, refetch, isMentor, dateToShow } = props;
  const { students } = meeting;

  const name = students[0]?.fullName || '';
  const isStudentDeactivated = meeting.enrollment?.user?.isDezactivated;

  const [DetailsModal, showDetailsModal] = useModal(StudentDetailsModal);

  return (
    <>
      <NextMeeting
        meeting={meeting}
        otherName={name}
        isMentor={isMentor}
        showDetailsModal={showDetailsModal}
        dateToShow={dateToShow}
      >
        {!isStudentDeactivated && (
          <div style={{ width: '100%' }}>
            <HomeworkButton meeting={meeting} refetch={refetch} />
          </div>
        )}
        <div style={{ width: '100%' }}>
          <JoinButtonMentor
            meeting={meeting}
            hideJoinAndRescheduleBtn={isStudentDeactivated}
          />
        </div>
      </NextMeeting>
      {DetailsModal}
    </>
  );
};

export default NextMeetingMentor;
