import { defaultSubjects } from '@shared/constants';
import { Select } from 'antd';
import { CSSProperties } from 'react';

type Props = {
  value?: string[];
  onChange?: (value: string[]) => void;
  style?: CSSProperties;
  placeholder?: string;
};

const StudySubjectsSelector = ({
  placeholder,
  value,
  onChange,
  style,
}: Props) => {
  return (
    <Select
      mode="multiple"
      allowClear
      placeholder={placeholder || 'Please select your study subject'}
      value={value}
      onChange={onChange}
      style={style}
    >
      {defaultSubjects.map((selectedSubject) => {
        return (
          <Select.Option key={selectedSubject} value={selectedSubject}>
            {selectedSubject}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default StudySubjectsSelector;
