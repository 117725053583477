import { SortOrder } from '@components/V4/Table/Table';
import SessionHistoryFiltersDesktop from './SessionHistoryFiltersDesktop';
import SessionHistoryFiltersMobile from './SessionHistoryFiltersMobile';

interface SessionHistoryFiltersProps {
  sortBy: string | null;
  sortOrder: SortOrder;
  searchParams: any;
  isFilterDropdownActive: boolean;
  courseFilters?: { _id: string; title: string }[];
  studentsFilter?: { _id: string; fullName: string }[];
  sortHandler: (field: string, order: SortOrder) => void;
  courseHandler: (course: string) => void;
  statusHandler: (status: number | null) => void;
  studentHandler: (student: string) => void;
}

export interface SessionHistoryFiltersChildProps {
  courseFilterOptions: { _id: string; title: string }[];
  sortOptions: {
    label: string;
    value: string;
    sort: string;
    order: string;
  }[];
  selectedLabel: string;
  searchParams: any;
  studentsFilterOptions: { _id: string; fullName: string }[];
  isFilterDropdownActive?: boolean;
  handleSortChange: (value: string | number | null) => void;
  courseChangeHandler: (value: string) => void;
  statusHandler: (status: number | null) => void;
  studentHandler: (student: string) => void;
  getSelectedStudent: (id: string) =>
    | {
        _id: string;
        fullName: string;
      }
    | undefined;
}

const SessionHistoryFilters = (props: SessionHistoryFiltersProps) => {
  const {
    sortBy,
    sortOrder,
    sortHandler,
    courseHandler,
    searchParams,
    courseFilters,
    isFilterDropdownActive,
    statusHandler,
    studentsFilter,
    studentHandler,
  } = props;

  let courseFilterOptions: { title: string; _id: string }[] = [
    {
      title: 'All Courses',
      _id: '',
    },
  ];
  let studentsFilterOptions: { fullName: string; _id: string }[] = [
    { fullName: 'All Students', _id: '' },
  ];

  if (courseFilters) {
    courseFilterOptions = [...courseFilterOptions, ...courseFilters];
  }

  if (studentsFilter) {
    studentsFilterOptions = [...studentsFilterOptions, ...studentsFilter];
  }
  const sortOptions = [
    {
      label: 'Course Name (Asc)',
      value: 'nameAsc',
      sort: 'name',
      order: 'asc',
    },
    {
      label: 'Course Name (Desc)',
      value: 'nameDesc',
      sort: 'name',
      order: 'desc',
    },
    {
      label: 'Session Number (Asc)',
      value: 'sessionNoAsc',
      sort: 'sessionNo',
      order: 'asc',
    },
    {
      label: 'Session Number (Desc)',
      value: 'sessionNoDesc',
      sort: 'sessionNo',
      order: 'desc',
    },
    {
      label: 'Date & Time (Asc)',
      value: 'startDateAsc',
      sort: 'startDate',
      order: 'asc',
    },
    {
      label: 'Date & Time (Desc)',
      value: 'startDateDesc',
      sort: 'startDate',
      order: 'desc',
    },
    { label: 'Status (Asc)', value: 'statusAsc', sort: 'status', order: 'asc' },
    {
      label: 'Status (Desc)',
      value: 'statusDesc',
      sort: 'status',
      order: 'desc',
    },
  ];

  const getSelectedOption = (sortBy: string | null, sortOrder: SortOrder) => {
    return sortOptions.find(
      (option) => option.sort === sortBy && option.order === sortOrder
    );
  };

  const selectedOption = getSelectedOption(sortBy, sortOrder);
  const selectedLabel = selectedOption
    ? selectedOption.label
    : 'Select Sort Option';

  const handleSortChange = (value: string | number | null) => {
    if (typeof value === 'string') {
      const selectedOption = sortOptions.find(
        (option) => option.value === value
      );
      if (selectedOption) {
        sortHandler(selectedOption.sort, selectedOption.order as SortOrder);
      }
    }
  };

  const courseChangeHandler = (value: string) => {
    courseHandler(value);
  };

  const getSelectedStudent = (id: string) => {
    return studentsFilterOptions.find((val) => val._id === id);
  };

  return (
    <section>
      <SessionHistoryFiltersDesktop
        courseFilterOptions={courseFilterOptions}
        sortOptions={sortOptions}
        selectedLabel={selectedLabel}
        handleSortChange={handleSortChange}
        courseChangeHandler={courseChangeHandler}
        statusHandler={statusHandler}
        searchParams={searchParams}
        studentsFilterOptions={studentsFilterOptions}
        studentHandler={studentHandler}
        getSelectedStudent={getSelectedStudent}
      />
      <SessionHistoryFiltersMobile
        courseFilterOptions={courseFilterOptions}
        sortOptions={sortOptions}
        selectedLabel={selectedLabel}
        handleSortChange={handleSortChange}
        courseChangeHandler={courseChangeHandler}
        statusHandler={statusHandler}
        searchParams={searchParams}
        isFilterDropdownActive={isFilterDropdownActive}
        studentsFilterOptions={studentsFilterOptions}
        studentHandler={studentHandler}
        getSelectedStudent={getSelectedStudent}
      />
    </section>
  );
};

export default SessionHistoryFilters;
