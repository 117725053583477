import { ToggleModalProps } from '@shared/common';
import { Modal } from 'antd';

import EdjDisplay from '../../Base/LessonDisplay/EdjDisplay';

type LessonPreviewProps = ToggleModalProps & {
  data: any;
};

const LessonPreview = ({ visible, setVisible, data }: LessonPreviewProps) => {
  return (
    <Modal
      title="Lesson Preview"
      centered
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      width={1000}
    >
      <EdjDisplay data={data} preview />
    </Modal>
  );
};

export default LessonPreview;
