import JoinIcon from '@assets/icons/Icon-material-video-call.svg';
import { ReactComponent as BinIcon } from '@assets/icons/V4/trash-icon.svg';
import RescheduleModal from '@components/V4/RescheduleModal';
import { searchMyMeetings } from '@shared/apis/meetings.apis';
import { Meeting, MeetingStatus } from '@shared/common';
import { useUpdateMeeting } from '@shared/react';
import button from '@styles/components/reusables/buttons.module.scss';
import { message } from 'antd';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { COLORS } from '../../../../../v3/global';
import MeetingListItemButton from './MeetingListItemButton';

interface JoinButtonProps {
  meeting: Meeting;
  hideJoinAndRescheduleBtn?: boolean;
  afterSave?: () => void;
}

const JoinButtonMentor: React.FC<JoinButtonProps> = ({
  meeting,
  hideJoinAndRescheduleBtn,
  afterSave,
}) => {
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);

  const queryClient = useQueryClient();

  const { mutate: updateMeeting } = useUpdateMeeting(meeting._id, {
    onSuccess: () => {
      queryClient.refetchQueries(searchMyMeetings.endpoint);
      message.success('Meeting cancelled successfully.');
    },
  });

  return (
    <div className={button.joinButtonMentorContainer}>
      {!hideJoinAndRescheduleBtn && (
        <>
          <span
            style={{
              color: COLORS.TEXT_PRIMARY,
            }}
            className={`${button.actionButton} gradient ${button.joinButtonMentorJoinContainer}`}
          >
            <MeetingListItemButton>
              <Link
                to={`/video-meeting/${meeting._id}`}
                className="flex items-center w-full h-full text-inherit py-2 px-4"
              >
                <img src={JoinIcon} className={button.joinButtonMentorIcon} />
                <span className="text-white">Join meeting</span>
              </Link>
            </MeetingListItemButton>
          </span>

          <MeetingListItemButton>
            <div
              onClick={() => setIsRescheduleModalOpen(true)}
              className="flex items-center w-full h-full text-white py-2 px-4"
            >
              <img
                src={JoinIcon}
                alt="Join meeting"
                className={button.joinButtonMentorIcon}
              />
              <div>Reschedule</div>
            </div>
          </MeetingListItemButton>
        </>
      )}
      <MeetingListItemButton>
        <div
          onClick={() => updateMeeting({ status: MeetingStatus.CANCELLED })}
          className="flex items-center w-full h-full text-white py-2 px-4"
        >
          <BinIcon className="w-3.5 text-customRed mr-3" />
          <span>Cancel</span>
        </div>
      </MeetingListItemButton>
      {isRescheduleModalOpen && (
        <RescheduleModal
          isOpen
          onClose={() => setIsRescheduleModalOpen(false)}
          meetingId={meeting._id}
          onReschedule={afterSave && afterSave}
        />
      )}
    </div>
  );
};

export default JoinButtonMentor;
