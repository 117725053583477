import { createContext, useContext } from 'react';

import { Device, Environment } from '../../common';

type State = {
  environment: Environment;
  device: Device;
};
export const EnvironmentStateContext = createContext<State | undefined>(
  undefined
);

type Props = {
  environment: Environment;
  device: Device;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

const EnvironmentProvider = ({ children, ...props }: Props) => {
  const state: State = {
    ...props,
  };

  return (
    <EnvironmentStateContext.Provider value={state}>
      {children}
    </EnvironmentStateContext.Provider>
  );
};

const useEnvironmentState = () => {
  const context = useContext(EnvironmentStateContext);
  if (context === undefined) {
    throw new Error(
      'useEnvironmentState must be used within a EnvironmentProvider'
    );
  }

  return context;
};

export { EnvironmentProvider, useEnvironmentState };
