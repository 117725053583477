import confetti from 'canvas-confetti';
import { useEffect } from 'react';

const useConfetti = (seconds = 15, show = false) => {
  useEffect(() => {
    if (!show) return;

    const end = Date.now() + seconds * 1000;

    (function frame() {
      confetti({
        particleCount: 3,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
      });
      confetti({
        particleCount: 3,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  }, [show]);
};

export default useConfetti;
