import { knowledgeBaseChatApis } from '@shared/apis';
import { genericMutation, genericQuery, genericQueryWithId } from './generics';

export const useGetUserChatsTitles =
  genericQuery<knowledgeBaseChatApis.GetUserKnowledgeBaseChatsTitles>(
    knowledgeBaseChatApis.getUserKnowledgeBaseChatsTitles
  );

export const useCreateNewChat =
  genericMutation<knowledgeBaseChatApis.CreateNewKnowledgeBaseChat>(
    knowledgeBaseChatApis.createNewKnowledgeBaseChat
  );

export const useGetChat =
  genericQueryWithId<knowledgeBaseChatApis.GetKnowledgeBaseChat>(
    knowledgeBaseChatApis.getKnowledgeBaseChat
  );
