import calendarIcon from '@assets/icons/input-calendar.svg';
import InputLabel from '@components/V4/Inputs/InputLabel';
import { DatePicker, DatePickerProps } from 'antd';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import classNames from 'classnames';
import moment from 'moment';
import './agora-date-picker.styles.scss';
import AgoraInputError from '../AgoraInputError';

type Props = {
  allowClear?: boolean;
  value?: moment.Moment;
  size?: 'default' | 'large';
  variant?: 'primary' | 'secondary';
  onBlur?: (e: any) => void;
  disabledDate?: (e: any) => boolean;
  isRequired?: boolean;
  className?: string;
  isValid?: boolean;
  isTouched?: boolean;
  errorText?: string;
  label?: string;
};

const AgoraDatePicker = ({
  allowClear,
  value,
  variant = 'primary',
  picker,
  onChange,
  onBlur,
  disabledDate,
  disabled,
  isRequired,
  className,
  isValid,
  isTouched,
  errorText,
  label,
  size,
}: Omit<DatePickerProps, 'size'> & Props) => {
  return (
    <div
      className={classNames(
        'agora-date-picker',
        className,
        isValid && isTouched
          ? 'agora-date-picker--valid'
          : !isValid && isTouched
          ? 'agora-date-picker--error'
          : '',
        variant,
        size
      )}
    >
      {label && (
        <InputLabel
          className={mergeClassNames(
            { 'text-white': variant === 'secondary' },
            'text-xsm'
          )}
          label={label}
          isRequired={isRequired}
          isTouched={isTouched}
          isValid={isValid}
        />
      )}

      <DatePicker
        disabled={disabled}
        picker={picker}
        allowClear={allowClear}
        value={value}
        onChange={onChange}
        disabledDate={disabledDate}
        onBlur={onBlur}
        suffixIcon={
          <img
            className="agora-date-picker-calendar-icon"
            src={calendarIcon}
            alt="Press to expand the date picker."
          />
        }
      />

      {!isValid && isTouched && errorText && (
        <AgoraInputError errorText={errorText} />
      )}
    </div>
  );
};

export default AgoraDatePicker;
