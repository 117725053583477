import NextMeeting from '@components/V3/NextMeeting';
import { MeetingWithHomework } from '@shared/common';
import React from 'react';
import './meeting-needing-feedback-student.styles.scss';

interface Props {
  meeting: MeetingWithHomework;
}

const MeetingNeedingFeedbackStudent: React.FC<Props> = ({ meeting }) => {
  const { mentor } = meeting;

  return (
    <NextMeeting
      meetingNeedingFeedbackStudent={true}
      meeting={meeting}
      otherName={mentor.fullName}
      isMentor={false}
      hideJoinBtn
    />
  );
};

export default MeetingNeedingFeedbackStudent;
