import { VIRTUAL_BACKGROUND_NOT_SUPPORTED_REASON } from '@modules/MeetingVideo/utils/constants';
import { isCameraHDCapable } from '@modules/MeetingVideo/utils/helpers';
import { Participant, Stream } from '@zoom/videosdk';

export const findParticipantsDifference = (
  pastParticipants: Participant[],
  newParticipants: Participant[]
) => {
  const pastParticipantsIdSet = new Set(
    pastParticipants.map((item) => item.userId)
  );
  const newParticipantsIdSet = new Set(
    newParticipants.map((item) => item.userId)
  );

  const addedParticipants = newParticipants.filter(
    (item) => !pastParticipantsIdSet.has(item.userId)
  );

  const removedParticipants = pastParticipants.filter(
    (item) => !newParticipantsIdSet.has(item.userId)
  );

  return { addedParticipants, removedParticipants };
};

interface StartVideoProps {
  stream?: typeof Stream;
  cameraId?: string;
  virtualBackground?: string;
  onFailure?: (error: any) => void;
  onSuccess?: () => void;
}

export const startVideo = async (props: StartVideoProps) => {
  const { stream, cameraId, virtualBackground, onFailure, onSuccess } = props;

  if (!stream || !cameraId) return;

  let isNewCameraHdCapable;

  try {
    isNewCameraHdCapable = await isCameraHDCapable(cameraId);

    await stream.startVideo({
      cameraId: cameraId,
      hd: isNewCameraHdCapable && stream.isSupportHDVideo(),
      mirrored: true,
      virtualBackground: stream.isSupportVirtualBackground()
        ? { imageUrl: virtualBackground }
        : undefined,
    });

    onSuccess?.();
  } catch (error: any) {
    if (error.reason === VIRTUAL_BACKGROUND_NOT_SUPPORTED_REASON) {
      try {
        await stream.startVideo({
          cameraId: cameraId,
          hd: isNewCameraHdCapable && stream.isSupportHDVideo(),
          mirrored: true,
        });
      } catch (error) {
        onFailure?.(error);
        throw new Error('Could not start video.');
      }
    } else {
      onFailure?.(error);
      throw new Error('Could not start video.');
    }
  }
};
