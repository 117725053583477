import { DatePicker } from 'antd';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import { Moment } from 'moment';
import './RangePicker.scss';
import { ReactNode } from 'react';
import InputError from '../Inputs/InputError';
import InputLabel from '../Inputs/InputLabel';

interface RangePickerProps extends BasicProps {
  label?: string;
  variant?: 'primary' | 'secondary';
  allowClear?: boolean;
  suffixIcon?: ReactNode;
  value: [Moment, Moment] | null;
  isValid?: boolean;
  isTouched?: boolean;
  errorText?: string;
  disabled?: boolean;
  onChange: (arr: any) => void;
}

const RangePicker = (props: RangePickerProps) => {
  const {
    value,
    className,
    variant = 'primary',
    label,
    allowClear = true,
    suffixIcon,
    isValid,
    isTouched,
    errorText,
    onChange,
    ...rest
  } = props;

  return (
    <div className="custom-range-picker">
      {!!label && <InputLabel className="text-xsm" label={label} />}
      <DatePicker.RangePicker
        {...rest}
        className={mergeClassNames(variant, className)}
        suffixIcon={suffixIcon}
        allowClear={allowClear}
        value={value}
        onChange={onChange}
      />
      {!isValid && isTouched && errorText && (
        <InputError errorText={errorText} />
      )}
    </div>
  );
};

export default RangePicker;
