import Button from '@components/V4/Button';
import { SESSION_STATUSES, SessionStatus } from '@shared/constants';
import moment from 'moment';
import SessionDetailsLayoutCard from '../components/SessionDetailsLayoutCard';
import CourseAbbreviationCard from './CourseAbbreviationCard';
import DetailsHeaderParticipantsList from './DetailsHeaderParticipantsList';
import HeaderLabelAndValue from './HeaderLabelAndValue';
import { DetailsHeaderProps } from './SessionDetailsHeader';
import SessionDetailsNavigationArrows from './SessionDetailsNavigationArrows';
import SessionSummaryCard from './SessionSummaryCard';
import { convertSecondsToTime } from 'apps/agora/src/utils/helpers';

const DetailsHeaderDesktop = (props: DetailsHeaderProps) => {
  const {
    abbreviation,
    title,
    sessionSummary,
    agenda,
    scheduledDate,
    endDate,
    mentor,
    student,
    accentColor,
    nextSession,
    prevSession,
    meetingDuration,
    sessionStatus,
    setIsMentorFeedbackOpen,
    onMeetingModalOpen,
  } = props;

  return (
    <SessionDetailsLayoutCard className="hidden gap-8 tablet:flex">
      <div className="flex flex-col gap-4">
        <div className="flex gap-8">
          <CourseAbbreviationCard
            abbreviation={abbreviation}
            accentColor={accentColor}
          />
          <div className="flex flex-col gap-4 w-full">
            <div className="flex justify-between">
              <div className="flex gap-4">
                <h1
                  className="text-3.5xl font-raleway font-semibold"
                  style={{ color: accentColor }}
                >
                  {title}
                </h1>

                <div className="flex items-center gap-2">
                  <DetailsHeaderParticipantsList
                    mentor={mentor}
                    student={student}
                    accentColor={accentColor}
                  />
                </div>
              </div>

              <SessionDetailsNavigationArrows
                nextSession={nextSession}
                prevSession={prevSession}
              />
            </div>

            <div className="flex flex-col gap-3">
              {!!endDate && (
                <HeaderLabelAndValue
                  label="End Date:"
                  value={moment(endDate).format('DD.MM.YYYY, HH:mm')}
                />
              )}

              {!endDate && !!scheduledDate && (
                <HeaderLabelAndValue
                  label="Scheduled Date:"
                  value={moment(scheduledDate).format('DD.MM.YYYY, HH:mm')}
                />
              )}

              {!endDate && !scheduledDate && (
                <Button
                  buttonText="Schedule Meeting"
                  className="w-36"
                  onClick={onMeetingModalOpen}
                />
              )}

              {!!sessionStatus && (
                <HeaderLabelAndValue
                  label="Session Status:"
                  value={SESSION_STATUSES[sessionStatus]}
                />
              )}

              {!!meetingDuration && (
                <HeaderLabelAndValue
                  label="Meeting Duration:"
                  value={convertSecondsToTime(meetingDuration ?? 0)}
                />
              )}

              {!!agenda && (
                <HeaderLabelAndValue label="Agenda:" value={agenda} />
              )}
            </div>
          </div>
        </div>
      </div>

      <SessionSummaryCard
        needsFeedback={sessionStatus === SessionStatus.NEEDS_FEEDBACK}
        sessionSummary={sessionSummary}
        accentColor={accentColor}
        setIsMentorFeedbackOpen={setIsMentorFeedbackOpen}
      />
    </SessionDetailsLayoutCard>
  );
};

export default DetailsHeaderDesktop;
