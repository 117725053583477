import styles from '@styles/components/form.module.scss';
import { ChangeEvent } from 'react';
import { COLORS } from '../../v3/global';

const FormInput = ({
  value,
  title,
  disabled = false,
  type = 'text',
  placeholder = '',
  error,
  success,
  style,
  className,
  onChange,
  onBlur,
  ...rest
}: {
  title?: string;
  value: string;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  error?: boolean;
  success?: boolean;
  style?: React.CSSProperties;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  [x: string]: any;
}) => {
  return (
    <div
      className={`${styles.formInput} ${error ? styles.formInputError : ''} ${
        success ? styles.formInputSuccessBorder : ''
      }`}
    >
      {title && (
        <label htmlFor={title} style={{ color: COLORS.TEXT_PRIMARY }}>
          {title}
        </label>
      )}
      <input
        type={type}
        value={value}
        disabled={disabled}
        name={title}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        style={style}
        className={className}
        {...rest}
      />
    </div>
  );
};

export default FormInput;
