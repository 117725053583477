import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import { forwardRef } from 'react';

const VideoPlayerContainer = forwardRef((props: BasicProps, ref: any) => {
  const { className, children } = props;

  return (
    <video-player-container className={mergeClassNames(className)} ref={ref}>
      {children}
    </video-player-container>
  );
});

export default VideoPlayerContainer;
