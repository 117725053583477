import { message } from 'antd';
import axios from 'axios';
import authHeader from '../../../services/auth-header';

const IS_FREE = false; // constant due to current business model

const edit = ({ content, title, courseId, lessonId }: any) => {
  axios
    .post(
      `/api/lesson/edit/${lessonId}`,
      {
        title,
        content,
        isFree: IS_FREE,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      message.success('Lesson saved.');
    })
    .catch((error) => {
      message.error(error.response?.data?.message || error?.message);
      message.error('An error occurred. Check console for details.');
      console.error(error);
    });
};

const add = ({ content, title, courseId, moduleId }: any) => {
  axios
    .post(
      '/api/lesson/new',
      {
        title,
        content,
        isFree: IS_FREE,
        module: moduleId,
        parentCourse: courseId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      message.success(response.data.message);
      setTimeout(() => {
        window.location.href = `/admin/lesson/edit-lesson/${courseId}/${response.data.id}/${moduleId}`;
      }, 1000);
    })
    .catch((error) => {
      message.error(error.response?.data?.message || error?.message);
      message.error('An error occurred. Check console for details.');
      console.error(error);
    });
};

const get = async ({ lessonId }: any) => {
  const response = await axios.get(`/api/page/${lessonId}?refType=Lesson`, {
    headers: authHeader(),
  });

  return {
    title: response.data.title,
    content: response.data.content,
  };
};

const deleteEverywhere = async ({ lessonId, courseId, moduleId }: any) => {
  await axios.post(
    `/api/lesson/delete`,
    { lessonId, courseId, moduleId },
    { headers: authHeader() }
  );
};

const LessonCRUD = { edit, add, get, deleteEverywhere };

export default LessonCRUD;
