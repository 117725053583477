import { CloseModalProps } from '@shared/types';
import { Modal } from 'antd';
import ChecklistEditor from './ChecklistEditor';

type Props = CloseModalProps & {
  checklistId: string;
};

const ChecklistEditorModal = ({ checklistId, visible, hide }: Props) => {
  return (
    <Modal
      title="Edit Checklist"
      visible={visible}
      onCancel={hide}
      footer={null}
    >
      <ChecklistEditor
        defaultId={checklistId}
        isAdmin={false}
        showLayout={false}
      />
    </Modal>
  );
};

export default ChecklistEditorModal;
