import { useEffect, useRef } from 'react';

type UseObserveElementOptions = {
  id: string;
  threshold?: number;
  onIntersect?: () => void;
};

const useObserveElement = ({
  id,
  threshold = 0.5,
  onIntersect,
}: UseObserveElementOptions) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const observerOptions = {
      root: container,
      threshold: threshold,
    };

    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.target.id === id) {
          onIntersect?.();
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    const element = container.querySelector(`#${id}`);

    if (element) {
      observer.observe(element);
    }

    return () => {
      observer.disconnect();
    };
  }, [id, threshold]);

  return containerRef;
};

export default useObserveElement;
