import AgoraModal from '@utils/AgoraModal';
import type { RadioChangeEvent } from 'antd';
import { message, Radio, Space } from 'antd';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './user-status-modal.styles.scss';
import AgoraInput from '@utils/AgoraInput';
import SecondaryButton from '@utils/SecondaryButton';
import { useSetActiveStatus } from '@shared/react';

type Props = {
  showModal: boolean;
  handleShowModal: () => void;
  title: string;
  user: any;
  refetch: () => any;
};

const UserStatusModal = ({
  showModal,
  handleShowModal,
  title,
  user,
  refetch,
}: Props) => {
  const { mutate: deactivateUser, isLoading } = useSetActiveStatus({
    onSuccess: (data, variables) => {
      message.success(
        `User ${
          variables.deactivate ? 'deactivated' : 'activated'
        } successfully`
      );
      refetch();
      handleShowModal();
    },
  });

  const [reason, setReason] = useState<string | undefined>(undefined);
  const [otherReason, setOtherReason] = useState<string | undefined>(undefined);

  const onChange = (e: RadioChangeEvent) => {
    setReason(e.target.value);
    setOtherReason(undefined);
  };

  return (
    <div>
      <AgoraModal
        isModalOpen={showModal}
        setModalOpen={handleShowModal}
        title={title}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ marginTop: '80px', marginBottom: '24px' }}>
            <h3
              style={{ color: COLORS.TEXT_SECONDARY }}
              className="deactivate-account-text"
            >
              Please provide a reason for deactivating this account:
            </h3>
          </div>

          <div style={{ marginBottom: '8px' }}>
            <Radio.Group
              onChange={onChange}
              value={reason}
              buttonStyle="solid"
              id="deactivate-account"
              className="deactivate-account-radio"
            >
              <Space direction="vertical">
                <Radio
                  value={'Signed but never payed'}
                  style={{
                    color:
                      reason === 'Signed but never payed'
                        ? COLORS.PRIMARY
                        : COLORS.TEXT_SECONDARY,
                  }}
                  id="deactivate-account"
                  className="deactivate-account-radio deactivate-account-select-text"
                >
                  Signed but never payed
                </Radio>
                <Radio
                  value={'Delinquent'}
                  style={{
                    color:
                      reason === 'Delinquent'
                        ? COLORS.PRIMARY
                        : COLORS.TEXT_SECONDARY,
                  }}
                  className="deactivate-account-select-text"
                >
                  Delinquent
                </Radio>
                <Radio
                  value={'Churned'}
                  style={{
                    color:
                      reason === 'Churned'
                        ? COLORS.PRIMARY
                        : COLORS.TEXT_SECONDARY,
                  }}
                  className="deactivate-account-select-text"
                >
                  Churned
                </Radio>
                <Radio
                  value={'Left'}
                  style={{
                    color:
                      reason === 'Left'
                        ? COLORS.PRIMARY
                        : COLORS.TEXT_SECONDARY,
                  }}
                  className="deactivate-account-select-text"
                >
                  Left
                </Radio>
                <Radio
                  value={'Fired'}
                  style={{
                    color:
                      reason === 'Fired'
                        ? COLORS.PRIMARY
                        : COLORS.TEXT_SECONDARY,
                  }}
                  className="deactivate-account-select-text"
                >
                  Fired
                </Radio>
                <Radio
                  value={'On a break'}
                  style={{
                    color:
                      reason === 'On a break'
                        ? COLORS.PRIMARY
                        : COLORS.TEXT_SECONDARY,
                  }}
                  className="deactivate-account-select-text"
                >
                  On a break
                </Radio>
                <Radio
                  value={'Other'}
                  style={{
                    color:
                      reason === 'Other'
                        ? COLORS.PRIMARY
                        : COLORS.TEXT_SECONDARY,
                  }}
                  className="deactivate-account-select-text"
                >
                  Other
                </Radio>
              </Space>
            </Radio.Group>
          </div>

          <div style={{ height: '38px' }}>
            {reason === 'Other' && (
              <AgoraInput
                htmlFor="reason"
                type="text"
                name="reason"
                id="reason"
                placeholder="Please provide a reason"
                label=""
                value={otherReason}
                onChange={(e) => {
                  setOtherReason(e.target.value);
                }}
              />
            )}
          </div>

          <div style={{ marginTop: '78px', height: '36px' }}>
            {!!reason && (
              <SecondaryButton
                onClick={() => {
                  deactivateUser({
                    userId: user._id,
                    deactivate: !user.isDezactivated,
                    reason: otherReason ? otherReason : reason,
                  });
                }}
                backgroundColor={COLORS.WHITE}
                margin="0px"
                width="100%"
              >
                <span style={{ color: COLORS.BLACK }}>Deactivate Account</span>
              </SecondaryButton>
            )}
          </div>
        </div>
      </AgoraModal>
    </div>
  );
};

export default UserStatusModal;
