import { HOME, MENTOR_HOME } from '@routes';
import { MENTOR_ROLE } from '@shared/common';
import { useAuthState } from '@shared/react';
import styles from '@styles/components/sider.module.scss';
// import { useThemeSwitcher } from 'react-css-theme-switcher';
import { Link } from 'react-router-dom';

type Props = {
  width?: string;
  height?: string;
};

const LogoButton = ({ width, height }: Props) => {
  const { roles } = useAuthState();
  const isMentor = roles.includes(MENTOR_ROLE);
  // const { currentTheme } = useThemeSwitcher();

  const HOME_PATH = isMentor ? MENTOR_HOME : HOME;

  return (
    <Link to={HOME_PATH}>
      <img
        // src={
        //   currentTheme === 'light'
        //     ? '../../assets/ue_logo_small_dark.svg'
        //     : '../../assets/ue_logo_small_light.svg'
        // }
        src={'../../assets/ue_logo_small_light.svg'}
        alt="logo"
        className={styles.logo}
        width={width || '80px'}
        height={height || '40px'}
      />
    </Link>
  );
};

export default LogoButton;
