import Copy from '@assets/icons/copy.svg';
import Copy2 from '@assets/icons/copy2.svg';
import HarvesterImage from '@assets/images/harvester-banner-image.png';
import { BASE_URL } from '@shared/frontendEnv';
import { useAuthState } from '@shared/react';
import { message } from 'antd';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './harvester-banner.styles.scss';

const HarvesterBanner = () => {
  const user = useAuthState();
  const harvesterLink = `${BASE_URL}/referral/${user?.harvesterToken}`;

  const [isHovered, setIsHovered] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(harvesterLink)
      .then(() => {
        message.success('Link copied to clipboard!');
      })
      .catch((err) => {
        message.error('Failed to copy the link.');
      });
  };

  return (
    <div className="harvester-banner-container">
      <div
        style={{
          backgroundColor: COLORS.COMPONENT_BACKGROUND,
        }}
        className="harvester-banner-inner"
      >
        {/*IMAGE*/}
        <div className="harvester-banner-image">
          <img
            style={{ height: '98px', minWidth: '98px', maxWidth: '98px' }}
            src={HarvesterImage}
            alt="banner-image"
          />
        </div>
        {/*CONTENT*/}
        <div>
          {/*BANNER TITLE*/}
          <div style={{ marginBottom: '8px' }}>
            <h2 style={{ color: COLORS.TEXT_PRIMARY }} className="banner-title">
              Get referrals from your students!
            </h2>
          </div>
          {/*BANNER DESCRIPTION*/}
          <div style={{ marginBottom: '12px' }}>
            <p
              style={{ color: COLORS.TEXT_PRIMARY }}
              className="banner-description"
            >
              Get referrals from your students by providing them with this link.
              If the referred student signs a contract with us, you will be
              rewarded with 200EUR per new client. Your students or their
              parents should fill in the information within this link, we'll
              take it from there and notify you when the new client signed.
            </p>
          </div>
          {/*BANNER LINK*/}
          <div
            className="banner-link-container"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <span
              style={{ color: COLORS.TEXT_PRIMARY, marginRight: '8px' }}
              className="banner-description"
            >
              {harvesterLink}
            </span>
            <span
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              style={{
                height: '28px',
                width: '24px',
                cursor: 'pointer',
              }}
              onClick={handleCopyClick}
            >
              <img
                src={isHovered ? Copy2 : Copy}
                style={{
                  height: 'auto',
                  minWidth: '24px',
                  maxWidth: '24px',
                }}
                alt="copy"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HarvesterBanner;
