import { videoApis } from '@shared/apis';
import { genericMutationWithId, genericQueryWithId } from './generics';

export const useGetMeetingDetails =
  genericQueryWithId<videoApis.GetWaitingScreenMeetingDetails>(
    videoApis.getWaitingScreenMeetingDetails
  );

export const useGetHasMentorEntered =
  genericQueryWithId<videoApis.GetHasMentorEntered>(
    videoApis.getHasMentorEntered
  );

export const useStartMeeting = genericMutationWithId<videoApis.StartMeeting>(
  videoApis.startMeeting
);

export const useEndMeeting = genericMutationWithId<videoApis.EndMeeting>(
  videoApis.endMeeting
);
