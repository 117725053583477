import { ReactComponent as FlagIcon } from '@assets/icons/V4/flag-icon.svg';
import { ReactComponent as NoDataIcon } from '@assets/icons/V4/no-data.svg';
import { ReactComponent as ResumeIcon } from '@assets/icons/V4/resume-icon.svg';
import { ReactComponent as SettingsIcon } from '@assets/icons/V4/settings-icon.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/V4/trash-icon.svg';
import IconButton from '@components/V4/IconButton';
import Table from '@components/V4/Table/Table';
import { SessionStatus } from '@shared/constants';
import {
  useEndEnrollment,
  useResumeEnrollment,
  useUnenrollUser,
} from '@shared/react';
import useClientPagination from 'apps/agora/src/hooks/useClientPagination';
import useToast from 'apps/agora/src/hooks/useToast';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import SessionModalSection, {
  ActionButton,
} from '../../../SessionModalSection';
import { StudentDetailsPageContext } from '../../StudentDetailsPage';
import EnrollmentForm from './EnrollmentForm';

interface EnrollmentsTabProps {
  areEnrollmentsActive?: boolean;
}

const EnrollmentsTab = (props: EnrollmentsTabProps) => {
  const { areEnrollmentsActive = false } = props;

  const [isAddingEnrollment, setIsAddingEnrollment] = useState(false);
  const [showToast] = useToast();

  const {
    student,
    courses,
    onEnrollmentModalOpen,
    onSessionModalOpen,
    onMentorModalOpen,
  } = useContext(StudentDetailsPageContext);

  const enrollments = useMemo(
    () =>
      student?.enrollments?.filter((enrollment) =>
        areEnrollmentsActive ? !enrollment.ended : enrollment.ended
      ) || [],

    [student?.enrollments, areEnrollmentsActive]
  );

  const [paginatedEnrollments, pagination] = useClientPagination({
    data: enrollments,
    shouldOnlyShowPages: true,
  });

  const { mutate: endEnrollment, isLoading: isEndingEnrollment } =
    useEndEnrollment({
      onSuccess: () => {
        showToast({
          variant: 'info',
          messageBody: 'Enrollment ended successfully.',
        });
      },
      onError: (error: any) => {
        showToast({
          variant: 'error',
          messageBody: error?.response?.data?.error?.message,
        });
      },
    });

  const { mutate: unenrollUser, isLoading: isEnrollmentDeleting } =
    useUnenrollUser({
      onSuccess: () => {
        showToast({
          variant: 'info',
          messageBody: 'Enrollment deleted successfully.',
        });
      },
      onError: (error: any) => {
        showToast({
          variant: 'error',
          messageBody: error?.response?.data?.error?.message,
        });
      },
    });

  const { mutate: resumeEnrollment, isLoading: isResumingEnrollment } =
    useResumeEnrollment({
      onSuccess: () => {
        showToast({
          variant: 'info',
          messageBody: 'Enrollment resumed successfully.',
        });
      },
      onError: (error: any) => {
        showToast({
          variant: 'error',
          messageBody: error?.response?.data?.error?.message,
        });
      },
    });

  useEffect(() => {
    setIsAddingEnrollment(false);
  }, [areEnrollmentsActive]);

  const getSectionButtons = () => {
    const buttons: ActionButton[] = [];

    if (areEnrollmentsActive) {
      buttons.push({
        buttonText: 'Add Enrollment',
        onClick: () => setIsAddingEnrollment(true),
      });
    }

    return buttons;
  };

  const getLastCompletedSessionCell = (enrollment: any) => {
    const filteredSessions = enrollment.sessions.filter((session: any) => {
      const lastStatus = session.statuses[session.statuses.length - 1].value;

      return lastStatus !== SessionStatus.PENDING;
    });

    const sortedSessions = filteredSessions.sort((a: any, b: any) => {
      const dateA = moment(a.relevantMeetingInfo?.startDate);
      const dateB = moment(b.relevantMeetingInfo?.startDate);

      return dateB.diff(dateA);
    });

    if (sortedSessions[0]) {
      return {
        text: moment(sortedSessions[0].relevantMeetingInfo?.startDate).format(
          'DD.MM.YYYY'
        ),
        onClick: () => onSessionModalOpen(sortedSessions[0]._id),
      };
    }

    return { text: 'n/a' };
  };

  const endClickHandler = (enrollmentId: string) => {
    endEnrollment({ enrollmentId });
  };

  const resumeClickHandler = (enrollmentId: string) => {
    resumeEnrollment({ enrollmentId });
  };

  const deleteClickHandler = (enrollmentId: string) => {
    unenrollUser({ enrollmentId });
  };

  const isLoading =
    isEndingEnrollment || isResumingEnrollment || isEnrollmentDeleting;

  return isAddingEnrollment ? (
    <EnrollmentForm
      onCloseEditForm={() => setIsAddingEnrollment(false)}
      courses={courses}
    />
  ) : (
    <SessionModalSection
      className="mt-6"
      sectionTitle="Enrolled In"
      buttonsArray={getSectionButtons()}
    >
      {enrollments?.length ? (
        <Table size="small" pagination={pagination}>
          <Table.Header>
            <Table.Row isHeader>
              <Table.HeaderCell>Enrollment</Table.HeaderCell>
              <Table.HeaderCell>Sessions</Table.HeaderCell>
              <Table.HeaderCell>Mentor</Table.HeaderCell>
              <Table.HeaderCell>Last Session</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {paginatedEnrollments.map((enrollment) => (
              <Table.Row key={enrollment._id}>
                <Table.Cell
                  onClick={() => onEnrollmentModalOpen(enrollment._id ?? '')}
                >
                  <div className="flex items-center gap-2">
                    <span
                      className="w-[9px] h-[9px] rounded-full"
                      style={{ backgroundColor: enrollment.course?.color }}
                    />
                    {enrollment?.course?.title}
                  </div>
                </Table.Cell>
                <Table.Cell>{`${enrollment?.noOfCompletedSessions}/${enrollment?.noOfSessions}`}</Table.Cell>
                <Table.Cell
                  onClick={
                    enrollment?.mentor?._id
                      ? () => onMentorModalOpen(enrollment?.mentor)
                      : undefined
                  }
                >
                  {enrollment?.mentor?.fullName ?? 'n/a'}
                </Table.Cell>
                <Table.Cell
                  onClick={getLastCompletedSessionCell(enrollment)?.onClick}
                >
                  {getLastCompletedSessionCell(enrollment).text}
                </Table.Cell>
                <Table.Cell>
                  <div className="flex gap-4">
                    <IconButton
                      isDisabled={isLoading}
                      size="small"
                      variant="ghost"
                      icon={<SettingsIcon />}
                      onClick={() =>
                        onEnrollmentModalOpen(enrollment._id ?? '')
                      }
                    />
                    {areEnrollmentsActive ? (
                      <IconButton
                        isDisabled={isLoading}
                        size="small"
                        variant="ghost"
                        color="danger"
                        icon={<FlagIcon />}
                        onClick={() => endClickHandler(enrollment._id ?? '')}
                      />
                    ) : (
                      <IconButton
                        isDisabled={isLoading}
                        size="small"
                        variant="ghost"
                        icon={<ResumeIcon />}
                        onClick={() => resumeClickHandler(enrollment._id ?? '')}
                      />
                    )}
                    <IconButton
                      isDisabled={isLoading}
                      size="small"
                      variant="ghost"
                      color="danger"
                      icon={<TrashIcon />}
                      onClick={() => deleteClickHandler(enrollment._id ?? '')}
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <EmptyStateCard
          text="No Existing Data"
          icon={<NoDataIcon className="text-customGrey" />}
        />
      )}
    </SessionModalSection>
  );
};

export default EnrollmentsTab;
