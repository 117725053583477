import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import TableColumnSortButtons from '@components/V3/TableColumnSortButtons';
import TableColumns from '@components/V3/TableColumns';
import UsersTableHeader from '@components/V3/UsersTableHeader';
import UsersTableRow from '@components/V3/UsersTableRow';
import AgoraSpinner from '@components/V3/Utils/AgoraSpinner';
import { useSearchUsersV2 } from '@shared/react';
import { Pagination } from 'antd';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './users-v3.styles.scss';

const UsersV3 = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState<'fullName' | 'createdAt' | undefined>(
    undefined
  );
  const [sortDirectionFullName, setSortDirectionFullName] = useState<
    'asc' | 'desc' | undefined
  >(undefined);
  const [sortDirectionCreatedAt, setSortDirectionCreatedAt] = useState<
    'asc' | 'desc' | undefined
  >(undefined);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState();
  const [roles, setRoles] = useState();
  const [showTestUsers, setShowTestUsers] = useState<
    'checked' | 'unchecked' | 'indeterminate'
  >('indeterminate');

  const {
    data: usersData,
    isLoading: isLoadingSearchUsers,
    refetch: refetchUsers,
  } = useSearchUsersV2({
    search: search,
    page: currentPage,
    limit: pageSize,
    sortBy: sortBy,
    showTestUsers,
    sortDirection:
      sortBy === 'fullName'
        ? sortDirectionFullName
        : sortBy === 'createdAt'
        ? sortDirectionCreatedAt
        : undefined,
    status: status,
    roles: roles,
  });

  const handleSearch = (search: string) => {
    setSearch(search);
    setCurrentPage(1);
  };

  const handleFilterBy = (status: any) => {
    setStatus(status);
    setCurrentPage(1);
  };

  const handleRoles = (role: any) => {
    setRoles(role);
    setCurrentPage(1);
  };

  return (
    <div
      style={{
        backgroundColor: COLORS.SECTION_BACKGROUND,
        color: COLORS.TEXT_PRIMARY,
        minHeight: 'calc(100vh - 74px)',
      }}
    >
      <UsersTableHeader
        showTestUsers={showTestUsers}
        onShowTestUsersChange={setShowTestUsers}
        handleSearch={handleSearch}
        handleFilterBy={handleFilterBy}
        handleRoles={handleRoles}
      />

      <div>
        <div className="table-container">
          {/*TABLE COLUMNS*/}
          <div style={{ marginBottom: '16px' }}>
            <TableColumns>
              <div className="table-columns-container">
                {/*NAME*/}
                <div
                  className="table-filter-columns-container"
                  style={{ width: '14%', marginRight: '1%' }}
                  onClick={() => {
                    setSortBy('fullName');
                    setSortDirectionCreatedAt(undefined);
                    if (sortDirectionFullName === 'asc') {
                      setSortDirectionFullName('desc');
                    } else if (sortDirectionFullName === 'desc') {
                      setSortDirectionFullName(undefined);
                      setSortBy(undefined);
                    } else {
                      setSortDirectionFullName('asc');
                    }
                  }}
                >
                  <span style={{ marginRight: '8px' }}>Name</span>
                  <TableColumnSortButtons sortType={sortDirectionFullName} />
                </div>
                {/*MAIL*/}
                <div style={{ width: '24%', marginRight: '1%' }}>
                  <span>Mail</span>
                </div>
                {/*ROLE*/}
                <div style={{ width: '10%', marginRight: '4%' }}>
                  <span>Role</span>
                </div>
                {/*CREATION DATE*/}
                <div
                  className="table-filter-columns-container"
                  style={{ width: '17%', marginRight: '1%' }}
                  onClick={() => {
                    setSortBy('createdAt');
                    setSortDirectionFullName(undefined);
                    if (sortDirectionCreatedAt === 'asc') {
                      setSortDirectionCreatedAt('desc');
                    } else if (sortDirectionCreatedAt === 'desc') {
                      setSortDirectionCreatedAt(undefined);
                      setSortBy(undefined);
                    } else {
                      setSortDirectionCreatedAt('asc');
                    }
                  }}
                >
                  <span style={{ marginRight: '8px' }}>Creation Date</span>
                  <TableColumnSortButtons sortType={sortDirectionCreatedAt} />
                </div>

                {/*ACTIONS*/}
                <div style={{ width: '28%' }}>
                  <span>Actions</span>
                </div>
              </div>
            </TableColumns>
          </div>

          {/*TABLE ROWS*/}
          {!isLoadingSearchUsers ? (
            usersData?.users?.map((item) => (
              <UsersTableRow
                userId={item?._id}
                key={item?._id}
                userData={item}
                refetchSearchUsers={refetchUsers}
              />
            ))
          ) : (
            <AgoraSpinner fontSize={32} margin="32px" />
          )}
        </div>

        {/*PAGINATION*/}
        <div
          style={{
            width: '100%',
            padding: '24px 24px 120px 24px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <span
              style={{ color: COLORS.TEXT_SECONDARY, marginRight: '8px' }}
              className="flags-table-pagination-label"
            >
              Rows per page
            </span>
            <select
              onChange={(e) => {
                const selectedValue = e.target.value;
                setPageSize(Number(selectedValue));
              }}
              className="mentors-row-custom-select-component pagination-select-container"
              style={{
                borderColor: COLORS.TEXT_PRIMARY,
                color: COLORS.TEXT_PRIMARY,
                backgroundColor: COLORS.SECTION_BACKGROUND,
              }}
              defaultValue={10}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={usersData?.total}>All</option>
            </select>
          </div>

          <div
            style={{ color: COLORS.TEXT_SECONDARY }}
            className="mentors-table-pagination-label"
          >
            {`${(currentPage - 1) * pageSize + 1}-${Math.min(
              currentPage * pageSize,
              usersData?.total || 0
            )} of ${usersData?.total || 0}`}
          </div>

          {/*PAGINATION NAVIGATOR*/}
          <div>
            <Pagination
              className="custom-pagination"
              showSizeChanger={false}
              current={currentPage}
              defaultCurrent={1}
              total={usersData?.total}
              pageSize={pageSize}
              onChange={(page) => setCurrentPage(page)}
              prevIcon={
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <LeftOutlined
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                    }}
                  />
                </div>
              }
              nextIcon={
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <RightOutlined
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                    }}
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersV3;
