import { message } from 'antd';
import axios from 'axios';
import authHeader from '../../../services/auth-header';

const create = async ({ title, keywords, body }) => {
  const article = await axios.post(
    '/api/knowledge-base/create',
    { title, keywords, body },
    { headers: authHeader() }
  );
  message.success('Article created.');
  return article;
};

const read = async (id) => {
  const article = await axios.get(`/api/knowledge-base/${id}`, {
    headers: authHeader(),
  });
  return article;
};

const update = async (id, data) => {
  const article = await axios.post(
    `/api/knowledge-base/${id}/update`,
    { ...data },
    { headers: authHeader() }
  );
  message.success('Article updated.');
  return article;
};

const remove = async (id) => {
  await axios.delete(`/api/knowledge-base/${id}/delete`, {
    headers: authHeader(),
  });
  message.success('Article deleted.');
};

const like = async (id) => {
  await axios.post(
    `/api/knowledge-base/${id}/like`,
    {},
    {
      headers: authHeader(),
    }
  );
};

const dislike = async (id) => {
  await axios.post(
    `/api/knowledge-base/${id}/dislike`,
    {},
    {
      headers: authHeader(),
    }
  );
};

const ArticleCRUD = { create, read, update, remove, dislike, like };

export default ArticleCRUD;
