import { MentorSelector } from '@components';
import { useEnrollment, useUpdateEnrollment } from '@shared/react';
import { Form, message } from 'antd';
import React from 'react';

type Props = { enrollmentId: string };

const UpdateMentor: React.FC<Props> = ({ enrollmentId }) => {
  const [form] = Form.useForm();

  const { data: enrollment, refetch } = useEnrollment(enrollmentId);
  const { mutate: updateEnrollment, isLoading: updateEnrollmentLoading } =
    useUpdateEnrollment({
      onSuccess: () => {
        message.success('Mentor updated');
        refetch();
      },
    });

  if (!enrollment) {
    return null;
  }

  const handleUpdate = () => {
    const { mentor } = form.getFieldsValue();
    const choice = mentor.choice?.value || '';

    if (choice === 'USER' || choice === 'TOP') {
      return updateEnrollment({
        enrollmentId: enrollmentId,
        mentorId: mentor.user.value,
      });
    }
    if (choice === 'TBD') {
      return updateEnrollment({ enrollmentId: enrollmentId, mentorId: 'TBD' });
    }
  };

  const initialMentor = {
    choice: { value: 'USER' },
    user: {
      value: enrollment?.mentor?._id || '',
      label: enrollment?.mentor?.fullName || 'No mentor, choose here',
    },
  };

  return (
    <Form form={form} initialValues={{ ...enrollment, mentor: initialMentor }}>
      <Form.Item name="mentor">
        <MentorSelector
          courseId={enrollment?.course._id}
          disabled={updateEnrollmentLoading}
          onChange={handleUpdate}
        />
      </Form.Item>
    </Form>
  );
};

export default UpdateMentor;
