import { ReactComponent as BackSignIcon } from '@assets/icons/back-sign.svg';
import Button from '@components/V4/Button';
import Modal from '@components/V4/Modal/Modal';
import { useEnrollment } from '@shared/react';
import EnrollmentDetailsSection from './EnrollmentDetailsSection/EnrollmentDetailsSection';
import SessionsListSection from './SessionsListSection/SessionsListSection';

interface EnrollmentDetailsPageProps {
  enrollmentId: string;
  isBackButtonActive?: boolean;
  onGoBack?: () => void;
  onSessionModalOpen: (session: any) => void;
}

const EnrollmentDetailsPage = (props: EnrollmentDetailsPageProps) => {
  const {
    enrollmentId,
    isBackButtonActive = false,
    onSessionModalOpen,
    onGoBack,
  } = props;
  const { data: enrollment } = useEnrollment(enrollmentId);

  return (
    <>
      <Modal.Header className="relative font-raleway text-xl font-bold text-center mb-6">
        {isBackButtonActive && (
          <Button
            icon={<BackSignIcon />}
            className="absolute top-0 left-0"
            variant="secondary"
            onClick={onGoBack}
            buttonText="Back"
          />
        )}
        <h3>Enrollment Details</h3>
        <h3>{`${enrollment?.mentor?.fullName ?? 'n/a'} / ${
          enrollment?.user?.fullName ?? 'n/a'
        }`}</h3>
      </Modal.Header>
      <Modal.Body>
        <section className="flex flex-col gap-6">
          {enrollment && (
            <>
              <EnrollmentDetailsSection enrollment={enrollment} />
              <SessionsListSection
                onSessionModalOpen={onSessionModalOpen}
                enrollment={enrollment}
              />
            </>
          )}
        </section>
      </Modal.Body>
    </>
  );
};

export default EnrollmentDetailsPage;
