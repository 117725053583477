import * as react from '@shared/react';
import { Checkbox, DatePicker, Input, message, Select } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

type UserEditableFieldProps = {
  updateLocation: string;
  userId: string;
  type: 'string' | 'number' | 'dropdown' | 'checkbox' | 'date' | 'multi';
  initialValue: string | number | boolean | string[];
  dropdownOptions?: string[];
  prefix?: string;
  suffix?: string;
  multiOptions?: {
    value: string;
    text: string;
  }[];
};

const UserEditableField = (props: UserEditableFieldProps) => {
  const {
    updateLocation,
    type,
    prefix,
    suffix,
    initialValue,
    userId,
    dropdownOptions,
    multiOptions,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const { mutate } = react.useUpdateUser(userId);

  const showSavingNotification = () => {
    message.loading({
      content: 'Saving...',
      key: 'saving-notification',
      duration: 1.5,
    });
  };

  const updateInDatabase = (overrideValue?: string | number | boolean) => {
    mutate({ [updateLocation]: overrideValue ? overrideValue : value });
    showSavingNotification();
  };

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    updateInDatabase();
  }, [value]);

  const key = () => `${updateLocation}-${userId}`;

  if (type === 'checkbox' && typeof value === 'boolean') {
    return (
      <Checkbox
        defaultChecked={value}
        onChange={(e) => {
          setValue(e.target.checked);
        }}
        key={key()}
      />
    );
  }

  if (!isEditing && type === 'date') {
    return (
      <span
        key={key()}
        onClick={() => setIsEditing(true)}
        style={{
          cursor: 'pointer',
        }}
      >
        {moment(value as unknown as string).format('MM/DD/YYYY')}
      </span>
    );
  }

  if (type === 'multi' && multiOptions) {
    return (
      <Select
        mode="multiple"
        allowClear
        style={{ minWidth: '240px', maxWidth: '400px' }}
        placeholder="No interests selected"
        onChange={(value) => {
          setValue(value);
          setIsEditing(false);
        }}
        defaultValue={value}
      >
        {multiOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.text}
          </Select.Option>
        ))}
      </Select>
    );
  }

  if (!isEditing) {
    if (value) {
      return (
        <div
          onClick={() => setIsEditing(true)}
          style={{
            cursor: 'pointer',
          }}
        >
          {prefix && <span>{prefix}</span>} {value}{' '}
          {suffix && <span>{suffix}</span>}
        </div>
      );
    } else {
      setIsEditing(true);
    }
  }

  if (isEditing && type === 'date' && typeof value === 'string') {
    <DatePicker
      defaultValue={moment(value)}
      onChange={(date, dateString) => {
        setValue(dateString);
      }}
      key={key()}
    />;
  }

  if (isEditing && type === 'dropdown' && dropdownOptions) {
    return (
      <Select
        defaultValue={value}
        onChange={(value) => {
          setValue(value);
          setIsEditing(false);
        }}
        style={{ width: '100%' }}
        placeholder="Select"
      >
        {dropdownOptions.map((option) => (
          <Select.Option key={option} value={option}>
            {option}
          </Select.Option>
        ))}
      </Select>
    );
  }

  return (
    <div key={key()}>
      <Input
        defaultValue={value ? value.toString() : ''}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => {
          setIsEditing(false);
        }}
        addonBefore={prefix}
        addonAfter={suffix}
        type={type}
        autoFocus={false}
      />
    </div>
  );
};

export default UserEditableField;
