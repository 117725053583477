import {
  Participant,
  VideoTransmissionQuality,
} from '@modules/MeetingVideo/utils/types';
import { BasicProps } from 'apps/agora/src/utils/types';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import InfoItem from './InfoItem';
import ParticipantCard from './ParticipantCard';

interface ShareTabProps extends BasicProps {
  participantsList?: Participant[];
  shareQuality: Record<
    number,
    Record<'sending' | 'receiving', VideoTransmissionQuality>
  >;
  hasEmptyState?: boolean;
}

const ShareTab = (props: ShareTabProps) => {
  const { participantsList, shareQuality, hasEmptyState } = props;

  if (hasEmptyState) {
    return <EmptyStateCard text="No data available." icon={undefined} />;
  }

  return (
    <div className="flex flex-col gap-4">
      {participantsList?.map((user) => (
        <ParticipantCard key={user._id} name={user.fullName}>
          <InfoItem
            label="Average Loss (Sending)"
            value={shareQuality?.[user.zoomUserId]?.sending?.avgLoss}
          />
          <InfoItem
            label="Average Loss (Receiving)"
            value={shareQuality?.[user.zoomUserId]?.receiving?.avgLoss}
          />
          <InfoItem
            label="Maximum Loss (Sending)"
            value={shareQuality?.[user.zoomUserId]?.sending?.maxLoss}
          />
          <InfoItem
            label="Maximum Loss (Receiving)"
            value={shareQuality?.[user.zoomUserId]?.receiving?.maxLoss}
          />
          <InfoItem
            label="Resolution (Sending)"
            value={
              shareQuality?.[user.zoomUserId]?.sending?.width
                ? `${shareQuality?.[user.zoomUserId]?.sending?.width} x ${
                    shareQuality?.[user.zoomUserId]?.sending?.height
                  }`
                : 'n/a'
            }
          />
          <InfoItem
            label="Resolution (Receiving)"
            value={
              shareQuality?.[user.zoomUserId]?.receiving?.width
                ? `${shareQuality?.[user.zoomUserId]?.receiving?.width} x ${
                    shareQuality?.[user.zoomUserId]?.receiving?.height
                  }`
                : 'n/a'
            }
          />
          <InfoItem
            label="FPS (Sending)"
            value={shareQuality?.[user.zoomUserId]?.sending?.fps}
          />
          <InfoItem
            label="FPS (Receiving)"
            value={shareQuality?.[user.zoomUserId]?.receiving?.fps}
          />
        </ParticipantCard>
      ))}
    </div>
  );
};

export default ShareTab;
