interface InputErrorProps {
  errorText: string;
}

const InputError = (props: InputErrorProps) => {
  const { errorText } = props;

  return (
    <div className="mt-2 ml-2 whitespace-pre-wrap text-xxs font-semibold">
      <p className="text-customRed">{errorText}</p>
    </div>
  );
};

export default InputError;
