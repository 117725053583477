import ProfileIconPrimary from '@assets/icons/profile-icon-primary.png';
import ProfileIcon from '@assets/icons/profile-icon-white.png';
import './students-table-row.styles.scss';

type Props = {
  key?: string | number;
  studentData?: any;
  currentUserId: string;
  refetchSearchStudents: () => void;
  onProfileClick: (studentId: string) => void;
};

const StudentsTableRow = ({ studentData, onProfileClick }: Props) => {
  return (
    <div style={{ marginTop: '16px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        {/*NAME*/}
        <div
          style={{
            width: '19%',
            display: 'flex',
            alignItems: 'center',
            marginRight: '1%',
          }}
          className="flags-table-row-text"
        >
          <span>{studentData?.fullName ?? '-'}</span>
        </div>

        {/*ENROLLED IN*/}
        <div
          style={{
            width: '34%',
            marginRight: '1%',
          }}
          className="flags-table-row-text"
        >
          <span>
            {studentData?.enrolledIn?.length === 0
              ? '-'
              : studentData?.enrolledIn?.map(
                  (enrolment: string, index: number) => (
                    <span>
                      {enrolment}
                      {index !== studentData?.enrolledIn?.length - 1 && ', '}
                    </span>
                  )
                )}
          </span>
        </div>

        {/*GRADUATION YEAR*/}
        <div
          style={{ width: '19%', marginRight: '1%' }}
          className="flags-table-row-text"
        >
          <span>{studentData?.graduationYear ?? '-'}</span>
        </div>

        {/*ACTIONS*/}
        <div style={{ width: '24%', marginRight: '1%', display: 'flex' }}>
          <div style={{ width: '25%', display: 'flex' }}>
            <div
              onClick={() => onProfileClick(studentData._id)}
              style={{
                display: 'flex',
                marginRight: '12px',
                cursor: 'pointer',
              }}
              className="enrollments-action"
            >
              <img
                style={{
                  marginRight: '8px',
                  minWidth: '14px',
                  maxWidth: '14px',
                }}
                className="external-link-icon"
                src={ProfileIcon}
                alt="enrollments"
              />
              <img
                style={{
                  marginRight: '8px',
                  minWidth: '14px',
                  maxWidth: '14px',
                }}
                className="external-link-icon-primary"
                src={ProfileIconPrimary}
                alt="enrollments"
              />
              <span className="mentors-table-access-button">Profile</span>
            </div>
          </div>
        </div>
      </div>
      {/*DIVIDERS*/}
      <div style={{ borderTopWidth: '1px' }} className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>
    </div>
  );
};

export default StudentsTableRow;
