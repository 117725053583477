import { Button, Result } from 'antd';
import { useParams } from 'react-router-dom';

const PaymentSuccess = () => {
  const { invoiceId }: { invoiceId: string } = useParams();

  return (
    <Result
      status="success"
      title="Thank you for your payment!"
      subTitle={`Invoice ${invoiceId} has been paid. Your student will receive login information soon via email.`}
    />
  );
};

export default PaymentSuccess;
