import { ApiDescription, ApiData, RequestsMethod } from '../types';

export const generatePersonalLastMonthActivityReport: ApiDescription = {
  endpoint: '/pdf-generator/activity-report/personal',
  method: RequestsMethod.Post,
};

export type GeneratePersonalLastMonthActivityReport = ApiData<
  { mentorId: string },
  {
    pdfBuffer: any;
  }
>;

export const previewActivityReport: ApiDescription = {
  endpoint: '/pdf-generator/activity-report/preview',
  method: RequestsMethod.Post,
};

export type PreviewActivityReport = ApiData<
  {
    date: string;
    report: any;
  },
  {
    pdfBuffer: any;
  }
>;
