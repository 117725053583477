import FormInput from '@components/Form/FormInput';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import {
  useDeleteMentorRaliabilityScore,
  useGetMentorReliabilityScoreHistory,
  useUpdateMentorRaliabilityScore,
} from '@shared/react';
import { CloseModalProps } from '@shared/types';
import styles from '@styles/components/mentor-availability.module.scss';
import { Input, message, Modal, Tooltip } from 'antd';
import { useState } from 'react';

type Props = CloseModalProps & {
  userId: string;
};

const MentorReliabilityScoreModal = ({ visible, hide, userId }: Props) => {
  const { data: mrs, refetch: refetchMRSHistory } =
    useGetMentorReliabilityScoreHistory({
      mentorId: userId,
    });
  const title = `${mrs?.mentor?.fullName}'s Reliability Score HISTORY`;

  const { mutate: updateMRS } = useUpdateMentorRaliabilityScore(userId, {
    onSuccess: () => {
      refetchMRSHistory();
      message.success('MRS updated successfully');
    },
  });

  const { mutate: deleteMRS } = useDeleteMentorRaliabilityScore(userId, {
    onSuccess: () => {
      refetchMRSHistory();
      message.success('MRS deleted successfully');
    },
  });

  const handleChangePercent = (
    key: string,
    reason: string,
    percent: number
  ) => {
    updateMRS({
      mrsId: key,
      reason,
      percentChange: percent,
    });
  };

  const handleDeletePercent = (key: string) => {
    deleteMRS({ mrsId: key });
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={hide}
      footer={null}
      width={700}
    >
      <h2>
        Current Reliability Score:{' '}
        <b>{mrs?.mentor?.mentorData?.reliability?.toFixed(1)}</b>
      </h2>
      {mrs?.mentorMRSHistory.map((data: any) => (
        <MRS
          key={data._id}
          currentPercentage={data.change}
          handleChangePercent={(key, reason, percent) =>
            handleChangePercent(data._id, reason, percent)
          }
          handleDeletePercent={() => handleDeletePercent(data._id)}
        />
      ))}
    </Modal>
  );
};

const MRS = ({
  key,
  currentPercentage,
  handleChangePercent,
  handleDeletePercent,
}: {
  key: string;
  currentPercentage: number;
  handleChangePercent?: (
    key: string,
    reason: string,
    currentPercentage: number
  ) => void;
  handleDeletePercent?: (key: string) => void;
}) => {
  const [percentage, setPercentage] = useState<number>(currentPercentage);
  const [reason, setReason] = useState('');

  return (
    <div className={styles.dayIntervalsItem}>
      <Input
        value={percentage}
        onChange={(e) => setPercentage(e.target.value as unknown as number)}
        type="number"
      />

      <div className={styles.br}></div>

      <FormInput
        value={reason}
        style={{ minWidth: 250 }}
        placeholder="Please insert the reason ..."
        onChange={(e) => setReason(e.target.value)}
        type="string"
      />

      {handleChangePercent && (
        <Tooltip title="Confirm">
          <PencilIcon
            className={styles.icon}
            onClick={() => handleChangePercent(key, reason, percentage)}
          />
        </Tooltip>
      )}

      {handleDeletePercent && (
        <Tooltip title="Delete">
          <TrashIcon
            className={styles.icon}
            onClick={() => handleDeletePercent(key)}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default MentorReliabilityScoreModal;
