import React from 'react';
import { COLORS } from '../../../../v3/global';
import './section-title.styles.scss';

type Props = {
  title: string;
};

const SectionTitle = ({ title }: Props) => {
  return (
    <h2 style={{ color: COLORS.TEXT_PRIMARY }} className="section-title-text">
      {title}
    </h2>
  );
};

export default SectionTitle;
