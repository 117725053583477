import AcademicExperienceDetailsSection from '@components/V3/ProfileModalMentors/AcademicExperienceDetailsSection';
import CapacityDetailsSection from '@components/V3/ProfileModalMentors/CapacityDetailsSection';
import PaymentDetailsSection from '@components/V3/ProfileModalMentors/PaymentDetailsSection';
import WorkExperienceDetailsSection from '@components/V3/ProfileModalMentors/WorkExperienceDetailsSection';
import { searchMentors } from '@shared/apis/mentor.apis';
import { useGetAllCourses, useSaveUserProfile } from '@shared/react';
import SecondaryButton from '@utils/SecondaryButton';
import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { COLORS } from '../../../v3/global';
import './profile-modal-mentors.styles.scss';
import AgoraBigSelector from '../Utils/InputsV3/AgoraBigSelector';
import Input from '@components/V4/Inputs/Input';
import { Course } from '@shared/common';
import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';

type Props = {
  mentorId: string;
  mentorData?: Partial<UserProfileDTO>;
  handleCurrentPage: (page: string) => void;
  selectedTab: string;
  handleSelectedTab: (tab: string) => void;
  refetchMentorData: () => void;
};

const ProfilePageMentors = ({
  mentorId,
  mentorData,
  handleCurrentPage,
  selectedTab,
  handleSelectedTab,
  refetchMentorData,
}: Props) => {
  const [isEditMentorDetails, setIsEditMentorDetails] = useState(false);

  const QueryClient = useQueryClient();

  const { mutate: saveUserProfile } = useSaveUserProfile(mentorId, {
    onSuccess: () => {
      refetchMentorData();
      // Invalidate mentor table
      QueryClient.invalidateQueries(searchMentors.endpoint);
      setIsEditMentorDetails(false);
    },
  });

  const { data: allCourses } = useGetAllCourses();

  const [email, setEmail] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const [parentName, setParentName] = useState<string | null>(null);
  const [parentEmail, setParentEmail] = useState<string | null>(null);
  const [parentPhone, setParentPhone] = useState<string | null>(null);

  const [formCoursesCanTeach, setFormCoursesCanTeach] = useState<
    { _id: string | number; title: string }[]
  >([]);

  const [formUniversity, setFormUniversity] = useState<string>();
  const [formStudySubjects, setFormStudySubjects] = useState<string>();

  const [editCoursesCanTeach, setEditCoursesCanTeach] =
    useState<boolean>(false);

  useEffect(() => {
    if (mentorData && mentorData.mentorData && allCourses) {
      const coursesCanTeachTitles = mentorData.mentorData.coursesCanTeach.map(
        (courseId: string) => {
          const course = allCourses.find(
            (courseFromAllCourses) => courseFromAllCourses._id === courseId
          );
          return {
            title: course?.title ?? '',
            _id: course?._id ?? '',
          };
        }
      );

      setFormCoursesCanTeach(coursesCanTeachTitles);
      setFormUniversity(mentorData?.mentorData?.universities?.[0]);
      setFormStudySubjects(mentorData?.studySubjects?.[0]);
    }
  }, [mentorData, allCourses]);

  // TODO Delete old edit profile details

  const mentorCourses = useMemo(() => {
    return mentorData?.mentorData?.coursesCanTeach.map((courseId: string) => {
      const course = allCourses?.find(
        (courseFromAllCourses) => courseFromAllCourses._id === courseId
      );
      return { title: course?.title ?? '', _id: course?._id ?? '' };
    });
  }, [mentorData, allCourses]);

  const coursesCanTeachSelectHandler = (value: string) => {
    const courseToAdd = {
      _id: value ?? '',
      title: '',
    };
    setFormCoursesCanTeach([...formCoursesCanTeach, courseToAdd]);
  };

  const coursesCanTeachDeselectHandler = (value: string) => {
    const updatedCourses = formCoursesCanTeach.filter(
      (course) => course._id !== value
    );
    setFormCoursesCanTeach(updatedCourses);
  };

  const editCoursesCanTeachSubmitHandler = () => {
    const selectedCoursesIds = formCoursesCanTeach.map(
      (course) => course._id as string
    );
    saveUserProfile({
      coursesCanTeach: selectedCoursesIds,
      universities: formUniversity,
      studySubjects: formStudySubjects,
    });
    setEditCoursesCanTeach(false);
  };

  return (
    <div className="flex flex-col gap-8">
      {/*USER INFO*/}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                lineHeight: '14px',
              }}
            >
              <span
                style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '16px' }}
                className="edit-enrolment-section-subtitle"
              >
                Contact Info
              </span>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Email:{' '}
                </span>
                {isEditMentorDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={email === null ? mentorData?.email : email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {mentorData?.email ?? '-'}
                  </span>
                )}
              </div>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Phone:{' '}
                </span>
                {isEditMentorDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={phone === null ? mentorData?.phoneNumber : phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {mentorData?.phoneNumber ?? '-'}
                  </span>
                )}
              </div>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  City:{' '}
                </span>
                {isEditMentorDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={city === null ? mentorData?.city : city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {mentorData?.city ?? '-'}
                  </span>
                )}
              </div>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    height: 'fit-content',
                    marginRight: '4px',
                  }}
                  className="edit-enrolment-details-label"
                >
                  Country:{' '}
                </span>
                {isEditMentorDetails ? (
                  <span>
                    <input
                      className="edit-enrolment-details-value block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
                      style={{
                        backgroundColor: 'transparent',
                        maxWidth: '160px',
                        height: '16px',
                        marginBottom: '0px',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                      value={country === null ? mentorData?.country : country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ color: COLORS.TEXT_PRIMARY }}
                    className="edit-enrolment-details-value"
                  >
                    {mentorData?.country ?? '-'}
                  </span>
                )}
              </div>
            </div>

            <div
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                lineHeight: '14px',
              }}
            >
              <span
                style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '16px' }}
                className="edit-enrolment-section-subtitle"
              >
                Biography
              </span>
              <div
                style={{
                  marginBottom: '16px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <span
                  style={{ color: COLORS.TEXT_PRIMARY }}
                  className="edit-enrolment-details-value"
                >
                  {mentorData?.mentorData?.biography ?? '-'}
                </span>
              </div>
              {/*////*/}
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {isEditMentorDetails ? (
              <div style={{ display: 'flex' }}>
                <SecondaryButton
                  onClick={() => {
                    setIsEditMentorDetails(false);
                    // handleCurrentPage('edit-profile-details')
                  }}
                  backgroundColor={COLORS.TRANSPARENT}
                  height="27px"
                  padding="6px 24px 6px 24px"
                  margin="0px 16px 0px 0px"
                >
                  <span
                    className="button-text"
                    style={{ color: COLORS.TEXT_PRIMARY }}
                  >
                    Cancel
                  </span>
                </SecondaryButton>
                <SecondaryButton
                  onClick={() => {
                    saveUserProfile({
                      email: email === null ? mentorData?.email : email,
                      phoneNumber:
                        phone === null ? mentorData?.phoneNumber : phone,
                      city: city === null ? mentorData?.city : city,
                      country: country === null ? mentorData?.country : country,
                      parentData: {
                        fullName:
                          parentName === null
                            ? mentorData?.parentData?.fullName
                            : parentName,
                        phoneNumber:
                          parentPhone === null
                            ? mentorData?.parentData?.phoneNumber
                            : parentPhone,
                        email:
                          parentEmail === null
                            ? mentorData?.parentData?.email
                            : parentEmail,
                      },
                    });
                  }}
                  backgroundColor={COLORS.WHITE}
                  height="27px"
                  padding="6px 24px 6px 24px"
                  margin="0px"
                >
                  <span className="button-text text-black">Save</span>
                </SecondaryButton>
              </div>
            ) : (
              <SecondaryButton
                onClick={() => {
                  // setIsEditStudentDetails(true);
                  handleCurrentPage('edit-profile-details');
                }}
                backgroundColor={COLORS.WHITE}
                height="27px"
                padding="6px 24px 6px 24px"
                margin="0px"
              >
                <span className="button-text text-black">Edit</span>
              </SecondaryButton>
            )}
          </div>
        </div>
      </div>

      <section>
        <div className="flex justify-between gap-6">
          <div className="w-1/2">
            <span className="edit-enrolment-section-subtitle text-white mb-4">
              Courses Can Teach
            </span>
            <div className="flex flex-col">
              {!editCoursesCanTeach && (
                <div>
                  <ol>
                    {mentorCourses?.map((course, index: number) => (
                      <li className=" text-sm" key={course._id}>
                        {index + 1}. {course.title}
                      </li>
                    ))}
                  </ol>
                </div>
              )}
            </div>
          </div>
          <div className="w-1/2">
            <span className="edit-enrolment-section-subtitle text-white mb-4">
              University
            </span>
            <div className="flex flex-col">
              {!editCoursesCanTeach && (
                <>
                  <div>
                    {mentorData?.mentorData?.universities?.map(
                      (university: string, index: number) => (
                        <p className=" text-sm" key={index}>
                          {university}
                        </p>
                      )
                    )}
                  </div>
                  <div>
                    <span className="edit-enrolment-section-subtitle text-white mb-4">
                      Study Subjects
                    </span>
                    {mentorData?.studySubjects?.map(
                      (university: any, index: number) => (
                        <p className=" text-sm" key={index}>
                          {university}
                        </p>
                      )
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex ">
          <div className="w-full flex flex-col items-end">
            {editCoursesCanTeach && (
              <div className="flex gap-6 w-full">
                <div className="w-1/2 mt-2">
                  <AgoraBigSelector
                    onSelect={(value) =>
                      coursesCanTeachSelectHandler(value as string)
                    }
                    onDeselect={(value) =>
                      coursesCanTeachDeselectHandler(value as string)
                    }
                    onClear={() => setFormCoursesCanTeach([])}
                    options={allCourses?.map((course) => ({
                      label: course?.title,
                      value: course?._id,
                    }))}
                    labelKey="label"
                    valueKey="value"
                    maxTagCount={10}
                    mode="multiple"
                    value={formCoursesCanTeach?.map((course: any) => ({
                      lable: course?.title,
                      value: course?._id,
                    }))}
                  />
                </div>

                <div className="w-1/2 mt-2">
                  <Input
                    value={formUniversity}
                    onChange={(e) => setFormUniversity(e.target.value)}
                    type="University"
                    name="University"
                    id="University"
                    label=""
                  />

                  <div className="">
                    <span
                      style={{
                        color: COLORS.TEXT_PRIMARY,
                        marginBottom: '16px',
                      }}
                      className="edit-enrolment-section-subtitle"
                    >
                      Study Subjects
                    </span>
                    <Input
                      value={formStudySubjects}
                      onChange={(e) => setFormStudySubjects(e.target.value)}
                      type="University"
                      name="University"
                      id="University"
                      label=""
                    />
                  </div>
                </div>
              </div>
            )}

            {editCoursesCanTeach && (
              <div className="flex justify-end">
                <SecondaryButton
                  onClick={editCoursesCanTeachSubmitHandler}
                  backgroundColor={COLORS.WHITE}
                  height="32px"
                  padding="4px 10px 4px 10px"
                  margin="12px 0 0 0"
                >
                  <span className="button-text" style={{ color: COLORS.BLACK }}>
                    Save
                  </span>
                </SecondaryButton>
              </div>
            )}
            {!editCoursesCanTeach && (
              <SecondaryButton
                onClick={() => setEditCoursesCanTeach(true)}
                backgroundColor={COLORS.WHITE}
                height="27px"
                padding="6px 24px 6px 24px"
                margin="0px"
                className="ml-auto"
              >
                <span className="button-text" style={{ color: COLORS.BLACK }}>
                  Edit
                </span>
              </SecondaryButton>
            )}
          </div>
        </div>
      </section>

      <div style={{ width: '100%', marginTop: '8px' }}>
        {/*TABS*/}
        <div style={{ marginBottom: '16px' }}>
          <nav
            style={{ overflowX: 'auto' }}
            className="flex space-x-4"
            aria-label="Tabs"
          >
            {/*TODO Uncomment when tabs info is ready*/}
            {/*<span*/}
            {/*  onClick={() => {*/}
            {/*    handleSelectedTab('Academic experience');*/}
            {/*  }}*/}
            {/*  style={{*/}
            {/*    cursor: 'pointer',*/}
            {/*    color:*/}
            {/*      selectedTab === 'Academic experience'*/}
            {/*        ? COLORS.BLACK*/}
            {/*        : COLORS.TEXT_PRIMARY,*/}
            {/*    background:*/}
            {/*      selectedTab === 'Academic experience'*/}
            {/*        ? COLORS.PRIMARY*/}
            {/*        : COLORS.DARK_GREY_ALT,*/}
            {/*    borderRadius: '32px',*/}
            {/*    paddingLeft: '24px',*/}
            {/*    paddingRight: '24px',*/}
            {/*    paddingTop: '4px',*/}
            {/*    paddingBottom: '4px',*/}
            {/*    height: '24px',*/}
            {/*    fontSize: '11px',*/}
            {/*    lineHeight: '15px',*/}
            {/*  }}*/}
            {/*  aria-current={*/}
            {/*    selectedTab === 'Academic experience' ? 'page' : undefined*/}
            {/*  }*/}
            {/*>*/}
            {/*  Academic experience*/}
            {/*</span>*/}

            {/*<span*/}
            {/*  onClick={() => {*/}
            {/*    handleSelectedTab('Work experience');*/}
            {/*  }}*/}
            {/*  style={{*/}
            {/*    cursor: 'pointer',*/}
            {/*    color:*/}
            {/*      selectedTab === 'Work experience'*/}
            {/*        ? COLORS.BLACK*/}
            {/*        : COLORS.TEXT_PRIMARY,*/}
            {/*    background:*/}
            {/*      selectedTab === 'Work experience'*/}
            {/*        ? COLORS.PRIMARY*/}
            {/*        : COLORS.DARK_GREY_ALT,*/}
            {/*    borderRadius: '32px',*/}
            {/*    paddingLeft: '24px',*/}
            {/*    paddingRight: '24px',*/}
            {/*    paddingTop: '4px',*/}
            {/*    paddingBottom: '4px',*/}
            {/*    height: '24px',*/}
            {/*    fontSize: '11px',*/}
            {/*    lineHeight: '15px',*/}
            {/*  }}*/}
            {/*  aria-current={*/}
            {/*    selectedTab === 'Work experience' ? 'page' : undefined*/}
            {/*  }*/}
            {/*>*/}
            {/*  Work experience*/}
            {/*</span>*/}

            {/*<span*/}
            {/*  onClick={() => {*/}
            {/*    handleSelectedTab('Capacity');*/}
            {/*  }}*/}
            {/*  style={{*/}
            {/*    cursor: 'pointer',*/}
            {/*    color:*/}
            {/*      selectedTab === 'Capacity'*/}
            {/*        ? COLORS.BLACK*/}
            {/*        : COLORS.TEXT_PRIMARY,*/}
            {/*    background:*/}
            {/*      selectedTab === 'Capacity'*/}
            {/*        ? COLORS.PRIMARY*/}
            {/*        : COLORS.DARK_GREY_ALT,*/}
            {/*    borderRadius: '32px',*/}
            {/*    paddingLeft: '24px',*/}
            {/*    paddingRight: '24px',*/}
            {/*    paddingTop: '4px',*/}
            {/*    paddingBottom: '4px',*/}
            {/*    height: '24px',*/}
            {/*    fontSize: '11px',*/}
            {/*    lineHeight: '15px',*/}
            {/*  }}*/}
            {/*  aria-current={selectedTab === 'Capacity' ? 'page' : undefined}*/}
            {/*>*/}
            {/*  Capacity*/}
            {/*</span>*/}

            <span
              onClick={() => {
                handleSelectedTab('Payment details');
              }}
              style={{
                cursor: 'pointer',
                color:
                  selectedTab === 'Payment details'
                    ? COLORS.BLACK
                    : COLORS.TEXT_PRIMARY,
                background:
                  selectedTab === 'Payment details'
                    ? COLORS.PRIMARY
                    : COLORS.DARK_GREY_ALT,
                borderRadius: '32px',
                paddingLeft: '24px',
                paddingRight: '24px',
                paddingTop: '4px',
                paddingBottom: '4px',
                height: '24px',
                fontSize: '11px',
                lineHeight: '15px',
              }}
              aria-current={
                selectedTab === 'Payment details' ? 'page' : undefined
              }
            >
              Payment details
            </span>
          </nav>
        </div>

        {selectedTab === 'Academic experience' ? (
          <AcademicExperienceDetailsSection />
        ) : selectedTab === 'Work experience' ? (
          <WorkExperienceDetailsSection />
        ) : selectedTab === 'Capacity' ? (
          <CapacityDetailsSection />
        ) : selectedTab === 'Payment details' ? (
          <PaymentDetailsSection
            mentorData={mentorData}
            handleCurrentPage={handleCurrentPage}
            refetchMentorData={refetchMentorData}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ProfilePageMentors;
