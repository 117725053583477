import React, { ReactNode, useState } from 'react';
import { COLORS } from '../../../../../v3/global';
import './meeting-card-buttons.styles.scss';
// eslint-disable-next-line import/order
import { mergeClassNames } from 'apps/agora/src/utils/helpers';

type Props = {
  children: ReactNode;
  className?: string;
};

const MeetingListItemButton = ({ children, className }: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundColor: isHovered
          ? COLORS.SURFACE_BACKGROUND
          : COLORS.TRANSPARENT,
      }}
      className={mergeClassNames(
        'meeting-list-item-button-container cursor-pointer',
        className
      )}
    >
      {children}
    </div>
  );
};

export default MeetingListItemButton;
