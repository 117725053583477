import { ReactComponent as ArrowDownIcon } from '@assets/icons/V4/arrow-down.svg';
import { ReactComponent as EditIcon } from '@assets/icons/V4/edit-icon.svg';
import { ReactComponent as NoDataIcon } from '@assets/icons/V4/no-data.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/V4/trash-icon.svg';
import IconButton from '@components/V4/IconButton';
import Table from '@components/V4/Table/Table';
import { useSaveUserProfile } from '@shared/react';
import useClientPagination from 'apps/agora/src/hooks/useClientPagination';
import useToast from 'apps/agora/src/hooks/useToast';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import { useContext, useState } from 'react';
import SessionModalSection, {
  ActionButton,
} from '../../../SessionModalSection';
import { StudentDetailsPageContext } from '../../StudentDetailsPage';
import UniversityForm from './UniversityGoalForm';

const UniversityGoalsTab = () => {
  const { student } = useContext(StudentDetailsPageContext);

  const universities = student?.appliedUniversities || [];
  const studentId = student?._id ?? '';

  const [isAddingUniversity, setIsAddingUniversity] = useState(false);
  const [universityToEdit, setUniversityToEdit] = useState<any>();

  const [showToast] = useToast();

  const [paginatedEnrollments, pagination] = useClientPagination({
    data: universities || [],
    shouldOnlyShowPages: true,
  });

  const { mutate: updateUserProfile, isLoading: isUpdatingUserProfile } =
    useSaveUserProfile(studentId ?? '', {
      onSuccess: () => {
        showToast({
          variant: 'info',
          messageBody: 'Profile updated successfully.',
        });
      },
      onError: () => {
        showToast({
          variant: 'error',
          messageBody: 'Profile could not be updated.',
        });
      },
    });

  const buttons: ActionButton[] = [
    { buttonText: 'Add', onClick: () => setIsAddingUniversity(true) },
  ];

  const deleteGoalClickHandler = (goalId: string) => {
    updateUserProfile({
      appliedUniversities: [
        {
          _id: goalId,
          delete: true,
        },
      ],
    });
  };

  const moveHandler = (index: number, direction: 'up' | 'down') => {
    if (!universities?.[index]?.order) {
      return;
    }

    updateUserProfile({
      appliedUniversities: [
        {
          _id: universities?.[index]._id,
          newOrder:
            (universities?.[index]?.order || 0) + (direction === 'up' ? -1 : 1),
        },
      ],
    });
  };

  return isAddingUniversity ? (
    <UniversityForm
      isModeAddNew
      className="mt-6"
      studentId={studentId}
      onCloseEditForm={() => setIsAddingUniversity(false)}
    />
  ) : (
    <SessionModalSection
      className="mt-6"
      sectionTitle="Universities"
      buttonsArray={buttons}
    >
      {universities?.length ? (
        <Table size="small" pagination={pagination}>
          <Table.Header>
            <Table.Row isHeader>
              <Table.HeaderCell>University Name</Table.HeaderCell>
              <Table.HeaderCell>Major</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {paginatedEnrollments.map((item: any, index: number) => (
              <Table.Row key={item._id}>
                {universityToEdit?._id === item._id ? (
                  <Table.Cell className="!p-0" colSpan={4}>
                    <UniversityForm
                      className="rounded-none"
                      goal={item}
                      studentId={studentId ?? ''}
                      onCloseEditForm={() => setUniversityToEdit(undefined)}
                    />
                  </Table.Cell>
                ) : (
                  <>
                    <Table.Cell>{item.university.name}</Table.Cell>
                    <Table.Cell>{item.major.name}</Table.Cell>
                    <Table.Cell>{item.status}</Table.Cell>
                    <Table.Cell>
                      <div className="flex gap-4">
                        <IconButton
                          isDisabled={index === 0 || isUpdatingUserProfile}
                          size="small"
                          variant="ghost"
                          icon={
                            <ArrowDownIcon className="rotate-180 h-4 w-4" />
                          }
                          onClick={() => moveHandler(index, 'up')}
                        />
                        <IconButton
                          isDisabled={
                            index === universities.length - 1 ||
                            isUpdatingUserProfile
                          }
                          size="small"
                          variant="ghost"
                          icon={<ArrowDownIcon className="h-4 w-4" />}
                          onClick={() => moveHandler(index, 'down')}
                        />
                        <IconButton
                          isDisabled={isUpdatingUserProfile}
                          size="small"
                          variant="ghost"
                          icon={<EditIcon />}
                          onClick={() => setUniversityToEdit(item)}
                        />
                        <IconButton
                          isDisabled={isUpdatingUserProfile}
                          size="small"
                          variant="ghost"
                          color="danger"
                          icon={<TrashIcon />}
                          onClick={() => deleteGoalClickHandler(item._id)}
                        />
                      </div>
                    </Table.Cell>
                  </>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <EmptyStateCard
          text="No Existing Data"
          icon={<NoDataIcon className="text-customGrey" />}
        />
      )}
    </SessionModalSection>
  );
};

export default UniversityGoalsTab;
