import EnrollmentDetails from '@modules/Admin/Users/EnrollmentDetails';
import { useSearchUsers } from '@shared/react';
import React from 'react';

type Props = {
  email: string;
};

const PersonalizedPlanForm = ({ email }: Props) => {
  const { data: searchUserResponse } = useSearchUsers({
    search: email,
    limit: 1,
  });

  const userId = searchUserResponse?.users[0]?._id;

  if (!userId) {
    return <p>User not found</p>;
  }

  return <EnrollmentDetails userId={userId} />;
};

export default React.memo(PersonalizedPlanForm);
