import { PageLayout } from '@components';
import { Card, Space } from 'antd';
import MentorPerformance from './MentorPerformance';
import NPS from './NPS';
import PlatformNPS from './PlatformNPS';
import Students from './Students';

const CustomerSuccessDashboard = () => {
  return (
    <div>
      <PageLayout headerTitle="Customer Success Dashboard" isEmpty={true}>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Card title="Education NPS">
            <NPS />
          </Card>
          <Card title="Platform NPS">
            <PlatformNPS />
          </Card>
          <Card title="Mentor Performance" style={{ width: '100%' }}>
            <MentorPerformance />
          </Card>

          <Students />
        </Space>
      </PageLayout>
    </div>
  );
};

export default CustomerSuccessDashboard;
