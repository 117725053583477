import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
const VideoMeetingLoading = () => {
  return (
    <section className="flex flex-col items-center justify-center gap-4 w-full h-[calc(100vh-56px)] laptop:h-full">
      <LoadingSpinner />
      <h1 className="text-xsm leading-4">Starting call...</h1>
    </section>
  );
};

export default VideoMeetingLoading;
