import { useQueryParamsState } from '@hooks';
import KnowledgeBaseArticle from './Article';
import KnowledgeBaseSearch from './Search';

interface KnowledgeBaseWidgetProps {
  defaultArticleId?: string;
  showAdminButtons?: boolean;
}

const KnowledgeBaseWidget = (props: KnowledgeBaseWidgetProps) => {
  const { defaultArticleId, showAdminButtons } = props;
  const [queryParams, setQueryParams] = useQueryParamsState({
    article: defaultArticleId,
  });

  const articleId = queryParams.article;
  const changeId = (id: string) => setQueryParams({ article: id });

  if (articleId) {
    return (
      <div>
        <KnowledgeBaseArticle
          id={articleId}
          changeId={changeId}
          showAdminButtons={showAdminButtons}
        />
      </div>
    );
  } else {
    return <KnowledgeBaseSearch setOpenArticle={changeId} />;
  }
};

export default KnowledgeBaseWidget;
