import LessonDisplay from '@modules/Base/LessonDisplay';
import { useLocation } from 'react-router-dom';

import QuizPage from './QuizPage';

const useQueryParams = () => new URLSearchParams(useLocation().search);

const PageDisplay = () => {
  const params = useQueryParams();
  const refType = params.get('refType');
  const moduleId = params.get('m') || '';
  const skippedAhead = params.get('sa') === '1';

  if (refType === 'Lesson') return <LessonDisplay />;
  if (refType === 'Quiz')
    return <QuizPage moduleId={moduleId} skippedAhead={skippedAhead} />;
  else {
    /* try both and see what works */
    try {
      return <LessonDisplay />;
    } catch (e) {
      return <QuizPage moduleId={moduleId} skippedAhead={skippedAhead} />;
    }
  }

  return <div>refType unknown</div>;
};

export default PageDisplay;
