import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import CalendarIcon from '@assets/icons/ic-calendar.svg';
import React, { useState } from 'react';
import './nps-month-selector.styles.scss';
import { COLORS } from '../../../v3/global';

type Props = {
  selectedDate: any;
  prevMonthHandler: () => void;
  nextMonthHandler: () => void;
};

const NPSMonthSelector = ({
  selectedDate,
  prevMonthHandler,
  nextMonthHandler,
}: Props) => {
  const monthYearString = () => {
    return `${selectedDate.toLocaleString('default', {
      month: 'long',
    })} ${selectedDate.getFullYear()}`;
  };

  const isCurrentMonth = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth();
    return currentYear === selectedYear && currentMonth === selectedMonth;
  };

  return (
    <div style={{ width: '200px', backgroundColor: '#1F1F1F' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: `1px solid ${COLORS.TEXT_SECONDARY}`,
          // padding: '6px 12px',
          borderRadius: '4px',
        }}
      >
        <button
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '36px',
            width: '24px',
          }}
          onClick={() => prevMonthHandler()}
        >
          <LeftOutlined style={{ fontSize: '8px' }} />
        </button>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            style={{ width: '24px', height: '24px', marginRight: '8px' }}
            src={CalendarIcon}
            alt="calendar"
          />
          <span
            style={{ color: COLORS.TEXT_SECONDARY }}
            className="nps-month-selector-text"
          >
            {monthYearString()}
          </span>
        </div>

        <button
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '36px',
            width: '24px',
            cursor: isCurrentMonth() ? 'not-allowed' : 'pointer',
          }}
          onClick={() => nextMonthHandler()}
          disabled={isCurrentMonth()}
        >
          <RightOutlined style={{ fontSize: '8px' }} />
        </button>
      </div>
    </div>
  );
};

export default NPSMonthSelector;
