import { MinusCircleOutlined } from '@ant-design/icons/lib';
import { DynamicFormField, DynamicFormFieldItem } from '@components';
import LessonCRUD from '@modules/Admin/LessonEditor/lesson-crud';
import { Quiz } from '@shared/common';
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  message,
  PageHeader,
  Popconfirm,
  Space,
} from 'antd';
import axios from 'axios';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import authHeader from '../../services/auth-header';

const addNewPage = async (data: Quiz, courseId: string, moduleId: string) => {
  try {
    const response = await axios.post(
      '/api/page/new',
      {
        ...data,
        module: moduleId,
        refType: 'Quiz',
      },
      { headers: authHeader() }
    );

    window.location.href = `/admin/manage-courses?ref=edit/${courseId}/${response.data.id}`;
  } catch (error) {
    message.error((error as Error).message);
    message.error('An error occurred. Check console for details.');
    console.error(error);
    return;
  }
};

const editPage = async (
  data: Quiz,
  pageId: string,
  courseId: string,
  moduleId: string
) => {
  try {
    const response = await axios.post(
      `/api/page/${pageId}/modify`,
      {
        ...data,
        module: moduleId,
        refType: 'Quiz',
      },
      { headers: authHeader() }
    );

    message.success(response.data.message);
  } catch (error) {
    message.error((error as Error).message);
    message.error('An error occurred. Check console for details.');
    console.error(error);
    return;
  }
};

const QuizEditor = () => {
  const { action, courseId, lessonId, moduleId } = useParams<{
    [key: string]: string;
  }>();
  const isEditing = action.includes('edit');

  const quizQuery = useQuery<Quiz>(['page/$?refType=$', lessonId, 'Quiz'], {
    enabled: lessonId !== 'n',
  });

  useEffect(() => {
    form.setFieldsValue(quizQuery.data);
  }, [quizQuery.data]);

  const [form] = Form.useForm();

  const getData = () => {
    return {
      ...form.getFieldsValue(),
      parentCourse: courseId,
    };
  };

  const deleteLesson = async () => {
    await LessonCRUD.deleteEverywhere({ lessonId, courseId, moduleId });
    message.success('Lesson deleted successfully.');
    setTimeout(() => {
      window.location.href = '/admin/manage-courses';
    }, 1000);
  };

  return (
    <div>
      <PageHeader
        title="Quiz"
        ghost={false}
        extra={
          <>
            <Popconfirm
              title={`This action is irreversible. Are you sure you want to delete ${quizQuery.data?.title}?`}
              onConfirm={deleteLesson}
            >
              <Button>Delete</Button>
            </Popconfirm>
            <Button type="primary" onClick={form.submit}>
              {isEditing ? 'Save' : 'Publish'}
            </Button>
          </>
        }
      />
      <Card style={{ margin: 15, minHeight: 300 }}>
        <Form
          form={form}
          initialValues={{ isGraded: false }}
          onFinish={() =>
            isEditing
              ? editPage(getData(), lessonId, courseId, moduleId)
              : addNewPage(getData(), courseId, moduleId)
          }
        >
          <Form.Item name="title" label="Title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="isGraded" label="Is Graded" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <DynamicFormField
            name="questions"
            addNewText="New Question"
            renderField={(question, removeQuestion) => (
              <Card>
                <Space style={{ display: 'flex' }} align="center">
                  <DynamicFormFieldItem
                    field={question}
                    name="text"
                    label="Question"
                    missingMessage="Missing Question"
                    required
                  >
                    <Input.TextArea style={{ width: 400 }} />
                  </DynamicFormFieldItem>
                  <MinusCircleOutlined
                    style={{ fontSize: 30 }}
                    onClick={removeQuestion}
                  />
                </Space>
                <DynamicFormFieldItem
                  field={question}
                  name="imageUrl"
                  label="Image url"
                  missingMessage="Missing image"
                >
                  <Input style={{ width: 300 }} />
                </DynamicFormFieldItem>
                <DynamicFormField
                  field={question}
                  name="answers"
                  addNewText="New Answer"
                  renderField={(answer, removeAnswer) => (
                    <Space align="start" style={{ display: 'flex' }}>
                      <DynamicFormFieldItem
                        field={answer}
                        name="text"
                        label="Answer"
                        missingMessage="Missing Answer"
                        required
                      >
                        <Input style={{ width: '100%' }} />
                      </DynamicFormFieldItem>
                      <MinusCircleOutlined
                        style={{ fontSize: 20 }}
                        onClick={removeAnswer}
                      />
                    </Space>
                  )}
                />
                <DynamicFormFieldItem
                  field={question}
                  name="explanation"
                  label="Explanation"
                  missingMessage="Missing explanation"
                >
                  <Input.TextArea />
                </DynamicFormFieldItem>
              </Card>
            )}
          />
        </Form>
      </Card>
    </div>
  );
};

export default QuizEditor;
