import SecondaryButton from '@utils/SecondaryButton';
import React from 'react';
import { COLORS } from '../../../v3/global';
import './profile-modal-mentors.styles.scss';

type Props = {
  handleCurrentPage: (page: string) => void;
  mentorData: any;
  refetchMentorData: () => void;
};

const PaymentDetailsSection = ({
  handleCurrentPage,
  mentorData,
  refetchMentorData,
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '8px',
        marginBottom: '8px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              lineHeight: '14px',
            }}
          >
            <div
              style={{
                marginBottom: '16px',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  height: 'fit-content',
                  marginRight: '4px',
                }}
                className="edit-enrolment-details-label"
              >
                Payrate:{' '}
              </span>

              <span
                style={{ color: COLORS.TEXT_PRIMARY }}
                className="edit-enrolment-details-value"
              >
                {mentorData?.mentorData?.rate?.amount ?? '-'}
              </span>
            </div>
            <div
              style={{
                marginBottom: '16px',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  height: 'fit-content',
                  marginRight: '4px',
                }}
                className="edit-enrolment-details-label"
              >
                Currency:{' '}
              </span>

              <span
                style={{ color: COLORS.TEXT_PRIMARY }}
                className="edit-enrolment-details-value"
              >
                {mentorData?.mentorData?.rate?.currency ?? '-'}
              </span>
            </div>
            <div
              style={{
                marginBottom: '16px',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  height: 'fit-content',
                  marginRight: '4px',
                }}
                className="edit-enrolment-details-label"
              >
                Billing Currency:{' '}
              </span>

              <span
                style={{ color: COLORS.TEXT_PRIMARY }}
                className="edit-enrolment-details-value"
              >
                {mentorData?.mentorData?.rate?.billingCurrency ?? '-'}
              </span>
            </div>
            <div
              style={{
                marginBottom: '16px',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  height: 'fit-content',
                  marginRight: '4px',
                }}
                className="edit-enrolment-details-label"
              >
                VAT Payer:{' '}
              </span>

              <span
                style={{ color: COLORS.TEXT_PRIMARY }}
                className="edit-enrolment-details-value"
              >
                {mentorData?.mentorData?.rate?.vatPayer === true
                  ? 'True'
                  : mentorData?.mentorData?.rate?.vatPayer === false
                  ? 'False'
                  : '-'}
              </span>
            </div>

            <div
              style={{
                marginBottom: '16px',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  height: 'fit-content',
                  marginRight: '4px',
                }}
                className="edit-enrolment-details-label"
              >
                Company / Freelancer Name:{' '}
              </span>

              <span
                style={{ color: COLORS.TEXT_PRIMARY }}
                className="edit-enrolment-details-value"
              >
                {mentorData?.mentorData?.company?.name ?? '-'}
              </span>
            </div>

            <div
              style={{
                marginBottom: '16px',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  height: 'fit-content',
                  marginRight: '4px',
                }}
                className="edit-enrolment-details-label"
              >
                Company / Freelancer Country:{' '}
              </span>

              <span
                style={{ color: COLORS.TEXT_PRIMARY }}
                className="edit-enrolment-details-value"
              >
                {mentorData?.mentorData?.company?.country ?? '-'}
              </span>
            </div>

            <div
              style={{
                marginBottom: '16px',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  height: 'fit-content',
                  marginRight: '4px',
                }}
                className="edit-enrolment-details-label"
              >
                Company / Freelancer Address:{' '}
              </span>

              <span
                style={{ color: COLORS.TEXT_PRIMARY }}
                className="edit-enrolment-details-value"
              >
                {mentorData?.mentorData?.company?.address ?? '-'}
              </span>
            </div>

            <div
              style={{
                marginBottom: '16px',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  height: 'fit-content',
                  marginRight: '4px',
                }}
                className="edit-enrolment-details-label"
              >
                Fiscal registration number (or equivalent):{' '}
              </span>

              <span
                style={{ color: COLORS.TEXT_PRIMARY }}
                className="edit-enrolment-details-value"
              >
                {mentorData?.mentorData?.company?.CUI ?? '-'}
              </span>
            </div>

            <div
              style={{
                marginBottom: '16px',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <span
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  height: 'fit-content',
                  marginRight: '4px',
                }}
                className="edit-enrolment-details-label"
              >
                IBAN:{' '}
              </span>

              <span
                style={{ color: COLORS.TEXT_PRIMARY }}
                className="edit-enrolment-details-value"
              >
                {mentorData?.mentorData?.company?.IBAN ?? '-'}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '36px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <SecondaryButton
              onClick={() => {
                // setIsEditStudentDetails(true);
                handleCurrentPage('edit-payment-details');
                refetchMentorData();
              }}
              backgroundColor={COLORS.WHITE}
              height="27px"
              padding="6px 24px 6px 24px"
              margin="0px"
            >
              <span className="button-text" style={{ color: COLORS.BLACK }}>
                Edit Payment Details
              </span>
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailsSection;
