import { PlusOutlined } from '@ant-design/icons';
import { PageLayout } from '@components';
import { Button, Card } from 'antd';
import { Link } from 'react-router-dom';
import ActiveTable from './ActiveTable';
import TemplatesTable from './TemplatesTable';

const Checklists = () => {
  return (
    <PageLayout headerTitle="Checklists" isEmpty={true}>
      <Card
        title="Templates"
        extra={
          <Link to="/admin/checklists/new">
            <Button icon={<PlusOutlined />}>Create new</Button>
          </Link>
        }
      >
        <TemplatesTable />
      </Card>
    </PageLayout>
  );
};

export default Checklists;
