import { ReactComponent as ContactIcon } from '@assets/icons/profile-icons/contact-icon.svg';
import { ReactComponent as EditIcon } from '@assets/icons/profile-icons/edit-icon.svg';
import Button from '@components/V4/Button';
import IconButton from '@components/V4/IconButton';
import Input from '@components/V4/Inputs/Input';
import Tooltip from '@components/V4/Tooltip';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import StudentAsideInfo from './ProfileAsideInfo';

interface ProfileAsideContactProps {
  email: string;
  phone: string;
  userId: string;
  hasEditPermissions: boolean;
}

const contactSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  phone: z.string().min(1, { message: 'Please enter a valid phone number' }),
});

type FormData = z.infer<typeof contactSchema>;

const ProfileAsideContact = (props: ProfileAsideContactProps) => {
  const { email, phone, userId, hasEditPermissions } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [showToast] = useToast();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(contactSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      email,
      phone,
    },
  });

  const editButtonHandler = () => {
    reset({ email, phone });
    setIsEditMode((prev) => !prev);
  };

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      await queryClient.invalidateQueries('/users/:id/profile');

      editButtonHandler();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateUserProfile({
        phoneNumber: data.phone,
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (isEditMode) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, [isEditMode]);

  return isEditMode ? (
    <div className="flex gap-6 py-4 bg-surfaceHover px-6 rounded-xl">
      <div className="flex flex-col gap-4 w-full">
        <div className="flex gap-4 w-full">
          <ContactIcon />
          <h3 className="font-raleway font-bold">Contact Information</h3>
        </div>
        <div className="flex flex-col gap-6">
          <h4 className="text-customPrimary font-raleway text-xsm font-bold">
            Edit Contact Information
          </h4>

          <Tooltip
            text="Please contact an administrator in order to have your email changed."
            placement="top"
          >
            <div>
              <Input
                isDisabled
                type="email"
                autofocus
                id="email"
                placeholder="john.doe@gmail.com"
                label="Email"
                isTouched={touchedFields.email}
                isValid={!errors.email}
                errorText={errors.email?.message}
                {...register('email', { required: true })}
              />
            </div>
          </Tooltip>

          <Input
            type="tel"
            id="phone"
            placeholder="+49 1234 567890"
            label="Phone"
            isRequired
            isTouched={touchedFields.phone}
            isValid={!errors.phone}
            errorText={errors.phone?.message}
            {...register('phone', { required: true })}
          />
        </div>

        <div className="flex justify-end gap-4">
          <Button
            buttonText="Cancel"
            variant="secondary"
            onMouseDown={editButtonHandler}
          />
          <Button
            buttonText="Save"
            isLoading={isLoading}
            variant="primary"
            onMouseDown={submitHandler}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex gap-6 py-4 px-6">
      <div className="flex gap-4 w-full">
        <ContactIcon />
        <div className="flex flex-col gap-6">
          <h3 className="font-raleway font-bold">Contact Information</h3>
          <StudentAsideInfo label="Email" value={email} />
          <StudentAsideInfo label="Phone" value={phone} />
        </div>
      </div>
      {hasEditPermissions && (
        <IconButton
          onClick={editButtonHandler}
          className="min-h-6 min-w-6"
          icon={<EditIcon className="min-w-3 min-h-3" />}
        />
      )}
    </div>
  );
};

export default ProfileAsideContact;
