import { InfoCircleOutlined } from '@ant-design/icons';
import { COURSES } from '@routes';
import { useAuthState, useCourseProgress } from '@shared/react';
import pageStyles from '@styles/pages/page-defaults.module.scss';
import styles from '@styles/views/courses.module.scss';
import { Spin, Typography } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { COLORS } from '../../../v3/global';
import Breadcrumbs from './Breadcrumbs';
import TitleCard from './TitleCard';

let globalCompletedLessonsIds: string[] = [],
  globalCompletedQuizzesIds: string[] = [];

// Util functions
function isLessonCompleted(lessonId: string, refType: 'Lesson' | 'Quiz') {
  return refType === 'Lesson'
    ? globalCompletedLessonsIds.includes(lessonId)
    : globalCompletedQuizzesIds.includes(lessonId);
}

function countCompletedLessonsInChapter(chapterLessons: any[]) {
  let isFound,
    count = 0;

  const progressLessonsAndQuizzesIds = [
    ...globalCompletedLessonsIds,
    ...globalCompletedQuizzesIds,
  ];

  for (let i = 0; i < chapterLessons.length; i++) {
    isFound = progressLessonsAndQuizzesIds.find(
      (id: string) => id === chapterLessons[i]._id
    );
    if (isFound) {
      count++;
    }
  }

  return count;
}

const ChapterPage = () => {
  const location = useLocation();

  const { courseId, chapterIndex } = useParams<{
    courseId: string;
    chapterIndex: string;
  }>();
  const numberChapterIndex = parseInt(chapterIndex);
  const search = location.search;
  const studentId = new URLSearchParams(search).get('studentId') || '';

  const { userId } = useAuthState();

  const impersonateId = localStorage.getItem('impersonate-user-id');

  const getUserId = () => {
    if (studentId) return studentId;
    if (impersonateId) return impersonateId;
    return userId;
  };

  const {
    data: courseProgress,
    isLoading,
    isError,
  } = useCourseProgress({
    courseId,
    studentId: getUserId(),
  });

  if (isLoading) return <Spin />;

  if (courseProgress === undefined || isError) {
    return (
      <Typography.Paragraph
        style={{
          textAlign: 'center',
          color: COLORS.TEXT_PRIMARY,
        }}
      >
        <InfoCircleOutlined /> An error has occurred!
      </Typography.Paragraph>
    );
  }

  const { course, completedLessonIds, completedQuizzIds } = courseProgress;

  globalCompletedLessonsIds = [...completedLessonIds];
  globalCompletedQuizzesIds = [...completedQuizzIds];

  if (!course) return <div>No course data</div>;

  const module = course.modules[numberChapterIndex];
  const chapterName = module.title;
  const lessons = module.lessons;

  const completedLessons = countCompletedLessonsInChapter(lessons);

  const isChapterCompleted = completedLessons / lessons.length === 1;

  const crumbs = [
    { path: COURSES, name: 'Courses' },
    {
      path: `${COURSES}/${courseId}`,
      name: course.title,
    },
    {
      path: location.pathname,
      name: `${numberChapterIndex + 1}. ${chapterName}`,
    },
  ];

  return (
    <div
      style={{
        backgroundColor: COLORS.COMPONENT_BACKGROUND,
      }}
      className={pageStyles.page}
    >
      <Breadcrumbs crumbs={crumbs} />
      <div className={styles.chapterContent}>
        <div className={styles.lessonsTitles}>
          {module.lessons.map((lesson, index) => {
            const refType = lesson.refType;
            const taken = isChapterCompleted ? '&taken=1' : '';
            const notStarted = !isLessonCompleted(lesson._id, refType)
              ? '&sa=1'
              : '';
            const link = `/lesson/${lesson._id}?w=0&refType=${refType}${taken}&m=${numberChapterIndex}${notStarted}`;
            return (
              <TitleCard
                color={course?.color}
                link={studentId ? `${link}&studentId=${studentId}` : link}
                title={lesson.title}
                number={`${numberChapterIndex + 1}.${index + 1}`}
                isCompleted={isLessonCompleted(lesson._id, refType)}
                key={lesson._id}
                wide={true}
              />
            );
          })}
        </div>
        {module.resources.length > 0 ? (
          <div className={styles.chapterResources}>
            <Typography.Text
              style={{
                fontSize: '1.5em',
                padding: '.25em 2em',
                color: COLORS.TEXT_PRIMARY,
              }}
            >
              Resources
            </Typography.Text>
            <div className={styles.chapterResourcesLinks}>
              {module.resources.map((resource, index) => (
                <div className={styles.chapterResourcesLinksItem}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke={course?.color}
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                    />
                  </svg>

                  <a
                    key={index}
                    href={resource.url}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: course?.color }}
                  >
                    {resource.text}
                  </a>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default ChapterPage;
