import StepBanner from '@modules/Account/Profile/components/ProfileBanner/StepBanner';
import { useState } from 'react';
import { useDeleteProfileEntry } from '../../../hooks/useDeleteProfileEntry';
import { EducationalCardProps } from '../../../types';
import ProfileMainSection from '../../ResumeSection';
import ResumeTabSchoolForm from './ResumeTabSchoolForm';
import ResumeTabSchoolItem from './ResumeTabSchoolItem';

const ResumeSchoolSection = (props: EducationalCardProps) => {
  const { userId, eduList, isMentorViewer, isMentorProfile } = props;

  const [isAddNewItem, setIsAddNewItem] = useState(false);

  const [deleteEntryHandler] = useDeleteProfileEntry({
    userId,
    profileProperty: 'educationalBackground',
  });

  const addNewItemCancelHandler = () => {
    setIsAddNewItem(false);
  };

  return (
    <ProfileMainSection
      title="School"
      onClick={() => setIsAddNewItem((prev) => !prev)}
      isButtonActive={isAddNewItem}
    >
      <div>
        {isAddNewItem && (
          <ResumeTabSchoolForm
            userId={userId}
            isModeAddNew
            setIsAddNewItem={setIsAddNewItem}
            onCancel={addNewItemCancelHandler}
          />
        )}

        {eduList?.map((school) => (
          <ResumeTabSchoolItem
            key={school._id}
            userId={userId}
            schoolDetails={school}
            onDeleteEntry={deleteEntryHandler}
          />
        ))}

        {!isAddNewItem &&
          !eduList?.length &&
          (!isMentorViewer || isMentorProfile) && (
            <StepBanner
              stepOption={'missingSchool'}
              onClick={() => setIsAddNewItem((prev) => !prev)}
            />
          )}
      </div>
    </ProfileMainSection>
  );
};

export default ResumeSchoolSection;
