import { MARGIN, fontFamily } from '../../style/template';

const tableOutputStyle = {
  table: {
    borderSpacing: '1px 2px',
    margin: MARGIN,
    fontFamily,
  },
  th: {
    minWidth: '100px',
    padding: '8px 15px',
    borderRadius: '2px',
    backgroundColor: 'lightcoral',
    fontFamily,
  },
  td: {
    minWidth: '100px',
    padding: '8px 15px',
    borderRadius: '2px',
    textAlign: 'left',
    fontFamily,
  },
};

export default tableOutputStyle;
