import CourseCard from '@components/V3/CourseCard';
import PreviewStudentProgressCard from '@components/V3/CoursePictureEditor/preview-student-progress-card';
import React, { useState, useCallback, useEffect } from 'react';
import { COLORS } from '../../../v3/global';
import './image-upload-crop.styles.scss';
import Button from '@components/V4/Button';
import Modal from '@components/V4/Modal/Modal';

interface ImageUploadCropProps {
  aspect: number;
  refetchData: () => void;
  onCropComplete: (croppedImageUrl: string, croppedBlob: Blob) => void;
  handleImageSrc: (image: any) => void;
  title: string;
}

const ImageUploadCrop = ({
  aspect,
  refetchData,
  onCropComplete,
  handleImageSrc,
  title,
}: ImageUploadCropProps) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 160, y: 160 });
  const [zoom, setZoom] = useState(1);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  // TODO Remove canvas logic
  const getImgBlob = async (imageSrc: string) => {
    const image = new Image();
    image.src = imageSrc;
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('2D context is not available');
    }

    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0);

    return new Promise<{ url: string; blob: Blob }>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve({ url: URL.createObjectURL(blob), blob });
        } else {
          reject(new Error('Failed to create blob'));
        }
      }, 'image/jpeg');
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImageSrc(null);
    setImageDimensions({
      width: 0,
      height: 0,
    });

    const file = event.target.files?.[0];
    if (file) {
      setImageSrc(null);
      const url = window.URL.createObjectURL(file);
      // handleImageSrc(url);
      setImageSrc(url);
      setPreviewVisible(true);
    }
  };

  const handleCropComplete = useCallback(async () => {
    try {
      if (imageSrc) {
        const { url, blob } = await getImgBlob(imageSrc);
        onCropComplete(url, blob);

        setPreviewVisible(false);
      }
    } catch (e) {
      console.error(e);
    }
    setImageSrc(null);
  }, [imageSrc, crop, zoom, aspect, onCropComplete]);

  useEffect(() => {
    setImageSrc(null);
    setImageDimensions({
      width: 0,
      height: 0,
    });
  }, []);

  useEffect(() => {
    if (imageSrc) {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
      };
    }
  }, [imageSrc]);

  return (
    <div>
      <input
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="image-upload-input"
      />
      <label htmlFor="image-upload-input">
        <Button
          variant="secondary"
          size="large"
          className="w-40"
          buttonText="Change Course Image"
          onClick={() => {
            setImageSrc(null);
            document.getElementById('image-upload-input')?.click();
          }}
        />
      </label>

      <Modal isOpen={previewVisible} onClose={() => setPreviewVisible(false)}>
        <div className="mt-8 mb-0.5">
          {imageDimensions.width < 470 || imageDimensions.height < 300 ? (
            <p className="text-customRed">
              Image should be at least 470px width and 300px height
            </p>
          ) : (
            <div>
              <p className="mb-2">Course Cards Preview:</p>
              <div className="mb-2">
                <CourseCard title={title} image={imageSrc ?? null} />
              </div>
              <div className="mb-2">
                <PreviewStudentProgressCard
                  courseTitle={title}
                  image={imageSrc}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-between w-full mt-6">
          <Button
            buttonText="Cancel"
            key="back"
            onClick={() => setPreviewVisible(false)}
            variant="secondary"
          />
          <div className="flex gap-4">
            <Button
              key="change"
              onClick={() => {
                setImageSrc(null);
                document.getElementById('image-upload-input')?.click();
              }}
              buttonText="Change Image"
              variant="secondary"
            />
            <Button
              disabled={
                imageDimensions.width < 470 || imageDimensions.height < 300
              }
              key="submit"
              buttonText="Upload"
              onClick={handleCropComplete}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ImageUploadCrop;
