import { ReactComponent as BellIcon } from '@assets/icons/bell-icon.svg';
import { useCountNewNotifications } from '@shared/react';
import styles from '@styles/components/header.module.scss';
import { Dropdown } from 'antd';
import { useState } from 'react';
import NotificationsCard from './NotificationsCard';

const NotificationsBell = () => {
  const notificationsCount = useCountNewNotifications();
  const [notificationPanelVisible, setNotificationPanelVisible] =
    useState(false);

  const handleVisibleChange = (flag: boolean) => {
    setNotificationPanelVisible(flag);
  };

  return (
    <Dropdown
      overlay={<NotificationsCard onVisibleChange={handleVisibleChange} />}
      trigger={['click']}
      onVisibleChange={handleVisibleChange}
      visible={notificationPanelVisible}
    >
      <div className={styles.bell}>
        {notificationsCount > 0 && <div className={styles.bellAlert} />}
        <BellIcon className={styles.bellIcon} />
      </div>
    </Dropdown>
  );
};

export default NotificationsBell;
