import Button from '@components/V4/Button';
import Modal from '@components/V4/Modal/Modal';

type MentorEndMeetingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;
};

const MentorEndMeetingModal = (props: MentorEndMeetingModalProps) => {
  const { isOpen, onClose, onPrimaryButtonClick, onSecondaryButtonClick } =
    props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small">
      <Modal.Header title="End Meeting" className="text-center" />
      <Modal.Body className="flex flex-col justify-center items-center overflow-x-hidden mb-6 text-xs leading-5 text-center">
        <p>Are you sure you want to end the meeting?</p>
        <p>Please confirm whether the meeting took place as scheduled.</p>
      </Modal.Body>
      <Modal.Footer className="flex justify-center gap-4">
        <Button
          className="w-[90px]"
          variant="secondary"
          buttonText="No, it didn't"
          onClick={onSecondaryButtonClick}
        />
        <Button
          className="w-[90px]"
          variant="primary"
          buttonText="Yes, it did"
          onClick={onPrimaryButtonClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default MentorEndMeetingModal;
