import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import { zodResolver } from '@hookform/resolvers/zod';
import SessionModalFormWrapper from '@modules/Admin/components/SessionModal/SessionModalFormWrapper';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import { StudentDetailsPageContext } from '../../../StudentDetailsPage';

interface PastExamsSectionFormProps extends BasicProps {
  isModeAddNew?: boolean;
  pastExam?: any;
  onCloseEditForm: () => void;
}

const formDataSchema = z.object({
  exam: z.string().min(1, { message: 'Please enter a valid exam name.' }),
  prevGrade: z.string().optional().default(''),
  date: z.string().min(1, { message: 'Please enter a valid date.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const PastExamsSectionForm = (props: PastExamsSectionFormProps) => {
  const { isModeAddNew = false, pastExam, className, onCloseEditForm } = props;
  const { student } = useContext(StudentDetailsPageContext);

  const studentId = student?._id ?? '';

  const [showToast] = useToast();

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(
    studentId,
    {
      onSuccess: async () => {
        showToast({
          variant: 'success',
          messageTitle: 'Success',
          messageBody: 'Successfully updated your profile.',
        });
        onCloseEditForm();
      },
      onError: () => {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: 'Profile could not be saved.',
        });
      },
    }
  );

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      exam: pastExam?.exam ?? '',
      prevGrade: pastExam?.prevGrade ?? '',
      date: pastExam?.date,
    },
  });

  const { field: dateField, fieldState: dateFieldState } = useController({
    name: 'date',
    control,
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const languageExam: any = {
        ...data,
        _id: pastExam?._id,
      };

      updateUserProfile({
        languageExams: [languageExam],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <SessionModalFormWrapper
      contentClassName={className}
      title={isModeAddNew ? 'Add Past Exam' : 'Edit Past Exam'}
      isLoading={isLoading}
      onCancel={onCloseEditForm}
      submitHandler={submitHandler}
      submitButtonText="Create Past Exam"
    >
      <div className="flex gap-6">
        <Input
          isRequired
          type="text"
          id="exam_name"
          label="Exam Name"
          isTouched={touchedFields.exam}
          isValid={!errors.exam}
          errorText={errors.exam?.message}
          autofocus
          {...register('exam')}
        />
        <AgoraDatePicker
          isRequired
          value={dateField.value ? moment(dateField.value) : undefined}
          onChange={(value) =>
            dateField.onChange(moment(value).format('YYYY-MM-DD'))
          }
          disabledDate={(date) => date > moment(moment().format('YYYY-MM-DD'))}
          onBlur={dateField.onBlur}
          allowClear={false}
          label="Date"
          isValid={!dateFieldState.error}
          isTouched={dateFieldState.isTouched}
          errorText={dateFieldState.error?.message}
        />
      </div>
      <Input type="text" id="score" label="Score" {...register('prevGrade')} />
    </SessionModalFormWrapper>
  );
};

export default PastExamsSectionForm;
