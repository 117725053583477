import { ReactComponent as ErrorIcon } from '@assets/icons/toast-icons/error.svg';
import { ReactComponent as InfoIcon } from '@assets/icons/toast-icons/info.svg';
import { ReactComponent as SuccessIcon } from '@assets/icons/toast-icons/success.svg';
import { ReactComponent as WarningIcon } from '@assets/icons/toast-icons/warning.svg';
import { mergeClassNames } from '../../utils/helpers';

export interface InfoBarProps {
  className?: string;
  variant?: 'error' | 'warning' | 'info' | 'success';
  messageTitle?: string;
  messageBody: string;
}

const InfoBar = (props: InfoBarProps) => {
  const { className, variant = 'success', messageTitle, messageBody } = props;

  const iconClassName = 'min-w-4 min-h-4';

  const variantIcons = {
    error: <ErrorIcon className={iconClassName} />,
    info: <InfoIcon className={iconClassName} />,
    warning: <WarningIcon className={iconClassName} />,
    success: <SuccessIcon className={iconClassName} />,
  };

  return (
    <div
      className={mergeClassNames(
        'bg-surfaceHover rounded-lg shadow-default px-4 py-3 flex gap-4 w-full max-w-full',
        className
      )}
    >
      {variantIcons[variant]}

      <div className="flex flex-col items-center gap-4 tablet:flex-row">
        <div className="flex flex-col gap-1">
          {messageTitle && (
            <h3 className="text-xsm leading-4 font-raleway font-bold ">
              {messageTitle}
            </h3>
          )}
          <p className="text-xs leading-4 ">{messageBody}</p>
        </div>
      </div>
    </div>
  );
};

export default InfoBar;
