import { ReactComponent as NotesIcon } from '@assets/icons/V4/session-details/notes-icon.svg';
import Button from '@components/V4/Button';
import { useState } from 'react';
import SessionDetailsLayoutCard from '../components/SessionDetailsLayoutCard';
import SessionDetailsNotesForm from './SessionDetailsNotesForm';

interface SessionDetailsNotesProps {
  notes?: string;
  meetingId?: string;
}

const SessionDetailsNotes = (props: SessionDetailsNotesProps) => {
  const { notes, meetingId } = props;

  const [isEditMode, setIsEditMode] = useState(false);

  const cancelFormSubmissionHandler = () => {
    setIsEditMode(false);
  };

  return (
    <SessionDetailsLayoutCard
      title="Notes"
      icon={!!meetingId && <NotesIcon className="text-white" />}
      onClick={meetingId ? () => setIsEditMode((prev) => !prev) : undefined}
      className="gap-8"
    >
      <>
        {isEditMode && !!meetingId && (
          <SessionDetailsNotesForm
            setIsEditMode={setIsEditMode}
            onCancel={cancelFormSubmissionHandler}
            defaultData={{
              id: meetingId,
              notes: notes ?? undefined,
            }}
          />
        )}

        {!isEditMode &&
          (notes ? (
            <p className="text-sm text-white leading-5">{notes}</p>
          ) : (
            <div className="flex flex-col gap-6 items-center justify-center w-full ">
              <NotesIcon className="text-customGrey" />

              <p className="text-sm text-customGrey text-center  leading-5 whitespace-pre-wrap">
                There are no available notes.
              </p>

              {!!meetingId && (
                <Button
                  variant="secondary"
                  buttonText="Add Notes"
                  onClick={() => setIsEditMode(true)}
                />
              )}
            </div>
          ))}
      </>
    </SessionDetailsLayoutCard>
  );
};

export default SessionDetailsNotes;
