import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import moment from 'moment';
import React from 'react';
import { TranscriptMessage as TranscriptMessageType } from './SidebarTranscriptPage';

interface TranscriptMessageProps {
  message: TranscriptMessageType;
  shouldShowName: boolean;
  id?: string;
}

const TranscriptMessage = (props: TranscriptMessageProps) => {
  const { message, shouldShowName, id } = props;
  return (
    <div key={message.messageId} id={id} className="flex flex-col gap-2">
      <div
        className={mergeClassNames('flex gap-2 text-customGrey', {
          'mt-4': shouldShowName,
        })}
      >
        {shouldShowName && message?.author && (
          <h3 className="text-inherit text-xs font-bold">{message.author}</h3>
        )}
        {message?.timeStamp && (
          <h4 className="text-inherit text-xs font-light">
            {moment(message.timeStamp).format('HH:mm')}
          </h4>
        )}
      </div>
      {message?.message && (
        <p className="text-xs break-words">{message.message}</p>
      )}
    </div>
  );
};

export default TranscriptMessage;
