import arrowDown from '@assets/icons/input-arrow-expand.svg';
import { useGetAllCoursesLite } from '@shared/react';
import { Select } from 'antd';
import classNames from 'classnames';
import AgoraInputError from '../AgoraInputError';
import AgoraInputLabel from '../AgoraInputLabel';
import './agora-course-selector.styles.scss';

type Props = {
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  optionPlaceholder?: string;
  isValid?: boolean;
  isTouched?: boolean;
  isRequired?: boolean;
  label?: string;
  errorText?: string;
};

const AgoraCourseSelector = ({
  defaultValue,
  value,
  onChange,
  className,
  optionPlaceholder,
  isValid,
  isTouched,
  isRequired,
  label,
  errorText,
}: Props) => {
  const { data: courses, isLoading } = useGetAllCoursesLite();

  return (
    <div
      className={classNames(
        'agora-course-selector',
        className,
        isValid && isTouched
          ? 'agora-course-selector--valid'
          : !isValid && isTouched
          ? 'agora-course-selector--error'
          : ''
      )}
    >
      {label && (
        <AgoraInputLabel
          label={label}
          isRequired={isRequired}
          isTouched={isTouched}
          isValid={isValid}
        />
      )}

      <Select
        showSearch
        loading={isLoading}
        placeholder="Select a course"
        optionFilterProp="children"
        value={value}
        onChange={onChange}
        suffixIcon={
          <img
            className="suffix-icon"
            src={arrowDown}
            alt="Select a student."
          />
        }
        defaultValue={defaultValue}
        className={className}
      >
        {optionPlaceholder && (
          <Select.Option key="no-enrollment" value="">
            {optionPlaceholder}
          </Select.Option>
        )}

        {courses &&
          courses.map((course) => (
            <Select.Option key={course._id} value={course._id}>
              {course.title}
            </Select.Option>
          ))}
      </Select>

      {!isValid && isTouched && errorText && (
        <AgoraInputError errorText={errorText} />
      )}
    </div>
  );
};

export default AgoraCourseSelector;
