import {
  ApiDescription,
  ApiData,
  RequestsMethod,
  MeetingDetails,
} from '@shared/types';

export const getVideoAccessToken: ApiDescription = {
  endpoint: '/video/access-token',
  method: RequestsMethod.Get,
};

export type GetVideoAccessToken = ApiData<
  {
    session_key: string;
    session_name: string;
    name: string;
    type: string;
  },
  { token: string }
>;

export const getWaitingScreenMeetingDetails: ApiDescription = {
  endpoint: '/video/meeting-details/:id',
  method: RequestsMethod.Get,
};

export type GetWaitingScreenMeetingDetails = ApiData<unknown, MeetingDetails>;

export const startMeeting: ApiDescription = {
  endpoint: '/video/start-meeting/:id',
  method: RequestsMethod.Post,
};

export type StartMeeting = ApiData<unknown, string>;

export const endMeeting: ApiDescription = {
  endpoint: '/video/end-meeting/:id',
  method: RequestsMethod.Post,
};

export type EndMeeting = ApiData<
  { status: number; endMeetingReason?: string },
  string
>;

export const getHasMentorEntered: ApiDescription = {
  endpoint: '/video/has-mentor-entered/:id',
  method: RequestsMethod.Get,
};

export type GetHasMentorEntered = ApiData<unknown, boolean>;

export const roomActivityWs: ApiDescription = {
  endpoint: '/room-activity-ws',
  method: RequestsMethod.Ws,
};

export type RoomActivityWs = ApiData<unknown, unknown>;
