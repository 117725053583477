import { ReactComponent as EditIcon } from '@assets/icons/V4/edit-icon.svg';
import { ReactComponent as NoDataIcon } from '@assets/icons/V4/no-data.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/V4/trash-icon.svg';
import IconButton from '@components/V4/IconButton';
import Table from '@components/V4/Table/Table';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import moment from 'moment';
import { useContext, useState } from 'react';
import SessionModalSection, {
  ActionButton,
} from '../../../../SessionModalSection';
import { StudentDetailsPageContext } from '../../../StudentDetailsPage';
import UpcomingExamsSectionForm from './UpcomingExamsSectionForm';

interface UpcomingExamsSectionProps extends BasicProps {
  exams?: any;
}

const UpcomingExamsSection = (props: UpcomingExamsSectionProps) => {
  const { exams } = props;

  const { student } = useContext(StudentDetailsPageContext);

  const studentId = student?._id ?? '';

  const [isAddingExam, setIsAddingExam] = useState(false);
  const [upcomingExamToEdit, setUpcomingExamToEdit] = useState<any>();

  const [showToast] = useToast();

  const { mutate: updateUserProfile } = useSaveUserProfile(studentId, {
    onSuccess: () => {
      showToast({
        variant: 'info',
        messageBody: 'Upcoming exam deleted successfully.',
      });
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageBody: 'Upcoming exam could not be deleted.',
      });
    },
  });

  const positionButtons: ActionButton[] = [
    { buttonText: 'Add', onClick: () => setIsAddingExam(true) },
  ];

  const deletUpcomingExamClickHandler = (examId: string) => {
    updateUserProfile({
      languageExams: [
        {
          _id: examId,
          delete: true,
        },
      ],
    });
  };

  return isAddingExam ? (
    <UpcomingExamsSectionForm
      isModeAddNew
      onCloseEditForm={() => setIsAddingExam(false)}
    />
  ) : (
    <SessionModalSection
      className="mt-6"
      sectionTitle="Upcoming Exams"
      buttonsArray={positionButtons}
    >
      {exams?.length ? (
        <Table size="small">
          <Table.Header>
            <Table.Row isHeader>
              <Table.HeaderCell className="w-1/4">Exam</Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">Date</Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">
                Target Score
              </Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {exams.map((item: any) => (
              <Table.Row key={item._id}>
                {upcomingExamToEdit?._id === item._id ? (
                  <Table.Cell className="!p-0" colSpan={4}>
                    <UpcomingExamsSectionForm
                      className="rounded-none"
                      upcomingExam={item}
                      onCloseEditForm={() => setUpcomingExamToEdit(undefined)}
                    />
                  </Table.Cell>
                ) : (
                  <>
                    <Table.Cell>{item.exam}</Table.Cell>
                    <Table.Cell>
                      {moment(item.date).format('DD.MM.YYYY')}
                    </Table.Cell>
                    <Table.Cell>{item.targetGrade}</Table.Cell>
                    <Table.Cell>
                      <div className="flex gap-4">
                        <IconButton
                          size="small"
                          variant="ghost"
                          icon={<EditIcon />}
                          onClick={() => setUpcomingExamToEdit(item)}
                        />

                        <IconButton
                          size="small"
                          variant="ghost"
                          color="danger"
                          icon={<TrashIcon />}
                          onClick={() =>
                            deletUpcomingExamClickHandler(item._id)
                          }
                        />
                      </div>
                    </Table.Cell>
                  </>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <EmptyStateCard
          text="No Existing Data"
          icon={<NoDataIcon className="text-customGrey" />}
        />
      )}
    </SessionModalSection>
  );
};

export default UpcomingExamsSection;
