interface HeaderLabelAndValueProps {
  label: string;
  value: string;
}
const HeaderLabelAndValue = (props: HeaderLabelAndValueProps) => {
  const { label, value } = props;
  return (
    <div className="flex flex-col gap-2 text-sm leading-5 tablet:flex-row ">
      <h3 className="font-bold text-customGrey">{label}</h3>
      <p>{value}</p>
    </div>
  );
};

export default HeaderLabelAndValue;
