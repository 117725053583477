import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import AgoraInput from '@utils/AgoraInput';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './create-new-user.styles.scss';

type AdminDetails = {
  fullName: string;
  phoneNumber: string;
};

type Props = {
  email: string;
  role: 'STUDENT' | 'MENTOR' | 'ADMIN' | undefined;
  handleCurrentPage: (page: string) => void;
  adminDetails: AdminDetails;
  handleInputChangeAdmin: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CreateNewUserAdminStepOne = ({
  email,
  role,
  handleCurrentPage,
  adminDetails,
  handleInputChangeAdmin,
}: Props) => {
  const [touched, setTouched] = useState<Record<keyof AdminDetails, boolean>>({
    fullName: false,
    phoneNumber: false,
  });

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const getColor = (name: keyof typeof adminDetails) => {
    if (!touched[name] && !adminDetails[name]) {
      return COLORS.TEXT_SECONDARY;
    } else {
      return adminDetails[name] ? COLORS.PRIMARY : COLORS.ERROR;
    }
  };

  const areDetailsFilled = !!(
    adminDetails.fullName && adminDetails.phoneNumber
  );

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>

      <div
        style={{
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <h3
            style={{
              color: COLORS.TEXT_SECONDARY,
              marginBottom: '24px',
              // marginTop: '32px',
            }}
            className="modal-page-section-title"
          >
            Basic Info/ Contact Info:
          </h3>

          <AgoraInput
            htmlFor="fullName"
            type="text"
            name="fullName"
            id="fullName"
            placeholder=""
            label="Full Name"
            color={getColor('fullName')}
            labelColor={getColor('fullName')}
            borderColor={getColor('fullName')}
            labelMargin="0 0 0 8px"
            value={adminDetails?.fullName}
            margin="0px 0px 16px 0px"
            onChange={handleInputChangeAdmin}
            onBlur={handleBlur}
            isRequired
          />

          <AgoraInput
            htmlFor="phoneNumber"
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            placeholder=""
            label="Phone Number"
            color={getColor('phoneNumber')}
            labelColor={getColor('phoneNumber')}
            borderColor={getColor('phoneNumber')}
            labelMargin="0 0 0 8px"
            value={adminDetails?.phoneNumber}
            margin="0px 0px 16px 0px"
            onChange={handleInputChangeAdmin}
            onBlur={handleBlur}
            isRequired
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <SecondaryButton
            onClick={() => {
              if (areDetailsFilled) {
                handleCurrentPage('create-new-user-permissions-admin');
              }
            }}
            backgroundColor={COLORS.WHITE}
            height="40px"
            padding="12px"
            margin="0 0 0 0"
            width="100%"
            disabled={!areDetailsFilled}
          >
            <span
              className="create-new-user-modal-submit-button"
              style={{ color: COLORS.BLACK }}
            >
              Continue
            </span>
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default CreateNewUserAdminStepOne;
