import RaiseHandSound from '@assets/sounds/raise-hand.wav';
import RescheduleModal from '@components/V4/RescheduleModal';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import { PageNameType } from '@modules/MeetingVideo/types/PageNameType';

import FeedbackModal from '@modules/Student/components/FeedbackModal/FeedbackModal';
import { MeetingStatus } from '@shared/constants';
import { useAuthState, useEndMeeting } from '@shared/react';
import { storage } from '@shared/react/UniversalStorage';
import ZoomVideo, {
  CommandChannel,
  CommandChannelMsg,
  ConnectionChangePayload,
  LiveTranscriptionClient,
  MediaDevice,
  Participant,
  RecordingClient,
  VideoPlayer as VideoPlayerType,
  VideoQuality,
  ConnectionState,
  Stream,
} from '@zoom/videosdk';
import { ChatMessage as ZoomChatMessage } from '@zoom/videosdk/dist/types/chat';
import useHideHubspot from 'apps/agora/src/hooks/useHideHubspot';
import { usePrevious } from 'apps/agora/src/hooks/usePrevious';
import useToast from 'apps/agora/src/hooks/useToast';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  findRemovedDevicesById,
  getStartVideoToastErrors,
  mountDevices,
  stopTracks,
} from '../../utils/helpers';
import MeetingActionButtons from './MeetingActionButtons';
import ThumbsUpLottie from './ThumbsUpLottie';
import VideoActionButtons from './VideoActionButtons';
import VideoContent from './VideoContent';
import VideoMeetingLoading from './VideoMeetingLoading';
import CancelMeetingModal from './VideoMeetingModals/CancelMeetingModal';
import ConfirmNoShowModal from './VideoMeetingModals/ConfirmNoShowModal';
import MeetingStatusModal from './VideoMeetingModals/MeetingStatusModal';
import MentorEndMeetingModal from './VideoMeetingModals/MentorEndMeetingModal';
import SettingsModal from './VideoMeetingModals/SettingsModal/SettingsModal';
import StudentEndMeetingModal from './VideoMeetingModals/StudentEndMeetingModal';

import { ChatMessage } from './VideoSidebar/SidebarChatPage';
import VideoSidebar from './VideoSidebar/VideoSidebar';
import { findParticipantsDifference, startVideo } from './helpers';

export type VideoMeetingModal =
  | 'end-meeting'
  | 'settings'
  | 'meeting-status'
  | 'cancel-meeting'
  | 'confirm-no-show'
  | 'reschedule'
  | undefined;

const VideoMeeting = () => {
  const {
    videoSettings,
    isMeetingLoading,
    meetingDetails,
    zoomClient,
    activeCamera,
    activeMicrophone,
    stream,
    isCameraActive,
    isMicrophoneActive,
    webSocket,
    isSocketConnected,
    transcriptLanguage,
    setStream,
    setIsCameraActive,
    setIsMicrophoneActive,
    setActiveMicrophone,
    setActiveCamera,
    closeWebsocketConnection,
  } = useContext(ZoomContext);

  const [showSidebar, setShowSidebar] = useState(false);
  const [pageName, setPageName] = useState<PageNameType | undefined>();
  const [activeModal, setActiveModal] = useState<VideoMeetingModal>();
  const [activeButton, setActiveButton] = useState<PageNameType | undefined>(
    undefined
  );
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [speakingParticipants, setSpeakingParticipants] = useState<
    Record<number, boolean>
  >({});
  const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
  const [micList, setMicList] = useState<MediaDevice[]>([]);
  const [raisedHands, setRaisedHands] = useState<Record<number, boolean>>({});
  const [thumbsUpList, setThumbsUpList] = useState<
    { senderId: number; senderName: string; timestamp: number }[]
  >([]);
  const [isViewingScreenShare, setIsViewingScreenShare] = useState(false);
  const [isScreenShareLoading, setIsScreenShareLoading] = useState(false);
  const [backgroundSuppression, setBackgroundSuppression] = useState(
    !!videoSettings.noiseSuppression
  );
  const [virtualBackground, setVirtualBackground] = useState<
    string | undefined
  >(videoSettings.virtualBackground);
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [networkQuality, setNetworkQuality] = useState<
    Record<number, { uplink: number; downlink: number }>
  >({});
  const [connectionState, setConnectionState] = useState<ConnectionState>(
    ConnectionState.Connected
  );

  //TODO - read the TODO from reinitializeCanvas
  const [
    shouldRenderShareScreenContainer,
    setShouldRenderShareScreenContainer,
  ] = useState(true);

  const pastParticipants = usePrevious(participants);
  const videoListRef = useRef<Record<string, VideoPlayerType>>({});
  const screenShareVideoContainerRef = useRef<HTMLVideoElement>(null);
  const screenShareCanvasContainerRef = useRef<HTMLCanvasElement>(null);
  const isScreenShareLoadingRef = useRef(false);

  const commandChannelRef = useRef<typeof CommandChannel>();
  const streamRef = useRef<MediaStream>();
  const timeoutRefs = useRef<Record<string, NodeJS.Timeout | undefined>>({});
  const shouldRestartCameraRef = useRef(false);

  const [isCameraStateSwitchLoading, setIsCameraStateSwitchLoading] =
    useState(false);
  const [isMicrophoneStateSwitchLoading, setIsMicrophoneStateSwitchLoading] =
    useState(false);

  const [showToast] = useToast({ duration: 'infinite' });

  const { meetingId } = useParams<{ meetingId: string }>();

  const { isMentor, userId } = useAuthState();

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { mutate: endMeeting } = useEndMeeting(meetingId, {
    onSuccess: () => {
      closeModalHandler();
      history.replace('/home');
    },
  });

  useHideHubspot();

  useEffect(() => {
    //TODO remove this
    const token = storage.getItem('auth0token');

    if (
      !webSocket ||
      !isSocketConnected ||
      !token ||
      !userId ||
      isMeetingLoading
    )
      return;

    if (webSocket) {
      webSocket.send(
        JSON.stringify({
          type: 'join-meeting',
          meetingId,
          token: `Bearer ${token}`,
        })
      );
    }
  }, [isSocketConnected, userId, isMeetingLoading]);

  const startVideoFailureHandler = (error: any) => {
    const toast = getStartVideoToastErrors(error);

    showToast(toast);
    setIsCameraActive(false);
  };

  const reattachVideos = async () => {
    const allParticipants = zoomClient?.getAllUser();

    if (!allParticipants) return;

    for (const participant of allParticipants) {
      try {
        await stream?.detachVideo(participant.userId);
      } catch (error) {
        console.log(error);
      }
    }

    for (const participant of allParticipants) {
      try {
        await stream?.attachVideo(
          participant.userId,
          VideoQuality.Video_720P,
          videoListRef.current[`${participant.userId}`]
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const renderShareScreen = async (stream: typeof Stream) => {
    if (!zoomClient || !stream) {
      return;
    }

    if (isScreenShareLoadingRef.current) return;

    const allUsers = zoomClient.getAllUser();
    const myUserId = zoomClient.getSessionInfo().userId;

    for (const user of allUsers) {
      if (
        user.sharerOn &&
        !!screenShareCanvasContainerRef.current &&
        user.userId !== myUserId
      ) {
        setIsScreenShareLoading(true);
        isScreenShareLoadingRef.current = true;

        try {
          await stream.stopShareView();
        } catch (error) {
          console.log(error);
        }

        try {
          await stream.startShareView(
            screenShareCanvasContainerRef.current,
            user.userId
          );
          setIsViewingScreenShare(true);
        } catch (error) {
          console.log(error);

          showToast({
            variant: 'error',
            messageTitle: 'Error',
            messageBody: 'Could not start viewing the screen share.',
          });
        }

        setIsScreenShareLoading(false);
        isScreenShareLoadingRef.current = false;
      }
    }
  };

  useEffect(() => {
    if (isMeetingLoading || !zoomClient) {
      return;
    }

    setIsCameraActive(false);
    setIsMicrophoneActive(false);

    setIsCameraStateSwitchLoading(true);
    setIsMicrophoneStateSwitchLoading(true);

    let cloudRecording: typeof RecordingClient;
    let transcriptionClient: typeof LiveTranscriptionClient;

    commandChannelRef.current = zoomClient.getCommandClient();
    const stream = zoomClient.getMediaStream();

    setStream(stream);

    const visibilityChangeHandler = () => {
      if (!document.hidden) {
        renderShareScreen(stream);
        reattachVideos();
      }
    };

    (async () => {
      if (isCameraActive) {
        try {
          await startVideo({
            stream,
            cameraId: activeCamera,
            virtualBackground,
            onSuccess: () => setIsCameraActive(true),
            onFailure: startVideoFailureHandler,
          });
        } catch (error) {
          console.log(error);
        }
      }
      setIsCameraStateSwitchLoading(false);

      try {
        await stream.startAudio({
          microphoneId: activeMicrophone,
          backgroundNoiseSuppression: backgroundSuppression,
        });

        if (!isMicrophoneActive) {
          await stream.muteAudio();
          setIsMicrophoneActive(false);
        } else {
          setIsMicrophoneActive(true);
        }
      } catch (error: any) {
        setIsMicrophoneActive(false);
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: error.reason,
        });
      }
      setIsMicrophoneStateSwitchLoading(false);

      renderShareScreen(stream);

      if (meetingDetails?.accessTokenData.type === 1) {
        cloudRecording = zoomClient.getRecordingClient();

        try {
          await cloudRecording.startCloudRecording();
        } catch (error) {
          console.log(error);
        }
      }
      transcriptionClient = zoomClient.getLiveTranscriptionClient();

      try {
        await transcriptionClient?.startLiveTranscription();
        if (transcriptLanguage) {
          await transcriptionClient.setSpeakingLanguage(transcriptLanguage);
        }
      } catch (error) {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: 'Could not start live transcription.',
        });
      }

      stream.subscribeAudioStatisticData();
      stream.subscribeVideoStatisticData();
      stream.subscribeShareStatisticData();

      document.addEventListener('visibilitychange', visibilityChangeHandler);

      const participants = zoomClient.getAllUser();

      setParticipants(participants);

      for (const participant of participants) {
        const networkQualityInfo = stream.getNetworkQuality(participant.userId);

        if (!networkQualityInfo) continue;

        Object.keys(networkQualityInfo).forEach((key: unknown) => {
          setNetworkQuality((prev) => ({
            ...prev,
            [participant.userId]: {
              ...prev[participant.userId],
              [key as string]: networkQualityInfo[key as 'uplink' | 'downlink'],
            },
          }));
        });
      }
    })();

    return () => {
      stream?.unsubscribeAudioStatisticData();
      stream?.unsubscribeVideoStatisticData();
      stream?.unsubscribeShareStatisticData();
      document.removeEventListener('visibilitychange', visibilityChangeHandler);

      (async () => {
        Object.values(timeoutRefs.current).forEach((timeoutId) => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        });
      })();
    };
  }, [isMeetingLoading]);

  //TODO - This is a workaround. Test periodically if there's an update on this
  const reinitializeCanvas = () => {
    setShouldRenderShareScreenContainer(false);

    setTimeout(() => setShouldRenderShareScreenContainer(true));
  };

  const reinitializeStatisticsListeners = async () => {
    await stream?.unsubscribeAudioStatisticData();
    await stream?.unsubscribeVideoStatisticData();
    await stream?.unsubscribeShareStatisticData();

    stream?.subscribeAudioStatisticData();
    stream?.subscribeVideoStatisticData();
    stream?.subscribeShareStatisticData();
  };

  const removeDuplicateMe = (participants: Participant[]) => {
    const myUserInfo = zoomClient?.getCurrentUserInfo();

    return participants.filter(
      (participant) =>
        (participant.userGuid === myUserInfo?.userGuid &&
          participant.userId === myUserInfo?.userId) ||
        participant.userGuid !== myUserInfo?.userGuid
    );
  };

  const cleanupNetworkQuality = () => {
    if (!zoomClient) {
      return;
    }

    const participantIds = removeDuplicateMe(zoomClient.getAllUser()).map(
      (user) => user.userId
    );

    setNetworkQuality((networkQuality) => {
      const newNetworkQuality = { ...networkQuality };

      Object.keys(networkQuality).forEach((userId: string) => {
        if (!participantIds.includes(parseInt(userId))) {
          delete newNetworkQuality[parseInt(userId)];
        }
      });

      return newNetworkQuality;
    });
  };

  useEffect(() => {
    if (!stream || !zoomClient) return;

    const userAddedHandler = (event: any) => {
      const participants = zoomClient.getAllUser();

      setParticipants(removeDuplicateMe(participants));
    };

    const userUpdatedHandler = (event: any) => {
      const participants = zoomClient.getAllUser();
      const updatedSpeakingParticipants: Record<string, boolean> = {
        ...speakingParticipants,
      };

      participants.forEach((participant) => {
        if (speakingParticipants[participant.userId] && participant.muted) {
          updatedSpeakingParticipants[participant.userId] = false;
        }
      });

      setParticipants(removeDuplicateMe(participants));
      setSpeakingParticipants(updatedSpeakingParticipants);
    };

    const userRemovedHandler = (event: any) => {
      const participants = zoomClient.getAllUser();

      setParticipants(removeDuplicateMe(participants));
    };

    const activeSpeakerHandler = (speakingUsers: { userId: number }[]) => {
      const updatedSpeakingParticipants = { ...speakingParticipants };

      for (const speakingUser of speakingUsers) {
        const userId = speakingUser.userId;

        updatedSpeakingParticipants[userId] = true;

        if (!!timeoutRefs && timeoutRefs.current[userId]) {
          clearTimeout(timeoutRefs.current[userId]);
        }

        timeoutRefs.current[userId] = setTimeout(() => {
          setSpeakingParticipants((prevParticipants) => ({
            ...prevParticipants,
            [userId]: false,
          }));
          timeoutRefs.current[userId] = undefined;
        }, 3000);
      }

      setSpeakingParticipants(updatedSpeakingParticipants);
    };

    const commandChannelHandler = async (payload: CommandChannelMsg) => {
      try {
        const parsedPayload = JSON.parse(payload.text);

        if (parsedPayload.type === 'network-quality-change') {
          setNetworkQuality((prev) => ({
            ...prev,
            [payload.senderId]: {
              ...prev[payload.senderId],
              [parsedPayload.direction]: parsedPayload.score,
            },
          }));
        }
      } catch (error) {
        switch (payload.text) {
          case 'hand-raise':
            raiseHandClickHandler(payload.senderId);
            break;

          case 'thumbs-up':
            thumbsUpClickHandler(
              payload.senderId,
              payload?.senderName || '',
              payload.timestamp
            );
            break;

          case 'reconnect':
            cleanupNetworkQuality();
            break;
        }
      }
    };

    const shareScreenHandler = async (payload: {
      state: 'Active' | 'Inactive';
      userId: number;
    }) => {
      if (!screenShareCanvasContainerRef.current) return;

      if (payload.state === 'Active') {
        try {
          setIsScreenShareLoading(true);
          setIsViewingScreenShare(true);

          await stream.startShareView(
            screenShareCanvasContainerRef.current,
            payload.userId
          );

          setIsScreenShareLoading(false);
        } catch (error) {
          setIsViewingScreenShare(false);
          setIsScreenShareLoading(false);

          showToast({
            variant: 'error',
            messageBody: 'Cannot view share screen.',
          });

          console.log(error);
        }
      } else if (payload.state === 'Inactive') {
        try {
          await stream.stopShareView();

          setIsViewingScreenShare(false);
          reinitializeCanvas();
        } catch (error) {
          console.log(error);
        }
      }
    };
    // New chat message handler
    // NOTE: The 'chat-on-message' will also log when the current user sends a message
    // Thus this method will be called no matter who sent a new message.
    const newMessageHandler = (payload: ZoomChatMessage) => {
      const { sender, message, timestamp } = payload;

      const newChatMessage: ChatMessage = {
        author: sender.name,
        timeStamp: timestamp,
        message: message ?? '',
      };

      setChatMessages((prevMessages) => [...prevMessages, newChatMessage]);

      if (pageName !== 'chat') {
        setUnreadCount((prevCount) => prevCount + 1);
      }
    };

    const connectionChangeHandler = async (event: ConnectionChangePayload) => {
      setConnectionState(event.state);

      if (event.state === ConnectionState.Connected) {
        shouldRestartCameraRef.current = true;
      }

      if (event.reason === 'ended by host') {
        params.delete('inMeeting');

        history.replace({
          pathname: location.pathname,
          search: params.toString(),
        });

        closeWebsocketConnection();

        handleCloseSidebar();

        setShowFeedbackModal(true);
        closeModalHandler();
      }
    };

    const passivelyStopShareHandler = () => {
      reinitializeCanvas();
    };

    const networkQualityChangeHandler = (payload: any) => {
      setNetworkQuality((prev) => ({
        ...prev,
        [payload.userId]: {
          ...prev[payload.userId],
          [payload.type]: payload.level,
        },
      }));

      const myUserId = zoomClient?.getSessionInfo().userId;

      if (payload.userId === myUserId) {
        const direction = payload.type;

        const networkQuality = {
          type: 'network-quality-change',
          direction,
          score: payload.level,
        };

        const stringifiedNetworkQuality = JSON.stringify(networkQuality);

        commandChannelRef.current?.send(stringifiedNetworkQuality);
      }
    };

    const commandChannelStatus = async (event: string) => {
      if (shouldRestartCameraRef.current && event === 'Connected') {
        //After reconnection, the userId changes, so we need to delete the old entry
        cleanupNetworkQuality();

        try {
          commandChannelRef.current?.send('reconnect');
          shouldRestartCameraRef.current = false;
        } catch (error) {
          console.log(error);
        }

        try {
          await stream.startAudio({
            microphoneId: activeMicrophone,
            backgroundNoiseSuppression: backgroundSuppression,
          });

          if (!isMicrophoneActive) {
            await stream.muteAudio();
          }
        } catch (error) {
          console.log(error);
        }

        reattachVideos();
        reinitializeStatisticsListeners();
        renderShareScreen(stream);
      }
    };

    zoomClient.on('user-added', userAddedHandler);
    zoomClient.on('user-updated', userUpdatedHandler);
    zoomClient.on('user-removed', userRemovedHandler);
    zoomClient.on('active-speaker', activeSpeakerHandler);
    zoomClient.on(`command-channel-message`, commandChannelHandler);
    zoomClient.on(`command-channel-status`, commandChannelStatus);
    zoomClient.on('active-share-change', shareScreenHandler);
    zoomClient.on('chat-on-message', newMessageHandler);
    zoomClient.on('connection-change', connectionChangeHandler);
    zoomClient.on('passively-stop-share', passivelyStopShareHandler);
    zoomClient.on('network-quality-change', networkQualityChangeHandler);

    return () => {
      zoomClient.off('user-added', userAddedHandler);
      zoomClient.off('user-updated', userUpdatedHandler);
      zoomClient.off('user-removed', userRemovedHandler);
      zoomClient.off('active-speaker', activeSpeakerHandler);
      zoomClient.off(`command-channel-message`, commandChannelHandler);
      zoomClient.off(`command-channel-status`, commandChannelStatus);
      zoomClient.off(`active-share-change`, shareScreenHandler);
      zoomClient.off('chat-on-message', newMessageHandler);
      zoomClient.off('connection-change', connectionChangeHandler);
      zoomClient.off('passively-stop-share', passivelyStopShareHandler);
      zoomClient.off('network-quality-change', networkQualityChangeHandler);
    };
  }, [
    stream,
    connectionState,
    isCameraActive,
    isMicrophoneActive,
    location.pathname,
    isCameraStateSwitchLoading,
    activeMicrophone,
    backgroundSuppression,
  ]);

  useEffect(() => {
    if (!stream || isMeetingLoading || !zoomClient) {
      return;
    }

    return () => {
      (async () => {
        const cleanupTasks = [
          stream.stopAudio(),
          stream.stopVideo(),
          stream.detachVideo(zoomClient.getSessionInfo().userId),
          stream.stopShareView(),
          stream.stopShareScreen(),
          zoomClient.leave(!!isMentor),
        ];

        for (const task of cleanupTasks) {
          try {
            await task;
          } catch (error) {
            console.log(error);
          }
        }

        ZoomVideo.destroyClient();
      })();
    };
  }, [stream, isMeetingLoading, zoomClient]);

  useEffect(() => {
    if (!zoomClient || !stream) return;

    const deviceChangeHandler = async () => {
      const {
        cameras,
        microphones,
        stream: mountDevicesStream,
      } = await mountDevices(streamRef.current);

      streamRef.current = mountDevicesStream;

      if (microphones.length !== micList.length) {
        if (microphones.length < micList.length) {
          const removedMicrophone = findRemovedDevicesById(
            micList,
            microphones
          );
          if (activeMicrophone === removedMicrophone) {
            try {
              await stream.switchMicrophone(microphones?.[0].deviceId);
            } catch (error: any) {
              showToast({
                variant: 'error',
                messageTitle: 'Error',
                messageBody: error.reason,
              });
            }
            setActiveMicrophone(microphones?.[0].deviceId);
          }
        }
        setMicList(microphones);
      }

      if (cameras.length !== cameraList.length) {
        if (cameras.length < cameraList.length) {
          const removedCamera = findRemovedDevicesById(cameraList, cameras);

          if (activeCamera === removedCamera) {
            setIsCameraActive(false);
            setActiveCamera(cameras?.[0].deviceId);
          }
        }
        setCameraList(cameras);
      }
    };

    zoomClient.on('device-change', deviceChangeHandler);

    return () => {
      stopTracks(streamRef.current);

      zoomClient.off('device-change', deviceChangeHandler);
    };
  }, [stream, cameraList, micList, activeCamera, activeMicrophone]);

  useEffect(() => {
    if (
      !stream ||
      !pastParticipants ||
      (!pastParticipants.length && !participants.length)
    )
      return;

    const { addedParticipants, removedParticipants } =
      findParticipantsDifference(
        pastParticipants.filter((participant) => participant.bVideoOn),
        participants.filter((participant) => participant.bVideoOn)
      );

    addedParticipants.forEach((participant) => {
      stream.attachVideo(
        participant.userId,
        VideoQuality.Video_720P,
        videoListRef.current[`${participant.userId}`]
      );
    });

    removedParticipants.forEach((participant) => {
      stream.detachVideo(participant.userId);
    });
  }, [stream, participants]);

  const handleShowSidebar = (pageName: PageNameType) => {
    setShowSidebar(true);
    setPageName(pageName);
  };

  const handleCloseSidebar = () => {
    setShowSidebar(false);
    setPageName(undefined);
    setActiveButton(undefined);
  };

  //MODALS
  const closeModalHandler = () => {
    setActiveModal(undefined);
  };

  const leaveMeetingHandler = async (
    status?:
      | MeetingStatus.ENDED
      | MeetingStatus.CANCELLED
      | MeetingStatus.NO_SHOW,
    reason?: string
  ) => {
    if (isMentor) {
      if (status) {
        endMeeting({ status, endMeetingReason: reason });
      }
    } else {
      const redirectURL =
        params.get('inMeeting') === 'true'
          ? `/home?finishedMeetingId=${meetingId}`
          : '/home';

      history.replace(redirectURL);
    }
  };

  const displayModalByActiveModal = () => {
    switch (activeModal) {
      case 'end-meeting':
        return isMentor ? (
          <MentorEndMeetingModal
            isOpen
            onClose={closeModalHandler}
            onPrimaryButtonClick={() =>
              leaveMeetingHandler(MeetingStatus.ENDED)
            }
            onSecondaryButtonClick={() => setActiveModal('meeting-status')}
          />
        ) : (
          <StudentEndMeetingModal
            isOpen
            onClose={closeModalHandler}
            onPrimaryButtonClick={() => leaveMeetingHandler()}
            onSecondaryButtonClick={closeModalHandler}
          />
        );
      case 'meeting-status':
        return (
          <MeetingStatusModal
            isOpen
            onClose={closeModalHandler}
            onStudentCancelledClick={() => setActiveModal('cancel-meeting')}
            onStudentNoShowClick={() => setActiveModal('confirm-no-show')}
          />
        );
      case 'cancel-meeting':
        return (
          <CancelMeetingModal
            isOpen
            onClose={closeModalHandler}
            onPrimaryButtonClick={() => setActiveModal('reschedule')}
            onSecondaryButtonClick={() =>
              leaveMeetingHandler(MeetingStatus.CANCELLED)
            }
          />
        );
      case 'confirm-no-show':
        return (
          <ConfirmNoShowModal
            isOpen
            onClose={closeModalHandler}
            onPrimaryButtonClick={(reason?: string) =>
              leaveMeetingHandler(MeetingStatus.NO_SHOW, reason)
            }
            onSecondaryButtonClick={closeModalHandler}
          />
        );
      case 'reschedule':
        return (
          <RescheduleModal
            isOpen
            meetingId={meetingId}
            onClose={closeModalHandler}
            onReschedule={() => leaveMeetingHandler(MeetingStatus.CANCELLED)}
          />
        );
      case 'settings':
        return (
          <SettingsModal
            isOpen
            onClose={closeModalHandler}
            backgroundSuppression={backgroundSuppression}
            virtualBackground={virtualBackground}
            onBackgroundSuppressionChange={(value) =>
              setBackgroundSuppression(value)
            }
            onVirtualBackgroundChange={(value) => setVirtualBackground(value)}
            micList={micList}
            cameraList={cameraList}
          />
        );
      default:
        return null;
    }
  };

  const handleCameraButtonClick = async () => {
    const myUserId = zoomClient?.getSessionInfo().userId;

    if (!stream || !myUserId || !!isCameraStateSwitchLoading) {
      return;
    }

    setIsCameraStateSwitchLoading(true);

    if (stream.isCapturingVideo()) {
      try {
        await stream.stopVideo();
        await stream.detachVideo(myUserId);
        setIsCameraActive(false);
      } catch (error: any) {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: error.message,
        });
      }
      setIsCameraStateSwitchLoading(false);

      return;
    }

    try {
      await startVideo({
        stream,
        cameraId: activeCamera,
        virtualBackground,
        onSuccess: () => setIsCameraActive(true),
        onFailure: startVideoFailureHandler,
      });
    } catch (error) {
      console.log(error);
    }

    setIsCameraStateSwitchLoading(false);
  };

  const raiseHandClickHandler = (senderId: number) => {
    if (!raisedHands[senderId]) {
      const audio = new Audio(RaiseHandSound);
      audio.play();
    }
    setRaisedHands((state) => ({ ...state, [senderId]: !state[senderId] }));
  };

  const thumbsUpClickHandler = (
    senderId: number,
    senderName: string,
    timestamp: number
  ) => {
    setThumbsUpList((state) => [...state, { senderId, senderName, timestamp }]);

    timeoutRefs.current[timestamp] = setTimeout(() => {
      setThumbsUpList((state) =>
        state.filter((thumbsUp) => thumbsUp.timestamp !== timestamp)
      );
    }, 5000);
  };

  const shareScreenClickHandler = async () => {
    if (
      !stream ||
      !screenShareCanvasContainerRef.current ||
      !screenShareVideoContainerRef.current
    )
      return;

    if (isSharing) {
      try {
        await stream.stopShareScreen();
        reinitializeCanvas();
      } catch (error: any) {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: error.reason,
        });
        throw new Error(error.reason);
      }
      return;
    }

    const screenShareContainer = stream?.isStartShareScreenWithVideoElement()
      ? screenShareVideoContainerRef.current
      : screenShareCanvasContainerRef.current;

    try {
      await stream.startShareScreen(screenShareContainer);
    } catch (error: any) {
      if (error.reason !== 'user deny screen share') {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: error.reason,
        });
      }
      throw new Error(error.reason);
    }
  };

  const handleMicrophoneButtonClick = async () => {
    const myUserId = zoomClient?.getSessionInfo().userId;

    if (!stream || !myUserId || !!isMicrophoneStateSwitchLoading) {
      return;
    }

    setIsMicrophoneStateSwitchLoading(true);

    if (stream.isAudioMuted()) {
      try {
        await stream.unmuteAudio();

        setIsMicrophoneActive(true);
      } catch (error: any) {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: error.message,
        });
      }
      setIsMicrophoneStateSwitchLoading(false);

      return;
    }

    try {
      await stream.muteAudio();
      setIsMicrophoneActive(false);
    } catch (error: any) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: error.message,
      });
    }

    setIsMicrophoneStateSwitchLoading(false);
  };

  const setVideoPlayerRef = (
    userId: number,
    element: VideoPlayerType | null
  ) => {
    if (element) {
      videoListRef.current[`${userId}`] = element;
    }
  };

  const isSharing = stream?.getShareStatus() === 'sharing';

  const getIsNetworkWarning = () => {
    if (!networkQuality) {
      return false;
    }

    if (isMentor) {
      return Object.values(networkQuality).some(
        (quality) => quality?.downlink < 2 || quality?.uplink < 2
      );
    }

    const myUserId = zoomClient?.getSessionInfo().userId;

    if (!myUserId) return false;

    const myNetworkQuality = networkQuality[myUserId];

    return myNetworkQuality?.downlink < 2 || myNetworkQuality?.uplink < 2;
  };

  if (isMeetingLoading) {
    return <VideoMeetingLoading />;
  }

  const participantsLength = zoomClient?.getAllUser()?.length || 0;

  return (
    <section className="flex flex-col justify-between h-full px-3 laptop:h-screen laptop:p-9">
      <div className="flex items-center justify-center flex-auto gap-4 relative h-[calc(100%-48px)] laptop:h-[calc(100%-72px)]">
        <div className="flex flex-col w-full h-full items-center justify-center gap-4">
          <VideoContent
            connectionState={connectionState}
            participantsLength={participantsLength}
            ownId={zoomClient?.getSessionInfo().userId}
            isViewingScreenShare={isViewingScreenShare}
            isSharing={isSharing}
            isScreenShareLoading={isScreenShareLoading}
            shouldRenderShareScreenContainer={shouldRenderShareScreenContainer}
            isStartShareScreenWithVideoElement={stream?.isStartShareScreenWithVideoElement()}
            participants={participants}
            thumbsUpList={thumbsUpList}
            speakingParticipants={speakingParticipants}
            raisedHands={raisedHands}
            screenShareVideoContainerRef={screenShareVideoContainerRef}
            screenShareCanvasContainerRef={screenShareCanvasContainerRef}
            onSetVideoRef={setVideoPlayerRef}
          />
        </div>

        {/* mobile only meeting actions */}
        <MeetingActionButtons
          setActiveButton={setActiveButton}
          activeButton={activeButton}
          handleShowSidebar={handleShowSidebar}
          handleCloseSidebar={handleCloseSidebar}
          isMentor={isMentor}
          unreadCount={unreadCount}
          className={mergeClassNames(
            'flex flex-row tablet:flex-col absolute right-0 top-1 gap-2 laptop:hidden'
          )}
          isNetworkWarning={getIsNetworkWarning()}
        />
        {showSidebar && (
          <VideoSidebar
            connectionState={connectionState}
            networkQuality={networkQuality}
            isScreenBeingShared={isSharing || isViewingScreenShare}
            chatMessages={chatMessages}
            handleCloseSidebar={handleCloseSidebar}
            pageName={pageName ?? 'chat'}
            unreadCount={unreadCount}
            onUnreadCountChange={setUnreadCount}
          />
        )}

        {thumbsUpList.map((thumbsUp) => (
          <ThumbsUpLottie
            key={thumbsUp.timestamp}
            userName={thumbsUp.senderName}
          />
        ))}
      </div>

      <section className="relative flex items-center justify-center mt-4 mb-6 laptop:mt-14 laptop:justify-between">
        <h1 className="hidden absolute left-0 tablet:flex laptop:static">
          {meetingDetails?.name || ''}
        </h1>

        {params.get('inMeeting') === 'true' &&
          connectionState === ConnectionState.Connected &&
          participantsLength > 0 && (
            <VideoActionButtons
              isCameraButtonLoading={isCameraStateSwitchLoading}
              isMicrophoneButtonLoading={isMicrophoneStateSwitchLoading}
              isShareScreenDisabled={isViewingScreenShare}
              setActiveModal={setActiveModal}
              onCameraButtonClick={handleCameraButtonClick}
              onMicrophoneButtonClick={handleMicrophoneButtonClick}
              onRaiseHandClick={raiseHandClickHandler}
              onThumbsUpClick={thumbsUpClickHandler}
              onShareScreenClick={shareScreenClickHandler}
            />
          )}

        {showFeedbackModal && (
          <FeedbackModal
            isOpen={true}
            meetingId={meetingId}
            onClose={() => setShowFeedbackModal(false)}
          />
        )}

        <MeetingActionButtons
          setActiveButton={setActiveButton}
          activeButton={activeButton}
          unreadCount={unreadCount}
          handleShowSidebar={handleShowSidebar}
          handleCloseSidebar={handleCloseSidebar}
          isMentor={isMentor}
          className="hidden laptop:flex"
          isNetworkWarning={getIsNetworkWarning()}
        />
      </section>
      {displayModalByActiveModal()}
    </section>
  );
};

export default VideoMeeting;
