import { HomeOutlined, ReadOutlined } from '@ant-design/icons';
import { CoursesSelector } from '@components';
import CustomStudySubjectsSelector from '@components/Selectors/CustomStudySubjectsSelector';
import { CloseModalProps } from '@shared/common';
import { useMentorData, useUpdateMentorData } from '@shared/react';
import { stringFormatters } from '@shared/utils';
import { Button, Drawer, Form, Input, Space, Tag, Typography } from 'antd';
import { useEffect, useState } from 'react';

const { Text } = Typography;

type Props = CloseModalProps & {
  mentorId: string;
};

const MentorSettingsDrawer = ({ visible, hide, mentorId }: Props) => {
  const [form] = Form.useForm();

  const [universities, setUniversities] = useState<string[]>([]);
  const [courses, setCourses] = useState<string[]>([]);
  const [majors, setMajors] = useState<string[]>([]);
  const [targetUniversity, setTargetUniversity] = useState('');
  const [targetMajor, setTargetMajor] = useState('');

  const { data: mentor } = useMentorData(mentorId);
  const { mutate: updateMentorData, isLoading: updatingMentorData } =
    useUpdateMentorData(mentorId);

  useEffect(() => {
    if (mentor) {
      form.setFieldsValue(mentor);
      setUniversities(mentor?.universities || []);
      setMajors(mentor?.majors || []);
      setCourses(mentor?.coursesCanTeach || []);
    }
  }, [mentor]);

  const addUniversity = () => {
    if (targetUniversity) {
      setUniversities([...universities, targetUniversity]);
    }
    setTargetUniversity('');
  };

  const removeUniversity = (removedItem: string) => {
    setUniversities(
      universities.filter((university) => university !== removedItem)
    );
    setTargetUniversity('');
  };

  const addMajor = () => {
    if (targetMajor) {
      setMajors([...majors, targetMajor]);
    }
    setTargetMajor('');
  };

  const removeMajor = (removedItem: string) => {
    setMajors(majors.filter((major) => major !== removedItem));
    setTargetMajor('');
  };

  const submitData = (query: any) => {
    updateMentorData(query);
    // refresh window in 2 seconds
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <Drawer
      title={
        mentor?.fullName
          ? `${mentor?.fullName}'s mentor settings`
          : 'mentor settings'
      }
      visible={visible}
      onClose={hide}
      width={736}
    >
      <Form
        form={form}
        onFinish={() =>
          submitData({
            ...form.getFieldsValue(),
            universities,
            majors,
            coursesCanTeach: courses,
          })
        }
      >
        <Space direction="vertical" style={{ width: '100%', marginBottom: 10 }}>
          <Space direction="vertical">
            <Text strong>University</Text>
            <Space direction="horizontal">
              {universities.map((university) => {
                return (
                  <Tag
                    key={university}
                    icon={<HomeOutlined />}
                    color="#cd201f"
                    closable
                    onClose={() => removeUniversity(university)}
                  >
                    {stringFormatters.capitalizeFirstLetter(university)}
                  </Tag>
                );
              })}
            </Space>
            <Input
              style={{ width: 300 }}
              value={targetUniversity}
              onChange={(e: any) => setTargetUniversity(e.target.value)}
            />
            <Button onClick={addUniversity}>Add University</Button>
          </Space>
          <Space direction="vertical">
            <Text strong>Major/Field of study</Text>
            <CustomStudySubjectsSelector
              majors={majors}
              setMajors={setMajors}
            />
          </Space>
          <Space direction="vertical">
            <Text strong>Courses they teach</Text>
            <CoursesSelector value={courses} onChange={setCourses} />
          </Space>
        </Space>
        <Button type="primary" htmlType="submit" loading={updatingMentorData}>
          Save
        </Button>
      </Form>
    </Drawer>
  );
};

export default MentorSettingsDrawer;
