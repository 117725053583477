import styles from '@styles/components/sider.module.scss';
import { ReactNode } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

const NavItem = ({
  route,
  icon,
  title,
}: {
  route: string;
  title: string | ReactNode;
  icon?: ReactNode;
}) => {
  const { pathname } = useLocation();

  const isActive = matchPath(pathname, {
    path: route,
    strict: false,
    exact: false,
  });

  return (
    <Link
      to={route}
      className={`${styles.navItem} ${isActive ? styles.navItemActive : ''}`}
      style={{ marginBottom: '8px' }}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={styles.title}>{title}</div>
    </Link>
  );
};

export default NavItem;
