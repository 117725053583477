import { ReactComponent as ArrowIcon } from '@assets/icons/V4/arrow-icon.svg';
import { ReactComponent as OptionsIcon } from '@assets/icons/V4/options-icon.svg';
import Button from '@components/V4/Button';
import IconButton from '@components/V4/IconButton';
import IconButtonDropdown from '@components/V4/IconButtonDropdown';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import moment from 'moment';
import { ApplicationStatuses } from '../../../../types';
import UniversitiesSectionForm from '../UniversitiesSectionForm';
import ApplicationStatusPill from './ApplicationStatusPill';
import { CardProps } from './UniversitiesSectionCard';

const UniversitiesSectionCardDesktop = (props: CardProps) => {
  const {
    userId,
    arrLength,
    dropdownItems,
    universityGoal,
    isShowMore,
    isEditMode,
    isDropdownOpen,
    setIsShowMore,
    setIsEditMode,
    setIsDropdownOpen,
    isUpdatingUserProfile,
    onCancelEdit,
    incrementOrderHandler,
    decrementOrderHandler,
  } = props;

  return (
    <div
      className={mergeClassNames(
        'hidden group relative flex-col justify-between gap-4 px-4 py-6 bg-surfaceHover rounded-lg tablet:flex tablet:px-8 tablet:gap-6 laptop:bg-transparent laptop:hover:bg-surfaceHover laptop:gap-2',
        {
          'laptop:bg-surfaceHover': isEditMode || isDropdownOpen,
        }
      )}
    >
      <div className="flex justify-between tablet:gap-6">
        <h3 className="text-customPrimary text-xl font-bold font-raleway">
          #{universityGoal.order}
        </h3>
        {isEditMode ? (
          <div className="w-full flex flex-col gap-5">
            <h4 className="text-base font-bold font-raleway ">
              {universityGoal.university.name}
            </h4>
            <UniversitiesSectionForm
              goalData={universityGoal}
              userId={userId}
              isModeAddNew={false}
              onCancel={onCancelEdit}
              setIsEditMode={setIsEditMode}
              className="px-0 w-full tablet:px-0"
              contentClassName="bg-opacity-50 pt-0 px-0 tablet:px-0 gap-2"
            />
          </div>
        ) : (
          <div className="flex flex-col gap-2 w-full">
            <div className="flex justify-end gap-4 w-full tablet:justify-between">
              <div className="hidden gap-4 tablet:flex">
                <h4 className="text-base font-bold font-raleway ">
                  {universityGoal.university.name}
                </h4>
                <ApplicationStatusPill
                  className="hidden laptop:flex"
                  status={universityGoal.status as ApplicationStatuses}
                />
              </div>

              <div
                className={mergeClassNames(
                  'flex items-center gap-3 tablet:h-fit laptop:hidden laptop:group-hover:flex',
                  {
                    'laptop:flex': isDropdownOpen,
                  }
                )}
              >
                {universityGoal.order !== 1 && (
                  <IconButton
                    className="flex items-center justify-center"
                    icon={<ArrowIcon />}
                    onClick={decrementOrderHandler}
                    isDisabled={isUpdatingUserProfile}
                  />
                )}
                {arrLength && universityGoal.order !== arrLength && (
                  <IconButton
                    className="flex items-center justify-center"
                    icon={<ArrowIcon className="rotate-180 " />}
                    onClick={incrementOrderHandler}
                    isDisabled={isUpdatingUserProfile}
                  />
                )}
                <IconButtonDropdown
                  className="flex"
                  icon={<OptionsIcon className="min-w-6" />}
                  items={dropdownItems}
                  onClick={() => setIsDropdownOpen((prev) => !prev)}
                />
              </div>
            </div>

            <div className="hidden flex-col gap-2 tablet:flex laptop:gap-3">
              <ApplicationStatusPill
                className="laptop:hidden"
                status={universityGoal.status as ApplicationStatuses}
              />

              <p className="text-sm">{universityGoal.major.name}</p>

              <div className="flex gap-3">
                <p className="text-sm text-customGrey">Application Deadline:</p>
                <p className="text-sm">
                  {universityGoal.applicationDeadline
                    ? moment(universityGoal.applicationDeadline).format(
                        'DD MMMM YYYY'
                      )
                    : ''}
                </p>
              </div>

              {isShowMore && (
                <>
                  <p className="text-sm laptop:flex">
                    <span className="text-sm text-customGrey min-w-fit mr-3">
                      Pro's:
                    </span>
                    {universityGoal.pros}
                  </p>

                  <p className="text-sm laptop:flex">
                    <span className="text-sm text-customGrey min-w-fit mr-3">
                      Con's:
                    </span>
                    {universityGoal.cons}
                  </p>

                  <p className="text-sm laptop:flex">
                    <span className="text-sm text-customGrey min-w-fit mr-3">
                      Academic Requirements:
                    </span>
                    {universityGoal.academicRequirements}
                  </p>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {!isEditMode && (
        <Button
          variant="text"
          className="text-customPrimary w-fit ml-auto laptop:absolute laptop:bottom-6 laptop:right-8 laptop:hidden laptop:group-hover:flex"
          buttonText={`Show ${isShowMore ? 'Less' : 'More'} Info`}
          onClick={() => setIsShowMore((prev) => !prev)}
        />
      )}
    </div>
  );
};

export default UniversitiesSectionCardDesktop;
