// import checkedIcon from '@assets/icons/checked.svg';
import { ArrowRightIcon, CheckIcon } from '@heroicons/react/outline';
import styles from '@styles/views/courses.module.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import Check from '../../../assets/icons/check-icon-green.svg';

const TitleCard = ({
  title,
  isCompleted,
  number,
  link,
  wide,
  color,
}: {
  title: string;
  isCompleted: boolean;
  number: string;
  link: string;
  wide?: boolean;
  color?: string;
}) => {
  return (
    <Link
      to={link}
      className={`${styles.lessonsTitlesItem} ${
        wide ? styles.lessonsTitlesItemWide : ''
      }`}
      data-completed={isCompleted}
      style={{
        backgroundColor: isCompleted ? 'white' : color,
        border: !isCompleted ? 'inherit' : '1px solid #F4F4F6',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
      }}
    >
      {/*TODO Remove unused code and move in-line styles to scss file if possible*/}
      {/*{isCompleted ? (*/}
      {/*  <span*/}
      {/*    style={{*/}
      {/*      // color: progress?.course?.color,*/}
      {/*      fontWeight: 900,*/}
      {/*      font: 'normal normal bold 12px Open Sans',*/}
      {/*      letterSpacing: '0.45px',*/}
      {/*      fontSize: '12px',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Next Up!*/}
      {/*  </span>*/}
      {/*) : (*/}
      {/*  <span*/}
      {/*    style={{*/}
      {/*      // color: progress?.course?.color,*/}
      {/*      fontWeight: 900,*/}
      {/*      font: 'normal normal bold 12px Open Sans',*/}
      {/*      letterSpacing: '0.45px',*/}
      {/*      fontSize: '12px',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Completed*/}
      {/*  </span>*/}
      {/*)}*/}
      <p
        style={{
          color: isCompleted ? color : 'white',
          font: 'normal normal medium 32px/59px Urbane Rounded',
          fontWeight: 'normal',
          marginBottom: '0px',
        }}
        className={`${styles.lessonsTitlesItemDescriptionNumber}`}
      >
        {number}
      </p>
      <div
        className={`${styles.lessonsTitlesItemDescription}`}
        style={{
          minHeight: '94px',
          width: '30px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {/* <div className={`${styles.lessonsTitlesItemDescriptionInfo}`}> */}
        <p
          className={`${styles.lessonsTitlesItemDescriptionName}`}
          style={{
            paddingBottom: '0px',
            marginTop: '14px',
            whiteSpace: 'pre-wrap',
          }}
        >
          {title}
        </p>
        {/*{isCompleted && (*/}
        {/*  <p className={`${styles.lessonsTitlesItemDescriptionText}`}>*/}
        {/*    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae ab*/}
        {/*    cum sequi corporis magnam iusto*/}
        {/*  </p>*/}
        {/*)}*/}
        {/* </div> */}
      </div>
      {isCompleted ? (
        // <CheckIcon
        //   style={{ color: color }}
        //   className={`${styles.lessonsTitlesItemIcon}`}
        // />
        <img src={Check} style={{ width: '17px' }} />
      ) : (
        <span
          style={{
            width: 'fit-content',
            display: 'flex',
            whiteSpace: 'nowrap',
          }}
          className={`${styles.lessonsTitlesItemCTA}`}
        >
          Start Now
          <ArrowRightIcon className={`${styles.lessonsTitlesItemCTAIcon}`} />
        </span>
      )}
    </Link>
  );
};

export default TitleCard;
