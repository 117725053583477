import React from 'react';
import { COLORS } from '../../../v3/global';
import './profile-modal-mentors.styles.scss';

const AcademicExperienceDetailsSection = () => {
  return (
    <div style={{ color: COLORS.TEXT_PRIMARY, fontSize: '14px' }}>
      Academic Experience Details Section
    </div>
  );
};

export default AcademicExperienceDetailsSection;
