export const phoneRegex = new RegExp(
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
);

export const PUBLIC_PATHS = [
  '/parent-view',
  '/auth',
  '/licenses',
  '/payment/success',
  '/referral',
  '/thank-you',
  '/login-error',
  '/terms-and-conditions',
];
