// Input based on AgoraInput that accepts REFS from react-hook-form

import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import './input-styles.scss';
import { forwardRef } from 'react';
import InputError from './InputError';
import InputLabel from './InputLabel';

type Props = {
  type: string;
  name: string;
  id: string;
  size?: 'default' | 'large';
  placeholder?: string;
  label: string;
  value?: string | number;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  rows?: number;
  isRequired?: boolean;
  className?: string;
  isDisabled?: boolean;
  isValid?: boolean;
  isTouched?: boolean;
  errorText?: string;
  autofocus?: boolean;
};

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    type,
    name,
    id,
    label,
    placeholder,
    isRequired,
    isTouched,
    isValid,
    errorText,
    className,
    value,
    isDisabled = false,
    autofocus,
    size = 'default',
    onChange,
    onBlur,
  } = props;

  const sizeStyles = {
    large: 'h-15',
    default: 'h-10',
  };

  return (
    <div className="w-full">
      {label && (
        <InputLabel
          label={label}
          isDisabled={isDisabled}
          isRequired={isRequired}
          isTouched={isTouched}
          isValid={isValid}
          htmlFor={id}
        />
      )}

      <input
        className={mergeClassNames(
          {
            'block w-full py-2 px-2.5 mt-2 bg-transparent text-customGrey border border-customGrey rounded-lg text-xs input-autofill hover:bg-inputHover focus:bg-inputHover focus:text-white focus:border-customGrey focus-visible:border-customGrey focus:ring-0 focus-visible:ring-0 placeholder:text-placeholderColor':
              true,
            'text-customPrimary border-customPrimary focus:!border-customPrimary focus:text-white input-autofill-valid':
              isValid && isTouched && !isDisabled,
            'text-customRed border-customRed focus:!border-customRed focus:text-white input-autofill-error':
              !isValid && isTouched && !isDisabled,
            'input-autofill-default': !isTouched,
            'hover:bg-transparent border-opacity-50': isDisabled,
          },
          sizeStyles[size],
          className
        )}
        disabled={isDisabled}
        ref={ref}
        value={value}
        onChange={onChange}
        type={type}
        autoFocus={autofocus}
        name={name}
        id={id}
        placeholder={placeholder}
        onBlur={onBlur}
      />

      {!isValid && isTouched && errorText && (
        <InputError errorText={errorText} />
      )}
    </div>
  );
});

export default Input;
