import Pill from '@components/V4/Pill';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { ApplicationStatuses } from '../../../../types';

interface ApplicationStatusPillProps {
  status: ApplicationStatuses;
  className?: string;
}

const ApplicationStatusPill = (props: ApplicationStatusPillProps) => {
  const { status, className } = props;
  const statusPresets = {
    notStarted: {
      text: 'Application Not Started',
      color: 'bg-customRed',
    },
    inProgress: {
      text: 'Application In Progress',
      color: 'bg-customGold',
    },
    sent: {
      text: 'Application Sent',
      color: 'bg-customLila',
    },
    received: {
      text: 'Offer Received',
      color: 'bg-customLime',
    },
    accepted: {
      text: 'Offer Accepted',
      color: 'bg-customPrimary',
    },
    rejected: {
      text: 'Offer Rejected',
      color: 'bg-customGrey',
    },
  };

  return (
    <Pill
      isReadOnly
      size="small"
      text={statusPresets[status]?.text}
      className={mergeClassNames(statusPresets[status]?.color, className)}
    />
  );
};

export default ApplicationStatusPill;
