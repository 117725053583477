import { ReactComponent as ClockIcon } from '@assets/icons/clock-icon.svg';
import InputLabel from '@components/V4/Inputs/InputLabel';
import styles from '@styles/components/reusables/modal.module.scss';
import { AutoComplete } from 'antd';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { FC } from 'react';
import './agora-time-picker.styles.scss';
import AgoraInputError from '../AgoraInputError';

type Props = {
  time: string;
  handleChange: any;
  handleFocus: any;
  date?: Moment;
  disablePastHours?: boolean;
  className?: string;
  onBlur?: (e: any) => void;
  isValid?: boolean;
  isTouched?: boolean;
  isRequired?: boolean;
  errorText?: string;
  label?: string;
  allowClear?: boolean;
};

function AgoraTimePicker(props: Props) {
  const {
    time,
    handleChange,
    handleFocus,
    date,
    disablePastHours,
    className,
    onBlur,
    isValid,
    isTouched,
    isRequired,
    errorText,
    label,
    allowClear = true,
  } = props;

  const timeArray = [];
  // create time list
  for (let i = 0; i <= 23; i++) {
    const hour = `${i}:00`;
    const half = `${i}:30`;
    timeArray.push(moment(hour, 'H:mm').format('HH:mm'));
    timeArray.push(moment(half, 'H:mm').format('HH:mm'));
  }

  const isDisabledTime = (time: string) => {
    const isToday = date && moment(date)?.isSame(moment(), 'day');
    if (isToday) {
      if (time && moment(time, 'HH:mm') < moment()) {
        return true;
      }
    }
    return false;
  };

  return (
    <div
      className={classNames(
        'agora-time-picker',
        className,
        isValid && isTouched
          ? 'agora-time-picker--valid'
          : !isValid && isTouched
          ? 'agora-time-picker--error'
          : ''
      )}
    >
      {label && (
        <InputLabel
          label={label}
          isRequired={isRequired}
          isTouched={isTouched}
          isValid={isValid}
        />
      )}

      <AutoComplete
        value={time}
        notFoundContent={<div style={{ height: '0' }}></div>}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={onBlur}
        showSearch
        allowClear={allowClear}
      >
        {timeArray.map((timeOption, index: number) => (
          <AutoComplete.Option
            className={styles.newMeetingModalSelectTime}
            disabled={
              isDisabledTime && disablePastHours && isDisabledTime(timeOption)
            }
            key={index}
            value={timeOption}
          >
            {timeOption}
          </AutoComplete.Option>
        ))}
      </AutoComplete>

      {!isValid && isTouched && errorText && (
        <AgoraInputError errorText={errorText} />
      )}
    </div>
  );
}

// ! use only outside Form.Item
export const CustomTimePickerIcon: FC<{ timepickerFocus: boolean }> = ({
  timepickerFocus,
}) => (
  <div className={styles.newMeetingModalSelectTimeIcon}>
    {timepickerFocus && <ClockIcon />}
  </div>
);

export default AgoraTimePicker;
