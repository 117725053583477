import defaultAvatar from '@assets/default-user.png';
import { useUserDetails, useUserEnrollments } from '@shared/react';
import styles from '@styles/pages/mentor/my-students.module.scss';
import { Avatar } from 'antd';
import { COLORS } from '../../../../v3/global';
import AdditionalInfoCard from './AdditionalInfoCard';
import ContactInfoCard from './ContactInfoCard';
import ParentInfoCard from './ParentInfoCard';

type Props = {
  studentId: string;
};

const StudentDetails = ({ studentId }: Props) => {
  const { data: student, isLoading } = useUserDetails(studentId);

  if (isLoading) return <p>Loading...</p>;

  return (
    <div className={styles.studentModal}>
      <div className={styles.studentModalHead}>
        <Avatar
          size="large"
          src={student?.avatar || defaultAvatar}
          className={styles.studentModalHeadAvatar}
        />
        <div style={{ color: COLORS.TEXT_PRIMARY }}>
          {student?.fullName}'s Details
        </div>
      </div>

      <div className={styles.studentModalInfo}>
        <ContactInfoCard studentId={studentId} />
        <ParentInfoCard studentId={studentId} />
        <AdditionalInfoCard studentId={studentId} />
      </div>
    </div>
  );
};

export default StudentDetails;
