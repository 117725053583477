import thumbsUp from '@assets/lottie/thumbs-up.json';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';

interface ThumbsUpLottieProps {
  userName: string;
}

const ThumbsUpLottie = (props: ThumbsUpLottieProps) => {
  const { userName } = props;

  const [leftPosition, setLeftPosition] = useState<number>();

  useEffect(() => {
    const randomLeft = Math.random() * (window.innerWidth / 4);

    setLeftPosition(randomLeft);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: thumbsUp,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    clickToPause: false,
  };

  return (
    <div
      className={mergeClassNames(
        'flex flex-col items-center gap-2 absolute bottom-0 z-50 animate-moveUpFade',
        { hidden: !leftPosition }
      )}
      style={{ left: leftPosition }}
    >
      <Lottie
        isClickToPauseDisabled={true}
        options={defaultOptions}
        height={200}
        width={200}
      />
      {userName && (
        <span className="flex items-center bg-white text-black text-xs rounded-full p-2 max-w-min -mt-16">
          {userName}
        </span>
      )}
    </div>
  );
};

export default ThumbsUpLottie;
