import {
  ApiData,
  ApiDescription,
  KnowledgeBaseChat,
  RequestsMethod,
} from '@shared/types';

export const createNewKnowledgeBaseChat: ApiDescription = {
  endpoint: '/knowledge-base-chat',
  method: RequestsMethod.Post,
};
export type CreateNewKnowledgeBaseChat = ApiData<unknown, KnowledgeBaseChat>;

export const deleteKnowledgeBaseChat: ApiDescription = {
  endpoint: '/knowledge-base-chat/:id',
  method: RequestsMethod.Delete,
};

export type DeleteKnowledgeBaseChat = ApiData<never, unknown>;

export const getKnowledgeBaseChat: ApiDescription = {
  endpoint: '/knowledge-base-chat/:id',
  method: RequestsMethod.Get,
};
export type GetKnowledgeBaseChat = ApiData<never, KnowledgeBaseChat>;

export const updateKnowledgeBaseChat: ApiDescription = {
  endpoint: '/knowledge-base-chat/:id',
  method: RequestsMethod.Patch,
};

export type UpdateKnowledgeBaseChat = ApiData<
  {
    message: any;
    title?: string;
  },
  KnowledgeBaseChat
>;

export const getUserKnowledgeBaseChatsTitles: ApiDescription = {
  endpoint: '/knowledge-base-chat',
  method: RequestsMethod.Get,
};

export type GetUserKnowledgeBaseChatsTitles = ApiData<
  never,
  KnowledgeBaseChat[]
>;

export const handlerKnowledgeBaseChatWS: ApiDescription = {
  endpoint: '/handler-chat-ws',
  method: RequestsMethod.Ws,
};

export type HandlerKnowledgeBaseChatWS = ApiData<unknown, unknown>;
