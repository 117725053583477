import { Navigation } from '@components';
import Base from '@modules/Base';
import PendingNPS from '@modules/Base/Home/PendingNPS';
import React from 'react';
import './admin.styles.scss';

const AdminView: React.FC = () => {
  return (
    <Navigation>
      <div className="text-selector">
        <PendingNPS />
        <Base />
      </div>
    </Navigation>
  );
};

export default AdminView;
