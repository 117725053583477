import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import ZoomVideo, {
  LocalAudioTrack,
  LocalVideoTrack,
  MediaDevice,
} from '@zoom/videosdk';
import useToast from 'apps/agora/src/hooks/useToast';
import { useEffect, useRef, useState, useContext, RefObject } from 'react';
import {
  findRemovedDevicesById,
  mountDevices,
  stopTracks,
} from '../utils/helpers';

interface UseLocalTracksProps {
  videoRef: RefObject<any>;
  initializeVideo?: boolean;
  initializeAudio?: boolean;
  isCameraActive?: boolean;
  isMicrophoneActive?: boolean;
  activeCamera?: string;
  activeMicrophone?: string;
  onActiveMicrophoneChange?: (microphoneId: string) => void | Promise<void>;
  onActiveCameraChange?: (
    cameraId: string,
    shouldStartCamera?: boolean
  ) => void | Promise<void>;
  onSetIsCameraActive?: (isActive: boolean) => void;
  onSetIsMicrophoneActive?: (isActive: boolean) => void;
}

interface DeviceError {
  deviceLabel: string;
  name: string;
  message: string;
}

const useLocalTracks = (props: UseLocalTracksProps) => {
  const {
    initializeVideo = true,
    initializeAudio = true,
    isCameraActive = false,
    isMicrophoneActive = false,
    activeCamera,
    activeMicrophone,
    onSetIsCameraActive,
    onSetIsMicrophoneActive,
    onActiveMicrophoneChange,
    onActiveCameraChange,
    videoRef,
  } = props;

  const [micList, setMicList] = useState<MediaDevice[]>([]);
  const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
  const [initialSetupCompleted, setInitialSetupCompleted] = useState(false);

  const [isCameraStateSwitchLoading, setIsCameraStateSwitchLoading] =
    useState(false);
  const [isMicrophoneStateSwitchLoading, setIsMicrophoneStateSwitchLoading] =
    useState(false);

  const [isCameraSwitchLoading, setIsCameraSwitchLoading] = useState(false);
  const [isMicrophoneSwitchLoading, setIsMicrophoneSwitchLoading] =
    useState(false);

  const [error, setError] = useState<{
    name: string;
    message: string;
  }>();

  const [camerasWithErrors, setCamerasWithErrors] = useState<
    Record<string, DeviceError>
  >({});

  const [microphonesWithErrors, setMicrophonesWithErrors] = useState<
    Record<string, DeviceError>
  >({});

  const streamRef = useRef<MediaStream | undefined>();
  const isMountedRef = useRef(false);

  const localAudioRef = useRef<LocalAudioTrack | null>(null);
  const localVideoRef = useRef<LocalVideoTrack | null>(null);

  const { setCanJoinMeeting } = useContext(ZoomContext);
  const [showToast] = useToast({ duration: 'infinite' });

  const removeCameraErrorIfPresent = (deviceId: string) => {
    if (camerasWithErrors[deviceId]) {
      setCamerasWithErrors((prev) => {
        const newCameraObject = { ...prev };

        delete newCameraObject[deviceId];

        return newCameraObject;
      });
    }
  };

  const removeMicrophoneErrorIfPresent = (deviceId: string) => {
    if (microphonesWithErrors[deviceId]) {
      setMicrophonesWithErrors((prev) => {
        const newMicrophoneObject = { ...prev };

        delete newMicrophoneObject[deviceId];

        return newMicrophoneObject;
      });
    }
  };

  const addCameraError = (
    deviceId: string,
    label: string,
    error: DeviceError
  ) => {
    setCamerasWithErrors((prev) => ({
      ...prev,
      [deviceId]: {
        deviceLabel: label,
        name: error.name,
        message: error.message,
      },
    }));
  };

  const addMicrophoneError = (
    deviceId: string,
    label: string,
    error: DeviceError
  ) => {
    setMicrophonesWithErrors((prev) => ({
      ...prev,
      [deviceId]: {
        deviceLabel: label,
        name: error.name,
        message: error.message,
      },
    }));
  };

  const tryInitializeAudio = async (devices: MediaDevice[], index = 0) => {
    if (index >= devices.length) {
      showToast({
        variant: 'warning',
        messageTitle: 'Error',
        messageBody: 'Failed to start any microphone',
      });
      setIsMicrophoneStateSwitchLoading(false);
      return;
    }

    const deviceId = devices[index].deviceId;
    const audioTrack = ZoomVideo.createLocalAudioTrack(deviceId);
    localAudioRef.current = audioTrack;

    try {
      await audioTrack.start();

      if (!isMountedRef.current) return;

      await audioTrack.unmute();

      if (!isMountedRef.current) return;

      onSetIsMicrophoneActive?.(true);

      await onActiveMicrophoneChange?.(deviceId);

      setIsMicrophoneStateSwitchLoading(false);

      removeMicrophoneErrorIfPresent(deviceId);
    } catch (error: any) {
      audioTrack.stop().catch(console.log);
      localAudioRef.current = null;

      addMicrophoneError(deviceId, devices[index].label, error);

      // Retry with the next device
      await tryInitializeAudio(devices, index + 1);
    }
  };

  const tryInitializeVideo = async (devices: MediaDevice[], index = 0) => {
    if (index >= devices.length) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Failed to start any camera',
      });
      setIsCameraStateSwitchLoading(false);
      return;
    }

    const deviceId = devices[index].deviceId;
    const videoTrack = ZoomVideo.createLocalVideoTrack(deviceId);
    localVideoRef.current = videoTrack;

    try {
      await videoTrack.start(videoRef.current);
      if (!isMountedRef.current) return;

      onSetIsCameraActive?.(true);
      await onActiveCameraChange?.(deviceId);
      setIsCameraStateSwitchLoading(false);

      removeCameraErrorIfPresent(deviceId);
    } catch (error: any) {
      videoTrack.stop().catch(console.log);
      localVideoRef.current = null;

      addCameraError(deviceId, devices[index].label, error);

      // Retry with the next device
      await tryInitializeVideo(devices, index + 1);
    }
  };

  const initializeDevices = () => {
    if (isCameraStateSwitchLoading || isMicrophoneStateSwitchLoading) {
      return;
    }

    setInitialSetupCompleted(false);
    setIsCameraStateSwitchLoading(true);
    setIsMicrophoneStateSwitchLoading(true);
    setCanJoinMeeting(false);

    mountDevices(streamRef.current, 5)
      .then(async ({ microphones, cameras, stream }) => {
        if (!isMountedRef.current) return;

        streamRef.current = stream;

        if (initializeAudio && microphones.length > 0) {
          setMicList(microphones);

          await tryInitializeAudio(microphones);
        }

        if (initializeVideo && videoRef.current && cameras.length > 0) {
          setCameraList(cameras);

          await tryInitializeVideo(cameras);
        }

        if (!isMountedRef.current) return;

        setInitialSetupCompleted(true);
      })
      .catch((error) => {
        setIsCameraStateSwitchLoading(false);
        setIsMicrophoneStateSwitchLoading(false);
        setError({ name: error.name, message: error.message });
      })
      .finally(() => setCanJoinMeeting(true));
  };

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    isMountedRef.current = true;

    initializeDevices();

    return () => {
      isMountedRef.current = false;

      stopTracks(streamRef.current);

      localAudioRef.current?.stop().catch(console.log);
      localVideoRef.current?.stop().catch(console.log);
    };
  }, []);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const deviceChangeHandler = async () => {
      if (!initialSetupCompleted) {
        return;
      }

      setCanJoinMeeting(false);
      const { microphones, cameras } = await mountDevices(streamRef.current);

      if (microphones.length !== micList.length) {
        if (microphones.length < micList.length) {
          const removedMicrophone = findRemovedDevicesById(
            micList,
            microphones
          );

          if (activeMicrophone === removedMicrophone) {
            const audioTrack = ZoomVideo.createLocalAudioTrack(
              microphones[0].deviceId
            );

            localAudioRef.current = audioTrack;

            try {
              await audioTrack.start();

              await onActiveMicrophoneChange?.(microphones[0].deviceId);

              if (isMicrophoneActive) {
                await audioTrack.unmute();
              }

              removeMicrophoneErrorIfPresent(microphones[0].deviceId);
            } catch (error: any) {
              addMicrophoneError(
                microphones[0].deviceId,
                microphones[0].label,
                error
              );

              console.error(
                'Failed to start or unmute new audio track:',
                error
              );
            }
          }
        }
        setMicList(microphones);
      }

      if (cameras.length !== cameraList.length) {
        if (cameras.length < cameraList.length) {
          const removedCamera = findRemovedDevicesById(cameraList, cameras);

          if (activeCamera === removedCamera) {
            if (isCameraActive) {
              try {
                await localVideoRef.current?.stop();
                stopTracks(streamRef.current, 'video');
              } catch (error) {
                console.error('Failed to stop video:', error);
              }
            }

            const videoTrack = ZoomVideo.createLocalVideoTrack(
              cameras[0].deviceId
            );

            await onActiveCameraChange?.(cameras[0].deviceId, false);

            localVideoRef.current = videoTrack;

            if (isCameraActive) {
              try {
                await videoTrack?.start(videoRef.current);

                removeCameraErrorIfPresent(cameras[0].deviceId);
              } catch (error: any) {
                addCameraError(cameras[0].deviceId, cameras[0].label, error);

                console.log({ error });
              }
            }
          }
        }
        setCameraList(cameras);
      }
      setCanJoinMeeting(true);
    };

    navigator.mediaDevices.addEventListener(
      'devicechange',
      deviceChangeHandler
    );

    return () =>
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        deviceChangeHandler
      );
  }, [
    micList,
    cameraList,
    initialSetupCompleted,
    isMicrophoneActive,
    isCameraActive,
    camerasWithErrors,
    microphonesWithErrors,
    onActiveCameraChange,
  ]);

  const toggleMicrophone = async (isMicrophoneActive: boolean) => {
    if (isMicrophoneStateSwitchLoading) return;

    if (!activeMicrophone) {
      return showToast({
        variant: 'warning',
        messageBody: 'Please select an input audio device.',
      });
    }

    setIsMicrophoneStateSwitchLoading(true);

    try {
      if (isMicrophoneActive) {
        await localAudioRef.current?.mute();
      } else {
        await localAudioRef.current?.unmute();
      }

      onSetIsMicrophoneActive?.(!isMicrophoneActive);
    } catch (error: any) {
      const microphoneLabel = micList.find(
        (mic) => mic.deviceId === activeMicrophone
      )?.label;

      addMicrophoneError(activeMicrophone, microphoneLabel || '', error);
    }
    setIsMicrophoneStateSwitchLoading(false);
  };

  const toggleCamera = async (isCameraActive: boolean) => {
    if (!videoRef.current || isCameraStateSwitchLoading) return;

    if (!activeCamera) {
      return showToast({
        variant: 'warning',
        messageBody: 'Please select a video device.',
      });
    }
    setIsCameraStateSwitchLoading(true);

    try {
      if (isCameraActive) {
        await localVideoRef.current?.stop();
        stopTracks(streamRef.current, 'video');
      } else {
        await localVideoRef.current?.start(videoRef.current);

        removeCameraErrorIfPresent(activeCamera);
      }
      onSetIsCameraActive?.(!isCameraActive);
    } catch (error: any) {
      const cameraLabel = cameraList.find(
        (mic) => mic.deviceId === activeMicrophone
      )?.label;

      addCameraError(activeCamera, cameraLabel || '', error);
    }

    setIsCameraStateSwitchLoading(false);
  };

  const changeMicrophone = async (value: string) => {
    if (isMicrophoneSwitchLoading) return;

    setIsMicrophoneSwitchLoading(true);

    try {
      const audioTrack = ZoomVideo.createLocalAudioTrack(value);
      localAudioRef.current = audioTrack;

      await audioTrack.start();

      if (isMicrophoneActive) {
        await audioTrack.unmute();
        onSetIsMicrophoneActive?.(true);
      }
      await onActiveMicrophoneChange?.(value);

      removeMicrophoneErrorIfPresent(value);
    } catch (error: any) {
      const microphoneLabel = micList.find(
        (mic) => mic.deviceId === value
      )?.label;

      addMicrophoneError(value, microphoneLabel || '', error);
      onSetIsMicrophoneActive?.(false);
    }

    setIsMicrophoneSwitchLoading(false);
  };

  const changeCamera = async (value: string, shouldStartCamera = false) => {
    if (isCameraSwitchLoading) return;

    setIsCameraSwitchLoading(true);

    if (!isCameraActive) {
      const videoTrack = ZoomVideo.createLocalVideoTrack(value);
      localVideoRef.current = videoTrack;
      await onActiveCameraChange?.(value);

      setIsCameraSwitchLoading(false);
      return;
    }

    try {
      await localVideoRef.current?.switchCamera(value);

      await onActiveCameraChange?.(value, shouldStartCamera);

      removeCameraErrorIfPresent(value);
    } catch (error: any) {
      if (activeCamera) {
        localVideoRef.current = ZoomVideo.createLocalVideoTrack(activeCamera);
      }

      const cameraLabel = cameraList.find(
        (mic) => mic.deviceId === value
      )?.label;

      addCameraError(value, cameraLabel || '', error);
      onSetIsCameraActive?.(false);
    }

    setIsCameraSwitchLoading(false);
  };

  return {
    error,
    camerasWithErrors,
    microphonesWithErrors,
    isCameraSwitchLoading,
    isMicrophoneSwitchLoading,
    isCameraStateSwitchLoading,
    isMicrophoneStateSwitchLoading,
    micList,
    cameraList,
    retryInitializeDevices: initializeDevices,
    toggleMicrophone,
    toggleCamera,
    changeMicrophone,
    changeCamera,
  };
};

export default useLocalTracks;
