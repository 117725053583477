import { ReactComponent as EditIcon } from '@assets/icons/V4/edit-icon.svg';
import { ReactComponent as NoDataIcon } from '@assets/icons/V4/no-data.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/V4/trash-icon.svg';
import IconButton from '@components/V4/IconButton';
import Table from '@components/V4/Table/Table';
import { ACTIVITY_TYPE, EMPLOYMENT_TYPE, Experience } from '@shared/common';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import moment from 'moment';
import { useContext, useState } from 'react';
import SessionModalSection, {
  ActionButton,
} from '../../../../SessionModalSection';
import { StudentDetailsPageContext } from '../../../StudentDetailsPage';
import WorkExperienceSectionForm from './WorkExperienceSectionForm';

interface WorkExperienceSectionProps extends BasicProps {
  experiences?: Experience[];
}

const WorkExperienceSection = (props: WorkExperienceSectionProps) => {
  const { experiences } = props;

  const { student } = useContext(StudentDetailsPageContext);

  const studentId = student?._id ?? '';

  const [isAddingWorkExperience, setIsAddingWorkExperience] = useState(false);
  const [workExperienceToEdit, setWorkExperienceToEdit] = useState<any>();

  const [showToast] = useToast();

  const { mutate: updateUserProfile } = useSaveUserProfile(studentId, {
    onSuccess: () => {
      showToast({
        variant: 'info',
        messageBody: 'Experience deleted successfully.',
      });
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageBody: 'Experience could not be deleted.',
      });
    },
  });

  const workExperienceButtons: ActionButton[] = [
    {
      buttonText: 'Add Experience',
      onClick: () => setIsAddingWorkExperience(true),
    },
  ];

  const getDuration = (workExperienceItem: any) => {
    const startDate = moment(workExperienceItem.startDate).format('DD.MM.YYYY');
    const endDate = moment(workExperienceItem.endDate).format('DD.MM.YYYY');

    return `${startDate} - ${endDate}`;
  };

  const getEmploymentOrActivityType = (item: Experience) => {
    if (item.employmentType !== undefined) {
      return EMPLOYMENT_TYPE[item.employmentType];
    }

    if (item.activityType !== undefined) {
      return ACTIVITY_TYPE[item.activityType];
    }

    return undefined;
  };

  const deleteWorkExperienceClickHandler = (workExperienceId: string) => {
    updateUserProfile({
      experiences: [
        {
          _id: workExperienceId,
          delete: true,
        },
      ],
    });
  };

  return isAddingWorkExperience ? (
    <WorkExperienceSectionForm
      isModeAddNew
      onCloseEditForm={() => setIsAddingWorkExperience(false)}
    />
  ) : (
    <SessionModalSection
      className="mt-6"
      sectionTitle="Experience"
      buttonsArray={workExperienceButtons}
    >
      {experiences?.length ? (
        <Table size="small">
          <Table.Header>
            <Table.Row isHeader>
              <Table.HeaderCell className="w-1/4">Position</Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">Duration</Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">Type</Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {experiences.map((item) => (
              <Table.Row key={item._id}>
                {workExperienceToEdit?._id === item._id ? (
                  <Table.Cell className="!p-0" colSpan={4}>
                    <WorkExperienceSectionForm
                      className="rounded-none"
                      workExperienceData={item}
                      onCloseEditForm={() => setWorkExperienceToEdit(undefined)}
                    />
                  </Table.Cell>
                ) : (
                  <>
                    <Table.Cell>{item.positionTitle}</Table.Cell>
                    <Table.Cell>{getDuration(item)}</Table.Cell>
                    <Table.Cell>{getEmploymentOrActivityType(item)}</Table.Cell>
                    <Table.Cell>
                      <div className="flex gap-4">
                        <IconButton
                          size="small"
                          variant="ghost"
                          icon={<EditIcon />}
                          onClick={() => setWorkExperienceToEdit(item)}
                        />
                        <IconButton
                          size="small"
                          variant="ghost"
                          color="danger"
                          icon={<TrashIcon />}
                          onClick={() =>
                            deleteWorkExperienceClickHandler(item?._id ?? '')
                          }
                        />
                      </div>
                    </Table.Cell>
                  </>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <EmptyStateCard
          text="No Existing Data"
          icon={<NoDataIcon className="text-customGrey" />}
        />
      )}
    </SessionModalSection>
  );
};

export default WorkExperienceSection;
