import { ReactComponent as OptionsIcon } from '@assets/icons/V4/options-icon.svg';
import { ReactComponent as BinIcon } from '@assets/icons/bin-scaled-icon.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg';
import { ReactComponent as ExamIcon } from '@assets/icons/profile-icons/exam-icon.svg';
import Button from '@components/V4/Button';
import { DropdownItem } from '@components/V4/Dropdown';
import IconButtonDropdown from '@components/V4/IconButtonDropdown';
import moment from 'moment';
import React, { useState } from 'react';
import LogProgressForm from './LogProgressForm';
import UpcomingExamsForm from './UpcomingExamsForm';
import { ExamsCardProps } from './UpcomingExamsSection';

const UpcomingExamsCard = (props: ExamsCardProps) => {
  const { userId, languageExam, onDeleteEntry } = props;

  const [isEditMode, setIsEditMode] = useState(false);
  const [isLogProgress, setIsLogProgress] = useState(false);

  const cancelEditHandler = () => {
    setIsEditMode(false);
  };

  const getInnerCardContent = () => {
    if (isEditMode) {
      return (
        <UpcomingExamsForm
          userId={userId}
          isModeAddNew={false}
          setIsEditMode={setIsEditMode}
          onCancel={cancelEditHandler}
          examData={languageExam}
          className="hidden w-full tablet:flex tablet:px-0"
          contentClassName="bg-opacity-50"
        />
      );
    }

    if (isLogProgress) {
      return (
        <LogProgressForm
          isModeAddNew={false}
          userId={userId}
          examData={languageExam}
          setIsLogProgress={setIsLogProgress}
          onCancel={() => setIsLogProgress(false)}
          className="hidden w-full tablet:flex tablet:px-0"
          contentClassName="bg-opacity-50"
        />
      );
    }

    return (
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <p>Date:</p>
          <p>{moment(languageExam.date).format('DD MMMM YYYY')}</p>
        </div>
        <div className="flex gap-2">
          <p>Last Score:</p>
          <p>{languageExam.prevGrade}</p>
        </div>
        <div className="flex gap-2">
          <p>Target Score:</p>
          <p>{languageExam.targetGrade}</p>
        </div>
        <div className="flex gap-2">
          <p>Areas of improvement:</p>
          <p>{languageExam.areasOfImprovement}</p>
        </div>
        <div className="">
          <Button
            variant="primary"
            buttonText="Log Progress"
            onClick={() => setIsLogProgress((prev) => !prev)}
          />
        </div>
      </div>
    );
  };

  const dropdownItems: DropdownItem[] = [
    {
      icon: <EditIcon className="w-3 h-3" />,
      text: 'Edit',
      onClick: () => setIsEditMode(true),
    },
    {
      icon: <BinIcon className="w-3 text-customRed" />,
      text: 'Delete',
      onClick: () => onDeleteEntry(languageExam._id),
    },
  ];
  return (
    <div className="flex flex-col justify-between gap-4 px-4 py-6 bg-customBlue rounded-lg tablet:flex-row tablet:px-8 tablet:gap-6">
      <div className="flex justify-between w-full">
        <div className="flex flex-col  gap-4 tablet:gap-6 tablet:flex-1">
          <div className="flex  gap-4 tablet:gap-6">
            <ExamIcon />
            <div className="flex flex-col gap-3 tablet:flex-1">
              <h4>{languageExam.exam}</h4>

              {getInnerCardContent()}
            </div>
          </div>
        </div>

        {!isEditMode && !isLogProgress && (
          <IconButtonDropdown
            icon={<OptionsIcon className="min-w-6" />}
            items={dropdownItems}
          />
        )}
      </div>

      {isEditMode && (
        <UpcomingExamsForm
          userId={userId}
          isModeAddNew={false}
          examData={languageExam}
          onCancel={cancelEditHandler}
          setIsEditMode={setIsEditMode}
          className="px-0 tablet:hidden"
          contentClassName="bg-opacity-50"
        />
      )}

      {isLogProgress && (
        <LogProgressForm
          isModeAddNew={false}
          userId={userId}
          examData={languageExam}
          setIsLogProgress={setIsLogProgress}
          onCancel={() => setIsLogProgress(false)}
          className="px-0 tablet:hidden"
          contentClassName="bg-opacity-50"
        />
      )}
    </div>
  );
};

export default UpcomingExamsCard;
