import { NotesModal, UserEditableField } from '@components';

import {
  DotsVerticalIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
  CodeIcon,
} from '@heroicons/react/outline';
import { useModal } from '@hooks';
import {
  ADMIN_ROLE,
  COURSE_CREATOR,
  ALUMNI_ROLE,
  FINANCIAL_ROLE,
  MENTOR_ROLE,
  ROLES,
  STUDENT_ROLE,
  SUPER_MENTOR_ROLE,
  SUPER_USER,
} from '@shared/common';
import {
  useAuthState,
  useClearUserRoles,
  useSetActiveStatus,
  useGetEnrollments,
  usePossessUser,
  usePromoteUser,
  useSearchUsers,
  useChangeUserTestState,
} from '@shared/react';
import styles from '@styles/pages/admin/all-users.module.scss';
import {
  Table,
  Dropdown,
  Button,
  Popconfirm,
  message,
  Pagination,
  Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import EnrollmentModal from './EnrollmentModal';
import { UserSearchParams } from './ManageUsers';
import ViewUserObject from './ViewUserObject';

import './users.styles.scss';
// eslint-disable-next-line import/order
import AgoraSpinner from '@components/V3/Utils/AgoraSpinner';
import UserStatusModal from '@components/V3/UserStatusModal';

type Props = {
  searchParams: UserSearchParams;
  tableHeight: number;
};

const UsersTable: React.FC<Props> = ({ searchParams, tableHeight }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [exportCSVRequsted, setExportCSVRequested] = useState(false);
  const [currentPageSize, setCurrentPageSize] = useState<number | undefined>(
    20
  );

  const [showModal, setShowModal] = useState(false);

  const {
    data: searchUserResponse,
    isLoading: usersLoading,
    refetch,
  } = useSearchUsers({
    ...searchParams,
    page: currentPage,
    limit: currentPageSize,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [searchParams]);

  const { data: exportCSVUserResponse, isLoading: csvLoading } = useSearchUsers(
    {
      ...searchParams,
      page: 1,
      limit: 2000,
    },
    {
      enabled: exportCSVRequsted,
    }
  );

  const { mutate: clearUserRoles } = useClearUserRoles({
    onSuccess: async () => {
      await refetch();
      message.success('User roles cleared successfully');
    },
    onError: () => {
      message.error('Error clearing user roles');
    },
  });

  const { mutate: promoteUser } = usePromoteUser({
    onSuccess: async () => {
      await refetch();
      message.success('User promoted successfully');
    },
    onError: () => {
      message.error('Error promoting user');
    },
  });
  const [NotesM, showNotes] = useModal<any>(NotesModal);
  const [EnrollmentsM, showEnrollmentsM] = useModal(EnrollmentModal);
  // const [GeneratPDFM, showGeneratPDFM] = useModal(GeneratePDFModal);
  // const [UserSettings, showUserSettings] = useModal(UpdateUserDrawer);

  const [record, setRecord] = useState<any>(null);

  const { setPossessedUserId } = usePossessUser();
  const { mutate: deactivateUser, isLoading } = useSetActiveStatus({
    onSuccess: (data, variables) => {
      message.success(
        `User ${
          variables.deactivate ? 'deactivated' : 'activated'
        } successfully`
      );
      refetch();
    },
  });

  const { mutate: changeUserTestState, isLoading: isTestLoading } =
    useChangeUserTestState({
      onSuccess: (data, variables) => {
        message.success(
          `User ${
            variables.isTestUser
              ? 'is now a Test User.'
              : 'has been reverted to Normal User.'
          }`
        );
        refetch();
      },
    });

  const { roles } = useAuthState();
  const [isSuperUser, setIsSuperAdmin] = useState<boolean>(false);

  const handleShowModal = (record?: any) => {
    if (record) {
      setRecord(record);
    }
    setShowModal(!showModal);
  };

  useEffect(() => {
    setIsSuperAdmin(roles?.includes(SUPER_USER));
  }, [roles]);

  return (
    <>
      {NotesM} {EnrollmentsM}
      <UserStatusModal
        showModal={showModal}
        handleShowModal={() => handleShowModal(record)}
        title="Deactivate Account"
        user={record}
        refetch={refetch}
      />
      {/*{GeneratPDFM}*/}
      {/*{UserSettings} */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          type="text"
          onClick={() => setExportCSVRequested(true)}
          style={{
            padding: 0,
            color: '#2ABD78',
            display:
              exportCSVRequsted && exportCSVUserResponse?.users
                ? 'none'
                : 'block',
          }}
        >
          {csvLoading ? 'Loading data..' : 'Export to CSV'}
        </Button>
        {exportCSVRequsted && exportCSVUserResponse?.users && (
          <CSVLink
            asyncOnClick
            data={exportCSVUserResponse?.users}
            filename={`users-${new Date().getTime()}.csv`}
            className="btn btn-primary"
            headers={[
              { label: 'Full Name', key: 'fullName' },
              { label: 'Email', key: 'email' },
              { label: 'Phone Number', key: 'phoneNumber' },
              { label: 'Parent Full Name', key: 'parentData.fullName' },
              { label: 'Parent Email', key: 'parentData.email' },
              { label: 'Parent Phone Number', key: 'parentData.phoneNumber' },
              { label: 'Graduation Year', key: 'graduationYear' },
            ]}
            onClick={() => setExportCSVRequested(false)}
          >
            Download CSV
          </CSVLink>
        )}
      </div>
      <Table
        dataSource={searchUserResponse?.users}
        loading={usersLoading}
        rowKey="_id"
        pagination={false}
        expandable={{
          expandedRowRender: (record) => <ViewUserObject user={record} />,
        }}
        scroll={{ x: 800, y: tableHeight }}
        columns={[
          {
            title: 'Full Name',
            dataIndex: 'fullName',
            key: 'fullName',
            render: (text: any, record: any) => (
              <div
                style={{
                  width: 150,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <UserEditableField
                  userId={record._id}
                  updateLocation="fullName"
                  initialValue={text}
                  type="string"
                />
              </div>
            ),
          },
          {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            render: (text: any, record: any) => (
              <UserEditableField
                userId={record._id}
                updateLocation="email"
                initialValue={text}
                type="string"
              />
            ),
          },
          {
            title: 'Roles',
            key: 'roles',
            dataIndex: 'roles',
            render: (value, record) => {
              const text = value.map((role: 0 | 1) => ROLES[role]).join(', ');

              return (
                <Dropdown
                  overlay={
                    <div className={styles.dropdownMenu}>
                      <div
                        className={styles.dropdownMenuItem}
                        onClick={() =>
                          promoteUser({ id: record._id, role: ALUMNI_ROLE })
                        }
                      >
                        Make Alumnus
                      </div>

                      <div
                        className={styles.dropdownMenuItem}
                        onClick={() =>
                          promoteUser({ id: record._id, role: MENTOR_ROLE })
                        }
                      >
                        Make Mentor
                      </div>

                      <div
                        className={styles.dropdownMenuItem}
                        onClick={() =>
                          promoteUser({
                            id: record._id,
                            role: SUPER_MENTOR_ROLE,
                          })
                        }
                      >
                        Make SUPER-Mentor
                      </div>

                      <div
                        className={styles.dropdownMenuItem}
                        onClick={() =>
                          promoteUser({ id: record._id, role: COURSE_CREATOR })
                        }
                      >
                        Make Course Creator (LMS)
                      </div>

                      {isSuperUser && (
                        <div
                          className={styles.dropdownMenuItem}
                          onClick={() =>
                            promoteUser({
                              id: record._id,
                              role: FINANCIAL_ROLE,
                            })
                          }
                        >
                          Make Financial
                        </div>
                      )}

                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => clearUserRoles({ id: record._id })}
                        okText="Yes"
                        cancelText="No"
                        style={{ zIndex: '99999 !important' }}
                      >
                        <div className={styles.dropdownMenuItem}>
                          Clear Roles
                        </div>
                      </Popconfirm>
                    </div>
                  }
                  trigger={['click']}
                >
                  <div className={styles.dropdownTitle}>
                    {text} <DotsVerticalIcon className={styles.dropdownIcon} />
                  </div>
                </Dropdown>
              );
            },
          },
          {
            title: 'Enrolled in',
            key: 'enrolledIn',
            render: (_, record) => {
              return (
                <Dropdown
                  overlay={
                    <div className={styles.dropdownMenu}>
                      <div
                        className={styles.dropdownMenuItem}
                        onClick={() => showEnrollmentsM({ userId: record._id })}
                      >
                        View Enrollments
                      </div>
                    </div>
                  }
                  trigger={['click']}
                >
                  <div className={styles.dropdownTitle}>
                    <CoursesText userId={record._id} />
                    <DotsVerticalIcon className={styles.dropdownIcon} />
                  </div>
                </Dropdown>
              );
            },
          },
          {
            title: 'Auth&Lms',
            key: 'authLms',
            render: (text: any, record: any) => {
              return (
                <div>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    Auth:{' '}
                    {record.authId ? (
                      <CheckCircleIcon style={{ width: '20px' }} />
                    ) : (
                      <XCircleIcon style={{ width: '20px' }} />
                    )}
                  </div>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    Lms:{' '}
                    {record.lmsId ? (
                      <CheckCircleIcon style={{ width: '20px' }} />
                    ) : (
                      <XCircleIcon style={{ width: '20px' }} />
                    )}
                  </div>
                </div>
              );
            },
          },
          {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <div
                      onClick={() => {
                        if (record.roles.includes(ADMIN_ROLE)) {
                          message.error('Cannot impersonate admin');
                          return;
                        }
                        localStorage.setItem(
                          'menuType',
                          record.roles.includes(MENTOR_ROLE)
                            ? MENTOR_ROLE.toString()
                            : STUDENT_ROLE.toString()
                        );
                        localStorage.setItem(
                          'userRole',
                          record.roles.includes(MENTOR_ROLE)
                            ? 'mentor'
                            : 'student'
                        );
                        setPossessedUserId(record._id);
                      }}
                      className={styles.actionItem}
                    >
                      Impersonate
                    </div>
                    <div
                      onClick={() => message.info(`User id: ${record._id}`)}
                      className={styles.actionItem}
                    >
                      Show info
                    </div>

                    <div
                      onClick={() =>
                        showNotes({
                          userId: record._id,
                        })
                      }
                      className={styles.actionItem}
                    >
                      Notes
                    </div>
                    {!isLoading ? (
                      <div
                        onClick={() => {
                          if (record.isDezactivated) {
                            deactivateUser({
                              userId: record._id,
                              deactivate: !record.isDezactivated,
                            });
                          } else {
                            handleShowModal(record);
                          }
                        }}
                        className={styles.actionItem}
                      >
                        {record.isDezactivated
                          ? 'Activate User'
                          : 'Deactivate User'}
                      </div>
                    ) : (
                      <AgoraSpinner fontSize={10} margin="10px" />
                    )}
                    {!isTestLoading ? (
                      <div
                        onClick={() => {
                          changeUserTestState({
                            userId: record._id,
                            isTestUser: !record.isTestUser,
                          });
                        }}
                        className={styles.actionItem}
                      >
                        {record.isTestUser
                          ? 'Revert Test User'
                          : 'Make Test User'}
                      </div>
                    ) : (
                      <AgoraSpinner fontSize={10} margin="10px" />
                    )}
                  </div>
                  {record.isDezactivated && (
                    <div
                      style={{
                        width: 30,
                        borderColor: '#FEA746',
                      }}
                      className={styles.status}
                    >
                      <Tooltip
                        title={`Reason: ${record.deactivationReason ?? 'N/A'}`}
                      >
                        <ExclamationCircleIcon className={styles.icon} />
                      </Tooltip>
                    </div>
                  )}
                  {record.isTestUser && (
                    <div
                      style={{
                        width: 30,
                        borderColor: '#FEA746',
                      }}
                      className={styles.status}
                    >
                      <Tooltip title="This is a Testing User!">
                        <CodeIcon className={styles.icon} />
                      </Tooltip>
                    </div>
                  )}
                </div>
              );
            },
          },
        ]}
      />
      <div style={{ textAlign: 'center', marginTop: 20 }}>
        <Pagination
          total={searchUserResponse?.total}
          current={currentPage}
          showTotal={(total: number) => `Total ${total} items`}
          defaultPageSize={20}
          defaultCurrent={1}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setCurrentPageSize(pageSize);
          }}
        />
      </div>
    </>
  );
};

const CoursesText: React.FC<{ userId: string }> = ({ userId }) => {
  const { data: userData } = useGetEnrollments(userId);
  const preview = userData?.enrollments
    .map((enrollment) => enrollment?.course?.title ?? 'No title')
    .join(', ')
    .slice(0, 15);

  if (!preview) {
    return <span>-</span>;
  }

  return <span>{preview}...</span>;
};

export default UsersTable;
