import { useGetAllCoursesLite } from '@shared/react';
import { Select } from 'antd';
import { CSSProperties } from 'react';

type Props = {
  value?: string[];
  onChange?: (value: string[]) => void;
  style?: CSSProperties;
};

const CourseSelector = ({ value, onChange, style }: Props) => {
  const { data: courses, isLoading } = useGetAllCoursesLite();

  return (
    <Select
      showSearch
      loading={isLoading}
      style={style || { width: 170 }}
      placeholder="Select a course"
      optionFilterProp="children"
      value={value}
      onChange={onChange}
      mode="multiple"
    >
      {courses &&
        courses.map((course) => (
          <Select.Option key={course._id} value={course._id}>
            {course.title}
          </Select.Option>
        ))}
    </Select>
  );
};

export default CourseSelector;
