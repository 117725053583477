import EnImage from '@assets/images/en.png';
import RoImage from '@assets/images/ro.png';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from '../../../TranslationContext';
import { COLORS } from '../../../v3/global';
import './language-switcher.styles.scss';

const LanguageSwitcher: React.FC = () => {
  const { switchLanguage, language } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const handleLanguageChange = (lng: 'ro' | 'en') => {
    switchLanguage(lng);
    setVisible(false);
  };

  return (
    <div
      style={{ backgroundColor: COLORS.TRANSPARENT, boxShadow: 'none' }}
      className="tooltip-container"
    >
      <Tooltip
        showArrow={false}
        zIndex={1}
        placement="bottomLeft"
        trigger="click"
        visible={visible}
        style={{ boxShadow: 'none' }}
        overlayStyle={{ boxShadow: 'none' }}
        overlayInnerStyle={{ backgroundColor: 'transparent' }}
        onVisibleChange={handleVisibleChange}
        title={
          <div
            style={{
              backgroundColor: COLORS.SURFACE_BACKGROUND,
            }}
            className="tooltip-inner-container"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '16px',
                cursor: 'pointer',
              }}
              onClick={() => handleLanguageChange('en')}
            >
              <img className="language-flag-image" src={EnImage} alt="en" />
              <span
                style={{ color: COLORS.TEXT_PRIMARY }}
                className="language-switcher-text"
              >
                English
              </span>
            </div>
            <div
              onClick={() => handleLanguageChange('ro')}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <img className="language-flag-image" src={RoImage} alt="ro" />
              <span
                style={{ color: COLORS.TEXT_PRIMARY }}
                className="language-switcher-text"
              >
                Română
              </span>
            </div>

            <div
              style={{
                borderLeft: '6px solid transparent',
                borderRight: '6px solid transparent',
                borderBottom: `6px solid ${COLORS.SURFACE_BACKGROUND}`,
              }}
              className="language-tooltip-arrow"
            ></div>
          </div>
        }
      >
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => setVisible(!visible)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
            alignItems: 'center',
            minWidth: '40px',
          }}
        >
          <img
            className="language-flag-image"
            src={language === 'en' ? EnImage : RoImage}
            alt="lang"
          />
          <span
            style={{ color: isHovered ? COLORS.GOLD : COLORS.TEXT_PRIMARY }}
            className="language-switcher-text"
          >
            {language === 'en' ? 'English' : 'Română'} (Change)
          </span>
        </div>
      </Tooltip>
    </div>
  );
};

export default LanguageSwitcher;
