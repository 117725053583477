import { RolesSelector } from '@components';
import FormInput from '@components/Form/FormInput';
import { useGetAllCourses, useGetAllMentors } from '@shared/react';
import styles from '@styles/pages/admin/all-users.module.scss';
import { Checkbox, InputNumber, Select } from 'antd';
import { COLORS } from 'apps/agora/src/v3/global';
import { useEffect, useState } from 'react';
import { UserSearchParams } from './ManageUsers';

type Props = {
  searchParams: UserSearchParams;
  updateSearchParams: (params: Partial<UserSearchParams>) => void;
};

const UsersFilters = ({ updateSearchParams, searchParams }: Props) => {
  const { data: mentors } = useGetAllMentors();
  const { data: courses } = useGetAllCourses();

  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedMentor, setSelectedMentor] = useState('');

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    updateSearchParams({ search: searchValue });
  }, [searchValue]);

  const [checkedDeactivatedState, setCheckedDeactivatedState] =
    useState('indeterminate');
  const handleDeactivatedCheckboxChange = () => {
    let nextState: 'checked' | 'unchecked' | 'indeterminate';

    switch (checkedDeactivatedState) {
      case 'checked':
        nextState = 'unchecked';
        break;
      case 'unchecked':
        nextState = 'indeterminate';
        break;
      case 'indeterminate':
        nextState = 'checked';
        break;
      default:
        nextState = 'indeterminate';
        break;
    }

    updateSearchParams({
      isDezactivated: nextState,
    });
    setCheckedDeactivatedState(nextState);
  };

  const [checkedTestUserState, setCheckedTestUserState] =
    useState('indeterminate');
  const handleTestUserCheckboxChange = () => {
    let nextState: 'checked' | 'unchecked' | 'indeterminate';

    switch (checkedTestUserState) {
      case 'checked':
        nextState = 'unchecked';
        break;
      case 'unchecked':
        nextState = 'indeterminate';
        break;
      case 'indeterminate':
        nextState = 'checked';
        break;
      default:
        nextState = 'indeterminate';
        break;
    }

    updateSearchParams({
      isTestUser: nextState,
    });
    setCheckedTestUserState(nextState);
  };

  return (
    <div className={styles.filters}>
      <FormInput
        title="Search"
        placeholder="Search by username, full name, e-mail or phone number"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <div className={styles.filtersItems}>
        <div className={styles.filtersItemsSection}>
          <div className={styles.filtersItemsItem}>
            Roles
            <RolesSelector
              value={searchParams.roles}
              onChange={(roles) => updateSearchParams({ roles })}
            />
          </div>

          <div>
            <Checkbox
              indeterminate={checkedDeactivatedState === 'indeterminate'}
              checked={checkedDeactivatedState === 'checked'}
              onChange={handleDeactivatedCheckboxChange}
            />
            <span style={{ marginLeft: '8px', color: COLORS.TEXT_SECONDARY }}>
              {checkedDeactivatedState === 'indeterminate'
                ? 'Show All users'
                : checkedDeactivatedState === 'checked'
                ? 'Show Deactivated users'
                : 'Show Activated users'}
            </span>
          </div>

          <div>
            <Checkbox
              indeterminate={checkedTestUserState === 'indeterminate'}
              checked={checkedTestUserState === 'checked'}
              onChange={handleTestUserCheckboxChange}
            />
            <span style={{ marginLeft: '8px', color: COLORS.TEXT_SECONDARY }}>
              {checkedTestUserState === 'indeterminate'
                ? 'Show All users'
                : checkedTestUserState === 'checked'
                ? 'Show Test users'
                : 'Show Normal users'}
            </span>
          </div>
        </div>

        <div className={styles.filtersItemsSection}>
          <div className={styles.filtersItemsItem}>
            Enrolled in
            <Select
              showSearch
              placeholder="Select a course"
              optionFilterProp="children"
              onChange={(value: string) => {
                updateSearchParams({
                  courseId: value,
                });
                setSelectedCourse(value);
              }}
            >
              <Select.Option key="no-enrollment" value="">
                Select a course
              </Select.Option>

              {courses &&
                courses?.map((course) => (
                  <Select.Option key={course?._id} value={course?._id}>
                    {course.title}
                  </Select.Option>
                ))}
            </Select>
          </div>

          <div className={styles.filtersItemsItem}>
            Mentored by
            <Select
              showSearch
              placeholder="Select a mentor"
              optionFilterProp="children"
              onChange={(value: string) => {
                updateSearchParams({
                  mentorId: value,
                });
                setSelectedMentor(value);
              }}
            >
              <Select.Option key="select-a-mentor" value="">
                Select a mentor
              </Select.Option>
              <Select.Option key="no-mentor" value="no-mentor">
                No mentor
              </Select.Option>
              {mentors &&
                mentors?.map((mentor) => (
                  <Select.Option key={mentor?._id} value={mentor?._id}>
                    {mentor?.fullName}
                  </Select.Option>
                ))}
            </Select>
          </div>

          <div className={styles.filtersItemsItem} style={{ width: 100 }}>
            Grad Year
            <InputNumber
              value={searchParams.graduationYear}
              onChange={(newNumber) => {
                newNumber &&
                  updateSearchParams({
                    graduationYear: newNumber,
                  });
              }}
              placeholder="Grad Year"
              min={2000}
              max={2050}
            />
          </div>

          <div className={styles.filtersItemsItem}>
            Taking Course
            <Checkbox
              disabled={!(selectedCourse || selectedMentor)}
              onChange={(e) =>
                updateSearchParams({
                  isTakingCourse: e.target.checked,
                })
              }
              checked={searchParams.isTakingCourse}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersFilters;
