import { SearchOutlined } from '@ant-design/icons';
import React from 'react';
import { COLORS } from '../../../../v3/global';

type Props = {
  search: string | undefined;
  handleSearch: (value: string) => void;
  placeholder: string;
};

const AgoraSearchBar = ({ search, handleSearch, placeholder }: Props) => {
  return (
    <div
      style={{ marginBottom: '20px' }}
      className="relative rounded-md shadow-sm"
    >
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <SearchOutlined
          aria-hidden="true"
          style={{ color: COLORS.TEXT_SECONDARY }}
        />
      </div>
      <input
        style={{
          backgroundColor: 'transparent',
          borderRadius: '18px',
          height: '32px',
          borderColor: COLORS.TEXT_SECONDARY,
        }}
        className="block w-full rounded-md border-0 py-1.5 pl-10 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-customGrey sm:text-sm sm:leading-6"
        title="Search"
        placeholder={placeholder}
        value={search}
        onChange={(e) => handleSearch(e.target.value)}
      />
    </div>
  );
};

export default AgoraSearchBar;
