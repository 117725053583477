import SecondaryButtonV3 from '@components/V3/SecondaryButtonV3';
import { ChecklistItem, MeetingWithHomework } from '@shared/types';
import styles from '@styles/components/calendar.module.scss';
import useCalendar from 'apps/agora/src/hooks/useCalendar';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { COLORS } from '../../../../../v3/global';
import MeetingButton from '../../Common/Buttons/MeetingButton';
import CalendarItem from './CalendarItem';

const Calendar = ({
  milestonesForDate,
  allMeetings,
}: {
  milestonesForDate?: (date: Moment) => ChecklistItem[] | undefined;
  allMeetings: MeetingWithHomework[] | undefined;
}) => {
  const { calendar, goForward, goBackward, goToday, selected } = useCalendar();
  const [displayedMonth, setDisplayedMonth] = useState<Moment>(selected);

  useEffect(() => {
    setDisplayedMonth(selected);
  }, [selected]);

  return (
    <div
      style={{
        backgroundColor: COLORS.SECTION_BACKGROUND,
      }}
      className={styles.calendar}
    >
      <div className={styles.calendarHeader}>
        <div className={styles.calendarHeaderInnerContainer}>
          <div className={styles.calendarHeaderItems}>
            <div className={styles.arrowsContainer}>
              <div
                onClick={goBackward}
                className={`${styles.arrow} ${styles.arrowLeft}`}
              />
              <div
                onClick={goForward}
                className={`${styles.arrow} ${styles.arrowRight}`}
              />
            </div>

            <div
              style={{ color: COLORS.TEXT_PRIMARY }}
              className={styles.calendarDateTitle}
            >
              {selected.format('MMMM YYYY')}
            </div>

            <SecondaryButtonV3 onClick={goToday} buttonText="Today" />
          </div>
          <div className={styles.newMeetingButtonContainer}>
            <MeetingButton />
          </div>
        </div>
      </div>

      <div className={styles.calendarGrid}>
        {calendar.map((week, weekIndex) =>
          week.map((day, dayIndex) => {
            const isToday = day.isSame(new Date(), 'day');
            const milestones = milestonesForDate?.(day) || [];

            return (
              <CalendarItem
                allMeetings={allMeetings}
                isToday={isToday}
                day={day}
                milestones={milestones}
                displayedMonth={displayedMonth}
                key={`${weekIndex}-${dayIndex}`}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default Calendar;
