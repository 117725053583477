import ArrowDown from '@assets/icons/ic-arrow-narrow-down.svg';
import ArrowUp from '@assets/icons/ic-arrow-narrow-up.svg';
import UsersIcon from '@assets/icons/ic_users.svg';
import React from 'react';
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
} from 'recharts';
import { COLORS } from '../../../v3/global';
import './nps-line-chart.styles.scss';

type Props = {
  title: string;
  trendPoints: any;
  type: string;
};

type TooltipProps = {
  active?: boolean;
  payload?: any[];
  label?: string | number;
};

const NPSLineChart = ({ title, trendPoints, type }: Props) => {
  const data =
    type === 'education'
      ? trendPoints?.map((trendPoint: any, index: number) => ({
          name: trendPoint.name,
          Mentors: Number((trendPoint.mentorEdu ?? 0).toFixed(2) * 100),
          Students: Number((trendPoint.studentEdu ?? 0).toFixed(2) * 100),
          Parents: Number((trendPoint.parentEdu ?? 0).toFixed(2) * 100),
        }))
      : trendPoints?.map((trendPoint: any, index: number) => ({
          name: trendPoint.name,
          Mentors: Number((trendPoint.mentorPlatform ?? 0).toFixed(2) * 100),
          Students: Number((trendPoint.studentPlatform ?? 0).toFixed(2) * 100),
        }));

  const legendIconStyle = {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '8px',
  };

  const CustomLegend = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 20,
        alignItems: 'center',
      }}
    >
      <div style={{ ...legendIconStyle, background: COLORS.PURPLE }}></div>
      <span
        style={{ marginRight: '32px', color: COLORS.TEXT_SECONDARY }}
        className="nps-line-chart-legend-title"
      >
        Mentors
      </span>
      <div style={{ ...legendIconStyle, background: COLORS.PRIMARY }}></div>
      <span
        style={{ marginRight: '32px', color: COLORS.TEXT_SECONDARY }}
        className="nps-line-chart-legend-title"
      >
        Students
      </span>
      {type === 'education' && (
        <>
          <div style={{ ...legendIconStyle, background: COLORS.GOLD }}></div>
          <span
            style={{ color: COLORS.TEXT_SECONDARY }}
            className="nps-line-chart-legend-title"
          >
            Parents
          </span>
        </>
      )}
    </div>
  );

  const CustomTooltip: React.FC<TooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: '#444444 0% 0% no-repeat padding-box',
            boxShadow: '0px 5px 15px #44444F1A',
            opacity: 1,
            padding: '12px',
            borderRadius: '8px',
          }}
        >
          <p
            style={{ marginBottom: '8px', color: COLORS.TEXT_PRIMARY }}
            className="nps-line-chart-tooltip-title"
          >{`${label}`}</p>
          <p style={{ marginBottom: '8px', color: COLORS.TEXT_PRIMARY }}>
            <div
              style={{ ...legendIconStyle, background: COLORS.PURPLE }}
            ></div>
            <span className="nps-line-chart-tooltip-title">Mentors NPS: </span>
            <span className="nps-line-chart-tooltip-value">{`${payload[0]?.value?.toFixed(
              0
            )}`}</span>
          </p>
          <p style={{ marginBottom: '8px', color: COLORS.TEXT_PRIMARY }}>
            <div
              style={{ ...legendIconStyle, background: COLORS.PRIMARY }}
            ></div>
            <span className="nps-line-chart-tooltip-title">Students NPS: </span>
            <span className="nps-line-chart-tooltip-value">{`${payload[1]?.value?.toFixed(
              0
            )}`}</span>
          </p>
          {type === 'education' && (
            <p style={{ color: COLORS.TEXT_PRIMARY }}>
              <div
                style={{ ...legendIconStyle, background: COLORS.GOLD }}
              ></div>
              <span className="nps-line-chart-tooltip-title">
                Parents NPS:{' '}
              </span>
              <span className="nps-line-chart-tooltip-value">{`${payload[2]?.value?.toFixed(
                0
              )}`}</span>
            </p>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: `${COLORS.COMPONENT_BACKGROUND} 0% 0% no-repeat padding-box`,
        boxShadow: '0px 1px 4px #15223214',
        padding: '24px',
        marginBottom: '24px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '40px',
        }}
      >
        <h3
          style={{ color: COLORS.TEXT_PRIMARY }}
          className="nps-line-chart-title"
        >
          {title}
        </h3>
        <CustomLegend />
        <p
          style={{ color: COLORS.TEXT_SECONDARY }}
          className="nps-line-chart-description"
        >
          Last 12 Months
        </p>
      </div>
      <ResponsiveContainer width="100%" height={330}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={true} horizontal={false} />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 100]} />
          <Tooltip content={<CustomTooltip />} />

          <Line type="monotone" dataKey="Mentors" stroke={COLORS.PURPLE} />
          <Line type="monotone" dataKey="Students" stroke={COLORS.PRIMARY} />
          {type === 'education' && (
            <Line type="monotone" dataKey="Parents" stroke={COLORS.GOLD} />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default NPSLineChart;
