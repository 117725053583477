import React from 'react';
import { mergeClassNames } from '../../utils/helpers';

interface ToggleButtonProps
  extends Omit<React.ComponentProps<'button'>, 'disabled'> {
  color?: 'primary' | 'danger';
  isDisabled?: boolean;
  isChecked: boolean;
  className?: string;
  dotClassName?: string;
}

const ToggleButton = (props: ToggleButtonProps) => {
  const {
    isDisabled,
    color = 'primary',
    isChecked = false,
    className,
    dotClassName,
    ...rest
  } = props;

  return (
    <button
      {...rest}
      disabled={isDisabled}
      className={mergeClassNames(
        'relative h-6 min-w-10 p-1 rounded-full transition-colors ease-out duration-200',
        {
          'bg-customPrimary': color === 'primary',
          'bg-customRed': color === 'danger',
          'bg-customGrey': !isChecked,
        },
        className
      )}
    >
      <div
        className={mergeClassNames(
          'w-4 h-4 absolute top-1 rounded-full transition-all ease-out duration-200',
          {
            'bg-white left-5': isChecked,
            'bg-black left-1': !isChecked,
          },
          dotClassName
        )}
      />
    </button>
  );
};

export default ToggleButton;
