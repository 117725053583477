import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { authApis, usersApis } from '../../../apis';
import { storage } from '../../UniversalStorage';
import { useUrlState } from '../UrlContext';

export const useMe = (
  token: string | null,
  auth0token: string | null,
  removeToken: () => void
) => {
  const { apiUrl } = useUrlState();

  const headers = {
    'x-access-token': token,
    Authorization: `Bearer ${auth0token}`,
    'impersonate-user-id': storage.getItem('impersonate-user-id') ?? '',
  };

  return useQuery<usersApis.Me['response']>(
    usersApis.me.endpoint,
    async () => {
      const url = `${apiUrl}${usersApis.me.endpoint}`;

      const { data } = await axios.get(url, {
        headers,
      });
      return data;
    },
    {
      retry: 0,
      onError: () => {
        removeToken();
      },
      enabled: !!token || !!auth0token,
    }
  );
};

export const useLogin = (setToken: (token: string) => void) => {
  const queryClient = useQueryClient();
  const { apiUrl } = useUrlState();

  const loginAction = async (params: authApis.Login['payload']) => {
    const url = `${apiUrl}${authApis.login.endpoint}`;

    const { data } = await axios.post(url, params);
    return data as authApis.Login['response'];
  };

  return useMutation(loginAction, {
    onSettled: async (data) => {
      if (!data) return;
      try {
        storage.setItem('authToken', data.token);
      } catch (e) {
        console.log(e);
      }

      setToken(data.token);
      queryClient.invalidateQueries('usersApis/me');
    },
  });
};

export const useLogin0Auth = (setToken0Auth: (token: string) => void) => {
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently()
      .then((accessToken) => setToken0Auth(accessToken))
      .catch(console.log);
  }, []);

  return useMutation('', {
    onSettled: async () => {
      queryClient.invalidateQueries('usersApis/me');
    },
  });
};
