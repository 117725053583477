import styles from '@styles/components/common/buttons.module.scss';
import { FC } from 'react';

export const PrimaryButton: FC<{ onClick?: (...args: any[]) => void }> = ({
  children,
  onClick,
}) => {
  return (
    <button className={styles.primaryButton} onClick={onClick}>
      {children}
    </button>
  );
};
