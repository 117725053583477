import { ExclamationCircleFilled } from '@ant-design/icons';
import { MeetingModal } from '@components';
import { SortOrder } from '@components/V4/Table/Table';
import { useDebounce, useModal, useQueryParamsState } from '@hooks';
import MyStudentsDesktopTable from '@modules/Mentor/MyStudents/MyStudentsDesktopTable';
import MyStudentsFilters from '@modules/Mentor/MyStudents/MyStudentsFilters';
import MyStudentsMobileCard from '@modules/Mentor/MyStudents/MyStudentsMobileCard';
import { EnrollmentWithStudentTypes } from '@shared/common';
import { useEndEnrollment, useMyMentorEnrollments } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => new URLSearchParams(useLocation().search);

const MyStudents = () => {
  const query = useQuery();
  const status = query.get('status') || null;
  const course = query.get('course') || null;
  const limit = query.get('limit') || '10';
  const page = query.get('page') || '1';
  const gradYear = query.get('gradYear') || null;
  const sortBy = query.get('sortBy') || null;
  const sortOrder = (query.get('sortOrder') || null) as SortOrder;

  const [searchParams, setSearchParams] = useQueryParamsState<any>({
    page: page,
    limit: limit,
    status: status,
    course: course,
    sortBy: sortBy,
    sortOrder: sortOrder,
    gradYear: gradYear,
  });

  const [studentSearch, setStudentSearch] = useState(
    searchParams.studentSearch || null
  );
  const [showToast] = useToast();

  const debouncedStudentSearch = useDebounce(studentSearch, 500);

  const { data: myStudentsData, isLoading } = useMyMentorEnrollments({
    ...searchParams,
    limit: searchParams.limit || 10,
    sortOrder: searchParams.sortOrder || 'asc',
  });

  const currentPageChangeHandler = (page: number) => {
    setSearchParams({
      ...searchParams,
      page,
    });
  };

  const pageSizeChangeHandler = (size: number) => {
    setSearchParams({
      ...searchParams,
      limit: size,
      page: 1,
    });
  };

  const sortHandler = (field: string, order: SortOrder) => {
    setSearchParams({
      ...searchParams,
      sortBy: order ? field : null,
      sortOrder: order || null,
      page: 1,
    });
  };

  const courseHandler = (course: number | string | null) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      course: course,
    });
  };

  const statusHandler = (status: number | string | null) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      status: status,
    });
  };

  const gradYearHandler = (year: string | number | null) => {
    setSearchParams({
      ...searchParams,
      page: 1,
      gradYear: year ? year : null,
    });
  };

  useEffect(() => {
    if (debouncedStudentSearch !== searchParams.studentSearch) {
      setSearchParams({
        ...searchParams,
        studentSearch: debouncedStudentSearch,
        page: 1,
      });
    }
  }, [debouncedStudentSearch]);

  const pagination = {
    pageSize: limit !== null ? parseInt(limit) : 10,
    total: myStudentsData?.total,
    currentPage: page !== null ? parseInt(page) : 1,
    onCurrentPageChange: currentPageChangeHandler,
    onPageSizeChange: pageSizeChangeHandler,
  };

  const [Modal, show] = useModal(MeetingModal);

  const showModalHandler = (defaultEnrollment: EnrollmentWithStudentTypes) => {
    show({
      defaultEnrollment: defaultEnrollment,
      defaultStudent: {
        id: defaultEnrollment.user._id,
        fullName: defaultEnrollment.user.fullName,
      },
    });
  };

  const { mutate: endEnrollment } = useEndEnrollment({
    onSuccess() {
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Enrollment ended successfully.',
      });
    },
    onError(err: any) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: err?.message || 'An error has occurred!',
      });
    },
  });

  const endEnrollmentHandler = (enrollment: EnrollmentWithStudentTypes) => {
    endEnrollment({
      enrollmentId: enrollment?._id,
    });
  };

  return (
    <section className="laptop:ml-1 min-h-screen w-full pt-6 bg-surfaceBackground">
      <section className="max-w-[1564px] flex flex-col m-auto gap-6 p-4 tablet:p-6 laptop:px-24">
        <h1 className="flex justify-between font-raleway font-semibold text-2xl">
          My Students
        </h1>
        <MyStudentsFilters
          searchParams={searchParams}
          updateSearchParams={(data) =>
            setSearchParams({ ...searchParams, ...data })
          }
          courseHandler={courseHandler}
          statusHandler={statusHandler}
          gradYearHandler={gradYearHandler}
          setStudentSearch={setStudentSearch}
          debouncedStudentSearch={debouncedStudentSearch}
          studentSearch={studentSearch}
          sortHandler={sortHandler}
        />

        <section className="flex flex-col gap-6">
          <MyStudentsDesktopTable
            enrollments={myStudentsData?.documents}
            pagination={pagination}
            sortBy={sortBy}
            sortOrder={sortOrder}
            sortHandler={sortHandler}
            isLoading={isLoading}
            onEndEnrollment={endEnrollmentHandler}
            onShowModal={showModalHandler}
          />
          <MyStudentsMobileCard
            enrollments={myStudentsData?.documents}
            pagination={pagination}
            isLoading={isLoading}
            onEndEnrollment={endEnrollmentHandler}
            onShowModal={showModalHandler}
          />
        </section>
      </section>
      {Modal}
    </section>
  );
};

export default MyStudents;
