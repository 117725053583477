import styles from '@styles/components/activity-report-table.module.scss';
import { Empty, Spin, Checkbox } from 'antd';
import { FC } from 'react';
import ActivityReportRow from './ActivityReportRow';

type Props = {
  selectedDate: any;
  reports:
    | { reports: any; total: number; page: number; limit: number }
    | undefined
    | any;
  isLoading: boolean;
  tableHeight?: number;
  handleSelected: (report: any) => void;
  handleSelectAll: (reports: any[]) => void;
  selectAll: boolean;
  selectedItems: any[];
  handleRowTouched: () => void;
  setSelectedReportId: (id: string) => void;
  setShowSideBySideModal: (show: boolean) => void;
  setShowElectronicInvoiceModal: (show: boolean) => void;
};

const ActivityReportTable: FC<Props> = ({
  selectedDate,
  reports,
  isLoading,
  tableHeight,
  handleSelected,
  handleSelectAll,
  selectAll,
  selectedItems,
  handleRowTouched,
  setSelectedReportId,
  setShowSideBySideModal,
  setShowElectronicInvoiceModal,
}) => {
  return (
    <div>
      <div
        className={`${styles.gridTable} ${
          isLoading ? styles.tableLoading : ''
        }`}
        style={{ color: 'white' }} // QUICK FIX FOR DEPRECATED COMPONENT
      >
        <div className={styles.gridTableHeader}>
          <Checkbox
            checked={selectAll}
            onClick={() => handleSelectAll(reports)}
          />
        </div>
        <div className={styles.gridTableHeader}>Mentor</div>
        <div className={styles.gridTableHeader}>Invoice Status</div>
        <div className={styles.gridTableHeader}>
          Sessions <br />
          Completed
        </div>
        <div className={styles.gridTableHeader}>Students</div>
        {/*<div className={styles.gridTableHeader}>Hubspot Currency</div>*/}
        <div className={styles.gridTableHeader}>Total Meetings</div>
        <div className={styles.gridTableHeader}>Total Extra Services</div>
        {/*<div className={styles.gridTableHeader}>Invoice Currency</div>*/}
        <div className={styles.gridTableHeader}>Total Invoice</div>
        <div className={styles.gridTableHeader}>Actions</div>

        {isLoading && (
          <Spin className="w-full" style={{ gridColumn: '1 / 11' }} />
        )}

        {reports?.length && !isLoading ? (
          reports?.map((report: any, index: any) => (
            <ActivityReportRow
              handleRowTouched={handleRowTouched}
              selectedDate={selectedDate}
              selectAll={selectAll}
              handleSelected={handleSelected}
              selectedItems={selectedItems}
              isEven={true}
              report={report}
              key={`${report._id}-${index}`}
              setSelectedReportId={setSelectedReportId}
              setShowSideBySideModal={setShowSideBySideModal}
              setShowElectronicInvoiceModal={setShowElectronicInvoiceModal}
            />
          ))
        ) : (
          <div style={{ gridColumn: '1 / 11' }}>{!isLoading && <Empty />}</div>
        )}
      </div>
    </div>
  );
};

export default ActivityReportTable;
