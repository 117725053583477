import Accordion from '@components/V4/Accordion';
import { StudentFeedback } from '@shared/common';
import SessionModalEmptyState from '../../../SessionModalEmptyState';
import FeedbackEntry from './FeedbackEntry';

interface StudentFeedbackAccordionProps {
  studentFeedback?: StudentFeedback[];
  defaultIsAccordionOpen: boolean;
}

const StudentFeedbackAccordion = (props: StudentFeedbackAccordionProps) => {
  const { studentFeedback, defaultIsAccordionOpen } = props;
  return (
    <Accordion
      defaultIsOpen={defaultIsAccordionOpen}
      titleWrapperClassName="bg-surfaceHover p-2"
      title={
        <p className="font-raleway font-medium text-xs text-white">
          Student Feedback
        </p>
      }
      className="flex flex-col gap-4 my-2 px-2 max-h-[80px] laptop:max-h-[140px]"
    >
      {!studentFeedback?.[0]?.rating ? (
        <SessionModalEmptyState />
      ) : (
        <FeedbackEntry
          title="Message:"
          score={`${studentFeedback?.[0]?.rating}/5`}
          description={studentFeedback?.[0]?.message || 'n/a'}
        />
      )}
    </Accordion>
  );
};

export default StudentFeedbackAccordion;
