import React, { ReactNode, useState } from 'react';
import { mergeClassNames } from '../../utils/helpers';
import Dropdown, { DropdownItem } from './Dropdown';
import IconButton from './IconButton';

interface IconButtonDropdownProps {
  icon: ReactNode;
  items: DropdownItem[];
  YPosition?: 'above' | 'below';
  XPosition?: 'left' | 'right';
  className?: string;
  onClick?: () => void;
}

const IconButtonDropdown = (props: IconButtonDropdownProps) => {
  const { icon, items, YPosition, XPosition, className, onClick } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const clickHandler = () => {
    setIsDropdownOpen((prev) => !prev);
    onClick?.();
  };

  return (
    <div className="relative">
      <IconButton
        icon={icon}
        className={mergeClassNames(
          'flex items-center justify-center',
          className
        )}
        onClick={clickHandler}
        isActive={isDropdownOpen}
      />
      {isDropdownOpen && (
        <Dropdown
          XPosition={XPosition}
          YPosition={YPosition}
          setIsDropdownOpen={setIsDropdownOpen}
          items={items}
        />
      )}
    </div>
  );
};

export default IconButtonDropdown;
