import { ReactComponent as ArrowTailless } from '@assets/icons/select-arrow.svg';
import { Pagination as PaginationInner } from 'antd';
import { useEffect } from 'react';
import { mergeClassNames } from '../../utils/helpers';
import Select from './Select/Select';

export interface PaginationProps {
  pageSize: number;
  currentPage: number;
  total?: number;
  shouldOnlyShowPages?: boolean;
  size?: 'small' | 'default';
  onPageSizeChange: (pageSize: number) => void;
  onCurrentPageChange: (currentPage: number) => void;
}

const Pagination = (props: PaginationProps) => {
  const {
    pageSize,
    total,
    currentPage,
    shouldOnlyShowPages = false,
    size = 'default',
    onPageSizeChange,
    onCurrentPageChange,
  } = props;

  const itemsPerPageOptions = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];

  const recordsFrom =
    (currentPage - 1) * pageSize ? (currentPage - 1) * pageSize + 1 : 0;
  const recordsTo = Math.min(currentPage * pageSize, total || 0);
  const recordsOf = total || 0;

  useEffect(() => {
    const noOfPages = total && total / pageSize + (total % pageSize ? 1 : 0);

    if (noOfPages && currentPage > noOfPages) {
      onCurrentPageChange(Math.floor(noOfPages));
    }
  }, [total, pageSize, currentPage]);

  return (
    <div
      className={mergeClassNames(
        'w-full py-6 flex flex-col gap-2 items-center justify-between custom-table-pagination tablet:flex-row tablet:gap-0',
        { 'justify-end': shouldOnlyShowPages, 'py-2': size === 'small' }
      )}
    >
      {!shouldOnlyShowPages && (
        <>
          <div className="flex items-center gap-4">
            <span className="text-sm text-customGrey min-w-max">
              Rows per page
            </span>
            <Select
              className="!w-16"
              showSearch={false}
              allowClear={false}
              value={pageSize}
              options={itemsPerPageOptions}
              onSelect={(value) => onPageSizeChange(value as number)}
            />
          </div>

          <div className="text-sm text-customGrey font-bold">
            {`${recordsFrom} - ${recordsTo} of ${recordsOf}`}
          </div>
        </>
      )}

      <PaginationInner
        className={mergeClassNames('text-customGrey', size)}
        showSizeChanger={false}
        current={currentPage}
        defaultCurrent={1}
        total={total}
        pageSize={pageSize}
        onChange={(page) => onCurrentPageChange(page)}
        prevIcon={
          <ArrowTailless className="rotate-90 h-2 w-2 text-customGrey" />
        }
        nextIcon={
          <ArrowTailless className="-rotate-90 h-2 w-2 text-customGrey" />
        }
      />
    </div>
  );
};

export default Pagination;
