import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { useModal } from '@hooks';
import authHeader from '@services/auth-header';
import {
  useEndEnrollment,
  useResumeEnrollment,
  useGetEnrollments,
  useUnenrollUser,
} from '@shared/react';
import { Enrollment } from '@shared/types';
import styles from '@styles/components/reusables/modal.module.scss';
import { Button, Popconfirm, Spin, Table, Tooltip, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react';
import CreateEnrollmentModal from './CreateEnrollmentModal';
import UpdateEnrollmentModal from './UpdateEnrollmentModal';

type Props = {
  userId: string;
};

const Enrollments: React.FC<Props> = ({ userId }) => {
  const {
    data: userEnrollments,
    isLoading: userEnrollmentsLoading,
    refetch: refetchUserEnrollments,
  } = useGetEnrollments(userId);

  const { mutate: endEnrollment, isLoading: isLoadingEndEnrollment } =
    useEndEnrollment({
      onSuccess() {
        message.success('Enrollment ended successfully');
      },
      onError(err: any) {
        message.error(err?.message || 'An error has occurred!');
      },
    });

  const { mutate: resumeEnrollment, isLoading: isLoadingResumeEnrollment } =
    useResumeEnrollment({
      onSuccess() {
        message.success('Enrollment resumed successfully');
      },
      onError(err: any) {
        message.error(err?.message || 'An error has occurred!');
      },
    });

  const [UpdateEnrollmentM, showUpdateModal] = useModal(UpdateEnrollmentModal);
  const [CreateEnrollmentM, showCreateModal] = useModal(CreateEnrollmentModal);

  const { mutate: unenroll } = useUnenrollUser({
    onSuccess() {
      message.success('Enrollment deleted successfully');
      refetchUserEnrollments();
    },
    onError(err: any) {
      message.error(err?.message || 'An error has occurred!');
    },
  });

  useEffect(() => {
    refetchUserEnrollments();
  }, [isLoadingEndEnrollment, isLoadingResumeEnrollment]);

  if (userEnrollmentsLoading) {
    return <Spin />;
  }

  const dateFormat = (str: string | undefined) =>
    str ? moment(str).format('DD-MM-YYYY') : 'N/A';

  const getEnrollmentInfo = (enrollment: Enrollment) => {
    return (
      <div
        style={{
          width: '100%',
          overflow: 'hidden',
          display: 'grid',
          gridTemplateColumns: 'max-content 1fr',
          columnGap: '15px',
          rowGap: '5px',
        }}
      >
        <span>Starts:</span>
        <span>{dateFormat(enrollment.startDate)}</span>
        <span>Ends:</span>
        <span>{dateFormat(enrollment.estimatedEndDate)}</span>
        <span>Find mentor by:</span>
        <span>{dateFormat(enrollment.findBy)}</span>
        <span>Subject:</span>
        <span>{enrollment.subject || 'Undecided'}</span>
      </div>
    );
  };

  const dataSource = (userEnrollments?.enrollments ?? []).map(
    (enrollment, index) => {
      const completedSession = userEnrollments?.sessionCompleted[index] ?? 0;

      return {
        ...enrollment,
        completedSession,
      };
    }
  );

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <div className={styles.modalSecondarySectionTitle}>Enrollments</div>
        <Button
          type="primary"
          onClick={() => {
            showCreateModal({ userId });
          }}
        >
          <PlusOutlined /> Create
        </Button>
      </div>

      <Table
        pagination={false}
        rowKey="_id"
        dataSource={dataSource}
        columns={[
          {
            title: 'Course',
            render(text, record) {
              return record.course.title ?? 'TBD Course';
            },
          },
          {
            title: 'Meetings',
            render(text, record) {
              const { completedSession } = record;
              return `${completedSession} / ${record.sessions.length}`;
            },
          },
          {
            title: 'Mentor',
            render(text, record) {
              return record.mentor?.fullName || 'TBD Mentor';
            },
          },
          {
            title: 'Actions',
            render(text, record) {
              const enrollmentInfo = getEnrollmentInfo(record as any);

              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    fontSize: '16px',
                  }}
                >
                  <Tooltip
                    placement="bottomLeft"
                    title={enrollmentInfo}
                    style={{ maxWidth: 'auto' }}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>

                  <SettingOutlined
                    onClick={() =>
                      showUpdateModal({ enrollmentId: record._id })
                    }
                  />

                  <Popconfirm
                    title="Are you sure you want to delete this enrollment?"
                    onConfirm={() => unenroll({ enrollmentId: record._id })}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteOutlined style={{ color: '#D53030' }} />
                  </Popconfirm>

                  {
                    <div>
                      {!record.ended ? (
                        <SecondaryButton
                          onClick={() => {
                            endEnrollment({
                              enrollmentId: record._id,
                            });
                            refetchUserEnrollments();
                          }}
                          backgroundColor="#faad14"
                          borderColor="#000000"
                        >
                          <span style={{ color: '#000000' }}>
                            End Enrollment
                          </span>
                        </SecondaryButton>
                      ) : record.ended ? (
                        <SecondaryButton
                          onClick={() => {
                            resumeEnrollment({
                              enrollmentId: record._id,
                            });
                            refetchUserEnrollments();
                          }}
                          borderColor="#000000"
                        >
                          <span
                            style={{ color: '#000000', whiteSpace: 'nowrap' }}
                          >
                            Resume Enrollment
                          </span>
                        </SecondaryButton>
                      ) : null}
                    </div>
                  }
                </div>
              );
            },
          },
        ]}
      />

      {UpdateEnrollmentM}
      {CreateEnrollmentM}
    </div>
  );
};

export default Enrollments;
