import { ReactComponent as EmptyStateIcon } from '@assets/icons/V4/no-data.svg';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';

interface SessionModalEmptyStateProps {
  className?: string;
}

const SessionModalEmptyState = (props: SessionModalEmptyStateProps) => {
  const { className } = props;
  return (
    <EmptyStateCard
      className={className}
      icon={<EmptyStateIcon className="text-customGrey" />}
      text="No Existing Data"
    />
  );
};

export default SessionModalEmptyState;
