import { ReactNode } from 'react';

interface PaymentsCardProps {
  title?: string;
  subtitle?: any;
  selector?: ReactNode;
  width?: string;
  height?: string;
  font?: string;
}

const InformationCard = (props: PaymentsCardProps) => {
  return (
    <div
      style={{
        background: '#33A9AB 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 9px #00000029',
        width: props.width ?? '380px',
        height: props.height ?? '200px',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '16px 8px',
      }}
    >
      <div>
        <span
          style={{
            font: props.font
              ? props.font
              : 'normal normal bold 48px/58px Urbane Rounded',
            letterSpacing: '0px',
            color: '#FFFFFF',
            textAlign: 'center',
          }}
        >
          {props.title}
        </span>
      </div>

      {props.selector ? (
        props.selector
      ) : (
        <div style={{ textAlign: 'center' }}>
          <span
            style={{
              font: 'normal normal medium 16px/19px Urbane Rounded',
              letterSpacing: '0px',
              color: '#FFFFFF',
            }}
          >
            {props.subtitle}
          </span>
        </div>
      )}
    </div>
  );
};

export default InformationCard;
