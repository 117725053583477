import { Button, Modal, Input, message } from 'antd';
import axios from 'axios';
import { useState, useEffect } from 'react';
import authHeader from '../../services/auth-header';

const ModuleResourcesModal = ({
  courseId,
  moduleIndex,
  setCourseId,
  setModuleIndex,
}: any) => {
  const [resources, setResources] = useState<any>([]);

  const isModalVisible = courseId && moduleIndex;

  const getResources = async () => {
    const response = await axios.get(
      `/api/v2/course/${courseId}/${moduleIndex}`,
      { headers: authHeader() }
    );
    setResources(response.data.modules[moduleIndex].resources);
  };

  const saveResources = async () => {
    const response = await axios.post(
      `/api/v2/course/${courseId}/${moduleIndex}/edit-resources`,
      { resources },
      { headers: authHeader() }
    );
    message.success(response.data.message);
    hide();
  };

  useEffect(() => {
    if (!isModalVisible) return;
    getResources();
  }, [courseId, moduleIndex]);

  const setText = (index: any, text: any) => {
    const oldResources = [...resources];
    oldResources[index].text = text;
    setResources(oldResources);
  };

  const setUrl = (index: any, url: any) => {
    const oldResources = [...resources];
    oldResources[index].url = url;
    setResources(oldResources);
  };

  const setDescription = (index: any, description: any) => {
    const oldResources = [...resources];
    oldResources[index].description = description;
    setResources(oldResources);
  };

  const addNew = () => {
    const oldResources = [...resources];
    oldResources.push({
      text: '',
      url: '',
      description: '',
    });

    setResources(oldResources);
  };

  const popLast = () => {
    const oldResources = [...resources];
    oldResources.pop();
    setResources(oldResources);
  };

  const hide = () => {
    setCourseId('');
    setModuleIndex('');
    setResources([]);
  };

  return (
    <Modal
      title={`Module ${moduleIndex} resources`}
      visible={isModalVisible}
      onOk={saveResources}
      onCancel={hide}
    >
      {resources.map((resource: any, index: any) => (
        <div key={index}>
          <Input
            onChange={(e) => setText(index, e.target.value)}
            placeholder="Text"
            value={resource.text}
          />
          <Input
            onChange={(e) => setUrl(index, e.target.value)}
            placeholder="URL"
            value={resource.url}
          />
          <Input
            onChange={(e) => setDescription(index, e.target.value)}
            placeholder="Description"
            value={resource.description}
          />
          <hr />
          <br />
        </div>
      ))}
      <Button onClick={addNew}>Add new</Button>
      <Button onClick={popLast}>Remove last</Button>
    </Modal>
  );
};

export default ModuleResourcesModal;
