import {
  CloseOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Loading from '@components/Common/Loading';
import { StudentsOverview } from '@shared/apis/customerSuccess.apis';
import { useStudentsOverview } from '@shared/react';
import styles from '@styles/pages/admin/cs-students.module.scss';
import { Button, Card, Col, ColProps, Empty, Row, Statistic } from 'antd';
import { useState } from 'react';
import React from 'react';
import CSVTable from './CSVTable';

type Field = Exclude<keyof StudentsOverview['response'], 'users'>;

const ColButton: React.FC<
  ColProps & { field: Field; selectedField?: string }
> = ({ field, selectedField, ...props }) => {
  const { children } = props;

  const isBlue = field === selectedField;

  return (
    <Col
      {...props}
      style={{ cursor: 'pointer' }}
      className={`${styles.glanceCol} ${isBlue ? styles.selected : ''}`}
    >
      {children}
    </Col>
  );
};

const Students: React.FC = () => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedField, setSelectedField] = useState<Field | undefined>(
    undefined
  );
  const { data, isLoading } = useStudentsOverview();

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return <Empty />;
  }

  const { activeClients, enrolledWithoutMentor } = data;

  const getColProps = (field: Field) => {
    return {
      span: 6,
      field,
      selectedField,
      onClick: () => {
        setSelectedIds(data[field]);
        setSelectedField(field);
      },
    };
  };

  const reset = () => {
    setSelectedIds([]);
    setSelectedField(undefined);
  };

  return (
    <Card
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>Students at a glance</span>
          <Button disabled={!selectedField} danger onClick={reset}>
            <CloseOutlined /> Close
          </Button>
        </div>
      }
      style={{ width: '100%' }}
    >
      <div className={styles.glance}>
        <Row style={{ textAlign: 'center' }}>
          <ColButton {...getColProps('activeClients')}>
            <Statistic
              className={styles.glanceContent}
              title="Active students"
              value={activeClients.length}
              prefix={<UserOutlined />}
            />
          </ColButton>
          <ColButton {...getColProps('enrolledWithoutMentor')}>
            <Statistic
              title="Enrolled w/o mentor assigned"
              value={enrolledWithoutMentor.length}
              prefix={<UserDeleteOutlined />}
            />
          </ColButton>
        </Row>

        {selectedField && data && (
          <CSVTable selectedIds={selectedIds} selectedField={selectedField} />
        )}
      </div>
    </Card>
  );
};

export default Students;
