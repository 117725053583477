export const statusTypes = [
  { label: 'All Statuses', value: null },
  {
    label: 'Pending',
    value: 0,
  },
  {
    label: 'Ongoing',
    value: 1,
  },
  {
    label: 'Needs feedback',
    value: 2,
  },
  {
    label: 'Completed',
    value: 3,
  },
  {
    label: 'Pending review',
    value: 4,
  },
  {
    label: 'Paid',
    value: 5,
  },
  {
    label: 'Unpayable',
    value: 6,
  },
];
