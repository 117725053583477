import { authApis, usersApis } from '../../apis';
import { genericMutation, genericQueryWithParams } from './generics';

export const useExternalData =
  genericQueryWithParams<authApis.GetExternalUserData>(
    authApis.getExternalUserData
  );

export const useCreateNewUser = genericMutation<authApis.CreateNewUser>(
  authApis.createNewUser
);

export const useCreateNewMentor = genericMutation<authApis.CreateNewMentor>(
  authApis.createNewMentor
);

export const useResetPassword = genericMutation<authApis.ResetPassword>(
  authApis.resetPassword
);

export const useResetPasswordByAdmin =
  genericMutation<authApis.ResetPasswordByAdmin>(authApis.resetPasswordByAdmin);

export const useUploadProfilePicture =
  genericMutation<authApis.UploadProfilePicture>(
    authApis.uploadProfilePicture,
    usersApis.me.endpoint
  );

export const useCreateUserDriveByAdmin =
  genericMutation<authApis.CreateUserDriveByAdmin>(
    authApis.createUserDriveByAdmin
  );

export const useCreateUserV2 = genericMutation<authApis.CreateUserV2>(
  authApis.createUserV2
);

export const useHarvestReferral = genericMutation<authApis.Referral>(
  authApis.referral
);
