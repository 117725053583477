import { MailIcon, PhoneIcon } from '@heroicons/react/outline';
import { useUserDetails } from '@shared/react';
import styles from '@styles/pages/mentor/my-students.module.scss';
import { Typography } from 'antd';
import { COLORS } from '../../../../v3/global';

const { Text } = Typography;

type Props = {
  studentId: string;
};

const ContactInfoCard = ({ studentId }: Props) => {
  const { data: studentData, isLoading } = useUserDetails(studentId);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div
      style={{
        backgroundColor: COLORS.COMPONENT_BACKGROUND,
      }}
      className={styles.studentModalInfoCard}
    >
      <div
        className={styles.studentModalInfoCardHead}
        style={{ color: COLORS.TEXT_SECONDARY }}
      >
        Contact Info
      </div>

      <div
        style={{ color: COLORS.TEXT_PRIMARY }}
        className={styles.studentModalInfoCardItem}
      >
        <MailIcon className={styles.icon} />
        <Text style={{ color: COLORS.TEXT_PRIMARY }}>
          {studentData?.email || 'N/A'}
        </Text>
      </div>

      <div
        style={{ color: COLORS.TEXT_PRIMARY }}
        className={styles.studentModalInfoCardItem}
      >
        <PhoneIcon className={styles.icon} />
        <Text style={{ color: COLORS.TEXT_PRIMARY }}>
          {studentData?.phoneNumber || 'N/A'}
        </Text>
      </div>
    </div>
  );
};

export default ContactInfoCard;
