import { Blog } from '../common';
import { ApiDescription, ApiData, RequestsMethod } from '../types';

export const getBlog: ApiDescription = {
  endpoint: '/blog',
  method: RequestsMethod.Get,
};
export type GetBlog = ApiData<never, Blog>;

export const refreshBlog: ApiDescription = {
  endpoint: '/blog/refresh',
  method: RequestsMethod.Post,
};
export type RefreshBlog = ApiData<unknown, unknown>;

export const getBlogList: ApiDescription = {
  endpoint: '/blog/list',
  method: RequestsMethod.Get,
};

export type GetBlogList = ApiData<never, { obj: Blog[] }>;

export const updateBlogList: ApiDescription = {
  endpoint: '/blog/update',
  method: RequestsMethod.Post,
};

export type UpdateBlogList = ApiData<unknown, unknown>;
