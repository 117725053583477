import { useAuth0 } from '@auth0/auth0-react';
import { USE_AUTH0 } from '@shared/frontendEnv';
import { useEffect, useState } from 'react';
import { Environment } from '../common';
import BaseProvider from './BaseProvider';
import { EnvironmentProvider, ReactQueryProvider } from './contexts';

type Props = {
  environment: Environment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

const WebProvider = ({ children, environment }: Props) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { isLoading: isLoadingAuth } = useAuth0();

  const [authFlag] = useState<boolean>(USE_AUTH0 === '1');

  const isDevelopment = environment === 'development';

  useEffect(() => {
    if (isAuthenticated && authFlag) {
      const token = localStorage.getItem('auth0token');
      if (!token) {
        getAccessTokenSilently()
          .then((response) => {
            localStorage.setItem('auth0token', response);
            const impersonateId = localStorage.getItem('impersonate-user-id');
            if (!impersonateId) {
              localStorage.setItem('menuType', '2');
            }
          })
          .catch(console.log);
      }
    }
  }, [isLoadingAuth, isAuthenticated]);

  return (
    <EnvironmentProvider environment={environment} device="web">
      <ReactQueryProvider showWebDevTools={isDevelopment}>
        {!isLoadingAuth && <BaseProvider>{children}</BaseProvider>}
      </ReactQueryProvider>
    </EnvironmentProvider>
  );
};

export default WebProvider;
