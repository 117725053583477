import { BasicProps } from 'antd/lib/layout/layout';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { ComponentProps } from 'react';

interface TabProps extends BasicProps, ComponentProps<'div'> {
  text: string;
  isActive?: boolean;
}

const Tab = (props: TabProps) => {
  const { text, isActive, ...rest } = props;

  return (
    <div
      {...rest}
      className={mergeClassNames(
        'px-4 py-1 rounded-full text-xs text-white bg-surfaceHover cursor-pointer transition-all ease-in-out duration-200 hover:bg-customPrimary hover:text-black',
        { 'bg-customPrimary text-black': !!isActive }
      )}
    >
      {text}
    </div>
  );
};

export default Tab;
