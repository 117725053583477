import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { subjectsOptions } from '@shared/constants';
import {
  useCreateEnrollment,
  useDebounce,
  useGetAllMentors,
} from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import SessionModalFormWrapper from '../../../SessionModalFormWrapper';
import { StudentDetailsPageContext } from '../../StudentDetailsPage';

interface EnrollmentFormProps extends BasicProps {
  courses?: any[];
  onCloseEditForm: () => void;
}

const enrollmentSchema = z.object({
  course: z.string().min(1, { message: 'Please select a course' }),
  subject: z.string({ message: 'Please select a subject' }),
  mentor: z
    .object({
      _id: z.string({ message: 'Please select a mentor' }),
      fullName: z.string(),
    })
    .optional(),
  noOfMeetings: z.number({ message: 'Please enter a valid number' }),
  startDate: z.string({ message: 'Please select a start date' }),
  endDate: z.string({ message: 'Please select a start date' }),
});

type FormData = z.infer<typeof enrollmentSchema>;

const EnrollmentForm = (props: EnrollmentFormProps) => {
  const { courses, onCloseEditForm } = props;

  const { student } = useContext(StudentDetailsPageContext);

  const studentId = student?._id ?? '';

  const [mentorSearch, setMentorSearch] = useState('');

  const debouncedMentorSearch = useDebounce(mentorSearch, 500);

  const { data: mentors } = useGetAllMentors({
    search: debouncedMentorSearch,
    limit: 20,
  });

  const [showToast] = useToast();

  const { mutate: createEnrollment, isLoading: creatingEnrollment } =
    useCreateEnrollment({
      onSuccess: () => {
        showToast({
          variant: 'info',
          messageBody: 'Enrollment added successfully.',
        });
        onCloseEditForm();
      },
      onError: (error: any) => {
        showToast({
          variant: 'error',
          messageBody: error?.response?.data?.error?.message,
        });
      },
    });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(enrollmentSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const { field: courseField, fieldState: courseFieldState } = useController({
    name: 'course',
    control,
  });

  const { field: subjectField, fieldState: subjectFieldState } = useController({
    name: 'subject',
    control,
  });

  const { field: mentorField, fieldState: mentorFieldState } = useController({
    name: 'mentor',
    control,
  });

  const { field: startDateField, fieldState: startDateFieldState } =
    useController({
      name: 'startDate',
      control,
    });

  const { field: endDateField, fieldState: endDateFieldState } = useController({
    name: 'endDate',
    control,
  });

  const touchAllFields = (
    fields: Partial<
      Record<
        keyof FormData,
        string | number | { _id: string; fullName: string }
      >
    >
  ) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();

    if (isValid && studentId) {
      createEnrollment({
        userId: studentId,
        courseId: data.course,
        subject: data.subject,
        mentorId: data.mentor?._id,
        startDate: data.startDate,
        estimatedEndDate: data.endDate,
        numberOfSessions: data.noOfMeetings,
      });
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  return (
    <SessionModalFormWrapper
      title={'New Enrollment'}
      submitButtonText="Create Enrollment"
      isLoading={creatingEnrollment}
      onCancel={onCloseEditForm}
      submitHandler={submitHandler}
    >
      <div className="flex justify-between gap-4">
        <Select
          size="large"
          isRequired
          label="Course"
          options={
            courses?.map((course) => ({
              label: course.title,
              value: course._id,
            })) ?? []
          }
          value={courseField.value}
          onSelect={(value) => courseField.onChange(value)}
          onClear={() => courseField.onChange(undefined)}
          onBlur={courseField.onBlur}
          isValid={!courseFieldState.error}
          isTouched={courseFieldState.isTouched}
          errorText={courseFieldState.error?.message}
        />
        <Select
          size="large"
          label="Subject"
          options={subjectsOptions.map((subject) => ({
            label: subject,
            value: subject,
          }))}
          value={subjectField.value}
          onSelect={(value) => subjectField.onChange(value)}
          onClear={() => subjectField.onChange(undefined)}
          onBlur={subjectField.onBlur}
          isValid={!subjectFieldState.error}
          isTouched={subjectFieldState.isTouched}
          errorText={subjectFieldState.error?.message}
        />
      </div>
      <div className="flex justify-between gap-4">
        <Select
          size="large"
          label="Mentor"
          onSearch={setMentorSearch}
          filterOption={() => true}
          options={
            mentors?.map((mentor) => ({
              label: mentor?.fullName,
              value: mentor?._id,
            })) ?? []
          }
          value={mentorField.value?._id}
          onSelect={(value) =>
            mentorField.onChange(
              mentors?.find((mentor) => mentor?._id === value)
            )
          }
          onClear={() => mentorField.onChange(undefined)}
          onBlur={mentorField.onBlur}
          isValid={!mentorFieldState.error}
          isTouched={mentorFieldState.isTouched}
          errorText={mentorFieldState.error?.message}
        />
        <Input
          isRequired
          type="number"
          id="noOfMeetings"
          label="No. of meetings"
          isTouched={touchedFields.noOfMeetings}
          isValid={!errors.noOfMeetings}
          errorText={errors.noOfMeetings?.message}
          {...register('noOfMeetings', { required: true, valueAsNumber: true })}
        />
      </div>
      <div className="flex justify-between gap-4">
        <AgoraDatePicker
          isRequired
          label="Start Date"
          allowClear={false}
          value={
            startDateField.value ? moment(startDateField.value) : undefined
          }
          onChange={(value) =>
            startDateField.onChange(moment(value).format('YYYY-MM-DD'))
          }
          onBlur={startDateField.onBlur}
          isValid={!startDateFieldState.error}
          isTouched={startDateFieldState.isTouched}
          errorText={startDateFieldState.error?.message}
        />
        <AgoraDatePicker
          isRequired
          label="End Date"
          allowClear={false}
          value={endDateField.value ? moment(endDateField.value) : undefined}
          onChange={(value) =>
            endDateField.onChange(moment(value).format('YYYY-MM-DD'))
          }
          onBlur={endDateField.onBlur}
          isValid={!endDateFieldState.error}
          isTouched={endDateFieldState.isTouched}
          errorText={endDateFieldState.error?.message}
        />
      </div>
    </SessionModalFormWrapper>
  );
};

export default EnrollmentForm;
