import {
  admissionExamPrep,
  subjectsOptions,
  MENTOR_STATUS_V3,
  RECRUITED_FROM,
} from '@shared/constants';
import { BASE_URL } from '@shared/frontendEnv';
import { useCreateNewMentor } from '@shared/react';
import styles from '@styles/pages/sales-onboarding.module.scss';
import { Form, Input, Select, Button, message } from 'antd';
import { useState } from 'react';

const { TextArea } = Input;

const NewMentorForm = () => {
  const [form] = Form.useForm();
  const [id, setId] = useState('');
  const [registrationToken, setRegistrationToken] = useState('');
  const [fullName, setFullName] = useState('');

  const { mutate: createNewMentor } = useCreateNewMentor({
    onSuccess: (newMentor) => {
      form.resetFields();
      setId(newMentor.id);
      setRegistrationToken(newMentor.registrationToken);
    },
  });

  const onFinish = (fieldsValue: any) => {
    const values = {
      ...fieldsValue,
    };
    setFullName(values.fullName);
    createNewMentor({ ...values });
  };

  const onFinishFailed = () => {
    message.error('All fields are required.');
  };

  if (id && registrationToken)
    return (
      <TextArea
        rows={20}
        value={`Link generated for ${fullName}:\n\n${BASE_URL}/auth/welcome?id=${id}&token=${registrationToken}\n\nYou don't have to give them this link until you create a plan. After that you can click on "Activate Account" in your sales tools window.`}
      />
    );

  return (
    <Form
      {...formItemLayout}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      name="add-user"
      scrollToFirstError
    >
      <div className={styles.formHeader}>
        Add mentor details
        <button
          onClick={() => form.resetFields()}
          className={styles.formButton}
        >
          Clear all fields
        </button>
      </div>
      <Form.Item
        required
        name="email"
        label="Email"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          { required: true },
        ]}
      >
        <Input placeholder="Add new e-mail address" />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }]}
        name="fullName"
        label="Full Name"
      >
        <Input />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }]}
        name="phoneNumber"
        label="Phone Number (w/ country code)"
      >
        <Input />
      </Form.Item>

      <Form.Item required rules={[{ required: true }]} name="city" label="City">
        <Input />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }]}
        name="country"
        label="Country"
      >
        <Input />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }]}
        name={['mentorData', 'universities']}
        label="Universities"
      >
        <Input />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: false }]}
        name="admissionExamPrep"
        label="Admissions Exam Prep"
      >
        <Select mode="multiple">
          {admissionExamPrep.map((exam) => {
            return (
              <Select.Option key={exam} value={exam}>
                {exam}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }]}
        name={['mentorData', 'majors']}
        label="Subjects can teach"
      >
        <Select mode="multiple">
          {subjectsOptions.map((selectedSubject) => {
            return (
              <Select.Option key={selectedSubject} value={selectedSubject}>
                {selectedSubject}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }]}
        name="countries"
        label={'Countries to teach in'}
      >
        <Select mode="multiple">
          <Select.Option value="US">US</Select.Option>
          <Select.Option value="UK">UK</Select.Option>
          <Select.Option value="EU">EU</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        required
        rules={[{ required: true }]}
        name={['mentorData', 'status']}
        label="Status"
        initialValue={MENTOR_STATUS_V3[0]}
      >
        <Select>
          {MENTOR_STATUS_V3.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }]}
        name={['mentorData', 'maximumActiveEnrollments']}
        label="Maximum Capacity"
      >
        <Input type="number" />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }]}
        name={['mentorData', 'recruitedFrom']}
        label="Recruited From"
      >
        <Select placeholder="Select">
          {RECRUITED_FROM.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Register
        </Button>
      </Form.Item>
    </Form>
  );
};

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: {
    span: 24,
  },
};

const tailFormItemLayout = {
  wrapperCol: {},
};

export default NewMentorForm;
