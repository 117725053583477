import Output from './Output';
import renderers from './renderers';

type Props = {
  data: any;
  preview?: boolean;
};

const EdjDisplay = ({ data, preview }: Props) => {
  return (
    <Output
      renderers={renderers(!!preview)}
      data={data}
      classNames={undefined}
      style={undefined}
      config={undefined}
    />
  );
};

export default EdjDisplay;
