import React, { useState } from 'react';
import SessionModalSection, { ActionButton } from '../../SessionModalSection';
import SessionDetails from './SessionDetails';
import SessionDetailsForm from './SessionDetailsForm';
import { GetSessionDTO } from '@shared/types/DTOs/EnrollmentsDTO';

interface SessionDetailsSectionProps {
  session: GetSessionDTO;
}

const SessionDetailsSection = (props: SessionDetailsSectionProps) => {
  const { session } = props;

  const [isEdit, setIsEdit] = useState(false);

  const buttons: ActionButton[] = [
    {
      buttonText: 'Edit',
      onClick: () => setIsEdit(true),
    },
  ];

  return isEdit ? (
    <SessionDetailsForm
      sessionId={session.id}
      defaultData={session}
      onCloseEditMode={() => setIsEdit(false)}
    />
  ) : (
    <SessionModalSection
      sectionTitle={'Session Details'}
      buttonsArray={buttons}
    >
      <SessionDetails session={session} />
    </SessionModalSection>
  );
};

export default SessionDetailsSection;
