import styles from '@styles/components/form.module.scss';
import { FC } from 'react';
import { COLORS } from '../../v3/global';

const FormCustomElement: FC<{
  title: string;
  className?: string;
}> = ({ children, title, className }) => {
  return (
    <div className={`${styles.formInput} ${className}`}>
      <label style={{ color: COLORS.TEXT_PRIMARY }}>{title}</label>
      {children}
    </div>
  );
};

export default FormCustomElement;
