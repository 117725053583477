import { useMentorNoOfActiveEnrollments } from '@shared/react';
import { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

const ActiveEnrollmentsCalculator = ({ mentorId }: { mentorId: string }) => {
  const [isTriggered, setIsTriggered] = useState(false);

  const ActiveEnrollmentsCalculatorTriggered = () => {
    const { data } = useMentorNoOfActiveEnrollments(mentorId);

    return <span>{data?.noOfActives ?? 0}</span>;
  };

  return (
    <VisibilitySensor
      onChange={(isVisible) => {
        setIsTriggered(isVisible);
      }}
    >
      {isTriggered ? (
        <ActiveEnrollmentsCalculatorTriggered />
      ) : (
        <span>...</span>
      )}
    </VisibilitySensor>
  );
};

export default ActiveEnrollmentsCalculator;
