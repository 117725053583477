import { UPGRADE_EDUCATION_EMAIL } from '@shared/constants';
import React from 'react';
import { useTranslation } from '../../../TranslationContext';
import './terms-and-conditions.scss';
import LanguageSwitcher from '@components/V3/LanguageSwitcher';

const TermsAndConditionsView = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        backgroundColor: 'black',
        width: '100vw',
        height: 'auto',
        padding: '80px 32px 80px 32px',
        minHeight: '100vh',
      }}
      className="scrollableContainer"
    >
      <div className="scrollableContent">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <h1
            style={{
              color: '#fff',
              marginBottom: '40px',
              fontSize: '32px',
              lineHeight: '34px',
              textDecoration: 'underline',
            }}
          >
            {t('referral_terms_and_conditions')}
          </h1>
          <div style={{ width: 'fit-content' }}>
            <LanguageSwitcher />
          </div>
        </div>
        <ul className="termsAndConditionContent">
          <li>
            <strong>{t('referral_terms_eligibility')}:</strong>
            <ul style={{ listStyleType: 'initial' }}>
              <li>{t('terms_part1')}</li>
              <li>{t('terms_part2')}</li>
            </ul>
          </li>
          <br />

          <li>
            <strong>{t('referral_terms_process')}:</strong>
            <ul style={{ listStyleType: 'initial' }}>
              <li>{t('terms_part3')}</li>
              <li>{t('terms_part4')}</li>
            </ul>
          </li>
          <br />

          <li>
            <strong>{t('referral_terms_reward')}:</strong>
            <ul style={{ listStyleType: 'initial' }}>
              <li>{t('terms_part5')}</li>
            </ul>
          </li>
          <br />

          <li>
            <strong>{t('referral_terms_limitations')}:</strong>
            <ul style={{ listStyleType: 'initial' }}>
              <li>{t('terms_part6')}</li>
              <li>{t('terms_part7')}</li>
            </ul>
          </li>
          <br />

          <li>
            <strong>{t('referral_terms_general_conditions')}:</strong>
            <ul style={{ listStyleType: 'initial' }}>
              <li>{t('terms_part8')}</li>
              <li>{t('terms_part9')}</li>
              <li>{t('terms_part10')}</li>
            </ul>
          </li>
          <br />

          <li>
            <strong>{t('referral_terms_data_privacy')}:</strong>
            <ul style={{ listStyleType: 'initial' }}>
              <li>{t('terms_part11')}</li>
              <li>{t('terms_part12')}</li>
            </ul>
          </li>
        </ul>
        <br />
        <p className="termsAndConditionContent">
          {t('terms_part13')} {` ${UPGRADE_EDUCATION_EMAIL}`}.
          <br />
          <br />
          <strong>{t('referral_terms_address_part1')}</strong>
          <br />
          <br />
          {t('referral_terms_address_part2')}
          <br />
          <br /> {t('referral_terms_address_part3')}
          <br />
          <br /> Email: {UPGRADE_EDUCATION_EMAIL}
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditionsView;
