import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';
import ProfileAsideContact from './ProfileAsideContact';
import ProfileAsideHeader from './ProfileAsideHeader';
import ProfileAsideLocation from './ProfileAsideLocation';
import ProfileAsideParent from './ProfileAsideParent';
import ProfileAsideBiography from './ProfileAsideBiography';

interface ProfileAsideProps {
  userProfile?: Partial<UserProfileDTO>;
  hasEditPermissions: boolean;
  isMentorProfile: boolean;
}
const ProfileAside = (props: ProfileAsideProps) => {
  const { userProfile, hasEditPermissions, isMentorProfile } = props;

  return (
    <aside className="flex flex-col gap-4 p-6 bg-surfaceBackground rounded-lg tablet:w-2/5 laptop:w-1/3">
      {userProfile && (
        <>
          <ProfileAsideHeader
            name={userProfile.fullName ?? ''}
            profileImg={userProfile.avatar ?? ''}
            studentType={userProfile.studentType ?? ''}
            createdAt={userProfile.createdAt ?? ''}
            enrollments={userProfile.enrollments ?? []}
            hasEditPermissions={hasEditPermissions}
            isMentorProfile={isMentorProfile}
          />

          <ProfileAsideContact
            email={userProfile.email ?? ''}
            phone={userProfile.phoneNumber ?? ''}
            userId={userProfile._id ?? ''}
            hasEditPermissions={hasEditPermissions}
          />

          {isMentorProfile ? (
            <ProfileAsideBiography
              biography={userProfile?.mentorData?.biography ?? ''}
              userId={userProfile._id ?? ''}
              hasEditPermissions={hasEditPermissions}
            />
          ) : (
            <ProfileAsideParent
              email={userProfile.parentData?.email ?? ''}
              phone={userProfile.parentData?.phoneNumber ?? ''}
              name={userProfile.parentData?.fullName ?? ''}
              userId={userProfile._id ?? ''}
              hasEditPermissions={hasEditPermissions}
            />
          )}
          <ProfileAsideLocation
            location={`${userProfile.city} ${userProfile.country}`}
            city={userProfile.city ?? ''}
            country={userProfile.country ?? ''}
            timezone={userProfile.timezone ?? ''}
            language={userProfile.preferredLanguage ?? ''}
            userId={userProfile._id ?? ''}
            hasEditPermissions={hasEditPermissions}
          />
        </>
      )}
    </aside>
  );
};

export default ProfileAside;
