import Modal from '@components/V4/Modal/Modal';
import { useGetAllCoursesLite, useGetUserProfile } from '@shared/react';
import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';
import { createContext } from 'react';
import StudentDetailsSection from './StudentDetailsSection/StudentDetailsSection';
import StudentTabsSection, {
  StudentTab,
} from './StudentTabsSection/StudentTabsSection';

interface StudentDetailsPageProps {
  studentId: string;
  tab?: StudentTab;
  onEnrollmentModalOpen: (id: string) => void;
  onSessionModalOpen: (id: string) => void;
  onMentorModalOpen: (mentor: any) => void;
}

interface StudentDetailsPageContext {
  student?: Partial<UserProfileDTO>;
  courses?: any[];
  onEnrollmentModalOpen: (id: string) => void;
  onSessionModalOpen: (id: string) => void;
  onMentorModalOpen: (mentor: any) => void;
}

export const StudentDetailsPageContext =
  createContext<StudentDetailsPageContext>({
    onEnrollmentModalOpen: () =>
      console.warn('onEnrollmentModalOpen not implemented'),
    onSessionModalOpen: () =>
      console.warn('onSessionModalOpen not implemented'),
    onMentorModalOpen: () => console.warn('onMentorModalOpen not implemented'),
  });

const StudentDetailsPage = (props: StudentDetailsPageProps) => {
  const {
    studentId,
    tab,
    onEnrollmentModalOpen,
    onSessionModalOpen,
    onMentorModalOpen,
  } = props;
  const { data: student } = useGetUserProfile(studentId);
  const { data: courses } = useGetAllCoursesLite();

  return (
    <>
      <Modal.Header className="font-raleway text-xl font-bold text-center mb-6">
        <h3>Student Profile</h3>
        <h3>{student?.fullName}</h3>
      </Modal.Header>
      <Modal.Body>
        <StudentDetailsPageContext.Provider
          value={{
            student,
            courses,
            onEnrollmentModalOpen,
            onSessionModalOpen,
            onMentorModalOpen,
          }}
        >
          {student && <StudentDetailsSection />}
          <StudentTabsSection tab={tab} />
        </StudentDetailsPageContext.Provider>
      </Modal.Body>
    </>
  );
};

export default StudentDetailsPage;
