import { ReactComponent as CloseIcon } from '@assets/icons/V4/close-icon.svg';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import { createContext, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import IconButton from '../IconButton';

const ModalContext = createContext({ size: 'medium' });

export interface ModalProps extends BasicProps {
  isOpen: boolean;
  size?: 'small' | 'medium' | 'large' | 'fullScreen';
  hasFullMaxHeight?: boolean;
  hasCloseIcon?: boolean;
  shouldCloseOnBackdropClick?: boolean;
  onClose: () => void;
}

const Modal = (props: ModalProps) => {
  const {
    isOpen,
    size = 'medium',
    className,
    children,
    hasFullMaxHeight = false,
    hasCloseIcon = true,
    shouldCloseOnBackdropClick = true,
    onClose,
  } = props;
  useEffect(() => {
    const hasVerticalScrollbar =
      document.body.scrollHeight > window.innerHeight;

    if (!hasVerticalScrollbar || document.body.style.overflowY === 'hidden') {
      return;
    }

    if (isOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.width = 'calc(100% - 7px)';
    }

    return () => {
      document.body.style.overflowY = 'unset';
      document.body.style.width = '100%';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalContext.Provider value={{ size }}>
      <div
        className={mergeClassNames(
          'fixed top-0 left-0 right-0 bottom-0 w-full h-full flex z-[10001] bg-black bg-opacity-50 p-4 overflow-y-auto'
        )}
        onClick={() => shouldCloseOnBackdropClick && onClose()}
      >
        <div
          className={mergeClassNames(
            'relative w-full bg-surfaceObject rounded-lg flex flex-col px-3 py-8 tablet:p-8 m-auto font-raleway',
            {
              'max-w-122': size === 'small',
              'max-w-196': size === 'medium',
              'max-w-215 tablet:py-14': size === 'large',
              'max-w-full max-h-full h-full': size === 'fullScreen',
              'max-h-full': hasFullMaxHeight,
            },
            className
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {hasCloseIcon && (
            <IconButton
              className={mergeClassNames(
                'absolute top-4 right-4 tablet:top-8 tablet:right-8 z-10',
                { 'tablet:top-14 tablet:right-14': size === 'large' }
              )}
              icon={<CloseIcon className="h-3 w-3" />}
              onClick={onClose}
            />
          )}
          {children}
        </div>
      </div>
    </ModalContext.Provider>,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById('modal-root')!
  );
};

interface HeaderProps extends BasicProps {
  title?: string;
}

const Header = (props: HeaderProps) => {
  const { className, children, title } = props;

  return (
    <div
      className={mergeClassNames(
        'w-full flex flex-col align-center justify-center px-1 tablet:px-6',
        className
      )}
    >
      {!!title && (
        <h3 className="flex justify-center items-center text-center text-2xl font-bold text-customGrey mb-6 tablet:text-start">
          {title}
        </h3>
      )}
      {children}
    </div>
  );
};

const Body = (props: BasicProps) => {
  const { className, children } = props;

  const { size } = useContext(ModalContext);

  return (
    <div
      className={mergeClassNames(
        'w-full px-1 tablet:px-6 flex flex-col min-w-0',
        { 'h-full': size === 'fullScreen' },
        className
      )}
    >
      {children}
    </div>
  );
};

const Footer = (props: BasicProps) => {
  const { className, children } = props;

  const { size } = useContext(ModalContext);

  return (
    <div
      className={mergeClassNames(
        'w-full px-1 mt-auto tablet:px-6',
        { 'mt-auto': size === 'fullScreen' },
        className
      )}
    >
      {children}
    </div>
  );
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
