import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import StarRating from '@components/V4/StarRating/StarRating';

interface SessionDetailsFeedbackLabelValueProps {
  label: string;
  value?: string;
  ratingPosition?: 'sameLine' | 'below';
  starRating?: number;
}

const SessionDetailsFeedbackLabelValue = (
  props: SessionDetailsFeedbackLabelValueProps
) => {
  const { label, value, starRating, ratingPosition = 'sameLine' } = props;

  return (
    <section>
      <div
        className={mergeClassNames('flex gap-2.5 items-center', {
          'flex-col items-start': ratingPosition === 'below',
        })}
      >
        <h3 className="font-raleway font-bold text-customGrey text-base">
          {label}
        </h3>
        {starRating && (
          <StarRating value={starRating} size="small" isReadOnly />
        )}
      </div>
      {!!value && (
        <p className="text-white text-xs leading-5 break-words">{value}</p>
      )}
    </section>
  );
};

export default SessionDetailsFeedbackLabelValue;
