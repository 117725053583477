// The main function that handles scrolling
export const scrollToLastElement = (
  container: HTMLDivElement | null,
  behaviour: 'instant' | 'smooth' = 'smooth'
) => {
  if (container) {
    const lastMessage = container.lastElementChild;
    if (lastMessage) {
      lastMessage.scrollIntoView({ behavior: behaviour });
    }
  }
};
