import { SimpleQuiz as SimpleQuizType } from '@shared/types';
import { CSSProperties } from 'react';

import SimpleQuiz, { SimpleQuizBody } from './SimpleQuiz';

type Props<T extends { data: unknown }> = {
  data: T['data'];
  style: CSSProperties;
  classNames: string;
};

const SimpleQuizRenderer =
  (preview: boolean) =>
  ({ data }: Props<SimpleQuizType>) => {
    if (preview) return <SimpleQuizBody quiz={data} />;
    return <SimpleQuiz quiz={data} />;
  };

const renderers = (preview: boolean) => {
  return {
    simplequiz: SimpleQuizRenderer(preview),
  };
};

export default renderers;
