import Accordion from '@components/V4/Accordion';
import { MentorAIFeedback } from '@shared/types/DTOs/EnrollmentsDTO';
import moment from 'moment';
import SessionModalEmptyState from '../../../SessionModalEmptyState';
import MeetingDetail from '../SessionMeetingsTab/MeetingDetail';
import FeedbackEntry from './FeedbackEntry';

interface MentorAIFeedbackAccordionProps {
  mentorAIFeedback?: MentorAIFeedback;
  defaultIsAccordionOpen: boolean;
}
const MentorAIFeedbackAccordion = (props: MentorAIFeedbackAccordionProps) => {
  const { mentorAIFeedback, defaultIsAccordionOpen } = props;

  const AI_GENERATED_NOT_APPLICABLE_STRING = 'Not applicable.';

  const getMentorAIFeedbackAccordionContent = () => {
    if (!mentorAIFeedback) return <SessionModalEmptyState />;
    if (mentorAIFeedback && mentorAIFeedback?.generationFailed === true)
      return (
        <p className="font-raleway font-medium text-xs text-customRed">
          Error generating AI feedback.
        </p>
      );

    return (
      <div className="flex flex-col gap-4">
        <MeetingDetail
          name="Generated on:"
          value={
            mentorAIFeedback?.createdAt
              ? moment(mentorAIFeedback.createdAt).format('DD.MM.YYYY, HH:mm')
              : 'n/a'
          }
        />
        <FeedbackEntry
          title="Session Summary:"
          description={mentorAIFeedback?.sessionSummary ?? ''}
        />

        <FeedbackEntry
          title="Homework:"
          score={
            mentorAIFeedback?.homework?.explanation ===
            AI_GENERATED_NOT_APPLICABLE_STRING
              ? undefined
              : `${mentorAIFeedback?.homework?.rate}/5`
          }
          description={mentorAIFeedback?.homework?.explanation ?? ''}
        />

        <FeedbackEntry
          title="Engagement:"
          score={
            mentorAIFeedback?.engagement?.explanation ===
            AI_GENERATED_NOT_APPLICABLE_STRING
              ? undefined
              : `${mentorAIFeedback?.engagement?.rate}/5`
          }
          description={mentorAIFeedback?.engagement?.explanation ?? ''}
        />

        <FeedbackEntry
          title="Progress:"
          score={
            mentorAIFeedback?.progress?.explanation ===
            AI_GENERATED_NOT_APPLICABLE_STRING
              ? undefined
              : `${mentorAIFeedback?.progress?.rate}/5`
          }
          description={mentorAIFeedback?.progress?.explanation ?? ''}
        />

        {!!mentorAIFeedback?.parentHelp && (
          <FeedbackEntry
            title="Parent Help:"
            description={mentorAIFeedback?.parentHelp ?? ''}
          />
        )}
      </div>
    );
  };

  return (
    <Accordion
      defaultIsOpen={defaultIsAccordionOpen}
      titleWrapperClassName="bg-surfaceHover p-2"
      title={
        <p className="font-raleway font-medium text-xs text-white">
          AI generated Mentor Feedback
        </p>
      }
      className="flex flex-col gap-4 my-2 px-2 max-h-[80px] laptop:max-h-[140px]"
    >
      {getMentorAIFeedbackAccordionContent()}
    </Accordion>
  );
};

export default MentorAIFeedbackAccordion;
