import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import DetratorsAndPassivesRow from '@components/V3/NPSDetractorsAndPassivesModal/DetratorsAndPassivesRow';
import AgoraSpinner from '@components/V3/Utils/AgoraSpinner';
import { NPSCategoriesType } from '@shared/common';
import { useGetPaginatedNPS } from '@shared/react';
import { Pagination } from 'antd';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './nps-detractors-passives-modal.styles.scss';

type Props = {
  selectedTab: string | undefined;
  handleSelectedTab: (tab: string) => void;
  date: any;
  role: 'student' | 'mentor' | 'parent';
  npsType: 'platform' | 'education';
  reason?: string | undefined;
  category?: NPSCategoriesType[] | undefined;
  showCategoryTabs?: boolean | undefined;
};

const DetractorsAndPassivesPage = ({
  selectedTab,
  handleSelectedTab,
  date,
  role,
  npsType,
  reason,
  category,
  showCategoryTabs,
}: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    data: paginatedNpsData,
    isLoading: isLoadingPaginatedNps,
    refetch: refetchPaginatedNps,
  } = useGetPaginatedNPS({
    date: date,
    userRole: role,
    NPSType: npsType,
    category: (selectedTab?.toLowerCase() === 'detractors' ||
    selectedTab?.toLowerCase() === 'passives' ||
    selectedTab?.toLowerCase() === 'promoters'
      ? [selectedTab?.toLowerCase()]
      : undefined) as NPSCategoriesType[] | undefined,
    limit: pageSize,
    page: currentPage,
    reason: reason,
  });

  const handleCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const tabsToDisplay =
    category === undefined
      ? ['Detractors', 'Passives', 'Promoters']
      : category.map((item) => item[0].toUpperCase() + item.slice(1));

  return (
    <div>
      {showCategoryTabs && (
        <div style={{ marginBottom: '16px' }}>
          <nav
            style={{ overflowX: 'auto' }}
            className="flex space-x-4"
            aria-label="Tabs"
          >
            {tabsToDisplay.map((tab, index) => (
              <span
                key={`${tab}-${index}`}
                onClick={() => {
                  handleSelectedTab(tab);
                  setCurrentPage(1);
                }}
                style={{
                  cursor: 'pointer',
                  color:
                    selectedTab === tab ? COLORS.BLACK : COLORS.TEXT_PRIMARY,
                  background:
                    selectedTab === tab ? COLORS.PRIMARY : COLORS.DARK_GREY_ALT,
                  borderRadius: '32px',
                  paddingLeft: '24px',
                  paddingRight: '24px',
                  paddingTop: '4px',
                  paddingBottom: '4px',
                  height: '24px',
                  fontSize: '11px',
                  lineHeight: '15px',
                }}
                aria-current={selectedTab === tab ? 'page' : undefined}
              >
                {tab}
              </span>
            ))}
          </nav>
        </div>
      )}

      {isLoadingPaginatedNps ? (
        <AgoraSpinner fontSize={32} margin="32px" />
      ) : (
        <>
          {/* Display table */}
          <table className="NPS-modal-table">
            <thead>
              <tr style={{ width: '100%', marginTop: '8px' }}>
                <th
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    width: '19%',
                    marginRight: '1%',
                  }}
                  className="modal-column-text"
                >
                  Name
                </th>
                <th
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    width: '19%',
                    marginRight: '1%',
                  }}
                  className="modal-column-text"
                >
                  Rating
                </th>
                <th
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    width: '34%',
                    marginRight: '1%',
                  }}
                  className="modal-column-text"
                >
                  Reasons
                </th>
                <th
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    width: '24%',
                    marginRight: '1%',
                  }}
                  className="modal-column-text"
                >
                  Contact
                </th>
              </tr>
            </thead>

            <tbody>
              {paginatedNpsData?.nps?.map((nps: any) => (
                <DetratorsAndPassivesRow
                  nps={nps}
                  handleCurrentPage={handleCurrentPage}
                  refetchNps={refetchPaginatedNps}
                  role={role}
                  npsType={npsType}
                  date={date}
                />
              ))}
            </tbody>
          </table>

          {/* Display no results */}
          {paginatedNpsData?.total === 0 && (
            <div style={{ marginTop: '14px' }}>
              <p
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  textAlign: 'center',
                }}
                className="no-results-text"
              >
                No results
              </p>
            </div>
          )}

          {/*PAGINATION NAVIGATOR*/}
          {paginatedNpsData && paginatedNpsData.total > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '12px',
                width: '100%',
              }}
            >
              <div>
                <span
                  style={{ color: COLORS.TEXT_SECONDARY, marginRight: '8px' }}
                  className="flags-table-pagination-label"
                >
                  Rows per page
                </span>
                <select
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setPageSize(Number(selectedValue));
                  }}
                  className="mentors-row-custom-select-component pagination-select-container"
                  style={{
                    borderColor: COLORS.TEXT_PRIMARY,
                    color: COLORS.TEXT_PRIMARY,
                    backgroundColor: COLORS.SECTION_BACKGROUND,
                  }}
                  defaultValue={pageSize}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={paginatedNpsData?.total}>All</option>
                </select>
              </div>

              <div
                style={{ color: COLORS.TEXT_SECONDARY }}
                className="mentors-table-pagination-label"
              >
                {`${(currentPage - 1) * pageSize + 1}-${Math.min(
                  currentPage * pageSize,
                  paginatedNpsData?.total || 0
                )} of ${paginatedNpsData?.total || 0}`}
              </div>
              <Pagination
                className="custom-pagination"
                showSizeChanger={false}
                defaultCurrent={1}
                current={paginatedNpsData.page}
                total={paginatedNpsData.total}
                pageSize={pageSize}
                onChange={(page) => handleCurrentPage(page)}
                prevIcon={
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <LeftOutlined
                      style={{
                        color: COLORS.TEXT_SECONDARY,
                      }}
                    />
                  </div>
                }
                nextIcon={
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <RightOutlined
                      style={{
                        color: COLORS.TEXT_SECONDARY,
                      }}
                    />
                  </div>
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DetractorsAndPassivesPage;
