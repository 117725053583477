import { BasicProps } from 'apps/agora/src/utils/types';
import NavigationItem from './NavigationItem';

interface NavigationProps extends BasicProps {
  navigation: Record<string, { label: string; isCompleted: boolean }>;
  currentPage: string;
  onCurrentPageChange: (page: string) => void;
}

const Navigation = (props: NavigationProps) => {
  const { navigation, currentPage, onCurrentPageChange } = props;

  const navigationKeys = Object.keys(navigation);

  const areAnyPastPagesIncomplete = (currentIndex: number) => {
    for (let i = 0; i < currentIndex; i++) {
      if (!navigation[navigationKeys[i]].isCompleted) {
        return true;
      }
    }

    return false;
  };

  return (
    <div className="hidden tablet:flex flex-col gap-2 font-raleway">
      {navigationKeys.map((item, index) => (
        <NavigationItem
          key={item}
          isCompleted={navigation[item].isCompleted}
          isCurrentPage={currentPage === item}
          isDisabled={areAnyPastPagesIncomplete(index)}
          bubbleText={index + 1}
          isLast={index === navigationKeys.length - 1}
          label={navigation[item].label}
          onClick={() => onCurrentPageChange(item)}
        />
      ))}
    </div>
  );
};

export default Navigation;
