import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { Pagination } from 'antd';
import React from 'react';
import { COLORS } from '../../../v3/global';
import './enrollments-modal-students.styles.scss';
import EnrolmentModalRow from '@components/V3/EnrollmentsModalStudents/EnrolmentModalRow';

type Props = {
  enrollments: any;
  refetchEnrollments: () => any;
  isLoadingEnrollments: boolean;
  handleCurrentPage: (page: string) => void;
  handleEnrollmentsPage: (page: number) => void;
  handleIsEnded: (isEnded: boolean) => void;
  handleCurrentEnrolment: (id: string) => any;
  selectedTab: string;
  handleSelectedTab: (tab: string) => void;
  handleNewEnrolmentTeaching: (value: boolean) => void;
  handleLastMeeting: (lastMeeting: string) => void;
  handleEnrolmentData?: (enrolmentData: any) => void;
};

const EnrollmentsPageStudents = ({
  enrollments,
  refetchEnrollments,
  isLoadingEnrollments,

  handleCurrentPage,
  handleEnrollmentsPage,
  handleIsEnded,
  handleCurrentEnrolment,
  selectedTab,
  handleSelectedTab,
  handleNewEnrolmentTeaching,
  handleLastMeeting,
  handleEnrolmentData,
}: Props) => {
  return (
    <div>
      <div style={{ width: '100%', marginTop: '8px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '16px',
          }}
        >
          <p color={COLORS.TEXT_PRIMARY} className="modal-section-title">
            Enrolled In
          </p>
          <SecondaryButton
            // disabled={true}
            onClick={() => {
              handleCurrentPage('new-enrollment');
              handleNewEnrolmentTeaching(false);
            }}
            backgroundColor={COLORS.WHITE}
            height="27px"
            padding="4px 10px 4px 10px"
            margin="0px"
          >
            <PlusOutlined style={{ color: COLORS.BLACK, fontSize: '13px' }} />{' '}
            <span
              className="button-text"
              style={{ marginLeft: '8px', color: COLORS.BLACK }}
            >
              Add Enrolment
            </span>
          </SecondaryButton>
        </div>

        {/*TABS*/}
        <div style={{ marginBottom: '16px' }}>
          <nav
            style={{ overflowX: 'auto' }}
            className="flex space-x-4"
            aria-label="Tabs"
          >
            <span
              onClick={() => {
                handleSelectedTab('Active');
                handleIsEnded(false);
                handleEnrollmentsPage(1);
                refetchEnrollments();
              }}
              style={{
                cursor: 'pointer',
                color:
                  selectedTab === 'Active' ? COLORS.BLACK : COLORS.TEXT_PRIMARY,
                background:
                  selectedTab === 'Active'
                    ? COLORS.PRIMARY
                    : COLORS.DARK_GREY_ALT,
                borderRadius: '32px',
                paddingLeft: '24px',
                paddingRight: '24px',
                paddingTop: '4px',
                paddingBottom: '4px',
                height: '24px',
                fontSize: '11px',
                lineHeight: '15px',
              }}
              aria-current={selectedTab === 'Active' ? 'page' : undefined}
            >
              Active
            </span>

            <span
              onClick={() => {
                handleSelectedTab('Ended');
                handleIsEnded(true);
                handleEnrollmentsPage(1);
                refetchEnrollments();
              }}
              style={{
                cursor: 'pointer',
                color:
                  selectedTab === 'Ended' ? COLORS.BLACK : COLORS.TEXT_PRIMARY,
                background:
                  selectedTab === 'Ended'
                    ? COLORS.PRIMARY
                    : COLORS.DARK_GREY_ALT,
                borderRadius: '32px',
                paddingLeft: '24px',
                paddingRight: '24px',
                paddingTop: '4px',
                paddingBottom: '4px',
                height: '24px',
                fontSize: '11px',
                lineHeight: '15px',
              }}
              aria-current={selectedTab === 'Ended' ? 'page' : undefined}
            >
              Ended
            </span>
          </nav>
        </div>

        {/*COLUMNS*/}
        <div
          style={{
            display: 'flex',
            marginBottom: '12px',
            width: '100%',
          }}
        >
          <span
            style={{
              color: COLORS.TEXT_SECONDARY,
              width: '30%',
              marginRight: '8px',
            }}
            className="modal-column-text"
          >
            Enrolment
          </span>
          <span
            style={{
              color: COLORS.TEXT_SECONDARY,
              width: '15%',
              marginRight: '8px',
            }}
            className="modal-column-text"
          >
            Meetings
          </span>
          <span
            style={{
              color: COLORS.TEXT_SECONDARY,
              width: '20%',
              marginRight: '8px',
            }}
            className="modal-column-text"
          >
            Mentor
          </span>
          <span
            style={{
              color: COLORS.TEXT_SECONDARY,
              width: '15%',
              marginRight: '8px',
            }}
            className="modal-column-text"
          >
            Last Session
          </span>
          <span
            style={{ color: COLORS.TEXT_SECONDARY, width: '20%' }}
            className="modal-column-text"
          >
            Actions
          </span>
        </div>
      </div>

      {/*TABLE HEADER DIVIDER*/}
      <div style={{ borderTopWidth: '0.5px' }} className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>

      {enrollments?.enrollments?.length === 0 ? (
        <div style={{ marginTop: '14px' }}>
          <p
            style={{ color: COLORS.TEXT_SECONDARY, textAlign: 'center' }}
            className="no-results-text"
          >
            No results
          </p>
        </div>
      ) : (
        enrollments?.enrollments?.map((enrolment: any) => (
          <EnrolmentModalRow
            enrolment={enrolment}
            refetchEnrollments={refetchEnrollments}
            handleCurrentPage={handleCurrentPage}
            handleCurrentEnrolment={handleCurrentEnrolment}
            handleLastMeeting={handleLastMeeting}
            handleEnrolmentData={handleEnrolmentData}
          />
        ))
      )}

      {/*PAGINATION*/}
      {/*PAGINATION NAVIGATOR*/}

      {enrollments?.enrollments?.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              color: COLORS.TEXT_SECONDARY,
              marginTop: '12px',
            }}
            className="enrollments-students-modal-pagination-label"
          >
            {`Showing ${
              (enrollments?.page - 1) * enrollments?.limit + 1
            } to ${Math.min(
              enrollments?.page * enrollments?.limit,
              enrollments?.total || 0
            )} of ${enrollments?.total || 0}`}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'fle-end',
              alignItems: 'center',
              marginTop: '12px',
              width: 'fit-content',
            }}
          >
            <Pagination
              className="custom-pagination"
              showSizeChanger={false}
              defaultCurrent={1}
              current={enrollments?.page}
              total={enrollments?.total}
              pageSize={enrollments?.limit}
              onChange={(page) => handleEnrollmentsPage(page)}
              prevIcon={
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <LeftOutlined
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                    }}
                  />
                </div>
              }
              nextIcon={
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <RightOutlined
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                    }}
                  />
                </div>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EnrollmentsPageStudents;
