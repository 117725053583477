import { BasicProps } from 'apps/agora/src/utils/types';
import { mergeClassNames } from '../../utils/helpers';

interface PillProps extends BasicProps {
  text: string;
  isReadOnly?: boolean;
  isSelected?: boolean;
  size?: 'small' | 'default';
  color?: 'light' | 'custom';
  onClick?: () => void;
}

const Pill = (props: PillProps) => {
  const {
    text,
    isReadOnly = false,
    isSelected = false,
    color = 'custom',
    size = 'default',
    className,
    onClick,
  } = props;

  const clickHandler = () => {
    if (isReadOnly) return;

    onClick?.();
  };

  return (
    <div
      className={mergeClassNames(
        'w-fit py-1 px-4 rounded-full',
        {
          'bg-customLight': color === 'light',
          'py-2 px-6': size === 'default',
          'hover:bg-white cursor-pointer': !isReadOnly,
          'bg-customPrimary hover:bg-customPrimary': !isReadOnly && isSelected,
        },
        className
      )}
      onClick={clickHandler}
    >
      <p
        className={mergeClassNames('text-black text-xxs', {
          'text-xs': size === 'default',
        })}
      >
        {text}
      </p>
    </div>
  );
};

export default Pill;
