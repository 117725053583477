import Tabs from '@components/V4/Tabs/Tabs';
import EnrollmentsTab from './EnrollmentsTab/EnrollmentsTab';
import ExamsTab from './ExamsTab/ExamsTab';
import ResumeTab from './ResumeTab/ResumeTab';
import UniversitiesTab from './UniversityGoalsTab/UniversityGoalsTab';

export type StudentTab =
  | 'active-enrollments'
  | 'ended-enrollments'
  | 'resume'
  | 'exams'
  | 'universities';

interface StudentTabsSectionProps {
  tab?: StudentTab;
}

const StudentTabsSection = (props: StudentTabsSectionProps) => {
  const { tab = 'active-enrollments' } = props;

  const tabs: { label: string; value: StudentTab }[] = [
    { label: 'Active Enrollments', value: 'active-enrollments' },
    { label: 'Ended Enrollments', value: 'ended-enrollments' },
    { label: 'Resume', value: 'resume' },
    { label: 'Exams', value: 'exams' },
    { label: 'Universities (Goals)', value: 'universities' },
  ];

  const renderContent = (currentTab: StudentTab) => {
    switch (currentTab) {
      case 'active-enrollments':
        return <EnrollmentsTab areEnrollmentsActive />;
      case 'ended-enrollments':
        return <EnrollmentsTab />;
      case 'resume':
        return <ResumeTab />;
      case 'exams':
        return <ExamsTab />;
      case 'universities':
        return <UniversitiesTab />;
      default:
        return null;
    }
  };

  return (
    <div className="mt-6">
      <Tabs tabs={tabs} initialTab={tab} renderContent={renderContent} />
    </div>
  );
};

export default StudentTabsSection;
