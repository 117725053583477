import React from 'react';
import './index.styles.scss';
import MentorPlaceholderAvatar from '@assets/icons/mentor-placeholder-avatar.png';

type Props = {
  mentorFeedback?: any;
};

const MentorCard = ({ mentorFeedback }: Props) => {
  return (
    <div style={{ marginBottom: '24px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <img
          style={{ width: '48px', height: '48px', marginRight: '8px' }}
          className="inline-block rounded-full avatar"
          src={MentorPlaceholderAvatar}
          alt="avatar"
        />
        <div>
          <p className="mentor-card-title">{mentorFeedback?.mentor}</p>
          <p className="mentor-card-subtitle">{mentorFeedback?.course}</p>
        </div>
      </div>
    </div>
  );
};

export default MentorCard;
