import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

interface Translations {
  [key: string]: string;
}

interface TranslationResources {
  en: Translations;
  ro: Translations;
}

const translations: TranslationResources = {
  en: require('./constants/dictionary/en/translation.json'),
  ro: require('./constants/dictionary/ro/translation.json'),
};

type Language = keyof TranslationResources;

interface TranslationContextProps {
  language: Language;
  switchLanguage: (lng: Language) => void;
  t: (key: string) => string;
}

const TranslationContext = createContext<TranslationContextProps>({
  language: 'en',
  switchLanguage: () => {},
  t: (key: string) => key,
});

interface TranslationProviderProps {
  children: ReactNode;
}

export const TranslationProvider: React.FC<TranslationProviderProps> = ({
  children,
}) => {
  const [language, setLanguage] = useState<Language>('en');

  useEffect(() => {
    const savedLanguage =
      (localStorage.getItem('language') as Language) ||
      (navigator.language.split('-')[0] as Language) ||
      'en';
    setLanguage(savedLanguage);
  }, []);

  const switchLanguage = (lng: Language) => {
    setLanguage(lng);
    localStorage.setItem('language', lng);
  };

  const t = (key: string): string => {
    return translations[language][key] || key;
  };

  return (
    <TranslationContext.Provider value={{ language, switchLanguage, t }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => useContext(TranslationContext);
