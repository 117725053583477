import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { useSaveUserProfile } from '@shared/react';
import {
  SaveMentorProfileData,
  SaveStudentProfileData,
} from '@shared/types/misc';
import AgoraInput from '@utils/AgoraInput';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './profile-modal-users.styles.scss';

type Props = {
  handleCurrentPage: (page: string) => void;
  userId: string;
  userData: any;
  refetchUserData: () => void;
};

const EditProfileDetailsPage = ({
  handleCurrentPage,
  userId,
  userData,
  refetchUserData,
}: Props) => {
  const { mutate: saveUserProfile } = useSaveUserProfile(userId, {
    onSuccess: () => {
      refetchUserData();
      handleCurrentPage('user-profile');
    },
  });

  const [fullName, setFullName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);

  const handleSubmit = () => {
    const userDetailsToUpdate: SaveMentorProfileData | SaveStudentProfileData =
      {
        fullName: fullName?.length ? fullName : userData?.fullName,
        email: email?.length ? email : userData?.email,
        phoneNumber: phone?.length ? phone : userData?.phoneNumber,
      };

    saveUserProfile(userDetailsToUpdate);
  };
  return (
    <div style={{ marginTop: '8px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '60vh',
        }}
      >
        <div>
          <h3
            style={{ color: COLORS.TEXT_SECONDARY, marginBottom: '24px' }}
            className="modal-page-section-title"
          >
            Contact Info
          </h3>

          <AgoraInput
            htmlFor="fullName"
            name="fullName"
            id="fullName"
            type="text"
            placeholder="Dragos Popescu"
            label="Full Name"
            margin="0px 0px 16px 0px"
            value={fullName === null ? userData?.fullName : fullName}
            onChange={(e) => {
              setFullName(e.target.value);
            }}
          />

          <AgoraInput
            htmlFor="email"
            name="email"
            id="email"
            type="email"
            placeholder="email@example.com"
            label="Email"
            margin="0px 0px 16px 0px"
            value={email === null ? userData?.email : email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <AgoraInput
            htmlFor="phone"
            name="phone"
            id="phone"
            type="text"
            placeholder="004 0711 222 333"
            label="Phone"
            margin="0px 0px 32px 0px"
            value={phone === null ? userData?.phoneNumber : phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </div>

        <SecondaryButton
          onClick={handleSubmit}
          backgroundColor={COLORS.WHITE}
          height="32px"
          padding="4px 10px 4px 10px"
          margin="12px 0 0 0"
          width="100%"
        >
          <span className="button-text" style={{ color: COLORS.BLACK }}>
            Save
          </span>
        </SecondaryButton>
      </div>
    </div>
  );
};

export default EditProfileDetailsPage;
