import { ReactComponent as ExamIcon } from '@assets/icons/profile-icons/exam-icon.svg';
import Input from '@components/V4/Inputs/Input';
import TextArea from '@components/V4/Inputs/Textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { LanguageExam } from '@shared/common';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { ExamFormProps } from '../../../types';
import StudentFormWrapper from '../../ProfileFormWrapper';

interface LogProgressFormProps extends ExamFormProps {
  examData?: LanguageExam;
  setIsLogProgress: (value: React.SetStateAction<boolean>) => void;
}

const formDataSchema = z.object({
  prevGrade: z
    .string()
    .min(1, { message: 'Please enter a valid score.' })
    .default(''),
  areasOfImprovement: z.string().optional().default(''),
});

type FormData = z.infer<typeof formDataSchema>;

const LogProgressForm = (props: LogProgressFormProps) => {
  const {
    isModeAddNew,
    userId,
    contentClassName,
    className,
    examData,
    setIsLogProgress,
    onCancel,
  } = props;

  const [showToast] = useToast();
  const query = useQueryClient();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  const editButtonHandler = () => {
    setIsLogProgress?.(false);
    reset();
  };

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid && examData?._id) {
      const languageExam: Partial<LanguageExam> = {
        ...examData,
        ...data,
      };

      updateUserProfile({
        languageExams: [languageExam],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  return (
    <StudentFormWrapper
      title="Progress"
      isModeAddNew={isModeAddNew}
      onCancel={onCancel}
      className={className}
      contentClassName={contentClassName}
      submitHandler={submitHandler}
      isLoading={isLoading}
      editButtonHandler={editButtonHandler}
      icon={isModeAddNew && <ExamIcon className="min-w-5 w-5" />}
    >
      <Input
        type="text"
        id={'prevGrade'}
        placeholder={''}
        label={'Last Score'}
        isTouched={touchedFields.prevGrade}
        isValid={!errors.prevGrade}
        errorText={errors.prevGrade?.message}
        isRequired
        autofocus
        {...register('prevGrade')}
      />

      <TextArea
        id={'areasOfImprovement'}
        placeholder={''}
        className="hover:bg-opacity-50 focus:bg-opacity-50"
        label={'Ares Of Improvement'}
        {...register('areasOfImprovement')}
      />
    </StudentFormWrapper>
  );
};

export default LogProgressForm;
