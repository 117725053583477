import { MentorFeedback, SessionStatus } from '@shared/common';
import DetailsHeaderDesktop from './DetailsHeaderDesktop';
import DetailsHeaderMobile from './DetailsHeaderMobile';

export interface SessionDetailsUser {
  _id: string;
  fullName: string;
  avatar?: string;
}

export interface DetailsHeaderProps {
  abbreviation: string;
  title: string;
  scheduledDate?: string;
  endDate?: string;
  agenda?: string;
  sessionSummary?: string;
  accentColor: string;
  mentor: SessionDetailsUser;
  student: SessionDetailsUser;
  nextSession?: string;
  prevSession?: string;
  relevantMeetingId?: string;
  mentorFeedback?: MentorFeedback;
  meetingDuration?: number;
  sessionStatus?: SessionStatus;
  setIsMentorFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onMeetingModalOpen: () => void;
}

const SessionDetailsHeader = (props: DetailsHeaderProps) => {
  const {
    abbreviation,
    title,
    scheduledDate,
    endDate,
    agenda,
    accentColor,
    mentor,
    student,
    nextSession,
    prevSession,
    relevantMeetingId,
    mentorFeedback,
    meetingDuration,
    sessionStatus,
    setIsMentorFeedbackOpen,
    onMeetingModalOpen,
  } = props;

  return (
    <>
      <DetailsHeaderDesktop
        abbreviation={abbreviation}
        title={title}
        scheduledDate={scheduledDate}
        agenda={agenda}
        endDate={endDate}
        sessionSummary={mentorFeedback?.sessionSummary}
        mentor={mentor}
        student={student}
        accentColor={accentColor}
        nextSession={nextSession}
        prevSession={prevSession}
        relevantMeetingId={relevantMeetingId}
        meetingDuration={meetingDuration}
        sessionStatus={sessionStatus}
        setIsMentorFeedbackOpen={setIsMentorFeedbackOpen}
        onMeetingModalOpen={onMeetingModalOpen}
      />
      <DetailsHeaderMobile
        abbreviation={abbreviation}
        title={title}
        scheduledDate={scheduledDate}
        endDate={endDate}
        agenda={agenda}
        sessionSummary={mentorFeedback?.sessionSummary}
        mentor={mentor}
        student={student}
        accentColor={accentColor}
        nextSession={nextSession}
        prevSession={prevSession}
        relevantMeetingId={relevantMeetingId}
        meetingDuration={meetingDuration}
        sessionStatus={sessionStatus}
        setIsMentorFeedbackOpen={setIsMentorFeedbackOpen}
        onMeetingModalOpen={onMeetingModalOpen}
      />
    </>
  );
};

export default SessionDetailsHeader;
