import { CheckOutlined, RedoOutlined } from '@ant-design/icons';
import { useQueryParams } from '@hooks';
import { COURSES } from '@routes';
import { Quiz } from '@shared/types';
import { Button } from 'antd';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

type Props = {
  retake: () => void;
};

const QuizFail = ({ retake }: Props) => {
  const history = useHistory();

  const { id } = useParams<{ [key: string]: string }>();
  const { data: quiz } = useQuery<Quiz>(['page/$?refType=Quiz', id]);
  const params = useQueryParams();
  const chapter = params.get('m');
  const moduleUrl = `${COURSES}/${quiz?.parentCourse}`;

  return (
    <div>
      <h3>You're out of hearts</h3>
      <h4>Try again</h4>
      <Button
        style={{ marginTop: 15, marginRight: 15 }}
        shape="round"
        icon={<CheckOutlined />}
        size="large"
        onClick={() => {
          window.location.replace(`${moduleUrl}/chapters/${chapter}`);
        }}
      >
        Back to Module
      </Button>

      <Button
        style={{ marginTop: 15, marginRight: 15 }}
        shape="round"
        type="primary"
        icon={<RedoOutlined />}
        size="large"
        onClick={retake}
      >
        Retake Quiz
      </Button>
    </div>
  );
};

export default QuizFail;
