import { notificationApis } from '../../apis';
import { genericMutation, genericQuery } from './generics';

export const useListMyNewNotifications =
  genericQuery<notificationApis.ListMyNewNotifications>(
    notificationApis.listMyNewNotifications,
    { refetchInterval: 5000 }
  );

export const useCountNewNotifications = () => {
  const { data } = useListMyNewNotifications();
  return data?.length ?? 0;
};

export const useReadNotification =
  genericMutation<notificationApis.ReadNotification>(
    notificationApis.readNotification,
    notificationApis.listMyNewNotifications.endpoint
  );

export const useSendNotificationToAll =
  genericMutation<notificationApis.SendNotificationToAll>(
    notificationApis.sendNotificationToAll
  );
