import useMicrophoneLevel from '@modules/MeetingVideo/hooks/useMicrophoneLevel';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';

interface MicrophoneLevelIconProps {
  activeMicrophone?: string;
}

const MicrophoneLevelIcon = (props: MicrophoneLevelIconProps) => {
  const { activeMicrophone } = props;

  const microphoneLevel = useMicrophoneLevel(activeMicrophone);

  return (
    <div
      className={mergeClassNames(
        'min-w-6 h-6 rounded-full text-white flex items-center justify-center gap-0.5 bg-customPrimary'
      )}
    >
      <div className="w-1 h-1.5 bg-white rounded-full" />
      <div
        style={{ height: 6 + microphoneLevel / 2 }}
        className="w-1 min-h-1.5 max-h-5 bg-white rounded-full"
      />
      <div className="w-1 h-1.5 bg-white rounded-full" />
    </div>
  );
};

export default MicrophoneLevelIcon;
