import React from 'react';
import { COLORS } from '../../../v3/global';
import './profile-modal-mentors.styles.scss';

const WorkExperienceDetailsSection = () => {
  return (
    <div style={{ color: COLORS.TEXT_PRIMARY, fontSize: '14px' }}>
      Work Experience Details Section
    </div>
  );
};

export default WorkExperienceDetailsSection;
