import useToast from 'apps/agora/src/hooks/useToast';
import { useEffect, useRef, useState } from 'react';
import { stopTracks } from '../utils/helpers';

const useMicrophoneLevel = (activeMicrophone?: string) => {
  const [microphoneLevel, setMicrophoneLevel] = useState<number>(0);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const dataArrayRef = useRef<Uint8Array | null>(null);
  const animationFrameIdRef = useRef<number | null>(null);
  const isMountedRef = useRef(false);
  const mediaStreamRef = useRef<MediaStream>();

  const [showToast] = useToast();

  useEffect(() => {
    if (!activeMicrophone) return;

    isMountedRef.current = true;

    setupMicrophone(activeMicrophone);

    return () => {
      isMountedRef.current = false;

      if (audioContextRef.current) {
        audioContextRef.current.close().catch(console.log);
      }

      stopTracks(mediaStreamRef.current);

      if (animationFrameIdRef.current !== null) {
        cancelAnimationFrame(animationFrameIdRef.current);
      }
    };
  }, [activeMicrophone]);

  const setupMicrophone = async (microphone: string) => {
    try {
      // Obtain the audio stream for the selected device
      mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: microphone } },
      });

      // Initialize the audio context and analyser
      const AudioContext =
        window.AudioContext || (window as any).webkitAudioContext;
      const audioContext = new AudioContext();
      audioContextRef.current = audioContext;

      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 256;
      analyserRef.current = analyser;

      // Create a data array to hold the frequency data
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      dataArrayRef.current = dataArray;

      // Connect the microphone stream to the analyser
      const mediaStreamSource = audioContext.createMediaStreamSource(
        mediaStreamRef.current
      );
      mediaStreamSource.connect(analyser);

      // Start monitoring the microphone level
      monitorMicrophoneLevel();
    } catch (err) {
      if (isMountedRef) {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: 'Error accessing microphone',
        });
      }
    }
  };

  const monitorMicrophoneLevel = () => {
    if (!analyserRef.current || !dataArrayRef.current) return;

    analyserRef.current.getByteFrequencyData(dataArrayRef.current);

    // Calculate the average volume level
    let sum = 0;
    for (let i = 0; i < dataArrayRef.current.length; i++) {
      sum += dataArrayRef.current[i];
    }
    const average = sum / dataArrayRef.current.length;

    // Convert to a scale of 0-100
    const level = Math.round((average / 255) * 100);

    if (isMountedRef.current) {
      setMicrophoneLevel(level);
    }

    // Call this function recursively to keep updating the level
    animationFrameIdRef.current = requestAnimationFrame(monitorMicrophoneLevel);
  };

  return microphoneLevel;
};

export default useMicrophoneLevel;
