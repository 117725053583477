import { useGetAllCoursesLite } from '@shared/react';
import { Select } from 'antd';
import { CSSProperties } from 'react';

type Props = {
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
  style?: CSSProperties;
  className?: string;
  optionPlaceholder?: string;
};

const CourseSelector = ({
  defaultValue,
  value,
  onChange,
  style,
  className,
  optionPlaceholder,
}: Props) => {
  const { data: courses, isLoading } = useGetAllCoursesLite();

  return (
    <Select
      showSearch
      loading={isLoading}
      style={style || { width: 170 }}
      placeholder="Select a course"
      optionFilterProp="children"
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      className={className}
    >
      {optionPlaceholder && (
        <Select.Option key="no-enrollment" value="">
          {optionPlaceholder}
        </Select.Option>
      )}

      {courses &&
        courses.map((course) => (
          <Select.Option key={course._id} value={course._id}>
            {course.title}
          </Select.Option>
        ))}
    </Select>
  );
};

export default CourseSelector;
