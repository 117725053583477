import { useEffect, useRef } from 'react';

type Props = {
  color?: string;
};

const HoverShinyEffect = ({ color }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function mouseMoveEvent(e: MouseEvent) {
      const { x } = containerRef.current?.getBoundingClientRect() as {
        x: number;
      };

      containerRef.current?.style.setProperty('--px', `${e.clientX - x}`);
      color && containerRef.current?.style.setProperty('--color', color);
    }
    containerRef.current?.addEventListener('mousemove', mouseMoveEvent);

    return () => {
      try {
        containerRef.current?.removeEventListener('mousemove', mouseMoveEvent);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error', e);
      }
    };
  }, []);

  return <div ref={containerRef} className="shiny_boi"></div>;
};

export default HoverShinyEffect;
