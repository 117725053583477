import { Buffer } from 'buffer';
import { Environment } from '@shared/types';

export const ENVIRONMENT = process.env.NX_ENVIRONMENT as Environment;
export const isProduction = ENVIRONMENT === 'production';
export const isStaging = ENVIRONMENT === 'staging';
export const isDevelopment = ENVIRONMENT === 'development';
export const isCi = ENVIRONMENT === 'CI';

export const JWT_SECRET = process.env.JWT_SECRET;

export const MONGO_URI = process.env.MONGO_URI;
export const MONGO_SSL = process.env.MONGO_SSL === 'true';
export const CA_CERTIFICATE = process.env.CA_CERTIFICATE;

export const DRIVE_SERVICE_EMAIL = process.env.DRIVE_SERVICE_EMAIL;
const encoded_drive_service_key = process.env.DRIVE_SERVICE_KEY ?? '';
export const DRIVE_SERVICE_KEY = Buffer.from(
  encoded_drive_service_key,
  'base64'
).toString('utf-8');
export const DRIVE_BASE_FOLDER_ID = process.env.DRIVE_BASE_FOLDER_ID;
export const GOOGLE_CALENDAR_ID = process.env.GOOGLE_CALENDAR_ID;

export const SENDGRID_KEY = process.env.SENDGRID_KEY;

export const HUBSPOT_ACCESS_TOKEN = process.env.HUBSPOT_ACCESS_TOKEN;
export const HUBSPOT_KEY = process.env.HUBSPOT_KEY;

export const AWS_BUCKET_NAME = process.env.AWS_BUCKET_NAME;
export const AWS_BUCKET_REGION = process.env.AWS_BUCKET_REGION;
export const AWS_ACCESS_KEY = process.env.AWS_ACCESS_KEY;
export const AWS_SECRET_KEY = process.env.AWS_SECRET_KEY;

export const AUTH0_DOMAIN = process.env.NX_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.NX_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = process.env.NX_AUTH0_AUDIENCE;
export const AUTH0_JWKS_URI = process.env.AUTH0_JWKS_URI;
export const AUTH0_ISSUER = process.env.AUTH0_ISSUER;

export const USE_AUTH0 = process.env.NX_USE_AUTH0 ?? '1';

export const AUTH0_API_CLIENT_ID = process.env.AUTH0_API_CLIENT_ID;
export const AUTH0_API_CLIENT_SECRET = process.env.AUTH0_API_CLIENT_SECRET;

export const AUTH0_CONNECTION = process.env.NX_AUTH0_CONNECTION;

export const LMS_CLIENT_ID = process.env.LMS_CLIENT_ID;
export const LMS_CLIENT_SECRET = process.env.LMS_CLIENT_SECRET;

const baseUrls = {
  development: 'http://localhost:8080',
  staging: 'https://staging.agora.upgrade.education',
  CI: 'https://ci.agora.upgrade.education',
  production: 'https://agora.upgrade.education',
};

export const BASE_URL = baseUrls[ENVIRONMENT];

const domains = {
  development: 'localhost:8080',
  staging: 'staging.agora.upgrade.education',
  CI: 'ci.agora.upgrade.education',
  production: 'agora.upgrade.education',
};

export const DOMAIN = domains[ENVIRONMENT];

export const MAIN_WEBSITE_DOMAIN = 'upgrade.education';

export const NEW_RELIC_APP_NAME = process.env.NEW_RELIC_APP_NAME;
export const NEW_RELIC_LICENSE_KEY = process.env.NEW_RELIC_LICENSE_KEY;
export const NEW_RELIC_APP_ID = process.env.NEW_RELIC_APP_ID;

export const CHATGPT_API_KEY = process.env.CHATGPT_API_KEY;

export const ZOOM_VIDEO_SDK_KEY = process.env.ZOOM_VIDEO_SDK_KEY;
export const ZOOM_VIDEO_SDK_SECRET = process.env.ZOOM_VIDEO_SDK_SECRET;

export const FIRST_UPLOAD_INVOICE_DAY = Number(
  process.env.NX_FIRST_UPLOAD_INVOICE_DAY
);
export const LAST_UPLOAD_INVOICE_DAY = Number(
  process.env.NX_LAST_UPLOAD_INVOICE_DAY
);

export const KNOWLEDGE_BASE_ASSISTANT_ID =
  process.env.KNOWLEDGE_BASE_ASSISTANT_ID;
