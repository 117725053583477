import { XIcon } from '@heroicons/react/outline';
import { subjectsOptions } from '@shared/constants';
import styles from '@styles/components/reusables/multi-selector.module.scss';
import { useState } from 'react';

type Props = {
  majors: any;
  setMajors: any;
  saveCourses?: (arg1: string[]) => void;
};

const CustomStudySubjectsSelector = ({
  majors,
  setMajors,
  saveCourses,
}: Props) => {
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const majorsOptions = subjectsOptions.filter((major: string) => {
    return !majors.find((m: string) => m === major);
  });

  return (
    <div className={styles.selector}>
      {majors.map((major: string) => (
        <div
          className={styles.selectorOption}
          key={major}
          onClick={() => {
            setMajors(majors.filter((m: string) => m !== major));
            saveCourses?.(majors.filter((m: string) => m !== major));
          }}
        >
          {major}
          <XIcon className={styles.icon} />
        </div>
      ))}
      <div
        className={styles.selectorAdd}
        onClick={() => setIsOptionsVisible(!isOptionsVisible)}
      >
        Add new
        <div
          className={styles.selectorAddOptions}
          style={{ maxHeight: isOptionsVisible ? '150px' : 0 }}
        >
          {majorsOptions.map((major: string) => (
            <div
              key={major}
              onClick={() => {
                setMajors([...majors, major]);
                saveCourses?.([...majors, major]);
              }}
            >
              {major}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomStudySubjectsSelector;
