import { MentorData } from '@shared/common';

interface CapacityTabItemProps {
  mentorData?: MentorData;
  currentNoOfEnrollments?: number;
}

const CapacityTabItem = (props: CapacityTabItemProps) => {
  const { mentorData, currentNoOfEnrollments } = props;
  return (
    <section className="flex flex-col gap-6">
      <div className="text-sm flex justify-between">
        <p>Maximum number of enrollments you can take at the same time</p>
        <p className="w-6 text-center mr-4 tablet:mr-8">
          {mentorData?.mentorCapacity?.capacity ?? 'n/a'}
        </p>
      </div>
      <div className="h-0.5 w-full bg-customGrey"></div>
      <div className="text-sm flex justify-between">
        <p>Curret number of enrollments</p>
        <p className="w-6 text-center mr-4 tablet:mr-8">
          {currentNoOfEnrollments ?? 'n/a'}
        </p>
      </div>
    </section>
  );
};

export default CapacityTabItem;
