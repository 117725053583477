export enum studentStatuses {
  'Upcoming' = 1,
  'Active' = 2,
  'Alumni' = 3,
}

export const STUDENT_STATUSES: Record<number, string> = {
  [studentStatuses.Upcoming]: 'Upcoming',
  [studentStatuses.Active]: 'Active',
  [studentStatuses.Alumni]: 'Alumni',
};

export const studentStatusColors: { [key: number]: string } = {
  [studentStatuses.Active]: 'text-customLime',
  [studentStatuses.Alumni]: 'text-customGold',
  [studentStatuses.Upcoming]: 'text-customRed',
};
