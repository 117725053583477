import { PencilIcon } from '@heroicons/react/outline';
import styles from '@styles/components/form.module.scss';
import { ChangeEvent, CSSProperties, useState } from 'react';
import { COLORS } from '../../../../v3/global';
import './locked-form-input-v3.styles.scss';

const LockedFormInputV3 = ({
  value,
  title,
  disabled = false,
  type = 'text',
  placeholder = '',
  className,
  style,
  onChange,
  onBlur,
  ...rest
}: {
  title?: string;
  value?: any;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
  [key: string]: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const renderIcon = (type: string) => {
    if (type === 'date' || type === 'datetime-local') {
      return;
    }

    if (type === 'number') {
      return (
        <PencilIcon className={styles.icon} style={{ marginRight: '-2rem' }} />
      );
    }

    return <PencilIcon className={styles.icon} />;
  };

  const [valueState, setValueState] = useState(value);

  return (
    <div className="custom-input-v3">
      {title && <label htmlFor={title}>{title}</label>}
      <input
        style={{
          color: COLORS.TEXT_PRIMARY,
          backgroundColor: COLORS.COMPONENT_BACKGROUND,
          colorScheme: 'dark',
          fontSize: '12px',
          height: '14px',
          width: 'fit-content',
          borderWidth: '0px',
          paddingLeft: '0px',
        }}
        className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-white hover:ring-0 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
        type={type}
        // if it has an onChange event, will not be controlled by this component
        value={onChange ? value : valueState}
        disabled={disabled}
        name={title}
        placeholder={placeholder}
        onChange={onChange ? onChange : (e) => setValueState(e.target.value)}
        onBlur={onBlur}
        {...rest}
      />
      {renderIcon(type)}
    </div>
  );
};

export default LockedFormInputV3;
