import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { useModal } from '@hooks';
import { User } from '@shared/common';
import {
  useCurrentDiagramNPS,
  useCurrentNPS,
  useGenerateCSVforNPS,
} from '@shared/react';
import styles from '@styles/components/calendar.module.scss';
import reportsStyles from '@styles/pages/admin/activity-report.module.scss';
import {
  Alert,
  Button,
  Col,
  Row,
  Tabs,
  Tooltip as AntTooltip,
  message,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import useCalendar from '../../../hooks/useCalendar';
import NPSUsersModal from './NPSModals/NPSUsersModal';

const { TabPane } = Tabs;

const NPS: React.FC = () => {
  const { goForward, goBackward, selected } = useCalendar();

  const [userRole, setUserRole] = useState<'student' | 'mentor' | 'parent'>(
    'student'
  );
  const { data, isLoading } = useCurrentNPS({ userRole });
  const { data: trendPoints } = useCurrentDiagramNPS({
    NPSType: 'education',
    userRole,
  });
  const { mutate: generateCSVData, data: CSVData } = useGenerateCSVforNPS();

  const [NPSUsersM, showNPSUsersM] = useModal(NPSUsersModal);

  const [parentsFilter, setParentsFilter] = useState('all');

  const openUsersModal = (
    npsLevel: 'passives' | 'detractors' | 'promoters',
    data: Partial<User>[],
    userRates?: any
  ) => {
    const allowedUserRoles = ['student', 'mentor', 'parent'];
    if (!allowedUserRoles.includes(userRole)) return;

    showNPSUsersM({
      fieldType: 'rating',
      userRole,
      npsLevel,
      data,
      userRates,
    });
  };

  const convertToCSV = (data: any) => {
    if (data.length === 0) {
      message.error('No data to export');
      return;
    }
    const keys = Object.keys(data[0]); // Assuming all objects have the same keys
    const csvRows = [];

    // Add the headers as the first row
    csvRows.push(keys.join(','));

    for (const row of data) {
      const values = keys.map((key) => row[key]);
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  };

  const getCSVData = async () => {
    try {
      generateCSVData({
        date: selected.format('YYYY-MM-DD'),
        filters: {
          userRole: 'parent',
        },
        filterByInflluencers: parentsFilter,
      });
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    getCSVData();
  }, [selected, parentsFilter]);

  if (isLoading) return <p>Loading...</p>;
  if (!data) return <p>No data</p>;

  const { totalNps, responsesNumber } = data;

  const commas2Digits = (nr?: number) => {
    return (nr ?? 0).toLocaleString('en', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  const percent2Digits = (nr?: number) => {
    return (
      ((nr ?? 0) * 100).toLocaleString('en', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }) + ' %'
    );
  };

  const chartPoints = (trendPoints?.trendPoints ?? []).map(
    ({ y, ...point }) => {
      return { ...point, y: y * 100 };
    }
  );

  const downloadCSVData = () => {
    try {
      const csvContent = convertToCSV(CSVData);
      if (!csvContent) return;
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'data.csv';
      link.click();
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <>
      <Tabs
        defaultActiveKey="student"
        onChange={(key) => {
          if (key === 'student' || key === 'mentor' || key === 'parent') {
            setUserRole(key);
          }
        }}
        activeKey={userRole}
      >
        <TabPane tab="Students" key="student" />
        <TabPane tab="Mentors" key="mentor" />
        <TabPane tab="Parents" key="parent" />
      </Tabs>
      <Row>
        <Col span={6}>
          <h3>Current NPS</h3>
          <span style={{ fontSize: '3rem' }}>{percent2Digits(totalNps)}</span>
        </Col>

        <Col span={6}>
          <h3>Number of responses</h3>
          <span style={{ fontSize: '3rem' }}>{responsesNumber}</span>
        </Col>

        <Col span={6}>
          <h3>Distribution</h3>
          <ul>
            {(['detractors', 'passives', 'promoters'] as const).map((field) => {
              const { users, percentage } = data[field];

              return (
                <li key={field}>
                  <AntTooltip title={`${users.length} responses`}>
                    <span
                      className="main-green-link"
                      onClick={() =>
                        openUsersModal(field, users, data?.userRates)
                      }
                    >
                      {field.charAt(0).toUpperCase() + field.slice(1)}
                    </span>{' '}
                    {percent2Digits(percentage)}
                  </AntTooltip>
                </li>
              );
            })}
          </ul>
        </Col>

        <Col span={6}>
          <h3>Trend</h3>
          {chartPoints.length ? (
            <LineChart width={300} height={200} data={chartPoints}>
              <XAxis dataKey="name" />
              <YAxis domain={[-100, 100]} />
              <Tooltip
                formatter={(value: number) => `${commas2Digits(value)} %`}
              />
              <Line type="monotone" dataKey="y" stroke="#82ca9d" />
            </LineChart>
          ) : (
            <p>Loading...</p>
          )}
        </Col>
      </Row>
      {userRole === 'parent' && (
        <Row
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '40px',
            marginTop: '40px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div className={reportsStyles.selectorContainer}>
              <ChevronLeftIcon
                className={`${styles.icon} ${reportsStyles.directionIcon}`}
                onClick={goBackward}
              />
              <span className={reportsStyles.dateFormat}>
                {selected.format('MMMM YYYY')}
              </span>
              <ChevronRightIcon
                className={`${styles.icon} ${reportsStyles.directionIcon}`}
                onClick={goForward}
              />
            </div>
            <div style={{ marginLeft: '20px' }}>
              <span>Filter by: </span>
              <Select
                showSearch
                style={{ width: '180px' }}
                onChange={(val) => setParentsFilter(val)}
                value={parentsFilter}
              >
                <Select.Option value="all">ALL</Select.Option>
                <Select.Option value="influencers">INFLUENCERS</Select.Option>
                <Select.Option value="non-influencers">
                  NON-INFLUENCERS
                </Select.Option>
              </Select>
            </div>
          </div>

          <Button
            type="primary"
            disabled={CSVData ? false : true}
            style={{
              border: '2px solid #000000',
              margin: '0px 10px',
            }}
          >
            <div onClick={() => downloadCSVData()}>
              {CSVData ? 'Generate CSV' : 'Loading...'}
            </div>
          </Button>
        </Row>
      )}
      {responsesNumber < 50 && (
        <Alert
          message="Data is not yet conclusive (less than 50 points)."
          type="warning"
          showIcon
        />
      )}
      {NPSUsersM}
    </>
  );
};

export default NPS;
