import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { PageLayout } from '@components';
import FormCustomElement from '@components/Form/FormCustomElement';
import authHeader from '@services/auth-header';
import { useGetAllCourses } from '@shared/react';
import { Checklist } from '@shared/types';
import styles from '@styles/components/reusables/modal.module.scss';
import {
  Form,
  Input,
  Button,
  Switch,
  DatePicker,
  Space,
  message,
  Select,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type ChecklistEditorParams = {
  id: string;
};

type ChecklistEditorProps = {
  isAdmin: boolean;
  defaultId?: string;
  showLayout?: boolean;
};

const ChecklistEditor = ({
  isAdmin,
  defaultId,
  showLayout,
}: ChecklistEditorProps) => {
  const params = useParams<ChecklistEditorParams>();
  const id = defaultId || params.id || '';

  const [selectedCourse, setSelectedCourse] = useState<null | string>(null);
  const { data: courses } = useGetAllCourses();

  const [checklist, setChecklist] = useState<Partial<Checklist>>({
    title: '',
    isTemplate: true,
    enrollmentId: '',
    items: [],
  });

  const getChecklist = async () => {
    const newChecklist = await axios.get(`/api/checklists/${id}`, {
      headers: authHeader(),
    });
    setChecklist(newChecklist.data);
    setSelectedCourse(newChecklist.data.course);
  };

  useEffect(() => {
    if (id) getChecklist();
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setChecklist({ ...checklist, [name]: value });
  };

  const addItem = () => {
    const blankItem = {
      title: '',
      deadline: '',
      isDone: false,
    };

    setChecklist({
      ...checklist,
      items: [...(checklist as Checklist).items, blankItem],
    });
  };

  const editItemTitle = (index: number, title: string) => {
    const items = [...(checklist as Checklist).items];
    items[index] = { ...items[index], title };
    setChecklist({ ...checklist, items });
  };

  const editItemDeadline = (index: number, deadline: string) => {
    const items = [...(checklist as Checklist).items];
    items[index] = { ...items[index], deadline };
    setChecklist({ ...checklist, items });
  };

  const removeLastItem = () => {
    const items = [...(checklist as Checklist).items];
    items.pop();
    setChecklist({ ...checklist, items });
  };

  const postChecklist = async () => {
    const method = id ? 'patch' : 'post';

    await axios[method](
      `/api/checklists/${id ? id : ''}`,
      { ...checklist, course: selectedCourse },
      { headers: authHeader() }
    );

    message.success('Checklist saved successfully.');
    showLayout = true;

    setTimeout(() => {
      if (showLayout) window.history.back();
    }, 1000);
  };

  const FormBlock = (
    <Form onFinish={postChecklist}>
      <Form.Item label="Title">
        <Input name="title" value={checklist.title} onChange={onChange} />
      </Form.Item>
      <div className="mt2 mb2">
        <FormCustomElement title="Course: ">
          <Select
            showSearch
            value={selectedCourse}
            onSelect={setSelectedCourse}
            placeholder="Select a course"
            optionFilterProp="children"
            style={{ width: '100%' }}
            filterOption={(input: any, option: any) =>
              option &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {courses &&
              courses.map((course: any) => (
                <Select.Option key={course._id} value={course._id}>
                  {course.title}
                </Select.Option>
              ))}
          </Select>
        </FormCustomElement>
      </div>

      <Form.Item>
        {checklist.items?.map((item, index) => (
          <div key={item._id} style={{ marginBottom: '10px' }}>
            <span>Item {index + 1}: &nbsp;</span>
            <Input
              value={item.title}
              onChange={(e) => editItemTitle(index, e.target.value)}
              style={{ width: '200px' }}
            />{' '}
            <span>, deadline (optional): &nbsp;</span>
            <DatePicker
              value={item.deadline ? moment(item.deadline) : null}
              onChange={(date, dateString) =>
                editItemDeadline(index, dateString)
              }
            />
          </div>
        ))}
        <Space>
          <Button type="dashed" icon={<PlusOutlined />} onClick={addItem}>
            Add Item
          </Button>
          <Button
            type="default"
            icon={<DeleteOutlined />}
            onClick={removeLastItem}
          >
            Remove last
          </Button>
        </Space>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save Template
        </Button>
      </Form.Item>
    </Form>
  );

  if (showLayout === false) return FormBlock;

  return (
    <PageLayout headerTitle="Checklist Template Editor">{FormBlock}</PageLayout>
  );
};

export default ChecklistEditor;
