import { fontFamily } from '../../style/template';

const embedOutputStyle = {
  iframeStyle: {
    maxWidth: '100%',
    width: '100%',
    height: '400px',
    maxHeight: '400px',
    borderRadius: '5px',
    boxShadow: 'none',
    outline: 'none',
    border: 'none',
  },
  figureStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
    width: '100%',
    maxWidth: '100%',
    height: '400px',
    maxHeight: '400px',
    overflow: 'hidden',
  },
  figcaptionStyle: {
    padding: '5px 10px',
    fontSize: '12px',
    borderRadius: '2px',
    cursor: 'default',
    fontFamily,
  },
};

export default embedOutputStyle;
