import Button from '@components/V4/Button';
import Modal from '@components/V4/Modal/Modal';
import { PaginationContext } from '@components/V4/Modal/MultiPageModal/MultiPageModal';
import StarRating from '@components/V4/StarRating/StarRating';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useContext } from 'react';
import { Engagement } from '../EngagementPage/EngagementPage';
import { Homework } from '../HomeworkPage/HomeworkPage';
import { ParentHelp } from '../ParentHelpPage/ParentHelpPage';
import { Progress } from '../ProgressPage/ProgressPage';
import { SessionSummary } from '../SessionSummaryPage/SessionSummaryPage';

export interface Feedback {
  sessionSummary?: SessionSummary;
  homework?: Homework;
  engagement?: Engagement;
  progress?: Progress;
  parentHelp?: ParentHelp;
}

interface FinalReviewPageProps extends BasicProps {
  feedback: Feedback;
  isSubmitting?: boolean;
  onSubmit: () => void;
}

const FinalReviewPage = (props: FinalReviewPageProps) => {
  const { feedback, isSubmitting = false, onSubmit } = props;
  const { goBack } = useContext(PaginationContext);

  const feedbackArray = [
    {
      title: 'Session Summary',
      text: feedback.sessionSummary?.aboutSession,
    },
    {
      title: 'Topics To Be Covered Next',
      text: feedback.sessionSummary?.nextTopics,
    },
    {
      title: 'Homework',
      text: feedback.homework?.feedback,
      rating: feedback.homework?.rating,
    },
    {
      title: 'Engagement',
      text: feedback.engagement?.feedback,
      rating: feedback.engagement?.rating,
    },
    {
      title: 'Progress',
      text: feedback.progress?.feedback,
      rating: feedback.progress?.rating,
    },
    {
      title: 'Parent Help',
      text: feedback.parentHelp?.feedback,
    },
  ];

  return (
    <Modal.Body className="gap-6 overflow-auto max-h-[430px] tablet:px-2">
      <h3 className="text-customGrey text-2xl font-bold">Final Review</h3>

      {feedbackArray.map(
        (feedback) =>
          !!feedback.text && (
            <div key={feedback.title} className="flex flex-col gap-2">
              <div className="flex gap-2 items-center">
                <h4 className="text-customGrey text-base font-semibold">
                  {feedback.title}
                </h4>
                {!!feedback.rating && (
                  <StarRating isReadOnly size="small" value={feedback.rating} />
                )}
              </div>
              <p className="text-customGrey text-xs break-words">
                "{feedback.text}"
              </p>
            </div>
          )
      )}

      <div className="flex justify-end gap-4 flex-wrap">
        <Button
          variant="secondary"
          className="w-24"
          size="large"
          buttonText="Back"
          onClick={goBack}
        />
        <Button
          className="w-64"
          size="large"
          buttonText="Submit Feedback & Complete Session"
          isLoading={isSubmitting}
          onClick={onSubmit}
        />
      </div>
    </Modal.Body>
  );
};

export default FinalReviewPage;
