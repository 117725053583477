import Accordion from '@components/V4/Accordion';
import Markdown from '@components/V4/Markdown/Markdown';
import SessionModalEmptyState from '../../../SessionModalEmptyState';

interface MentorAIRecommendationAccordionProps {
  mentorRecommendation?: string;
  defaultIsAccordionOpen: boolean;
}
const MentorAIRecommendationAccordion = (
  props: MentorAIRecommendationAccordionProps
) => {
  const { mentorRecommendation, defaultIsAccordionOpen } = props;
  return (
    <Accordion
      defaultIsOpen={defaultIsAccordionOpen}
      titleWrapperClassName="bg-surfaceHover p-2"
      title={
        <p className="font-raleway font-medium text-xs text-white">
          Mentor Recommendation
        </p>
      }
      className="flex flex-col gap-4 my-2 px-2 max-h-[80px] laptop:max-h-[140px]"
    >
      {mentorRecommendation ? (
        <Markdown
          text={mentorRecommendation}
          className="font-raleway font-medium text-xs text-white flex flex-col gap-2"
        />
      ) : (
        <SessionModalEmptyState />
      )}
    </Accordion>
  );
};

export default MentorAIRecommendationAccordion;
