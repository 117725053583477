import { stringFormatters } from '@shared/utils';

const usePronouns = (pronounString: string) => {
  if (!pronounString || pronounString === 'none') pronounString = 'they/them';
  const pronouns = pronounString.split('/');
  const they = pronouns[0];
  const They = stringFormatters.capitalizeFirstLetter(they);
  const them = pronouns[1];
  const have = pronouns[0] === 'they' ? 'have' : 'has';
  const are = pronouns[0] === 'they' ? 'are' : 'is';

  return { they, They, them, have, are };
};

export default usePronouns;
