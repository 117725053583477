import Toast, { ToastProps } from '@components/V4/Toast';
import { render } from 'react-dom';

interface Props {
  duration?: number | 'infinite';
}
const useToast = (props?: Props) => {
  const { duration = 5000 } = props || {};

  const hide = (container: HTMLDivElement) => {
    render(<></>, container);
  };

  const show = (props: ToastProps) => {
    const individualToastContainer = document.createElement('div');
    let timeout: NodeJS.Timeout;

    const closeHandler = () => {
      hide(individualToastContainer);
      props.onClose?.();

      if (timeout) {
        clearTimeout(timeout);
      }
    };

    if (duration !== 'infinite') {
      timeout = setTimeout(() => {
        closeHandler();
      }, duration);
    }

    render(
      <Toast {...props} onClose={closeHandler} />,
      individualToastContainer
    );
  };

  return [show];
};

export default useToast;
