import { BasicProps } from 'apps/agora/src/utils/types';

interface ParticipantCardProps extends BasicProps {
  name: string;
}

const ParticipantCard = (props: ParticipantCardProps) => {
  const { name, children } = props;

  return (
    <div className="bg-surfaceObject rounded-lg shadow-default p-4 flex items-center gap-4">
      <div className="flex flex-col gap-2">
        <p className="font-raleway text-xsm font-bold capitalize">{name}</p>
        <div className="flex flex-col gap-1">{children}</div>
      </div>
    </div>
  );
};

export default ParticipantCard;
