import { AccountProfile } from '@modules/Account/Profile';
import React from 'react';
import { useParams } from 'react-router-dom';

const ViewStudentProfile = () => {
  const { studentId } = useParams<{ studentId: string }>();

  return <AccountProfile userId={studentId} />;
};

export default ViewStudentProfile;
