import {
  useAuthState,
  useMinimumMentorCapacity,
  useUpdateMentorCapacity,
} from '@shared/react';
import { Button, Tooltip } from 'antd';
import { useEffect, useState } from 'react';

import AgoraModal from '../Utils/AgoraModal';
import './mentor-capacity-style.scss';

type Props = {
  showButton?: boolean;
  isModalActive?: boolean;
};

const MentorCapacityCard = ({
  showButton = true,
  isModalActive = false,
}: Props) => {
  const { userId } = useAuthState();
  const {
    data: mentorCapacity,
    isLoading: isCapacityLoading,
    refetch,
  } = useMinimumMentorCapacity();
  const { mutate: updateMentorCapacity } = useUpdateMentorCapacity(userId);

  const [isModalOpen, setModalOpen] = useState(isModalActive);

  const [selectedCapacity, setSelectedCapacity] = useState(
    mentorCapacity?.currentCapacity ?? 0
  );

  useEffect(() => {
    refetch();
  }, [isModalOpen]);

  useEffect(() => {
    setSelectedCapacity(
      mentorCapacity?.currentCapacity ?? mentorCapacity?.activeEnrollments ?? 0
    );
  }, [isCapacityLoading]);

  useEffect(() => {
    setModalOpen(isModalActive);
  }, [isModalActive]);

  const isImpersonating = !!localStorage.getItem('impersonate-user-id');

  return (
    <div>
      {showButton && (
        <Button onClick={() => setModalOpen(true)}>
          Update my enrollment capacity
        </Button>
      )}

      <AgoraModal
        title="Let's update your capacity"
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        closable={isImpersonating}
        maskClosable={false}
        footer={
          <div
            style={{
              color: '#B0B0B0',
              width: '100%',
              marginBottom: '40px',
              marginTop: '32px',
            }}
          >
            <button
              style={{
                width: '100%',
                backgroundColor: selectedCapacity >= 0 ? '#F8F8F8' : '#B0B0B0',
                cursor: selectedCapacity >= 0 ? 'pointer' : 'not-allowed',
              }}
              disabled={selectedCapacity < 0}
              onClick={() => {
                updateMentorCapacity({
                  capacity: selectedCapacity,
                });
                refetch();
                setModalOpen(false);
              }}
            >
              Update my capacity
            </button>
          </div>
        }
      >
        <div>
          <div style={{ color: '#B0B0B0' }}>
            <p
              style={{ textAlign: 'center' }}
              className="mentor-capacity-description"
            >
              It's been a while since you updated your capacity, please update
              it to move on:
            </p>

            <p
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
              className="capacity-title"
            >
              Maximum Capacity
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                color: selectedCapacity > 0 ? '#36B3A8' : '#B0B0B0',
                cursor: selectedCapacity > 0 ? 'pointer' : 'not-allowed',
              }}
              className="capacity-selector-buttons"
              onClick={() => {
                if (selectedCapacity > 0)
                  setSelectedCapacity((prevsState: number) => prevsState - 1);
              }}
            >
              -
            </span>
            {!isCapacityLoading && (
              <span className="capacity-number">{selectedCapacity}</span>
            )}

            <Tooltip
              zIndex={99999}
              color="#222222"
              placement="top"
              title={
                <div className="tooltip-container">
                  <div className="tooltip-description">
                    Increasing your capacity allows us to send you more
                    enrollments requests. <br /> You will be able to deny
                    incoming enrollments requests to your liking.
                  </div>
                </div>
              }
            >
              <span
                style={{ color: '#36B3A8' }}
                className="capacity-selector-buttons"
                onClick={() =>
                  setSelectedCapacity((prevsState: number) => prevsState + 1)
                }
              >
                +
              </span>
            </Tooltip>
          </div>

          <div style={{ marginTop: '40px' }}>
            <p className="mentor-capacity-description">
              * Right now you have a total of{' '}
              {mentorCapacity?.activeEnrollments ?? 0} active enrollments
            </p>
          </div>
        </div>
      </AgoraModal>
    </div>
  );
};

export default MentorCapacityCard;
