interface CourseAbbreviationCardProps {
  abbreviation: string;
  accentColor: string;
}

const CourseAbbreviationCard = (props: CourseAbbreviationCardProps) => {
  const { accentColor, abbreviation } = props;

  return (
    <div
      className="flex items-center justify-center text-black min-w-17 h-17 rounded-2xl"
      style={{ backgroundColor: accentColor }}
    >
      <p className="text-xl text-black font-semibold leading-8 font-raleway">
        {abbreviation}
      </p>
    </div>
  );
};

export default CourseAbbreviationCard;
