import {
  ApiDescription,
  ApiData,
  RequestsMethod,
  ExtraService,
  ServiceType,
} from '../common';

const BASE_PATH = '/v1/extra-services';

type FilterAndPagination = {
  month?: number;
  year?: number;
  page?: number;
  limit?: number;
  status?: number | string;
  mentorId?: string;
};

export const getTotalPaymentForExtraServiceByMonth: ApiDescription = {
  endpoint: `${BASE_PATH}/monthly/total-payment`,
  method: RequestsMethod.Get,
};

export type GetTotalPaymentForExtraServiceByMonth = ApiData<
  {
    date: string;
  },
  {
    totalPaymentForExtraServices: {
      amountInEuro: number;
      amountInPounds: number;
      amountInDollars: number;
      amountInRON: number;
      paidAmountInEuro: number;
      paidAmountInPounds: number;
      paidAmountInDollars: number;
      paidAmountInRON: number;
    };
  }
>;

export const getMentorExtraService: ApiDescription = {
  endpoint: `${BASE_PATH}/mentor/:id`,
  method: RequestsMethod.Get,
};

export type GetMentorExtraService = ApiData<
  FilterAndPagination,
  ExtraService[]
>;

export const createExtraService: ApiDescription = {
  endpoint: `${BASE_PATH}/admin`,
  method: RequestsMethod.Post,
};

export type CreateExtraService = ApiData<
  {
    serviceType: ServiceType;
    description: string;
    amount: number;
    currency: string;
    mentorId: string;
    month: number;
    year: number;
  },
  ExtraService
>;

export const searchExtraServices: ApiDescription = {
  endpoint: `${BASE_PATH}/search`,
  method: RequestsMethod.Get,
};

export type SearchExtraServices = ApiData<FilterAndPagination, ExtraService[]>;

export const getExtraService: ApiDescription = {
  endpoint: `${BASE_PATH}/admin/:id`,
  method: RequestsMethod.Get,
};

export type GetExtraService = ApiData<never, ExtraService>;

export const updateExtraService: ApiDescription = {
  endpoint: `${BASE_PATH}/admin/:id`,
  method: RequestsMethod.Put,
};

export type UpdateExtraService = ApiData<Partial<ExtraService>, ExtraService>;

export const deleteExtraService: ApiDescription = {
  endpoint: `${BASE_PATH}/admin/:id`,
  method: RequestsMethod.Delete,
};

export type DeleteExtraService = ApiData<never, never>;
