import hubspotLoading from '@assets/lottie/hubspot-loading.json';
import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import AgoraInput from '@utils/AgoraInput';
import React, { useState, useEffect } from 'react';
import { COLORS } from '../../../v3/global';
import './create-new-user.styles.scss';

type Props = {
  handleCurrentPage: (page: string) => void;
  email: string;
  handleEmail: (email: string) => void;
  handleEmailError: (emailError: string) => void;
  emailError: string;
};

const CreateNewUserStepOne = ({
  handleCurrentPage,
  email,
  handleEmail,
  handleEmailError,
  emailError,
}: Props) => {
  const [validEmail, setValidEmail] = useState<undefined | boolean>();

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const isValid = validateEmail(e.target.value);
    setValidEmail(isValid);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!validEmail) {
      const isValid = validateEmail(e.target.value);
      if (isValid) {
        setValidEmail(isValid);
      }
    } else {
      const isValid = validateEmail(e.target.value);
      if (!isValid) {
        setValidEmail(isValid);
      }
    }

    if (emailError) {
      handleEmailError('');
    }
    handleEmail(e.target.value);
  };

  useEffect(() => {
    const isValid = validateEmail(email);
    if (isValid) {
      setValidEmail(isValid);
    }
  }, []);

  const colorAfterFieldValidation =
    validEmail === true && !emailError
      ? COLORS.PRIMARY
      : validEmail === false || !!emailError
      ? COLORS.ERROR
      : COLORS.TEXT_SECONDARY;

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>

      <div
        style={{
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <h3
            style={{
              color: COLORS.TEXT_SECONDARY,
              marginBottom: '48px',
              marginTop: '32px',
            }}
            className="modal-page-section-title"
          >
            Enter the Email for a new Agora user:
          </h3>

          <AgoraInput
            htmlFor="email"
            name="email"
            id="email"
            type="email"
            placeholder="example@domain.com"
            label="Email"
            labelMargin="0 0 0 8px"
            color={colorAfterFieldValidation}
            labelColor={colorAfterFieldValidation}
            margin="0px 0px 16px 0px"
            value={email}
            borderColor={colorAfterFieldValidation}
            onChange={handleChange}
            onBlur={handleBlur}
            isRequired
          />
          {validEmail === false ? (
            <p
              style={{ color: COLORS.ERROR }}
              className="create-new-user-modal-invalid-input-error"
            >
              Enter a valid email address format
            </p>
          ) : null}
          {!!emailError && (
            <p
              style={{ color: COLORS.ERROR }}
              className="create-new-user-modal-invalid-input-error"
            >
              {emailError}
            </p>
          )}
        </div>

        <SecondaryButton
          onClick={() => {
            if (validEmail) handleCurrentPage('create-new-user-step-two');
          }}
          backgroundColor={COLORS.WHITE}
          height="40px"
          padding="12px"
          margin="12px 0 0 0"
          width="100%"
          disabled={!validEmail}
        >
          <span
            className="create-new-user-modal-submit-button"
            style={{ color: COLORS.BLACK }}
          >
            Continue
          </span>
        </SecondaryButton>
      </div>
    </div>
  );
};

export default CreateNewUserStepOne;
