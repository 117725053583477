import { useEffect } from 'react';

const useHideHubspot = () => {
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
          #hubspot-messages-iframe-container {
            display: none !important;
          }
        `;
    document.body.appendChild(style);

    return () => {
      document.body.removeChild(style);
    };
  }, []);
};

export default useHideHubspot;
