import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { useQueryClient } from 'react-query';

interface useDeleteProfileEntryProps {
  userId: string;
  profileProperty: string;
}

export const useDeleteProfileEntry = (props: useDeleteProfileEntryProps) => {
  const { userId, profileProperty } = props;

  const [showToast] = useToast();
  const query = useQueryClient();

  const { mutate: updateUserProfile } = useSaveUserProfile(userId, {
    onSuccess: () => {
      query.invalidateQueries('/users/:id/profile');
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Work experience could not be deleted.',
      });
    },
  });

  const deleteEntryHandler = (id?: string) => {
    if (!id) return;

    updateUserProfile({
      [profileProperty]: [
        {
          _id: id,
          delete: true,
        },
      ],
    });
  };
  return [deleteEntryHandler];
};
