import { Form } from 'antd';

type Props = {
  field: any;
  label: string;
  name: string;
  missingMessage: string;
  required?: boolean;
  children: any;
};

const DynamicFormFieldItem = ({
  field,
  label,
  name,
  missingMessage,
  required,
  children,
}: Props) => {
  return (
    <Form.Item
      {...field}
      label={label}
      name={[field.name, name]}
      fieldKey={[field.fieldKey, name] as any}
      rules={
        required ? [{ required: true, message: missingMessage }] : undefined
      }
    >
      {children}
    </Form.Item>
  );
};

export default DynamicFormFieldItem;
