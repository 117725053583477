import { ReactComponent as ChatIcon } from '@assets/icons/V4/session-details/chat-history-icon.svg';
import Button from '@components/V4/Button';
import { useAuthState } from '@shared/react';
import { useState } from 'react';
import SessionDetailsEmptyState from '../components/SessionDetailsEmptyState';
import SessionDetailsLayoutCard from '../components/SessionDetailsLayoutCard';
import SessionDetailsChatMessage from './SessionDetailsChatMessage';

interface ChatMessage {
  user: string;
  message: {
    text: string;
    at: string;
    file?: string;
    error?: boolean;
  };
}

interface SessionDetailsChatProps {
  chatMessages?: ChatMessage[];
  startDate?: string;
  accentColor: string;
}

const SessionDetailsChat = (props: SessionDetailsChatProps) => {
  const { chatMessages, startDate, accentColor } = props;

  const [showEntireChat, setShowEntireChat] = useState(false);

  const showEntireChatButtonHandler = () => {
    setShowEntireChat((prev) => !prev);
  };

  const { fullName } = useAuthState();

  const messages = showEntireChat ? chatMessages : chatMessages?.slice(0, 5);

  return (
    <SessionDetailsLayoutCard
      icon={<ChatIcon className="text-white" />}
      className="gap-8"
      title="Chat History"
    >
      {messages?.length ? (
        <>
          <div className="flex flex-col gap-6">
            {messages.map((message, index) => (
              <SessionDetailsChatMessage
                key={`${message.message}-${index}`}
                startDate={startDate}
                timestamp={message.message.at}
                accentColor={accentColor}
                author={message.user}
                authenticatedUserFullName={fullName}
                message={message.message?.text ?? ''}
              />
            ))}
          </div>

          {chatMessages && chatMessages?.length > 5 && (
            <div className="flex justify-center">
              <Button
                variant="secondary"
                buttonText={showEntireChat ? 'Show less' : 'Show Entire Chat'}
                onClick={showEntireChatButtonHandler}
              />
            </div>
          )}
        </>
      ) : (
        <SessionDetailsEmptyState
          icon={<ChatIcon className="text-customGrey" />}
          text="No Chat History."
        />
      )}
    </SessionDetailsLayoutCard>
  );
};

export default SessionDetailsChat;
