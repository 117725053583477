import { NewMentorForm } from '@components';
import { CloseModalProps } from '@shared/common';
import { Modal } from 'antd';

const NewMentorModal = ({ visible, hide }: CloseModalProps) => {
  return (
    <Modal
      title="New Mentor"
      visible={visible}
      onCancel={hide}
      footer={null}
      width={700}
    >
      <NewMentorForm />
    </Modal>
  );
};

export default NewMentorModal;
