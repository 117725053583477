import { HeartTwoTone } from '@ant-design/icons';
import { COURSES } from '@routes';
import { Quiz } from '@shared/common';
import { useAuthState, useCourse } from '@shared/react';
import { Alert, Card, message, PageHeader, Progress, Statistic } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import authHeader from '../../services/auth-header';
import Breadcrumbs from '../../views/UserView/Course/Breadcrumbs';
import Question from './Question';
import QuizFail from './QuizFail';
import QuizFinish from './QuizFinish';

type Props = {
  skippedAhead: boolean;
  moduleId: string;
};

const DEFAULT_HEARTS = 3;

const QuizPage = ({ skippedAhead, moduleId }: Props) => {
  const { id } = useParams<{ [key: string]: string }>();
  const { userId } = useAuthState();

  const location = useLocation();
  const search = useLocation().search;
  const history = useHistory();
  const { data } = useQuery<Quiz>(['page/$?refType=Quiz', id]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [results, setResults] = useState<
    { answer: string; question: string; correct: boolean }[]
  >([]);

  const { data: courseData } = useCourse(data?.parentCourse);

  const wrongAnswers = results.reduce(
    (acc, cur) => acc + (cur.correct ? 0 : 1),
    0
  );
  const passed = wrongAnswers < 4;
  const rank = wrongAnswers < 4 ? wrongAnswers : -1;
  const hearts = DEFAULT_HEARTS - wrongAnswers;

  const failed = data?.isGraded && hearts < 0;
  const isFinished = data?.questions.length === questionIndex || failed;

  const answerQuiz = async () => {
    try {
      await axios.post(
        `/api/quiz/${id}/answer`,
        { quiz: id, results, passed, rank },
        { headers: authHeader() }
      );
      if (!failed) {
        message.success('Results registered');
      }
    } catch {
      message.error('Something went wrong saving the results');
    }
  };

  useEffect(() => {
    if (isFinished) answerQuiz();
  }, [isFinished]);

  const m2 = new URLSearchParams(search).get('m');

  const m = useParams();

  const { data: course } = useCourse(data?.parentCourse);

  const chapterName = m2 ? course?.modules[Number(m2)].title : '';

  const crumbs =
    courseData && data
      ? [
          { path: COURSES, name: 'Courses' },
          { path: `${COURSES}/${courseData._id}`, name: courseData.title },
          {
            path: `${COURSES}/${courseData._id}/chapters/${m2}`,
            name: `${Number(m2) + 1}. ${chapterName}`,
          },
          { path: location.pathname + location.search, name: data.title },
        ]
      : [];

  const header = (
    <>
      {crumbs.length && <Breadcrumbs crumbs={crumbs} />}
      <PageHeader
        key={data?.title}
        ghost={false}
        onBack={() => history.goBack()}
        title={data?.title || 'Loading...'}
        extra={[
          hearts >= 0 && (
            <Statistic
              value={hearts}
              prefix={<HeartTwoTone twoToneColor="red" />}
            />
          ),
        ]}
      />
    </>
  );

  if (!data) return header;

  const currentQuestion = data.questions[questionIndex];

  const nextQuestion = () => setQuestionIndex((q) => q + 1);
  const answerCorrect = (answerId: string) => {
    setResults([
      ...results,
      { answer: answerId, question: currentQuestion._id, correct: true },
    ]);
    nextQuestion();
  };
  const answerWrong = (answerId: string) => {
    setResults([
      ...results,
      { answer: answerId, question: currentQuestion._id, correct: false },
    ]);
    nextQuestion();
  };
  const retake = () => {
    setQuestionIndex(0);
    setResults([]);
  };

  let content = null;
  if (data.isGraded && hearts < 0) content = <QuizFail retake={retake} />;
  else if (isFinished)
    content = <QuizFinish retake={retake} rank={rank} results={results} />;
  else
    content = (
      <Question
        question={currentQuestion}
        answerCorrect={answerCorrect}
        answerWrong={answerWrong}
      />
    );

  return (
    <div>
      {header}
      <Card style={{ maxWidth: 800, margin: '15px auto' }}>
        {/*{skippedAhead && (*/}
        {/*  <Alert*/}
        {/*    message="While you're free to skip ahead, progress won't be saved unless you take lessons in order."*/}
        {/*    type="warning"*/}
        {/*    closable*/}
        {/*  />*/}
        {/*)}*/}
        <Progress
          percent={(questionIndex / data.questions.length) * 100}
          showInfo={false}
        />
        {content}
      </Card>
    </div>
  );
};

export default QuizPage;
