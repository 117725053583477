import DownIcon from '@assets/icons/down-icon.png';
import { FLAG_TYPE, MENTOR_ROLE, STUDENT_ROLE } from '@shared/constants';
import { useGetAllCourses, useSearchUsersByFullName } from '@shared/react';
import AgoraSearchBar from '@utils/AgoraSearchbar';
import AgoraSelector from '@utils/AgoraSmallSelector';
import { Checkbox, DatePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './flags-table-header.styles.scss';

type Props = {
  search?: string;
  handleFlagType: (flagType: string | undefined) => void;
  handleStartRange: (startRange: any) => void;
  handleEndRange: (endRange: any) => void;
  handleCourseId: (courseId: any) => void;
  handleStudentId: (studentId: any) => void;
  handleMentorId: (mentorId: any) => void;
  handleSearch: (searchValue: string) => void;
  handleActiveFlags: (active: string) => void;
  handleUserStatus: (userStatus: string) => void;
  selectedTab: string;
  handleSelectedTab: (tab: string) => void;
  startRange: any;
  endRange: any;
};

const tabs = [
  { name: 'Flags', current: false, key: 'Flags' },
  { name: 'Overview', current: false, key: 'Overview' },
];

const FlagsTableHeader = ({
  search,
  handleFlagType,
  handleStartRange,
  handleEndRange,
  handleCourseId,
  handleStudentId,
  handleMentorId,
  handleSearch,
  handleActiveFlags,
  handleUserStatus,
  selectedTab,
  handleSelectedTab,
  startRange,
  endRange,
}: Props) => {
  const { data: allCourses } = useGetAllCourses();

  const [mentorFullName, setMentorFullName] = useState('');
  const [studentFullName, setStudentFullName] = useState('');
  const [checkedState, setCheckedState] = useState('indeterminate');

  const { data: allMentors } = useSearchUsersByFullName({
    fullName: mentorFullName,
    role: MENTOR_ROLE,
  });

  const { data: allStudents } = useSearchUsersByFullName({
    fullName: studentFullName,
    role: STUDENT_ROLE,
  });

  const handleCheckboxChange = () => {
    let nextState: 'checked' | 'unchecked' | 'indeterminate';

    switch (checkedState) {
      case 'checked':
        nextState = 'unchecked';
        break;
      case 'unchecked':
        nextState = 'indeterminate';
        break;
      case 'indeterminate':
        nextState = 'checked';
        break;
      default:
        nextState = 'indeterminate';
        break;
    }

    handleUserStatus(nextState);
    setCheckedState(nextState);
  };

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ');
  };

  return (
    <div
      style={{
        backgroundColor: COLORS.DARK_GREY_ALT,
        padding: '24px 32px 0px 32px',
      }}
    >
      <div
        style={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/*HEADER TITLE AND BUTTON*/}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '24px',
          }}
        >
          <h1 style={{ color: COLORS.TEXT_PRIMARY }} className="header-title">
            Flags Dashboard
          </h1>

          <select
            onChange={(e) => {
              const selectedValue = e.target.value;
              handleActiveFlags(selectedValue);
            }}
            className="flags-table-header-custom-select"
            style={{
              borderColor: COLORS.TEXT_PRIMARY,
              color: COLORS.PRIMARY,
              WebkitAppearance: 'none',
              MozAppearance: 'none',
              appearance: 'none',
              paddingRight: '0px',
              paddingLeft: '0px',
              backgroundImage: `url(${DownIcon})`,
              backgroundSize: '11px auto',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right center',
              marginRight: '20px',
              cursor: 'pointer',
              boxShadow: 'none',
            }}
            defaultValue={'active'}
          >
            <option value={'0'}>Show active flags</option>
            <option value={'1'}>Show closed flags</option>
          </select>
        </div>

        <div style={{ maxWidth: '1920px' }}>
          {/*SEARCHBAR*/}
          <AgoraSearchBar
            search={search}
            handleSearch={handleSearch}
            placeholder={'Search by student, mentor, enrolment or keywords...'}
          />

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {/*FLAG TYPE*/}
              <AgoraSelector
                label="Flag Type"
                onSelect={handleFlagType}
                options={FLAG_TYPE}
                labelKey="title"
                showOptionsBy="index"
              />

              {/*COURSE FLAGGED*/}
              <AgoraSelector
                label="Course Flagged"
                onSelect={handleCourseId}
                options={allCourses}
                labelKey="title"
                valueKey="_id"
              />

              {/*STUDENT FLAGGED*/}
              <AgoraSelector
                label="Student Flagged"
                onSelect={handleStudentId}
                onSearch={(value) => setStudentFullName(value)}
                options={allStudents}
                labelKey="fullName"
                valueKey="_id"
              />

              {/*MENTOR FLAGGED*/}
              <AgoraSelector
                label="Mentor Flagged"
                onSelect={handleMentorId}
                onSearch={(value) => setMentorFullName(value)}
                options={allMentors}
                labelKey="fullName"
                valueKey="_id"
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: '24px',
                  marginTop: '12px',
                }}
              >
                <span
                  style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '8px' }}
                  className="filter-label"
                >
                  Date Created
                </span>
                <div
                  style={{ width: 'fit-content' }}
                  className="custom-date-picker"
                >
                  <DatePicker.RangePicker
                    allowEmpty={[true, true]}
                    value={[moment(startRange), moment(endRange)]}
                    onChange={(data) => {
                      const dates = data ? data : [null, null];

                      handleStartRange(
                        dates[0] ? dates[0].toISOString() : undefined
                      );
                      handleEndRange(
                        dates[1] ? dates[1].toISOString() : undefined
                      );
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: '24px',
                  marginTop: '12px',
                  justifyContent: 'space-between',
                }}
              >
                <span
                  style={{ color: COLORS.TEXT_PRIMARY }}
                  className="filter-label"
                >
                  Test Users
                </span>
                <div>
                  <Checkbox
                    indeterminate={checkedState === 'indeterminate'}
                    checked={checkedState === 'checked'}
                    onChange={handleCheckboxChange}
                  />
                  <span
                    style={{ marginLeft: '8px', color: COLORS.TEXT_SECONDARY }}
                  >
                    {checkedState === 'indeterminate'
                      ? 'Show all users'
                      : checkedState === 'checked'
                      ? 'Show test users'
                      : 'Show users'}
                  </span>
                </div>
              </div>
            </div>

            {/*TAILWIND TABS*/}
            <div style={{ marginTop: '12px' }}>
              <div className="hidden sm:block">
                <div>
                  <nav className="-mb-px flex" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <span
                        style={{
                          cursor: 'pointer',
                          color:
                            selectedTab === tab?.key
                              ? COLORS.PRIMARY
                              : COLORS.TEXT_SECONDARY,
                          borderColor:
                            selectedTab === tab?.key
                              ? COLORS.PRIMARY
                              : COLORS.DARK_GREY_ALT,
                          maxWidth: '100px',
                        }}
                        key={tab?.key}
                        onClick={() => handleSelectedTab(tab?.key)}
                        className={classNames(
                          tab?.key === selectedTab
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'
                        )}
                        aria-current={tab?.current ? 'page' : undefined}
                      >
                        {tab?.name}
                      </span>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlagsTableHeader;
