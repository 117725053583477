import EditIcon from '@assets/icons/edit-icon.svg';
import ExternalLinkIconPrimary from '@assets/icons/external-link-primary.svg';
import ExternalLinkIcon from '@assets/icons/external-link.svg';
import ProfileIconPrimary from '@assets/icons/profile-icon-primary.png';
import ProfileIcon from '@assets/icons/profile-icon-white.png';
import EnrollmentsModalMentors from '@components/V3/EnrollmentsModalMentors';
import ProfileModalMentors from '@components/V3/ProfileModalMentors';
import { MENTOR_STATUS_V3 } from '@shared/constants';
import { useUpdateMentorCapacity, useUpdateMentorData } from '@shared/react';
import { MentorWithRate } from '@shared/types';
import { Tooltip } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './mentors-table-row.styles.scss';

type Props = {
  key?: string | number;
  mentorData: MentorWithRate;
  refetchSearchMentors: () => void;
  refetchMentorStatistics: () => void;
};

const MentorsTableRow = ({
  key,
  mentorData,
  refetchSearchMentors,
  refetchMentorStatistics,
}: Props) => {
  const { mutate: updateMentorCapacity } = useUpdateMentorCapacity(
    mentorData.mentor._id,
    {
      onSuccess: () => {
        refetchMentorStatistics();
      },
    }
  );

  const [showModal, setShowModal] = useState(false);
  const [maxCap, setMaxCap] = useState(
    Number(mentorData.mentor.mentorData.mentorCapacity?.capacity)
  );

  const universities = mentorData?.mentor?.mentorData?.universities ?? [];

  const [showProfileModal, setShowProfileModal] = useState(false);

  const { mutate: updateMentorData } = useUpdateMentorData(
    mentorData.mentor._id,
    {
      onSuccess: () => {
        refetchSearchMentors();
      },
    }
  );

  const courses = mentorData.mentor.mentorData.coursesCanTeach ?? [];

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleShowProfileModal = () => {
    setShowProfileModal(!showProfileModal);
  };

  return (
    <tr>
      {/*MENTOR NAME*/}
      <td className="mentors-table-row-text">
        <span>{mentorData.mentor.fullName ?? '-'}</span>
      </td>

      {/*STATUS*/}
      <td style={{}} className="mentors-table-row-text">
        <select
          onChange={(e) => {
            const selectedValue = e.target.value;
            updateMentorData({
              status: selectedValue,
            });
          }}
          className="mentors-row-status-component"
          style={{
            borderColor: COLORS.TEXT_PRIMARY,
            color: COLORS.TEXT_PRIMARY,
            backgroundColor: COLORS.SECTION_BACKGROUND,
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            appearance: 'none',
            paddingRight: '15px',
            paddingLeft: '0px',
            backgroundImage: `url(${EditIcon})`,
            backgroundSize: '8px 8px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right center',
            cursor: 'pointer',
          }}
          defaultValue={mentorData.mentor.mentorData.status ?? 'N/A'}
        >
          {MENTOR_STATUS_V3.map((status) => (
            <option value={status}>{status}</option>
          ))}
        </select>
      </td>

      {/*CAPACITY*/}
      <td className="mentors-table-row-text">
        <span>{mentorData?.currentCapacity ?? '-'}</span>
      </td>

      {/*MAX CAPACITY*/}
      <td style={{}} className="mentors-table-row-text">
        <span>
          <input
            className="block w-full rounded-md border-0 py-1.5 px-0 text-white shadow-sm ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:px-1.5 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
            style={{
              backgroundColor: 'transparent',
              width: '64px',
              height: '26px',
              marginBottom: '0px',
              fontSize: '12px',
              lineHeight: '16px',
            }}
            type="number"
            value={maxCap}
            min={0}
            onChange={(e) => setMaxCap(Number(e.target.value))}
            onBlur={() => {
              updateMentorCapacity({
                capacity: maxCap,
              });
            }}
          />
        </span>
      </td>

      {/*MAX CAPACITY UPDATED AT*/}
      <td style={{}} className="mentors-table-row-text">
        <span>
          <input
            className="block w-full rounded-md border-0 py-1.5 px-0 text-white shadow-sm ring-0 ring-inset ring-gray-300 placeholder:text-gray-400 focus:px-1.5 focus:ring-1 focus:ring-inset focus:ring-white hover:ring-1 hover:ring-inset hover:ring-white sm:text-sm sm:leading-6"
            style={{
              backgroundColor: 'transparent',
              width: '88px',
              height: '26px',
              marginBottom: '0px',
              fontSize: '12px',
              lineHeight: '16px',
            }}
            type="text"
            value={
              mentorData.mentor.mentorData.mentorCapacity?.updatedAt
                ? moment(
                    mentorData.mentor.mentorData.mentorCapacity?.updatedAt
                  ).format('DD.MM.YYYY')
                : '-'
            }
          />
        </span>
      </td>

      {/*PAY RATE*/}
      <td className="mentors-table-row-text">
        <span>
          {mentorData?.rate?.amount !== undefined &&
          mentorData?.rate?.currency !== undefined
            ? `${mentorData?.rate?.amount} ${mentorData?.rate?.currency}/h`
            : '-'}
        </span>
      </td>

      {/*UNIVERSITIES*/}
      <td style={{}} className="mentors-table-row-text">
        {/*TOOLTIP*/}
        {universities.length > 1 ? (
          <Tooltip
            zIndex={1}
            color="#333333"
            title={
              <div>
                {universities.length > 1 &&
                  universities?.map((university: string) => (
                    <>
                      <span>{university};</span>
                      <br />
                    </>
                  ))}
              </div>
            }
          >
            <span>
              {Array.isArray(universities) && universities.length > 1
                ? universities[0] + '...'
                : Array.isArray(universities) && universities.length > 0
                ? universities[0] ?? '-'
                : '-'}
            </span>
          </Tooltip>
        ) : (
          <span>
            {Array.isArray(universities) && universities.length > 1
              ? universities[0] + '...'
              : Array.isArray(universities) && universities.length > 0
              ? universities[0] ?? '-'
              : '-'}
          </span>
        )}
      </td>

      {/*COURSES*/}
      <td className="mentors-table-row-text">
        {/*TOOLTIP*/}
        {courses.length > 1 ? (
          <Tooltip
            zIndex={1}
            color="#333333"
            title={
              <div>
                {courses.length > 1 &&
                  courses?.map((course: any) => (
                    <>
                      <span>{course?.title};</span>
                      <br />
                    </>
                  ))}
              </div>
            }
          >
            <span>
              {Array.isArray(courses) && courses.length > 1
                ? (courses[0] as { title?: string })?.title + '...'
                : Array.isArray(courses) && courses.length > 0
                ? (courses[0] as { title?: string })?.title ?? '-'
                : '-'}
            </span>
          </Tooltip>
        ) : (
          <span>
            {Array.isArray(courses) && courses.length > 1
              ? (courses[0] as { title?: string })?.title + '...'
              : Array.isArray(courses) && courses.length > 0
              ? (courses[0] as { title?: string })?.title ?? '-'
              : '-'}
          </span>
        )}
      </td>

      {/*STUDY SUBJECTS*/}
      <td className="mentors-table-row-text">
        <span>
          {mentorData?.mentor?.studySubjects?.map((studySubject) => (
            <span>{studySubject}</span>
          ))}
        </span>
      </td>

      {/*CREATED AT*/}
      <td className="mentors-table-row-text">
        <span>
          {moment(mentorData?.mentor?.createdAt).format('DD.MM.YYYY')}
        </span>
      </td>

      {/*ACCESS*/}
      <td>
        <div style={{ display: 'flex' }}>
          <div
            onClick={() => setShowModal(!showModal)}
            style={{ display: 'flex', marginRight: '12px' }}
            className="enrollments-action"
          >
            <img
              style={{ marginRight: '8px' }}
              className="external-link-icon"
              src={ExternalLinkIcon}
              alt="enrollments"
            />
            <img
              style={{ marginRight: '8px' }}
              className="external-link-icon-primary"
              src={ExternalLinkIconPrimary}
              alt="enrollments"
            />
            <span className="mentors-table-access-button">Enrollments</span>
          </div>
          <div style={{ width: '25%', display: 'flex' }}>
            <div
              onClick={() => setShowProfileModal(!showProfileModal)}
              style={{
                display: 'flex',
                marginRight: '12px',
                cursor: 'pointer',
              }}
              className="enrollments-action"
            >
              <img
                style={{
                  marginRight: '8px',
                  minWidth: '14px',
                  maxWidth: '14px',
                }}
                className="external-link-icon"
                src={ProfileIcon}
                alt="enrollments"
              />
              <img
                style={{
                  marginRight: '8px',
                  minWidth: '14px',
                  maxWidth: '14px',
                }}
                className="external-link-icon-primary"
                src={ProfileIconPrimary}
                alt="enrollments"
              />
              <span className="mentors-table-access-button">Profile</span>
            </div>
          </div>

          {/*ENROLLMENTS MODAL*/}
          {mentorData && showModal && (
            <EnrollmentsModalMentors
              showModal={showModal}
              handleShowModal={handleShowModal}
              mentorData={mentorData}
            />
          )}

          {mentorData && showProfileModal && (
            <ProfileModalMentors
              showModal={showProfileModal}
              handleShowModal={handleShowProfileModal}
              mentorData={mentorData}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

export default MentorsTableRow;
