import { MentoringStyleType, SelectOption } from '@shared/common';
import { useMentors } from '@shared/react';
import { getRoundedNumber } from '@shared/utils/number';
import { Select } from 'antd';
import { useEffect, useState } from 'react';

type Choice = {
  label: string;
  value: 'USER' | 'TBD' | 'ALGO' | 'TOP';
};

export type MentorSelectorValue = {
  choice?: Choice;
  user?: SelectOption;
  interests?: string[];
  mentoringStyle?: MentoringStyleType;
  coursesCanTeach?: string;
};

type Props = {
  value?: MentorSelectorValue;
  disabled?: boolean;
  onChange?: (val: MentorSelectorValue) => void;
  courseId?: string;
};

const NON_USER_CHOICES = ['TBD', 'ALGO', 'TOP'];

const MentorSelector = ({
  value,
  onChange,
  courseId,
  disabled = false,
}: Props) => {
  const [internalValue, setInternalValue] = useState<MentorSelectorValue>(
    value || {}
  );

  useEffect(() => {
    if (value) setInternalValue(value);
  }, [value]);

  const setValues = (val: MentorSelectorValue) => {
    if (onChange) onChange(val);
    if (!value) setInternalValue(val);
  };

  const { data: mentorsData, isLoading: mentorsLoading } = useMentors({
    isDezactivated: 'unchecked',
  });
  const options = (mentorsData?.users || []).map((mentor) => {
    const { mentorData } = mentor;
    const score = mentorData.averageFeedback
      ? getRoundedNumber(mentorData.averageFeedback)
      : '';
    return {
      value: mentor._id,
      label: `${mentor.fullName} ${score}`,
    };
  });

  const choice = internalValue?.choice?.value || '';

  options.unshift({
    value: 'TBD',
    label: 'TBD',
  });

  return (
    <Select
      placeholder="Select a Mentor"
      labelInValue
      showSearch
      disabled={disabled}
      optionFilterProp="label"
      options={options}
      loading={mentorsLoading}
      style={{ width: '100%' }}
      value={choice === 'USER' ? internalValue.user : internalValue.choice}
      onChange={(val) => {
        const newVal = { ...internalValue };
        if (NON_USER_CHOICES.includes(val.value)) {
          newVal.choice = val as Choice;
          newVal.user = undefined;
        } else {
          newVal.user = val;
          newVal.choice = { value: 'USER' } as Choice;
        }

        setValues(newVal);
      }}
    />
  );
};

export default MentorSelector;
