import { reportApis } from '@shared/apis';
import { message } from 'antd';
import {
  genericMutation,
  genericMutationWithId,
  genericQuery,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useGetAllReports = genericQuery<reportApis.GetAllReports>(
  reportApis.getAllReports
);

export const useGetLatestMentorReport =
  genericQuery<reportApis.GetLatestMentorReportStatus>(
    reportApis.getLatestMentorReportStatus,
    {
      onError: (err: any) => {
        if (err.response.status !== 404) {
          message.error(
            err.response?.data?.error?.message ||
              err.response?.error?.message ||
              err.error?.message ||
              err?.message ||
              'An error occurred'
          );
        }
      },
    }
  );

export const useGenerateMonthlyReports =
  genericMutation<reportApis.CreateMonthlyReports>(
    reportApis.createMonthlyReports
  );

export const useGetNoOfSessionsByMonth =
  genericQueryWithParams<reportApis.GetNoOfSessionsByMonth>(
    reportApis.getNoOfSessionsByMonth
  );

export const useGetNoOfActiveMentorsByMonth =
  genericQueryWithParams<reportApis.GetNoOfActiveMentorsByMonth>(
    reportApis.getNoOfActiveMentorsByMonth
  );

export const useGetTotalPaymentByMonth =
  genericQueryWithParams<reportApis.GetTotalPaymentByMonth>(
    reportApis.getTotalPaymentByMonth
  );

export const useGetMultipleReports =
  genericQueryWithParams<reportApis.GetMultipleReports>(
    reportApis.getMultipleReports
  );

export const useSearchReports =
  genericQueryWithParams<reportApis.SearchReports>(reportApis.searchReports);

export const useGetReport = genericQueryWithId<reportApis.GetReport>(
  reportApis.getReport
);
export const useCreateReport = genericMutationWithId<reportApis.CreateReport>(
  reportApis.createReport
);

export const useDeleteReport = genericMutationWithId<reportApis.DeleteReport>(
  reportApis.deleteReport
);

export const useGetMentorReportActivityHistory =
  genericQueryWithParams<reportApis.GetMentorReportActivityHistory>(
    reportApis.getMentorReportActivityHistory
  );

export const useUploadInvoiceByMentor =
  genericMutationWithId<reportApis.UploadInvoiceByMentor>(
    reportApis.uploadInvoiceByMentor
  );

export const useMarkBulkReportsReadyForPayment =
  genericMutation<reportApis.MarkBulkReportsReadyForPayment>(
    reportApis.markBulkReportsReadyForPayment
  );

export const useMarkBulkReportsAsPaid =
  genericMutation<reportApis.MarkBulkReportsAsPaid>(
    reportApis.markBulkReportsAsPaid
  );

export const useRegenerateReport =
  genericMutationWithId<reportApis.RegenerateReport>(
    reportApis.regenerateReport
  );

export const uploadInvoiceByOperations =
  genericMutationWithId<reportApis.UploadInvoiceByOperations>(
    reportApis.uploadInvoiceByOperations
  );

export const useMarkReportReadyForPayment =
  genericMutationWithId<reportApis.MarkReportReadyForPayment>(
    reportApis.markReportReadyForPayment
  );

export const useMarkReportAsPaid =
  genericMutationWithId<reportApis.MarkReportAsPaid>(
    reportApis.markReportAsPaid
  );

export const useDownloadInvoice =
  genericQueryWithId<reportApis.DownloadInvoice>(reportApis.downloadInvoice);

export const useGenerateCSVReport =
  genericMutation<reportApis.GenerateCSVReport>(reportApis.generateCSVReport);

export const useGetExchangeRateByMonth =
  genericQueryWithParams<reportApis.GetRatesByMonth>(
    reportApis.getRatesByMonth
  );

export const useCheckMentorLastMonthMeetingAndExtraServices =
  genericQueryWithParams<reportApis.CheckMentorLastMonthMeetingAndExtraServices>(
    reportApis.checkMentorLastMonthMeetingAndExtraServices
  );
