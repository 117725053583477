import { RenderRoutes, PageDisplay } from '@components';
import Admin from '@modules/Admin';
import Licenses from '@modules/Licenses';
import NotificationsPage from '@modules/Notifications';
import Sales from '@modules/Sales';
import AccountPage from '../Account';
import LessonDisplay from './LessonDisplay';

const Base = () => {
  return (
    <RenderRoutes
      routes={[
        { path: '/account', component: AccountPage },
        { path: '/lesson/:id', component: PageDisplay },
        { path: '/lesson', component: LessonDisplay },
        { path: '/admin', component: Admin },
        { path: '/sales', component: Sales },
        { path: '/licenses', component: Licenses },
        { path: '/notifications', component: NotificationsPage },
      ]}
      redirectPath={'/admin'}
    />
  );
};

export default Base;
