import React, { ReactNode } from 'react';
import './table-columns.styles.scss';

type Props = {
  children: ReactNode;
};

const TableColumns = ({ children }: Props) => {
  return (
    <div style={{ width: '100%' }} className="column-text">
      {children}
    </div>
  );
};

export default TableColumns;
