import React from 'react';
import 'tailwindcss/tailwind.css';
import './index.styles.scss';
import StudentPlaceholderAvatar from '@assets/icons/student-placeholder-avatar.png';

type Props = {
  fullName?: string;
  enrollments?: any[];
};

const StudentCard = ({ fullName, enrollments }: Props) => {
  return (
    <div style={{ minWidth: '60%' }}>
      <div className="student-container card-content-container">
        <div className="avatar-container">
          <img
            // style={{ width: '167px' }}
            className="student-image inline-block rounded-full avatar"
            src={StudentPlaceholderAvatar}
            alt="avatar"
          />
        </div>
        <div className="student-details-container">
          <h1 className="title">{fullName}</h1>
          <p className="details">
            Programs:{' '}
            {enrollments
              ?.filter((enrollment) => enrollment?.mentor !== null)
              .map((enrollment) => (
                <span key={enrollment._id}>{enrollment?.course?.title}; </span>
              ))}
          </p>
          <p className="details">
            Mentors:{' '}
            <span>
              {[
                ...new Set(
                  enrollments?.map((enrollment) => enrollment?.mentor?.fullName)
                ),
              ]
                .filter(Boolean) // Remove any falsy values (null, undefined, empty strings)
                .join('; ')}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default StudentCard;
