import {
  ApiDescription,
  ApiData,
  RequestsMethod,
  NewUserType,
  User,
  CreateNewUserResponse,
  createStudentFields,
  createMentorFields,
  createAdminFields,
  PermissionsType,
  Referral as ReferralType,
} from '../types';

export const login: ApiDescription = {
  endpoint: '/auth/login',
  method: RequestsMethod.Post,
};
export type Login = ApiData<
  { username: string; password: string },
  { token: string }
>;

export const resetPassword: ApiDescription = {
  endpoint: '/auth/change-password/send',
  method: RequestsMethod.Post,
};
export type ResetPassword = ApiData<{ email: string }, { message: string }>;

export const resetPasswordByAdmin: ApiDescription = {
  endpoint: '/auth/change-password-by-admin/send',
  method: RequestsMethod.Post,
};
export type ResetPasswordByAdmin = ApiData<{ email: string }, unknown>;

export const createUserDriveByAdmin: ApiDescription = {
  endpoint: '/auth/create-user-drive-by-admin/send',
  method: RequestsMethod.Post,
};
export type CreateUserDriveByAdmin = ApiData<
  { email: string; role: string },
  unknown
>;

export const getExternalUserData: ApiDescription = {
  endpoint: '/auth/get-external-user-data',
  method: RequestsMethod.Get,
};
export type GetExternalUserData = ApiData<{ email: string }, any>;

export const createNewUser: ApiDescription = {
  endpoint: '/auth/create-new-user',
  method: RequestsMethod.Post,
};
export type CreateNewUser = ApiData<NewUserType, CreateNewUserResponse>;

export const createNewMentor: ApiDescription = {
  endpoint: '/auth/create-new-mentor',
  method: RequestsMethod.Post,
};
export type CreateNewMentor = ApiData<
  Partial<NewUserType>,
  CreateNewUserResponse
>;

export const publicSignUp: ApiDescription = {
  endpoint: '/auth/public-signup',
  method: RequestsMethod.Post,
};
export type PublicSignUp = ApiData<Partial<User>, CreateNewUserResponse>;

export const uploadProfilePicture: ApiDescription = {
  endpoint: '/profile-picture/upload',
  method: RequestsMethod.Post,
};
export type UploadProfilePicture = ApiData<FormData, unknown>;

export const createUserV2: ApiDescription = {
  endpoint: '/auth/create-user',
  method: RequestsMethod.Post,
};

export type CreateUserV2 = ApiData<
  {
    email: string;
    userType: string;
    permissions?: PermissionsType;
    studentData?: createStudentFields;
    mentorData?: createMentorFields;
    adminData?: createAdminFields;
  },
  CreateNewUserResponse
>;

export const referral: ApiDescription = {
  endpoint: '/auth/referral',
  method: RequestsMethod.Post,
};

export type Referral = ApiData<
  {
    fields: ReferralType;
    harversterToken?: string;
  },
  unknown
>;
