import AgoraBigSelector from '@components/V3/Utils/InputsV3/AgoraBigSelector';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdateSession } from '@shared/react';
import { GetSessionDTO } from '@shared/types/DTOs/EnrollmentsDTO';
import useToast from 'apps/agora/src/hooks/useToast';
import React, { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import SessionModalFormWrapper from '../../SessionModalFormWrapper';

interface SessionDetailsFormProps {
  defaultData: GetSessionDTO;
  sessionId: string;
  onCloseEditMode: () => void;
}

const formDataSchema = z.object({
  status: z.number(),
});

type FormData = z.infer<typeof formDataSchema>;

const SessionDetailsForm = (props: SessionDetailsFormProps) => {
  const { defaultData, sessionId, onCloseEditMode } = props;

  const [showToast] = useToast();
  const query = useQueryClient();

  const statusTypes = [
    {
      label: 'Pending',
      value: 0,
    },
    {
      label: 'Ongoing',
      value: 1,
    },
    {
      label: 'Needs feedback',
      value: 2,
    },
    {
      label: 'Completed',
      value: 3,
    },
    {
      label: 'Pending review',
      value: 4,
    },
    {
      label: 'Paid',
      value: 5,
    },
    {
      label: 'Unpayable',
      value: 6,
    },
  ];

  const { handleSubmit, trigger, setValue, control, getValues } =
    useForm<FormData>({
      resolver: zodResolver(formDataSchema),
      reValidateMode: 'onBlur',
      mode: 'onBlur',
      defaultValues: {
        status: defaultData?.status,
      },
    });

  const { field: statusField, fieldState: statusFieldState } = useController({
    name: 'status',
    control,
  });

  const { mutate: updateSession, isLoading } = useUpdateSession(sessionId, {
    onSuccess: async () => {
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated the enrollment.',
      });
      query.invalidateQueries('/enrollments/:id');
      onCloseEditMode();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Enrollment could not be updated.',
      });
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateSession({
        status: data.status,
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    const formData = getValues();

    touchAllFields(formData);
  }, []);

  return (
    <SessionModalFormWrapper
      title={'Details'}
      isLoading={isLoading}
      onCancel={onCloseEditMode}
      submitHandler={submitHandler}
    >
      <AgoraBigSelector
        value={statusField.value}
        onSelect={(value) => statusField.onChange(value)}
        options={statusTypes}
        onBlur={statusField.onBlur}
        labelKey="label"
        valueKey="value"
        label="Status"
        isValid={!statusFieldState.error}
        isTouched={statusFieldState.isTouched}
        errorText={statusFieldState.error?.message}
        isRequired
      />
    </SessionModalFormWrapper>
  );
};

export default SessionDetailsForm;
