import { BasicProps } from 'apps/agora/src/utils/types';

interface InfoItemProps extends BasicProps {
  label: string;
  value?: string | number;
}

const InfoItem = (props: InfoItemProps) => {
  const { label, value } = props;

  return (
    <div className="flex items-center gap-4">
      <p className="text-xs">{label}:</p>
      <p className="text-nowrap">{value ?? 'n/a'}</p>
    </div>
  );
};

export default InfoItem;
