import Select from '@components/V4/Select/Select';
import { SESSION_STATUSES } from '@shared/constants';
import { useAuthState } from '@shared/react';
import { statusTypes } from '../constants';
import { SessionHistoryFiltersChildProps } from './SessionHistoryFilters';

const SessionHistoryFiltersMobile = (
  props: SessionHistoryFiltersChildProps
) => {
  const {
    courseFilterOptions,
    sortOptions,
    selectedLabel,
    handleSortChange,
    searchParams,
    courseChangeHandler,
    statusHandler,
    studentHandler,
    isFilterDropdownActive,
    studentsFilterOptions,
    getSelectedStudent,
  } = props;

  const { isMentor } = useAuthState();
  return isFilterDropdownActive ? (
    <section className="flex flex-col gap-4 tablet:flex-row tablet:w-2/3 tablet:hidden">
      <div className="flex gap-4 ">
        <Select
          variant="secondary"
          label="Sort By"
          options={sortOptions.map(({ label, value }) => ({
            label,
            value,
          }))}
          value={selectedLabel}
          onSelect={handleSortChange}
          allowClear={false}
          selectClassName="bg-surfaceBackground"
        />

        <Select
          variant="secondary"
          label="Course"
          options={courseFilterOptions.map(({ _id, title }) => ({
            label: title,
            value: _id,
          }))}
          value={
            searchParams.course ?? {
              value: courseFilterOptions[0]._id ?? '',
              label: courseFilterOptions[0].title ?? 'All Courses',
            }
          }
          onSelect={(value) => courseChangeHandler(value as string)}
        />
      </div>
      {isMentor && (
        <div className="flex gap-4 ">
          <Select
            variant="secondary"
            label="Status"
            options={statusTypes}
            value={SESSION_STATUSES[searchParams.status] ?? statusTypes[0]}
            onSelect={(value) => statusHandler(value as number)}
          />
          <Select
            variant="secondary"
            className="tablet:max-w-64 tablet:min-w-52"
            label="Students"
            options={studentsFilterOptions.map(({ _id, fullName }) => ({
              value: _id,
              label: fullName,
            }))}
            value={
              getSelectedStudent(searchParams.student)?.fullName ??
              'All Students'
            }
            onSelect={(value) => studentHandler(value as string)}
          />
        </div>
      )}
    </section>
  ) : null;
};

export default SessionHistoryFiltersMobile;
