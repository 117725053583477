import React, { useState } from 'react';

type Props = {
  text?: string;
};

const ViewMoreLess = ({ text }: Props) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const truncatedText = text?.slice(0, 102);
  const displayText = expanded ? text : truncatedText;

  return (
    <span>
      {displayText}
      {text && text?.length > 102 && (
        <span
          style={{ color: '#33A9AB', cursor: 'pointer' }}
          onClick={handleToggle}
        >
          {expanded ? ' View Less' : '... View More'}
        </span>
      )}
    </span>
  );
};

export default ViewMoreLess;
