import { ReactComponent as BlockIcon } from '@assets/icons/V4/block-icon.svg';
import { ReactComponent as DefaultAvatarIcon } from '@assets/icons/V4/default-profile-pic.svg';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import Button from '@components/V4/Button';
import Pagination, { PaginationProps } from '@components/V4/Pagination';
import { EnrollmentWithStudentTypes } from '@shared/common';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import { Link } from 'react-router-dom';
import { STUDENT_STATUSES, studentStatusColors, studentStatuses } from './misc';
import { Popconfirm } from 'antd';
import { useState } from 'react';

interface MyStudentsMobileCardProps {
  enrollments?: EnrollmentWithStudentTypes[];
  pagination: PaginationProps;
  isLoading: boolean;
  onEndEnrollment: (enrollment: EnrollmentWithStudentTypes) => void;
  onShowModal: (defaultEnrollment: EnrollmentWithStudentTypes) => void;
}

interface MyStudentsMobileItemProps {
  enrollment: EnrollmentWithStudentTypes;
  onEndEnrollment: (enrollment: EnrollmentWithStudentTypes) => void;
  onShowModal: (defaultEnrollment: EnrollmentWithStudentTypes) => void;
}

const MyStudentsMobileItem = (props: MyStudentsMobileItemProps) => {
  const { enrollment, onEndEnrollment, onShowModal } = props;

  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="flex flex-col gap-4 p-4 bg-surfaceObject rounded-2xl ">
      <div className="flex items-center gap-2">
        {enrollment.user.avatar ? (
          <img
            src={enrollment.user.avatar}
            crossOrigin="anonymous"
            alt=""
            className="w-8 h-8 rounded-full"
          />
        ) : (
          <DefaultAvatarIcon className="w-8 h-8" />
        )}
        <div className="flex flex-wrap gap-2">
          <div className="flex gap-2 text-xs">
            <Link
              className="text-white underline hover:text-white"
              to={`/student-profile/${enrollment.user._id}`}
            >
              {enrollment.user.fullName},
            </Link>
            <p className="text-customGrey">{enrollment.course.title}</p>
          </div>
          <div className="flex gap-2 text-xs">
            <p className="text-customGrey">
              {enrollment.noOfCompletedSessions} / {enrollment.noOfSessions}{' '}
              Sessions
            </p>
            <p className="text-customGrey">•</p>
            <p className="text-customGrey">
              {enrollment?.user?.graduationYear}
            </p>
            <p
              className={mergeClassNames(
                'capitalize',
                enrollment.status ? studentStatusColors[enrollment.status] : ''
              )}
            >
              •
            </p>
            <p
              className={mergeClassNames(
                'capitalize',
                enrollment.status ? studentStatusColors[enrollment.status] : ''
              )}
            >
              {enrollment.status ? STUDENT_STATUSES[enrollment.status] : 'n/a'}
            </p>
          </div>
        </div>
      </div>
      {!enrollment.ended && (
        <div className="flex gap-4">
          <Button
            buttonText="Add Meeting"
            onClick={() => onShowModal(enrollment)}
          />
          <Popconfirm
            title="Are you sure you want to end this enrollment?"
            onConfirm={() => onEndEnrollment(enrollment)}
            okText="Yes"
            visible={isVisible}
            cancelText="No"
            onCancel={() => setIsVisible(false)}
          >
            <Button
              buttonText="End"
              onClick={() => onEndEnrollment(enrollment)}
              variant="secondary-red"
            />
          </Popconfirm>
        </div>
      )}
    </div>
  );
};

const MyStudentsMobileCard = (props: MyStudentsMobileCardProps) => {
  const { enrollments, isLoading, pagination, onEndEnrollment, onShowModal } =
    props;
  return (
    <section className="flex flex-col gap-6 tablet:hidden">
      {enrollments?.map((enrollment) => (
        <MyStudentsMobileItem
          key={enrollment._id}
          enrollment={enrollment}
          onEndEnrollment={onEndEnrollment}
          onShowModal={onShowModal}
        />
      ))}

      {isLoading && (
        <section className="w-full flex justify-center items-center h-64">
          <LoadingSpinner className="w-5 h-5" />
        </section>
      )}

      {!isLoading && !enrollments?.length && (
        <EmptyStateCard
          className="h-64"
          icon={<BlockIcon className="text-customGrey" />}
          text={'No results.'}
        />
      )}

      {!!pagination && <Pagination size={'default'} {...pagination} />}
    </section>
  );
};

export default MyStudentsMobileCard;
