import { RenderRoutes } from '@components';
import PreOnboardingPage from '../Account/pre-onboarding';
import ParentView from './ParentView';

const Auth = () => {
  return (
    <RenderRoutes
      routes={[
        { path: '/welcome', component: PreOnboardingPage },
        {
          path: '/parent-view/:studentId',
          component: ParentView,
        },
      ]}
      redirectPath="/"
    />
  );
};

export default Auth;
