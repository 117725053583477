import Tooltip from '@components/V4/Tooltip';
import { ReactComponent as AvatarIcon } from '@assets/icons/V4/basic-avatar-skeleton.svg';
import { useAuthState } from '@shared/react';
import { SessionDetailsUser } from './SessionDetailsHeader';

interface DetailsHeaderParticipantsListProps {
  mentor: SessionDetailsUser;
  student: SessionDetailsUser;
  accentColor: string;
}

const DetailsHeaderParticipantsList = (
  props: DetailsHeaderParticipantsListProps
) => {
  const { mentor, student, accentColor } = props;

  const { userId } = useAuthState();

  return (
    <div className="flex gap-2">
      <Tooltip
        placement="top"
        text={
          <div className="flex flex-col">
            <p className="text-sm leading-5">
              {mentor?.fullName} {userId === mentor?._id && '(You)'}
            </p>
            <p
              className="text-xs font-semibold leading-5"
              style={{ color: accentColor }}
            >
              Mentor
            </p>
          </div>
        }
      >
        {!!mentor.avatar ? (
          <img
            src={mentor.avatar}
            alt={`Image of the mentor ${mentor.fullName}`}
            crossOrigin="anonymous"
            className="rounded-full border-2 border-solid w-8 h-8"
            style={{ borderColor: accentColor }}
          />
        ) : (
          <AvatarIcon className="text-customGrey rounded-full w-8 h-8" />
        )}
      </Tooltip>

      <Tooltip
        placement="top"
        text={
          <div className="flex flex-col">
            <p className="text-sm leading-5">
              {student?.fullName} {userId === student?._id && '(You)'}
            </p>
            <p
              className="text-xs font-semibold leading-5"
              style={{ color: accentColor }}
            >
              Student
            </p>
          </div>
        }
      >
        {!!student.avatar ? (
          <img
            src={student.avatar}
            alt={`Image of the student ${student.fullName}`}
            crossOrigin="anonymous"
            className="rounded-full border-2 border-solid border-customGrey w-8 h-8"
          />
        ) : (
          <AvatarIcon className="text-customGrey rounded-full w-8 h-8" />
        )}
      </Tooltip>
    </div>
  );
};

export default DetailsHeaderParticipantsList;
