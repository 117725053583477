import { ReactComponent as ImprovementIcon } from '@assets/icons/V4/session-details/areas-of-improvement-icon.svg';
import { ReactComponent as FeedbackIcon } from '@assets/icons/V4/session-details/feedback-icon.svg';
import Markdown from '@components/V4/Markdown/Markdown';
import { useAuthState } from '@shared/react';
import { convertHexToRGBA, normalizeFeedback } from '../../utils/helpers';
import SessionDetailsEmptyState from './components/SessionDetailsEmptyState';
import SessionDetailsFeedbackLabelValue from './components/SessionDetailsFeedbackLabelValue';
import SessionDetailsLayoutCard from './components/SessionDetailsLayoutCard';

export interface FeedbackSection {
  label: string;
  value?: string;
  ratingPosition?: 'sameLine' | 'below';
  starRating?: number;
}

interface SessionDetailsFeedbackProps {
  feedbackSections: FeedbackSection[];
  isMentorFeedback?: boolean;
  hasFeedback: boolean;
  needsFeedback: boolean;
  accentColor: string;
  areasOfImprovement?: string;
  className?: string;
  setIsMentorFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStudentFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SessionDetailsFeedback = (props: SessionDetailsFeedbackProps) => {
  const {
    feedbackSections,
    isMentorFeedback = false,
    hasFeedback,
    needsFeedback,
    accentColor,
    areasOfImprovement,
    className,
    setIsMentorFeedbackOpen,
    setIsStudentFeedbackOpen,
  } = props;
  const { isMentor } = useAuthState();

  const getCardTitle = () => {
    if (isMentorFeedback) {
      return isMentor ? 'Your Feedback' : "Your Mentor's Feedback";
    }
    return isMentor ? "Your Student's Feedback" : 'Your Feedback';
  };

  const getRelevantEmptyState = () => {
    if (isMentorFeedback) {
      return isMentor ? (
        <SessionDetailsEmptyState
          icon={<FeedbackIcon className="text-customGrey" />}
          text={
            needsFeedback
              ? `No feedback provided.
Session will be marked as unpayable unless feedback is given within 7 days.`
              : 'No feedback provided.'
          }
          buttonVariant="primary-error"
          buttonText={needsFeedback ? 'Submit Feedback' : undefined}
          onClick={
            needsFeedback ? () => setIsMentorFeedbackOpen(true) : undefined
          }
        />
      ) : (
        <SessionDetailsEmptyState
          icon={<FeedbackIcon className="text-customGrey" />}
          text={'No feedback provided by mentor.'}
        />
      );
    }

    return isMentor ? (
      <SessionDetailsEmptyState
        icon={<FeedbackIcon className="text-customGrey" />}
        text={'No feedback provided by student.'}
      />
    ) : (
      <SessionDetailsEmptyState
        icon={<FeedbackIcon className="text-customGrey" />}
        text={'No feedback provided.'}
        buttonText={needsFeedback ? 'Submit feedback' : undefined}
        buttonVariant="primary-error"
        onClick={
          needsFeedback ? () => setIsStudentFeedbackOpen(true) : undefined
        }
      />
    );
  };

  const getFeedbackValue = (section: FeedbackSection) =>
    isMentorFeedback ? section.value : normalizeFeedback(section.value);

  if (isMentor) {
    return (
      <SessionDetailsLayoutCard
        className={className}
        title={getCardTitle()}
        icon={<FeedbackIcon className="text-white" />}
      >
        {hasFeedback || areasOfImprovement ? (
          <>
            {hasFeedback && (
              <div className="flex flex-col gap-6">
                {feedbackSections.map(
                  (section, index) =>
                    (!!getFeedbackValue(section) || !!section.starRating) && (
                      <SessionDetailsFeedbackLabelValue
                        key={`${section.label}-${index}`}
                        label={section.label}
                        value={getFeedbackValue(section)}
                        starRating={section.starRating ?? undefined}
                        ratingPosition={section.ratingPosition ?? undefined}
                      />
                    )
                )}
              </div>
            )}
            {areasOfImprovement && (
              <SessionDetailsLayoutCard
                icon={<ImprovementIcon style={{ color: accentColor }} />}
                className="gap-6"
                title="Areas of Improvement"
                titleStyle={{ color: accentColor }}
                style={{ backgroundColor: convertHexToRGBA(accentColor, 10) }}
              >
                <div className="flex flex-col gap-4">
                  <Markdown
                    text={areasOfImprovement}
                    className="flex flex-col gap-2"
                  />
                  <p className="text-sm text-white italic leading-5">
                    This feedback has been generated by AI based on the session
                    transcript.
                  </p>
                </div>
              </SessionDetailsLayoutCard>
            )}
          </>
        ) : (
          getRelevantEmptyState()
        )}
      </SessionDetailsLayoutCard>
    );
  }

  return (
    <SessionDetailsLayoutCard
      className={className}
      title={getCardTitle()}
      icon={<FeedbackIcon className="text-white" />}
    >
      {hasFeedback ? (
        <div className="flex flex-col gap-6">
          {feedbackSections.map(
            (section, index) =>
              (!!getFeedbackValue(section) || !!section.starRating) && (
                <SessionDetailsFeedbackLabelValue
                  key={`${section.label}-${index}`}
                  label={section.label}
                  value={getFeedbackValue(section)}
                  starRating={section.starRating ?? undefined}
                  ratingPosition={section.ratingPosition ?? undefined}
                />
              )
          )}
        </div>
      ) : (
        getRelevantEmptyState()
      )}
    </SessionDetailsLayoutCard>
  );
};

export default SessionDetailsFeedback;
