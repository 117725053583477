import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  EngagementFormData,
  engagementSchema,
} from './EngagementPage/EngagementPage';
import { Feedback } from './FeedbackModal';
import { HomeworkFormData, homeworkSchema } from './HomeworkPage/HomeworkPage';
import {
  ParentHelpFormData,
  parentHelpSchema,
} from './ParentHelpPage/ParentHelpPage';
import { ProgressFormData, progressSchema } from './ProgressPage/ProgressPage';
import {
  SessionSummaryFormData,
  sessionSummarySchema,
} from './SessionSummaryPage/SessionSummaryPage';

interface UseFeedbackModalForm {
  feedback: Feedback;
}

const useFeedbackModalForm = (props: UseFeedbackModalForm) => {
  const { feedback } = props;

  const sessionSummaryFormData = useForm<SessionSummaryFormData>({
    resolver: zodResolver(sessionSummarySchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      aboutSession: feedback.sessionSummary?.aboutSession,
      nextTopics: feedback.sessionSummary?.nextTopics,
    },
  });

  const homeworkFormData = useForm<HomeworkFormData>({
    resolver: zodResolver(homeworkSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      rating: feedback.homework?.rating,
      feedback: feedback.homework?.feedback,
    },
  });

  const engagementFormData = useForm<EngagementFormData>({
    resolver: zodResolver(engagementSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      rating: feedback.engagement?.rating,
      feedback: feedback.engagement?.feedback,
    },
  });

  const progressFormData = useForm<ProgressFormData>({
    resolver: zodResolver(progressSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      rating: feedback.progress?.rating,
      feedback: feedback.progress?.feedback,
    },
  });

  const parentHelpFormData = useForm<ParentHelpFormData>({
    resolver: zodResolver(parentHelpSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      feedback: feedback.parentHelp?.feedback,
    },
  });

  useEffect(() => {
    sessionSummaryFormData.trigger();
    homeworkFormData.trigger();
    engagementFormData.trigger();
    progressFormData.trigger();
    parentHelpFormData.trigger();
  }, [
    sessionSummaryFormData,
    homeworkFormData,
    engagementFormData,
    progressFormData,
    parentHelpFormData,
  ]);

  return {
    sessionSummaryFormData,
    homeworkFormData,
    engagementFormData,
    progressFormData,
    parentHelpFormData,
  };
};

export default useFeedbackModalForm;
