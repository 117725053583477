import './nps.style.css';
import { LockFilled } from '@ant-design/icons';
import { useSubmitNPS } from '@shared/react';
import { Button, message, Modal, Progress, Rate, Input } from 'antd';
import { useState } from 'react';
import './nps-modal.style.scss';
import { COLORS } from '../../../../v3/global';

const { TextArea } = Input;

type Props = {
  visible: boolean;
  courseTitle: string;
  mentorName: string;
};

const LongNPSModal = ({ visible, courseTitle, mentorName }: Props) => {
  const [submitted, setSubmitted] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [toSubmit, setToSubmit] = useState({
    mentorSatisfaction: 0,
    suitsMyNeeds: 0,
    promptness: 0,
    enjoyedMost: '',
    dislikedMost: '',
    mentorCould: '',
    wishedDifferent: '',
    likelyRecommend: 0,
  });
  const mentorFirstName = mentorName.split(' ')[0];

  const { mutate } = useSubmitNPS();

  const submitFeedback = async (selection: number) => {
    updateToSubmit('likelyRecommend', selection);
    mutate({ rating: selection });
  };

  const updateToSubmit = (
    field: string,
    value: number | string,
    dontSubmit = false
  ) => {
    setToSubmit({
      ...toSubmit,
      [field]: value,
    });
    if (!dontSubmit) setCurrentStep(currentStep + 1);
  };

  const NPScolors = [
    '#a85a5a',
    '#ab6051',
    '#ab6848',
    '#a77142',
    '#a27a3d',
    '#99833c',
    '#8e8c3f',
    '#819547',
    '#719d54',
    '#5da564',
  ];

  const steps = [
    <div id="step-1">
      <h2 style={{ color: COLORS.TEXT_PRIMARY }} id="nps-h2">
        How likely are you to recommend Upgrade Education to a friend?
      </h2>
      <div
        style={{
          fontSize: '41px',
          marginTop: '30px',
        }}
      >
        {NPScolors.map((color, index) => (
          <span
            style={{
              backgroundColor: color,
              color: 'white',
              cursor: 'pointer',
            }}
            onClick={() => submitFeedback(index + 1)}
          >
            &nbsp;{index + 1}&nbsp;
          </span>
        ))}
        <br />
        <div
          id="labels"
          style={{
            width: '540px',
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '10px',
            margin: '10px auto 0 auto',
          }}
        >
          <span style={{ color: COLORS.TEXT_PRIMARY }}>Not at all likely</span>
          <span style={{ color: COLORS.TEXT_PRIMARY }}>Highly likely</span>
        </div>
      </div>
      <br />
      <span style={{ color: COLORS.TEXT_PRIMARY }} id="nps-info">
        <LockFilled /> We will associate your feedback with your account to make
        sure we don't ask you again this month.
      </span>
    </div>,
    <div id="step-2">
      <h2 style={{ color: COLORS.TEXT_PRIMARY }} id="nps-h2">
        How satisfied are you with {mentorFirstName} as your mentor?
      </h2>
      <Rate
        onChange={(value) => updateToSubmit('mentorSatisfaction', value * 2)}
        value={toSubmit.mentorSatisfaction / 2}
        allowHalf
        style={{ fontSize: '40px' }}
      />
    </div>,
    <div id="step-3">
      <h2 style={{ color: COLORS.TEXT_PRIMARY }} id="nps-h2">
        How well does {courseTitle} suit your needs?
      </h2>
      <Rate
        onChange={(value) => updateToSubmit('suitsMyNeeds', value * 2)}
        value={toSubmit.suitsMyNeeds / 2}
        allowHalf
        style={{ fontSize: '40px' }}
      />
    </div>,
    <div id="step-4">
      <h2 style={{ color: COLORS.TEXT_PRIMARY }} id="nps-h2">
        How satisfied are you with the promptness of our responses to your
        questions outside the coaching hours?
      </h2>
      <Rate
        onChange={(value) => updateToSubmit('promptness', value * 2)}
        value={toSubmit.promptness / 2}
        allowHalf
        style={{ fontSize: '40px' }}
      />
    </div>,
    <div id="step-5">
      <h2 style={{ color: COLORS.TEXT_PRIMARY }} id="nps-h2">
        What did you enjoy most about {courseTitle}?
      </h2>
      <TextArea
        id="enjoyed-most"
        onChange={(e) => updateToSubmit('enjoyedMost', e.target.value, true)}
        value={toSubmit.enjoyedMost}
        placeholder="Please enter your feedback here"
        rows={4}
        style={{
          fontSize: '15px',
        }}
      />
      <Button
        id="submit-button"
        onClick={() => setCurrentStep(currentStep + 1)}
        disabled={!toSubmit.enjoyedMost}
      >
        Next
      </Button>
    </div>,
    <div id="step-6">
      <h2 style={{ color: COLORS.TEXT_PRIMARY }} id="nps-h2">
        What did you dislike most about {courseTitle}?
      </h2>
      <TextArea
        id="disliked-most"
        onChange={(e) => updateToSubmit('dislikedMost', e.target.value, true)}
        value={toSubmit.dislikedMost}
        placeholder="Please enter your feedback here"
        rows={4}
        style={{ fontSize: '15px' }}
      />
      <Button
        id="submit-button"
        onClick={() => setCurrentStep(currentStep + 1)}
        disabled={!toSubmit.dislikedMost}
      >
        Next
      </Button>
    </div>,
    <div id="step-7">
      <h2 id="nps-h2" style={{ color: COLORS.TEXT_PRIMARY }}>
        What could {mentorFirstName} do to help you more during the sessions?
      </h2>
      <TextArea
        id="mentor-could"
        onChange={(e) => updateToSubmit('mentorCould', e.target.value, true)}
        value={toSubmit.mentorCould}
        placeholder="Please enter your feedback here"
        rows={4}
        style={{ fontSize: '15px' }}
      />
      <Button
        id="submit-button"
        onClick={() => setCurrentStep(currentStep + 1)}
        disabled={!toSubmit.mentorCould}
      >
        Next
      </Button>
    </div>,
    <div id="step-8">
      <h2 style={{ color: COLORS.TEXT_PRIMARY }} id="nps-h2">
        Is there anything you wish was approached differently during or outside
        of classes? Please explain.
      </h2>
      <TextArea
        id="wished-different"
        onChange={(e) =>
          updateToSubmit('wishedDifferent', e.target.value, true)
        }
        value={toSubmit.wishedDifferent}
        placeholder="Please enter your feedback here"
        rows={4}
        style={{ fontSize: '15px' }}
      />
      <Button
        id="submit-button"
        onClick={() => setCurrentStep(currentStep + 1)}
        disabled={!toSubmit.wishedDifferent}
      >
        Next
      </Button>
    </div>,

    <div id="last-step">
      <h2 style={{ color: COLORS.TEXT_PRIMARY }}>All done!</h2>
      <p style={{ color: COLORS.TEXT_PRIMARY }}>
        Thanks a lot, your feedback helps us provide you with the best
        experience!
      </p>
    </div>,
  ];

  return (
    <Modal
      centered
      visible={visible && !submitted}
      width={'70%'}
      footer={null}
      closable={false}
      maskClosable={false}
      bodyStyle={{
        backgroundColor: COLORS.SECTION_BACKGROUND,
      }}
    >
      <Progress
        percent={Number((((currentStep + 1) / steps.length) * 100).toFixed(0))}
      />
      <div
        style={{
          backgroundColor: COLORS.SECTION_BACKGROUND,
        }}
        id="nps-div"
      >
        {steps[currentStep]}
      </div>
    </Modal>
  );
};

export default LongNPSModal;
