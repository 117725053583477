import { Homework } from '@shared/common';
import { homeworksApis, meetingsApis } from '../../apis';
import {
  genericMutation,
  genericMutationWithId,
  genericQueryWithParams,
} from './generics';

export const useListMentorHomeworks =
  genericQueryWithParams<homeworksApis.ListMentorHomework>(
    homeworksApis.listMentorHomework
  );

export const useStudentHomework = (studentId: string) => {
  return useListMentorHomeworks({ student: studentId });
};

export const useCreateHomework = genericMutation<homeworksApis.CreateHomework>(
  homeworksApis.createHomework,
  homeworksApis.listMentorHomework.endpoint,
  meetingsApis.searchMyMeetings.endpoint
);

export const useMyHomeworks =
  genericQueryWithParams<homeworksApis.ListMyHomework>(
    homeworksApis.listMyHomework
  );

export const useMyCourseHomework = (courseId: string) => {
  return useMyHomeworks();
};

export const useMyUpcomingHomeworks = () => {
  return useMyHomeworks({ completed: false });
};

export const useUpdateHomework =
  genericMutationWithId<homeworksApis.UpdateHomework>(
    homeworksApis.updateHomework,
    homeworksApis.listMentorHomework.endpoint
  );

export const useToggleHomework = (homework: Homework) => {
  const { refetch } = useStudentHomework(homework.student);
  const { mutate, ...rest } = useUpdateHomework(homework._id, {
    onSuccess: () => refetch(),
  });

  return { rest, mutate: () => mutate({ completed: !homework.completed }) };
};
