import { SESSION_STATUSES } from '@shared/constants';
import { GetSessionDTO } from '@shared/types/DTOs/EnrollmentsDTO';
import moment from 'moment';

interface SessionDetailsProps {
  session: GetSessionDTO;
}

const SessionDetails = (props: SessionDetailsProps) => {
  const { session } = props;

  return (
    <div className="w-full flex gap-x-8">
      <div className="flex flex-col gap-2 w-1/2">
        <div className="flex text-customGrey">
          <span className="min-w-max">Session Status:</span>
          <span className="text-white ml-2 break-words">
            {SESSION_STATUSES[session?.status]}
          </span>
        </div>
      </div>

      {session?.completionDate && (
        <div className="flex flex-col gap-2 w-1/2">
          <div className="flex text-customGrey">
            <span className="min-w-max">Completed Date/Last Updated:</span>
            <span className="text-white ml-2 break-words">
              {moment(session?.completionDate).format('DD.MM.YYYY')}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SessionDetails;
