import 'tailwindcss/tailwind.css';
import { PhoneIcon } from '@heroicons/react/solid';
import { MailIcon } from '@heroicons/react/solid';
import { UPGRADE_EDUCATION_EMAIL } from '@shared/constants';
import questionsCardStyle from '@styles/components/questions-card.module.scss';

const QuestionsCard = () => {
  return (
    <div
      style={{ backgroundColor: '#161616' }}
      className="overflow-hidden rounded-lg shadow"
    >
      <div className="px-4 py-5 sm:p-6">
        <p className={questionsCardStyle.questionTitle}>
          We're here for you! Reach us anytime via:
        </p>
        <div
          style={{ display: 'flex', flexDirection: 'row', marginTop: '48px' }}
        >
          <PhoneIcon
            style={{ color: '#B0B0B0', marginRight: '24px' }}
            className="h-6 w-6"
            aria-hidden="true"
          />
          <p className={questionsCardStyle.questionDetails}>
            <span>Phone number:</span>
            <a
              style={{ color: '#36B3A8' }}
              href="tel:+40316305013"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              +40 316 305 013
            </a>
          </p>
        </div>
        <div className={questionsCardStyle.questionContactContainer}>
          <MailIcon
            style={{ color: '#B0B0B0', marginRight: '24px' }}
            className="h-6 w-6"
            aria-hidden="true"
          />
          <p className={questionsCardStyle.questionDetails}>
            <span>Email:</span>
            <a
              style={{ color: '#36B3A8' }}
              href={`mailto:${UPGRADE_EDUCATION_EMAIL}`}
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              {UPGRADE_EDUCATION_EMAIL}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuestionsCard;
