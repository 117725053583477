import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteFilled,
} from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import { COLORS } from '../../../v3/global';
import './enrollments-modal.styles.scss';
import { MEETING_STATUSES, MeetingStatus } from '@shared/constants';
import { useMeeting, useUpdateMeeting } from '@shared/react';
import { message, Popconfirm } from 'antd';
import moment from 'moment';
import EditIcon from '@assets/icons/edit-icon.svg';

type Props = {
  session: any;
  deleteSession: (params: any) => any;
  enrolmentId: string;
  meetingIndex: number;
  openSecondRow?: boolean;
  lastMeeting?: string;
};

const EditEnrolmentMeetingsModalRow = ({
  session,
  deleteSession,
  enrolmentId,
  meetingIndex,
  openSecondRow,
  lastMeeting,
}: Props) => {
  const { data: meetingData, isLoading: isLoadingMeetingData } = useMeeting(
    session?.meetings[0]
  );
  const { mutate: updateMeeting } = useUpdateMeeting(session?.meetings[0], {
    onSuccess: () => {
      message.success('Meeting updated successfully!');
    },
  });

  const [isHovered, setIsHovered] = useState(false);
  const [showSecondRow, setShowSecondRow] = useState(false);

  const [viewMoreHomework, setViewMoreHomework] = useState(false);
  const [viewMoreEngagement, setViewMoreEngagement] = useState(false);
  const [viewMoreProgress, setViewMoreProgress] = useState(false);
  const [viewMoreParentHelp, setViewMoreParentHelp] = useState(false);

  const homeworkRef = useRef<HTMLDivElement>(null);
  const engagementRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const parentHelpRef = useRef<HTMLDivElement>(null);

  const [homeworkHeight, setHomeworkHeight] = useState(0);
  const [engagementHeight, setEngagementHeight] = useState(0);
  const [progressHeight, setProgressHeight] = useState(0);
  const [parentHelpHeight, setParentHelpHeight] = useState(0);

  const [status, setStatus] = useState(meetingData?.status);

  const handleRowClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (
      !(event.target instanceof Element && event.target.closest('.delete-icon'))
    ) {
      setShowSecondRow(!showSecondRow);
    }
  };

  const statuses = Object.values(MeetingStatus).filter(
    (s) => typeof s == 'number'
  ) as MeetingStatus[];

  useEffect(() => {
    if (homeworkRef.current) {
      const height = homeworkRef.current.clientHeight;
      setHomeworkHeight(height);
    }
  }, [viewMoreHomework]);

  useEffect(() => {
    if (engagementRef.current) {
      const height = engagementRef.current.clientHeight;
      setEngagementHeight(height);
    }
  }, [viewMoreEngagement]);

  useEffect(() => {
    if (progressRef.current) {
      const height = progressRef.current.clientHeight;
      setProgressHeight(height);
    }
  }, [viewMoreProgress]);

  useEffect(() => {
    if (parentHelpRef.current) {
      const height = parentHelpRef.current.clientHeight;
      setParentHelpHeight(height);
    }
  }, [viewMoreParentHelp]);

  useEffect(() => {
    if (!meetingData) {
      return;
    }
    setStatus(meetingData.status);
  }, [meetingData]);

  useEffect(() => {
    if (status === meetingData?.status) {
      return;
    }
    updateMeeting({ _id: session?.meetings[0], status });
  }, [status]);

  useEffect(() => {
    setShowSecondRow(
      openSecondRow ||
        (lastMeeting !== '' && lastMeeting === meetingData?.startDate)
    );
  }, [isLoadingMeetingData]);

  return (
    <div>
      <div
        onClick={handleRowClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          paddingTop: '12px',
          paddingBottom: '12px',
          backgroundColor: isHovered ? COLORS.DARK_GREY_ALT : 'inherit',
        }}
      >
        {/*DATE*/}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: COLORS.TEXT_SECONDARY,
            width: '50%',
            marginRight: '8px',
          }}
        >
          <span
            style={{ color: COLORS.TEXT_PRIMARY }}
            className="modal-row-column-text"
          >
            {meetingData?.startDate
              ? moment(meetingData?.startDate).format('DD.MM.YYYY')
              : 'N/A'}
          </span>
        </div>
        {/*STATUS*/}
        <div
          style={{
            display: 'flex',
            color: COLORS.TEXT_SECONDARY,
            width: '30%',
            marginRight: '8px',
          }}
        >
          <span
            style={{ color: COLORS.TEXT_PRIMARY }}
            className="modal-row-column-text"
          >
            {meetingData ? MEETING_STATUSES[meetingData.status] : 'N/A'}
          </span>
        </div>
        {/*ACTIONS*/}
        <div
          style={{
            display: 'flex',
            color: COLORS.TEXT_SECONDARY,
            width: '20%',
          }}
        >
          <div onClick={(event) => event.stopPropagation()}>
            <Popconfirm
              icon={null}
              title="Are you sure you want to delete this meeting slot?"
              onConfirm={() => {
                deleteSession({
                  enrollmentId: enrolmentId,
                  sessionId: session._id,
                });
              }}
            >
              <span
                style={{
                  color: COLORS.TEXT_PRIMARY,
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                className="modal-row-column-text delete-icon"
              >
                <DeleteFilled
                  style={{ color: COLORS.ERROR, fontSize: '16px' }}
                />
              </span>
            </Popconfirm>
          </div>
        </div>
      </div>
      {showSecondRow && (
        <div
          style={{ backgroundColor: COLORS.DARK_GREY3, padding: '12px 16px' }}
        >
          {!meetingData ? (
            <p
              style={{ color: COLORS.TEXT_SECONDARY, textAlign: 'center' }}
              className="no-results-text"
            >
              No meeting data
            </p>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <div style={{ width: '30%' }}>
                  <p
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginBottom: '8px',
                    }}
                    className="meeting-details-second-row-label"
                  >
                    Name:
                  </p>
                  <p
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginBottom: '8px',
                    }}
                    className="meeting-details-second-row-label"
                  >
                    Status:
                  </p>
                  <p
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginBottom: '8px',
                    }}
                    className="meeting-details-second-row-label"
                  >
                    Date:
                  </p>
                </div>
                <div style={{ width: '70%' }}>
                  <p
                    style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '8px' }}
                    className="meeting-details-second-row-value"
                  >
                    {meetingData?.name ?? '-'}
                  </p>
                  <p
                    style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '8px' }}
                    className="meeting-details-second-row-value"
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '8px',
                        width: '40%',
                      }}
                      className="mentors-table-row-text modal-edit-enrolment-page-status"
                    >
                      <select
                        value={status}
                        onChange={(e) => {
                          const selectedValueString = e.target.value;
                          const selectedValue =
                            selectedValueString as unknown as MeetingStatus;
                          setStatus(selectedValue);
                        }}
                        className="mentors-row-custom-select-component"
                        style={{
                          borderColor: COLORS.TEXT_PRIMARY,
                          color: COLORS.TEXT_PRIMARY,
                          backgroundColor: COLORS.DARK_GREY3,
                          WebkitAppearance: 'none',
                          MozAppearance: 'none',
                          appearance: 'none',
                          paddingLeft: '0px',
                          backgroundImage: `url(${EditIcon})`,
                          backgroundSize: '8px 8px',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right center',
                          marginRight: '20px',
                          cursor: 'pointer',
                          height: '13px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                          paddingRight: '40px',
                          maxWidth: '132px',
                          fontSize: '11px',
                        }}
                      >
                        {/*<option>{currentEnrolmentData?.mentor?.fullName}</option>*/}
                        {statuses?.map((s) => (
                          <option key={s} value={s}>
                            {MEETING_STATUSES[s]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </p>
                  <p
                    style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '8px' }}
                    className="meeting-details-second-row-value"
                  >
                    {meetingData?.startDate
                      ? moment(meetingData?.startDate).format('DD.MM.YYYY')
                      : 'N/A'}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <p
                  style={{ color: COLORS.TEXT_PRIMARY }}
                  className="meeting-details-second-row-section-title"
                >
                  Mentor
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <div style={{ width: '30%' }}>
                  <p
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginBottom: '8px',
                    }}
                    className="meeting-details-second-row-label"
                  >
                    Name:
                  </p>
                  <p
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginBottom: '8px',
                    }}
                    className="meeting-details-second-row-label"
                  >
                    Attended:
                  </p>
                  <p
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginBottom: '8px',
                    }}
                    className="meeting-details-second-row-label"
                  >
                    Homework Rate:
                  </p>
                  <p
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginBottom: viewMoreHomework
                        ? `${homeworkHeight + 8}px`
                        : '8px',
                    }}
                    className="meeting-details-second-row-label"
                  >
                    Homework Explanation:
                  </p>
                  <p
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginBottom: '8px',
                    }}
                    className="meeting-details-second-row-label"
                  >
                    Engagement Rate:
                  </p>
                  <p
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginBottom: viewMoreEngagement
                        ? `${engagementHeight + 8}px`
                        : '8px',
                    }}
                    className="meeting-details-second-row-label"
                  >
                    Engagement Explanation:
                  </p>
                  <p
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginBottom: '8px',
                    }}
                    className="meeting-details-second-row-label"
                  >
                    Progress Rate:
                  </p>
                  <p
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginBottom: viewMoreProgress
                        ? `${progressHeight + 8}px`
                        : '8px',
                    }}
                    className="meeting-details-second-row-label"
                  >
                    Progress Explanation:
                  </p>
                  <p
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginBottom: '8px',
                    }}
                    className="meeting-details-second-row-label"
                  >
                    Parent Help:
                  </p>
                </div>
                <div style={{ width: '70%' }}>
                  <p
                    style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '8px' }}
                    className="meeting-details-second-row-value"
                  >
                    {meetingData?.mentor?.fullName ?? '-'}
                  </p>
                  <p
                    style={{
                      color: COLORS.TEXT_PRIMARY,
                      marginBottom: '8px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    className="meeting-details-second-row-value"
                  >
                    {meetingData?.attended?.mentor ? (
                      <CheckCircleOutlined
                        style={{
                          fontSize: '16px',
                          color: COLORS.PRIMARY,
                        }}
                      />
                    ) : (
                      <CloseCircleOutlined
                        style={{ fontSize: '16px', color: COLORS.ERROR }}
                      />
                    )}
                  </p>
                  {/*HOMEWORK RATE*/}
                  <p
                    style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '8px' }}
                    className="meeting-details-second-row-value"
                  >
                    {meetingData?.mentorFeedback?.homework?.rate
                      ? `${meetingData?.mentorFeedback?.homework?.rate}/5`
                      : '-'}
                  </p>
                  {/*HOMEWORK EXPLANATION*/}
                  <div
                    ref={homeworkRef}
                    style={{
                      marginBottom: viewMoreHomework ? '24px' : '11px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      height: 'fit-content',
                    }}
                  >
                    <p
                      style={{
                        width: '80%',
                        height: 'fit-content',
                        color: COLORS.TEXT_PRIMARY,
                        whiteSpace: viewMoreHomework ? '' : 'nowrap',
                        overflow: viewMoreHomework ? '' : 'hidden',
                        textOverflow: viewMoreHomework ? '' : 'ellipsis',
                      }}
                      className="meeting-details-second-row-value"
                    >
                      {meetingData?.mentorFeedback?.homework?.explanation ??
                        '-'}
                    </p>
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {meetingData?.mentorFeedback?.homework?.explanation && (
                        <span
                          style={{
                            cursor: 'pointer',
                            color: COLORS.PRIMARY,
                            alignSelf: 'flex-start',
                          }}
                          className="meeting-details-second-row-view-more-button"
                          onClick={() => setViewMoreHomework(!viewMoreHomework)}
                        >
                          {viewMoreHomework ? 'View less' : 'View More'}
                        </span>
                      )}
                    </div>
                  </div>
                  {/*ENGAGEMENT RATE*/}
                  <p
                    style={{
                      color: COLORS.TEXT_PRIMARY,
                      marginBottom: '8px',
                    }}
                    className="meeting-details-second-row-value"
                  >
                    {meetingData?.mentorFeedback?.engagement?.rate
                      ? `${meetingData?.mentorFeedback?.engagement?.rate}/5`
                      : '-'}
                  </p>
                  {/*ENGAGEMENT EXPLANATION*/}
                  <div
                    ref={engagementRef}
                    style={{
                      marginBottom: viewMoreEngagement ? '24px' : '11px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      height: 'fit-content',
                    }}
                  >
                    <p
                      style={{
                        width: '80%',
                        height: 'fit-content',
                        color: COLORS.TEXT_PRIMARY,
                        whiteSpace: viewMoreEngagement ? '' : 'nowrap',
                        overflow: viewMoreEngagement ? '' : 'hidden',
                        textOverflow: viewMoreEngagement ? '' : 'ellipsis',
                      }}
                      className="meeting-details-second-row-value"
                    >
                      {meetingData?.mentorFeedback?.engagement?.explanation ??
                        '-'}
                    </p>
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {meetingData?.mentorFeedback?.engagement?.explanation && (
                        <span
                          style={{
                            cursor: 'pointer',
                            color: COLORS.PRIMARY,
                            alignSelf: 'flex-start',
                          }}
                          className="meeting-details-second-row-view-more-button"
                          onClick={() =>
                            setViewMoreEngagement(!viewMoreEngagement)
                          }
                        >
                          {viewMoreEngagement ? 'View less' : 'View More'}
                        </span>
                      )}
                    </div>
                  </div>
                  {/*PROGRESS RATE*/}
                  <p
                    style={{
                      color: COLORS.TEXT_PRIMARY,
                      marginBottom: '8px',
                    }}
                    className="meeting-details-second-row-value"
                  >
                    {meetingData?.mentorFeedback?.progress?.rate
                      ? `${meetingData?.mentorFeedback?.progress?.rate}/5`
                      : '-'}
                  </p>

                  {/*PROGRESS EXPLANATION*/}
                  <div
                    ref={progressRef}
                    style={{
                      marginBottom: viewMoreProgress ? '24px' : '11px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      height: 'fit-content',
                    }}
                  >
                    <p
                      style={{
                        width: '80%',
                        height: 'fit-content',
                        color: COLORS.TEXT_PRIMARY,
                        whiteSpace: viewMoreProgress ? '' : 'nowrap',
                        overflow: viewMoreProgress ? '' : 'hidden',
                        textOverflow: viewMoreProgress ? '' : 'ellipsis',
                      }}
                      className="meeting-details-second-row-value"
                    >
                      {meetingData?.mentorFeedback?.progress?.explanation ??
                        '-'}
                    </p>
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {meetingData?.mentorFeedback?.progress?.explanation && (
                        <span
                          style={{
                            cursor: 'pointer',
                            color: COLORS.PRIMARY,
                            alignSelf: 'flex-start',
                          }}
                          className="meeting-details-second-row-view-more-button"
                          onClick={() => setViewMoreProgress(!viewMoreProgress)}
                        >
                          {viewMoreProgress ? 'View less' : 'View More'}
                        </span>
                      )}
                    </div>
                  </div>

                  {/*PARENT HELP*/}
                  <div
                    ref={parentHelpRef}
                    style={{
                      marginBottom: viewMoreParentHelp ? '24px' : '11px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      height: 'fit-content',
                    }}
                  >
                    <p
                      style={{
                        width: '80%',
                        height: 'fit-content',
                        color: COLORS.TEXT_PRIMARY,
                        whiteSpace: viewMoreParentHelp ? '' : 'nowrap',
                        overflow: viewMoreParentHelp ? '' : 'hidden',
                        textOverflow: viewMoreParentHelp ? '' : 'ellipsis',
                      }}
                      className="meeting-details-second-row-value"
                    >
                      {meetingData?.mentorFeedback?.parentHelp ?? '-'}
                    </p>
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {meetingData?.mentorFeedback?.parentHelp && (
                        <span
                          style={{
                            cursor: 'pointer',
                            color: COLORS.PRIMARY,
                            alignSelf: 'flex-start',
                          }}
                          className="meeting-details-second-row-view-more-button"
                          onClick={() =>
                            setViewMoreParentHelp(!viewMoreParentHelp)
                          }
                        >
                          {viewMoreParentHelp ? 'View less' : 'View More'}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <p
                  style={{ color: COLORS.TEXT_PRIMARY }}
                  className="meeting-details-second-row-section-title"
                >
                  Student
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <div style={{ width: '30%' }}>
                  <p
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginBottom: '8px',
                    }}
                    className="meeting-details-second-row-label"
                  >
                    Name:
                  </p>
                  <p
                    style={{
                      color: COLORS.TEXT_SECONDARY,
                      marginBottom: '8px',
                    }}
                    className="meeting-details-second-row-label"
                  >
                    Attended:
                  </p>
                </div>
                <div style={{ width: '70%' }}>
                  <p
                    style={{ color: COLORS.TEXT_PRIMARY, marginBottom: '8px' }}
                    className="meeting-details-second-row-value"
                  >
                    {meetingData?.students[0]?.fullName ?? '-'}
                  </p>
                  <p
                    style={{
                      color: COLORS.TEXT_PRIMARY,
                      marginBottom: '8px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    className="meeting-details-second-row-value"
                  >
                    {meetingData?.attended?.student ? (
                      <CheckCircleOutlined
                        style={{
                          fontSize: '16px',
                          color: COLORS.PRIMARY,
                        }}
                      />
                    ) : (
                      <CloseCircleOutlined
                        style={{ fontSize: '16px', color: COLORS.ERROR }}
                      />
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div style={{ borderTopWidth: '0.5px' }} className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>
    </div>
  );
};

export default EditEnrolmentMeetingsModalRow;
