import {
  DeleteFilled,
  PlayCircleOutlined,
  SettingFilled,
} from '@ant-design/icons';
import FinishFlagIcon from '@assets/icons/Icon-awesome-flag-checkered.svg';
import authHeader from '@services/auth-header';
import {
  useEndEnrollment,
  useResumeEnrollment,
  useUnenrollUser,
} from '@shared/react';
import { message, Popconfirm } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { COLORS } from '../../../v3/global';
import './enrollments-modal-students.styles.scss';

type Props = {
  handleCurrentPage: (page: string) => void;
  enrolment: any;
  refetchEnrollments: () => any;
  handleCurrentEnrolment: (id: string) => any;
  handleLastMeeting: (lastMeeting: string) => void;
  handleEnrolmentData?: (enrolmentData: any) => void;
};

const EnrolmentModalRow = ({
  handleCurrentPage,
  enrolment,
  refetchEnrollments,
  handleCurrentEnrolment,
  handleLastMeeting,
  handleEnrolmentData,
}: Props) => {
  const { mutate: endEnrollment } = useEndEnrollment({
    onSuccess() {
      message.success('Enrollment ended successfully');
      refetchEnrollments();
    },
    onError(err: any) {
      message.error(err?.message || 'An error has occurred!');
      refetchEnrollments();
    },
  });

  const { mutate: resumeEnrollment } = useResumeEnrollment({
    onSuccess() {
      message.success('Enrollment resumed successfully');
      refetchEnrollments();
    },
    onError(err: any) {
      message.error(err?.message || 'An error has occurred!');
      refetchEnrollments();
    },
  });

  let courseColor;

  if (
    enrolment?.enrollment?.course?.title?.toLowerCase()?.includes('coach') ||
    enrolment?.enrollment?.course?.title?.toLowerCase()?.includes('training')
  ) {
    courseColor = COLORS.PRIMARY;
  } else if (
    enrolment?.enrollment?.course?.title?.toLowerCase()?.includes('academic')
  ) {
    courseColor = COLORS.PURPLE;
  } else {
    courseColor = COLORS.GOLD;
  }

  const { mutate: unenroll } = useUnenrollUser({
    onSuccess() {
      message.success('Enrollment deleted successfully');
      refetchEnrollments();
    },
    onError(error: any) {
      message.error(
        error?.response?.data?.message ??
          error ??
          error?.message ??
          'An error has occurred!'
      );
    },
  });

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '12px',
          marginBottom: '12px',
        }}
      >
        {/*COURSE*/}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: COLORS.TEXT_SECONDARY,
            width: '30%',
            marginRight: '8px',
          }}
        >
          <div
            style={{
              backgroundColor: courseColor,
              width: '10px',
              height: '10px',
              borderRadius: '10px',
              marginRight: '16px',
            }}
          ></div>
          <span
            style={{ color: COLORS.TEXT_PRIMARY }}
            className="modal-row-column-text"
          >
            {enrolment?.enrollment?.course?.title ?? '-'}
          </span>
        </div>
        {/*MEETINGS*/}
        <div
          style={{
            display: 'flex',
            color: COLORS.TEXT_SECONDARY,
            width: '15%',
            marginRight: '8px',
          }}
        >
          <span
            style={{ color: COLORS.TEXT_PRIMARY }}
            className="modal-row-column-text"
          >
            {`${enrolment?.noOfCompletedSessions ?? '-'} / ${
              enrolment?.noOfSessions ?? '-'
            }`}
          </span>
        </div>

        {/*MENTOR*/}
        <div
          style={{
            display: 'flex',
            color: COLORS.TEXT_SECONDARY,
            width: '20%',
            marginRight: '8px',
          }}
        >
          <span
            style={{ color: COLORS.TEXT_PRIMARY }}
            className="modal-row-column-text"
          >
            {enrolment?.enrollment?.mentor?.fullName ?? '-'}
          </span>
        </div>
        {/*LAST SESSION*/}
        <div
          style={{
            display: 'flex',
            color: COLORS.TEXT_SECONDARY,
            width: '15%',
            marginRight: '8px',
          }}
        >
          <span
            style={{
              color: COLORS.TEXT_PRIMARY,
            }}
            className="modal-row-column-text"
        >
            {enrolment?.lastSession?.relevantMeetingInfo?.startDate &&
            enrolment?.lastSession?.relevantMeetingInfo?.startDate !== 'N/A'
              ? moment(
                  enrolment?.lastSession?.relevantMeetingInfo?.startDate
                ).format('DD.MM.YYYY')
              : '-'}
          </span>
        </div>
        {/*ACTIONS*/}
        <div
          style={{
            display: 'flex',
            color: COLORS.TEXT_SECONDARY,
            width: '20%',
          }}
        >
          <span
            onClick={() => {
              handleCurrentPage('edit-enrollment');
              handleCurrentEnrolment(enrolment?.enrollment?._id);
              if (handleEnrolmentData) {
                handleEnrolmentData(enrolment);
              }
            }}
            style={{
              color: COLORS.TEXT_PRIMARY,
              marginRight: '24px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            className="modal-row-column-text"
          >
            <SettingFilled
              style={{ color: COLORS.TEXT_PRIMARY, fontSize: '16px' }}
            />
          </span>
          {!enrolment?.enrollment?.ended ? (
            <span
              onClick={() => {
                endEnrollment({
                  enrollmentId: enrolment?.enrollment?._id,
                });
              }}
              style={{
                color: COLORS.TEXT_PRIMARY,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                marginRight: '24px',
              }}
              className="modal-row-column-text"
            >
              <img
                src={FinishFlagIcon}
                style={{
                  color: COLORS.ERROR,
                  maxWidth: '16px',
                  minWidth: '16px',
                }}
              />
            </span>
          ) : (
            <span
              onClick={() => {
                resumeEnrollment({
                  enrollmentId: enrolment?.enrollment?._id,
                });
              }}
              style={{
                color: COLORS.TEXT_PRIMARY,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                marginRight: '24px',
              }}
              className="modal-row-column-text"
            >
              <PlayCircleOutlined
                style={{ color: COLORS.PRIMARY, fontSize: '16px' }}
              />
            </span>
          )}
          <Popconfirm
            icon={null}
            title="Are you sure you want to delete this enrollment?"
            onConfirm={() =>
              unenroll({ enrollmentId: enrolment?.enrollment?._id })
            }
            okText="Yes"
            cancelText="No"
          >
            <span
              style={{
                color: COLORS.TEXT_PRIMARY,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              className="modal-row-column-text"
            >
              <DeleteFilled style={{ color: COLORS.ERROR, fontSize: '16px' }} />
            </span>
          </Popconfirm>
        </div>
      </div>
      <div style={{ borderTopWidth: '0.5px' }} className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>
    </div>
  );
};

export default EnrolmentModalRow;
