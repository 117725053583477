import { CommonChecklistTable } from '@components';
import authHeader from '@services/auth-header';
import axios from 'axios';
import { useEffect, useState } from 'react';

const TemplatesTable = () => {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    setIsLoading(true);
    const response = await axios.get('api/checklists/all-templates', {
      headers: authHeader(),
    });

    setTemplates(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (templates?.length) {
    return (
      <CommonChecklistTable isAdmin={true} data={templates} refetch={getData} />
    );
  }

  return <div>No templates found</div>;
};

export default TemplatesTable;
