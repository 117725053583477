import {
  admissionExamPrep,
  interestsOptions,
  MENTOR_ROLE,
  standardisedTests,
  subjectsOptions,
  learningStyles,
  MENTOR_STATUS_V3,
  RECRUITED_FROM,
} from '@shared/constants';
import { BASE_URL } from '@shared/frontendEnv';
import {
  useCreateNewUser,
  useExternalData,
  usePromoteUser,
} from '@shared/react';
import styles from '@styles/pages/sales-onboarding.module.scss';
import { Form, Input, Select, Button, message } from 'antd';
import { useEffect, useState } from 'react';

const { TextArea } = Input;

type Props = {
  isMentor: boolean;
};

const NewUserForm = ({ isMentor }: Props) => {
  const [form] = Form.useForm();
  const [id, setId] = useState('');
  const [registrationToken, setRegistrationToken] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const { mutate: promoteUser } = usePromoteUser();

  const { mutate: createNewUser } = useCreateNewUser({
    onSuccess: (newUser) => {
      form.resetFields();
      setId(newUser.id);
      setRegistrationToken(newUser.registrationToken);
      if (isMentor) {
        promoteUser({ id: newUser.id, role: MENTOR_ROLE });
      }
    },
  });

  const {
    data: externalUserData,
    isLoading,
    isError,
  } = useExternalData({
    email,
  });

  useEffect(() => {
    if (
      externalUserData &&
      !isError &&
      !isLoading &&
      typeof externalUserData !== 'string'
    ) {
      message.destroy();
      message.success(`Auto-filled from Hubspot: ${externalUserData.fullName}`);
      form.setFieldsValue(externalUserData);
    }
  }, [externalUserData, isLoading, isError]);

  const importData = (email: any) => {
    setEmail(email);
  };

  const checkForImport = (changedFields: any) => {
    if (changedFields.length !== 1) return;
    if (!changedFields[0].name) return;
    if (changedFields[0].errors.length) return;
    if (changedFields[0].name[0] !== 'email') return;

    importData(changedFields[0].value);
  };

  const onFinish = (fieldsValue: any) => {
    const values = {
      ...fieldsValue,
      isMentor,
    };
    setFullName(values.fullName);
    createNewUser({ ...values });
  };

  const onFinishFailed = () => {
    message.error('All fields are required.');
  };

  if (id && registrationToken)
    return (
      <TextArea
        rows={20}
        value={`Link generated for ${fullName}:\n\n${BASE_URL}/auth/welcome?id=${id}&token=${registrationToken}\n\nYou don't have to give them this link until you create a plan. After that you can click on "Activate Account" in your sales tools window.`}
      />
    );

  return (
    <Form
      {...formItemLayout}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      name="add-user"
      scrollToFirstError
      onFieldsChange={checkForImport}
    >
      <div className={styles.formHeader}>
        Add user details
        <button
          onClick={() => form.resetFields()}
          className={styles.formButton}
        >
          Clear all fields
        </button>
      </div>
      <Form.Item
        required
        name="email"
        label="Email"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          { required: true },
        ]}
      >
        <Input placeholder="Add new e-mail address" />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }]}
        name="fullName"
        label="Full Name"
      >
        <Input />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }]}
        name="phoneNumber"
        label="Phone Number (w/ country code)"
      >
        <Input />
      </Form.Item>

      {!isMentor && (
        <>
          <Form.Item
            name="isInfluencer"
            label="Is Influencer"
            valuePropName="checked"
          >
            <Input type="checkbox" />
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: true }]}
            name="highSchool"
            label="High School"
          >
            <Input />
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: true }]}
            name="graduationYear"
            label="Graduation Year"
          >
            <Input />
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: true }]}
            name="interests"
            label="Interests"
          >
            <Select mode="multiple">
              {interestsOptions.map((interest) => {
                return (
                  <Select.Option key={interest.value} value={interest.value}>
                    {interest.text}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: true }]}
            name="ecl"
            label="Extra Curricular Activites"
          >
            <Input />
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: true }]}
            name="contactOwner"
            label="Contact Owner"
          >
            <Select>
              <Select.Option key="amira" value="335410129">
                Amira
              </Select.Option>
              <Select.Option key="adriana" value="260366526">
                Adriana
              </Select.Option>
              <Select.Option key="gabi" value="467537870">
                Gabi
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: true }]}
            name="gpa"
            label="GPA"
          >
            <Input type="float" />
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: true }]}
            name="standardisedTests"
            label="Standardised Tests"
          >
            <Select>
              {standardisedTests.map((test) => {
                return (
                  <Select.Option key={test} value={test}>
                    {test}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: true }]}
            name="universityPrograms"
            label="University Programs"
          >
            <Input />
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: false }]}
            name="admissionExamPrep"
            label="Admissions Exam Prep"
          >
            <Select mode="multiple">
              {admissionExamPrep.map((exam) => {
                return (
                  <Select.Option key={exam} value={exam}>
                    {exam}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: true }]}
            name="subjectsStudentLikes"
            label="Subjects Student Likes"
          >
            <Input />
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: true }]}
            name="subjectsStudentDoesntLike"
            label="Subjects Student Doesn't Like"
          >
            <Input />
          </Form.Item>
        </>
      )}

      <Form.Item required rules={[{ required: true }]} name="city" label="City">
        <Input />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }]}
        name="country"
        label="Country"
      >
        <Input />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }]}
        name="universities"
        label="Universities"
      >
        <Input />
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: false }]}
        name="admissionExamPrep"
        label="Admissions Exam Prep"
      >
        <Select mode="multiple">
          {admissionExamPrep.map((exam) => {
            return (
              <Select.Option key={exam} value={exam}>
                {exam}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        required
        rules={[{ required: true }]}
        name="studySubjects"
        label="Subjects can teach"
      >
        <Select mode="multiple">
          {subjectsOptions.map((selectedSubject) => {
            return (
              <Select.Option key={selectedSubject} value={selectedSubject}>
                {selectedSubject}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      {!isMentor && (
        <Form.Item
          required
          rules={[{ required: true }]}
          name="learningStyle"
          label="Learning Style"
        >
          <Select mode="multiple">
            {learningStyles.map((style: string) => {
              return (
                <Select.Option key={style} value={style}>
                  {style}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        required
        rules={[{ required: true }]}
        name="countries"
        label={isMentor ? 'Countries to teach in' : 'Countries to study in'}
      >
        <Select mode="multiple">
          <Select.Option value="US">US</Select.Option>
          <Select.Option value="UK">UK</Select.Option>
          <Select.Option value="EU">EU</Select.Option>
        </Select>
      </Form.Item>

      {isMentor && (
        <>
          <Form.Item
            required
            rules={[{ required: true }]}
            name={['mentorData', 'status']}
            label="Status"
            initialValue={MENTOR_STATUS_V3[0]}
          >
            <Select>
              {MENTOR_STATUS_V3.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: true }]}
            name={['mentorData', 'maximumActiveEnrollments']}
            label="Maximum Capacity"
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: true }]}
            name={['mentorData', 'recruitedFrom']}
            label="Recruited From"
          >
            <Select placeholder="Select">
              {RECRUITED_FROM.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}

      {!isMentor && (
        <>
          <Form.Item
            required
            rules={[{ required: true }]}
            name="parentEmail"
            label="Parent Email"
          >
            <Input />
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: true }]}
            name="parentFullName"
            label="Parent Full Name"
          >
            <Input />
          </Form.Item>

          <Form.Item
            required
            rules={[{ required: true }]}
            name="parentPhoneNumber"
            label="Parent Phone Number"
          >
            <Input />
          </Form.Item>
        </>
      )}
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Register
        </Button>
      </Form.Item>
    </Form>
  );
};

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: {
    span: 24,
  },
};

const tailFormItemLayout = {
  wrapperCol: {},
};

export default NewUserForm;
