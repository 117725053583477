import AgoraModal from '@components/V3/Utils/AgoraModal';
import React from 'react';
import ReactJson from 'react-json-view';

type Props = {
  showModal: boolean;
  handleShowModal: () => void;
  jsonDetails: any;
  title: string;
};

const JSONDetailsModal = ({
  showModal,
  handleShowModal,
  jsonDetails,
  title,
}: Props) => {
  return (
    <div>
      <AgoraModal
        title={title}
        title2={''}
        setModalOpen={() => handleShowModal()}
        isModalOpen={showModal}
        closable={true}
        width="58vw"
      >
        <div style={{ margin: '16px' }}>
          <ReactJson
            src={jsonDetails}
            theme="monokai"
            collapsed={1}
            style={{ backgroundColor: 'transparent' }}
          />
        </div>
      </AgoraModal>
    </div>
  );
};

export default JSONDetailsModal;
