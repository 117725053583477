import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { useSaveUserProfile } from '@shared/react';
import React, { useEffect, useState } from 'react';
import { COLORS } from '../../../v3/global';
import './profile-modal-mentors.styles.scss';
import { Checkbox } from 'antd';
import { countryList } from '@shared/constants';
import { isValidWiseField } from 'apps/agora/src/utils/helpers';
import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';

type Props = {
  handleCurrentPage: (page: string) => void;
  mentorId: string;
  refetchEnrollments?: () => any;

  mentorData: any;
  refetchMentorData: () => void;
};

const currencyOptions2 = ['RON', 'EUR', 'USD'];

const EditPaymentDetailsPage = ({
  handleCurrentPage,
  mentorId,
  refetchEnrollments,

  mentorData,
  refetchMentorData,
}: Props) => {
  const { mutate: saveUserProfile } = useSaveUserProfile(mentorId, {
    onSuccess: () => {
      refetchMentorData();
      handleCurrentPage('mentor-profile');
    },
  });

  const [amount, setAmount] = useState<string | null>(null);
  const [currency, setCurrency] = useState<string | null>(null);
  const [billingCurrency, setBillingCurrency] = useState<string | null>(null);
  const [vatPayer, setVatPayer] = useState<boolean | null>(null);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [companyAddress, setCompanyAddress] = useState<string | null>(null);
  const [companyCountry, setCompanyCountry] = useState<string | null>(null);
  const [companyCUI, setCompanyCUI] = useState<string | null>(null);
  const [companyIBAN, setCompanyIBAN] = useState<string | null>(null);

  const checkIsFieldValid = (value: string) =>
    value !== '' && value?.length <= 32 && isValidWiseField(value);

  const [isValidCompanyName, setIsValidCompanyName] = useState(false);
  const [isTouchedCompanyName, setIsTouchedCompanyName] = useState(false);

  const [isValidIBAN, setIsValidIBAN] = useState(false);
  const [isTouchedIBAN, setIsTouchedIBAN] = useState(false);

  const amountChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (!isNaN(Number(inputValue)) || inputValue === '') {
      setAmount(inputValue);
    }
  };

  const amountBlurHandler = () => {
    if (amount !== null && !isNaN(Number(amount))) {
      setAmount(parseFloat(amount).toFixed(2));
    }
  };

  const companyNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    setIsValidCompanyName(checkIsFieldValid(inputValue));

    setCompanyName(inputValue);
  };

  const companyNameBlurHandler = () => {
    setIsTouchedCompanyName(true);

    if (!checkIsFieldValid(companyName || '')) {
      setIsValidCompanyName(false);
    }
  };

  const companyIBANChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    setIsValidIBAN(checkIsFieldValid(inputValue));

    setCompanyIBAN(inputValue);
  };

  const companyIBANBlurHandler = () => {
    setIsTouchedIBAN(true);

    if (!checkIsFieldValid(companyIBAN || '')) {
      setIsValidIBAN(false);
    }
  };

  const saveClickHandler = () => {
    setIsTouchedCompanyName(true);
    setIsTouchedIBAN(true);

    if (!isValidCompanyName || !isValidIBAN) {
      return;
    }

    saveUserProfile({
      rate: {
        amount: amount === null ? mentorData?.mentorData?.rate?.amount : amount,
        currency:
          currency === null ? mentorData?.mentorData?.rate?.currency : currency,
        billingCurrency:
          billingCurrency === null
            ? mentorData?.mentorData?.rate?.billingCurrency
            : billingCurrency,
        vatPayer:
          vatPayer === null ? mentorData?.mentorData?.rate?.vatPayer : vatPayer,
      },
      company: {
        name:
          companyName === null
            ? mentorData?.mentorData?.company?.name
            : companyName,
        country:
          companyCountry === null
            ? mentorData?.mentorData?.company?.country
            : companyCountry,
        address:
          companyAddress === null
            ? mentorData?.mentorData?.company?.address
            : companyAddress,
        CUI:
          companyCUI === null
            ? mentorData?.mentorData?.company?.CUI
            : companyCUI,
        IBAN:
          companyIBAN === null
            ? mentorData?.mentorData?.company?.IBAN
            : companyIBAN,
      },
    });
  };

  //Temporary fix
  useEffect(() => {
    setCompanyName(mentorData?.mentorData?.company?.name);
    setCompanyIBAN(mentorData?.mentorData?.company?.IBAN);

    setIsValidCompanyName(
      checkIsFieldValid(mentorData?.mentorData?.company?.name)
    );

    setIsValidIBAN(checkIsFieldValid(mentorData?.mentorData?.company?.IBAN));
  }, [
    mentorData?.mentorData?.company?.name,
    mentorData?.mentorData?.company?.IBAN,
  ]);

  return (
    <div className="flex flex-col gap-4" style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>

      <h3
        style={{ color: COLORS.TEXT_SECONDARY, marginBottom: '24px' }}
        className="modal-page-section-title"
      >
        Billing Info
      </h3>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Input
          name="amount"
          id="amount"
          type="text"
          className="w-[calc(50%-16px)]"
          placeholder="eg: 20"
          label="Amount"
          value={
            amount === null ? mentorData?.mentorData?.rate?.amount : amount
          }
          onChange={amountChangeHandler}
          onBlur={amountBlurHandler}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 'calc(50% - 16px)',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            height: '70px',
          }}
        >
          <span className="block text-sm font-medium leading-6 text-customPrimary">
            VAT Payer?
          </span>
          <Checkbox
            onChange={(e) => setVatPayer(e.target.checked)}
            checked={
              vatPayer === null
                ? mentorData?.mentorData?.rate?.vatPayer
                : vatPayer
            }
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            width: 'calc(50% - 16px)',
          }}
        >
          <Select
            size="large"
            label="Currency"
            onSelect={(value: any) => setCurrency(value)}
            options={currencyOptions2.map((option) => ({
              label: option,
              value: option,
            }))}
            value={
              currency === null
                ? mentorData?.mentorData?.rate?.currency
                : currency
            }
          />
        </div>

        <div
          style={{
            width: 'calc(50% - 16px)',
          }}
        >
          <Select
            size="large"
            label="Billing Currency"
            onSelect={(value: any) => setBillingCurrency(value)}
            options={currencyOptions2.map((option) => ({
              label: option,
              value: option,
            }))}
            value={
              billingCurrency === null
                ? mentorData?.mentorData?.rate?.billingCurrency
                : billingCurrency
            }
          />
        </div>
      </div>

      <Input
        name="companyName"
        id="companyName"
        type="text"
        label="Company / Freelancer Name"
        value={
          companyName === null
            ? mentorData?.mentorData?.company?.name
            : companyName
        }
        isTouched={isTouchedCompanyName}
        isValid={isValidCompanyName}
        errorText="Company name must be a maximum of 32 characters and contain only valid characters: A-Z, a-z, 0-9, -./?:(),+"
        onChange={companyNameChangeHandler}
        onBlur={companyNameBlurHandler}
        isRequired
      />

      <Select
        size="large"
        label="Country"
        onSelect={(value) => setCompanyCountry(value as string)}
        options={countryList.map((option) => ({
          label: option,
          value: option,
        }))}
        value={
          companyCountry === null
            ? mentorData?.mentorData?.rate?.country
            : companyCountry
        }
      />

      <Input
        name="companyAddress"
        id="companyAddress"
        type="text"
        label="Company / Freelancer Address"
        value={
          companyAddress === null
            ? mentorData?.mentorData?.company?.address
            : companyAddress
        }
        onChange={(e) => {
          setCompanyAddress(e.target.value);
        }}
      />

      <Input
        name="companyCUI"
        id="companyCUI"
        type="text"
        label="Fiscal registration number (or equivalent)"
        value={
          companyCUI === null
            ? mentorData?.mentorData?.company?.CUI
            : companyCUI
        }
        onChange={(e) => {
          setCompanyCUI(e.target.value);
        }}
      />

      <Input
        name="companyIBAN"
        id="companyIBAN"
        type="text"
        label="IBAN"
        value={
          companyIBAN === null
            ? mentorData?.mentorData?.company?.IBAN
            : companyIBAN
        }
        isTouched={isTouchedIBAN}
        isValid={isValidIBAN}
        errorText="Company IBAN must be a maximum of 32 characters and contain only valid characters: A-Z, a-z, 0-9, -./?:(),+"
        onChange={companyIBANChangeHandler}
        onBlur={companyIBANBlurHandler}
        isRequired
      />

      <SecondaryButton
        onClick={saveClickHandler}
        backgroundColor={COLORS.WHITE}
        height="32px"
        padding="4px 10px 4px 10px"
        margin="12px 0 0 0"
        width="100%"
      >
        <span className="button-text" style={{ color: COLORS.BLACK }}>
          Save
        </span>
      </SecondaryButton>
    </div>
  );
};

export default EditPaymentDetailsPage;
