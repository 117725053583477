import { useParams } from 'react-router-dom';

import LessonQuizEditor from './LessonQuizEditor';
import QuizEditor from './QuizEditor';

const PageEditor = () => {
  const { action } = useParams<{ action: string }>();

  if (action.includes('lesson')) return <LessonQuizEditor />;

  return <QuizEditor />;
};

export default PageEditor;
