import PastReportsRow from '@modules/Mentor/SessionHistory/ReportsAndInvoicesTable/PastReportsRow';
import styles from '@styles/components/reports-invoices-table.module.scss';
import { Empty, Spin } from 'antd';
import { FC } from 'react';
import { COLORS } from '../../../../v3/global';
import OpenReportsRow from './OpenReportsRow';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';

type Props = {
  reports?: any[] | undefined;
  isLoading?: boolean;
  tableHeight?: number;
  type?: string; // CURRENT_REPORTS || PAST_REPORTS || ALL_REPORTS
};

const ReportsAndInvoicesTable: FC<Props> = ({
  reports,
  isLoading,
  tableHeight,
  type,
}) => {
  return (
    <div>
      <div
        style={{
          backgroundColor: COLORS.BLACK,
        }}
        className={`${styles.gridTable} ${
          isLoading ? styles.tableLoading : ''
        }`}
      >
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Created Date
        </div>
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Updated Date
        </div>
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Amount
        </div>
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Status
        </div>
        <div
          style={{
            backgroundColor: COLORS.BLACK,
            color: COLORS.TEXT_PRIMARY,
          }}
          className={styles.gridTableHeader}
        >
          Actions
        </div>

        {isLoading && <LoadingSpinner className="w-full" />}

        {type === 'CURRENT_REPORTS' ? (
          reports?.length && !isLoading ? (
            reports?.map((report, index) => (
              <OpenReportsRow
                isEven={index % 2 === 0}
                report={report}
                key={`${report._id}-${index}`}
              />
            ))
          ) : (
            <div style={{ gridColumn: '1 / 6' }}>{!isLoading && <Empty />}</div>
          )
        ) : null}

        {type === 'PAST_REPORTS' ? (
          reports?.length && !isLoading ? (
            reports?.map((report, index) => (
              <PastReportsRow
                isEven={index % 2 === 0}
                report={report}
                key={`${report._id}-${index}`}
              />
            ))
          ) : (
            <div style={{ gridColumn: '1 / 6' }}>{!isLoading && <Empty />}</div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default ReportsAndInvoicesTable;
