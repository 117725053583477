import { PencilIcon } from '@heroicons/react/outline';
import styles from '@styles/components/form.module.scss';
import { ChangeEvent, CSSProperties, useState } from 'react';

const LockedFormInput = ({
  value,
  title,
  disabled = false,
  type = 'text',
  placeholder = '',
  className,
  style,
  onChange,
  onBlur,
  ...rest
}: {
  title?: string;
  value?: any;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
  [key: string]: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const renderIcon = (type: string) => {
    if (type === 'date' || type === 'datetime-local') {
      return;
    }

    if (type === 'number') {
      return (
        <PencilIcon className={styles.icon} style={{ marginRight: '-2rem' }} />
      );
    }

    return <PencilIcon className={styles.icon} />;
  };

  const [valueState, setValueState] = useState(value);

  return (
    <div className={`${styles.formLockedInput} ${className}`} style={style}>
      {title && <label htmlFor={title}>{title}</label>}
      <input
        type={type}
        // if it has an onChange event, will not be controlled by this component
        value={onChange ? value : valueState}
        disabled={disabled}
        name={title}
        placeholder={placeholder}
        onChange={onChange ? onChange : (e) => setValueState(e.target.value)}
        onBlur={onBlur}
        {...rest}
      />
      {renderIcon(type)}
    </div>
  );
};

export default LockedFormInput;
