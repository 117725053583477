import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { SearchIcon } from '@heroicons/react/outline';
import { useSearchKnowledgeBaseArticles } from '@shared/react';
import styles from '@styles/pages/mentor/knowledge-base.module.scss';
import AgoraSpinner from '@utils/AgoraSpinner';
import { Menu, Pagination } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import authHeader from '../../../services/auth-header';
import { COLORS } from '../../../v3/global';

const KnowledgeBaseSearch = ({ setOpenArticle }: any) => {
  const [search, setSearch] = useState('');
  const [hoveredArticle, setHoveredArticle] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const { data: articlesData, refetch } = useSearchKnowledgeBaseArticles({
    search,
    page: currentPage,
  });

  const handleSearchChange = (newSearch: string) => {
    setSearch(newSearch);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (articlesData) {
      setTotalPages(articlesData.totalPages);
    }
  }, [articlesData]);

  useEffect(() => {
    refetch();
  }, [search, currentPage]);

  return (
    <div>
      <SearchBox
        style={{ marginBottom: '2rem' }}
        currentRefinement={search}
        changeSearch={handleSearchChange}
      />
      <div
        style={{
          height: '432px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: articlesData?.articles ? 'flex-start' : 'center',
          alignItems: 'flex-start',
          marginBottom: '1.5rem',
        }}
      >
        <div style={{ width: '100%' }}>
          {articlesData?.articles ? (
            articlesData?.articles?.map((article: any) => (
              <div
                style={{
                  cursor: 'pointer',
                  width: 'fit-content',
                }}
                key={article._id}
                onClick={() => {
                  setOpenArticle(article._id);
                }}
                onMouseEnter={() => setHoveredArticle(article._id)}
                onMouseLeave={() => setHoveredArticle(null)}
              >
                <span
                  style={{
                    color:
                      hoveredArticle === article?._id
                        ? COLORS.PRIMARY
                        : COLORS.TEXT_PRIMARY,
                  }}
                >
                  {article?.title}
                </span>
              </div>
            ))
          ) : (
            <div style={{ width: '100%' }}>
              <AgoraSpinner fontSize={40} />
            </div>
          )}
        </div>
      </div>

      <div>
        <Pagination
          className="custom-pagination"
          showSizeChanger={false}
          current={currentPage + 1}
          defaultCurrent={1}
          total={totalPages * 20}
          pageSize={20}
          onChange={(page) => setCurrentPage(page - 1)}
          prevIcon={
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <LeftOutlined
                style={{
                  color: COLORS.TEXT_SECONDARY,
                }}
              />
            </div>
          }
          nextIcon={
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <RightOutlined
                style={{
                  color: COLORS.TEXT_SECONDARY,
                }}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

const SearchBox = ({ currentRefinement, changeSearch }: any) => {
  const fetchCourses = async () => {
    const response = await axios.get('/api/v2/course/list-all', {
      headers: authHeader(),
    });
    const courseTitles = response.data.docs.map((course: any) => course.title);
    return courseTitles;
  };

  const { data: courses } = useQuery('courses', fetchCourses);

  const coursesDropdown = (
    <Menu>
      {courses?.map((course: any, index: number) => (
        <Menu.Item key={`${course}-${index}`}>{course}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div style={{ marginBottom: '2rem' }}>
      <div className={styles.search} style={{ marginBottom: '1rem' }}>
        <SearchIcon className={styles.icon} />

        <input
          type="text"
          placeholder="Search articles..."
          value={currentRefinement}
          onChange={(event) => {
            changeSearch(event.currentTarget.value);
          }}
        />
      </div>
    </div>
  );
};

export default KnowledgeBaseSearch;
