import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';

const useCalendar = () => {
  const [calendar, setCalendar] = useState<Moment[][]>([]);
  const [selected, setSelected] = useState(moment());

  const startDay = selected.clone().startOf('month').startOf('week');
  const endDay = selected.clone().endOf('month').endOf('week');

  useEffect(() => {
    const day = startDay.clone();
    const tmp = [];
    while (day.isBefore(endDay, 'day')) {
      tmp.push(
        Array(7)
          .fill(null)
          .map(() => day.add(1, 'day').clone())
      );
    }
    setCalendar(tmp);
  }, [selected]);

  const goBackward = () => setSelected(selected.clone().subtract(1, 'month'));
  const goForward = () => setSelected(selected.clone().add(1, 'month'));
  const goToday = () => setSelected(moment());

  return { selected, calendar, goBackward, goForward, goToday };
};

export default useCalendar;
