import { GetAllCoursesCanTeachDTO } from '@shared/types/DTOs/MentorsDTO';
import {
  ApiData,
  ApiDescription,
  RequestsMethod,
  MentorCapacity,
  MentorRelabilityScore,
  MentorWidgetStatistics,
  MentorWithRate,
  User,
} from '../types';

export const getMentorMRSHistory: ApiDescription = {
  endpoint: '/mentor/mrs-history',
  method: RequestsMethod.Get,
};
export type GetMentorMRSHistory = ApiData<
  { mentorId: string },
  {
    mentor: Partial<User>;
    mentorMRSHistory: MentorRelabilityScore[];
  }
>;

export const getDefaultMentorReliabilityScore: ApiDescription = {
  endpoint: '/mentor/mrs-default',
  method: RequestsMethod.Get,
};
export type GetDefaultMentorReliabilityScore = ApiData<
  never,
  MentorRelabilityScore[]
>;

export const updateMentorMRSHistory: ApiDescription = {
  endpoint: '/mentor/update-mrs-history/:mentorId',
  method: RequestsMethod.Patch,
};
export type UpdateMentorMRSHistory = ApiData<
  {
    mrsId: string;
    reason: string;
    percentChange: number;
  },
  unknown
>;

export const deleteMentorMRS: ApiDescription = {
  endpoint: '/mentor/delete-mrs/:mentorId',
  method: RequestsMethod.Delete,
};
export type DeleteMentorMRS = ApiData<
  {
    mrsId: string;
  },
  unknown
>;

export const updateDefaultMRS: ApiDescription = {
  endpoint: '/mentor/update-default-mrs',
  method: RequestsMethod.Patch,
};
export type UpdateDefaultMRS = ApiData<
  {
    mrsId: string;
    reason: string;
    percentChange: number;
  },
  unknown
>;

export const deleteDefaultMRS: ApiDescription = {
  endpoint: '/mentor/delete-default-mrs',
  method: RequestsMethod.Delete,
};
export type DeleteDefaultMRS = ApiData<{ mrsId: string }, unknown>;

export const updateMentorCapacity: ApiDescription = {
  endpoint: '/mentor/update-mentor-capacity/:mentorId',
  method: RequestsMethod.Patch,
};
export type UpdateMentorCapacity = ApiData<
  {
    capacity: number;
  },
  unknown
>;

export const getMentorCapacity: ApiDescription = {
  endpoint: '/mentor/get-mentor-capacity/:id',
  method: RequestsMethod.Get,
};
export type GetMentorCapacity = ApiData<unknown, MentorCapacity | null>;

export const searchMentors: ApiDescription = {
  endpoint: '/mentor/search-mentors',
  method: RequestsMethod.Get,
};
export type SearchMentors = ApiData<
  {
    page: number;
    limit: number;
    sorting: any;
    search?: string;
    statuses?: string[];
    courses?: string[];
    isTestUser?: boolean;
    extraFilter?: any;
  },
  { mentors: MentorWithRate[]; page: number; limit: number; total: number }
>;

export const getMentorWidgetStatistics: ApiDescription = {
  endpoint: '/mentor/mentor-widget-statistics',
  method: RequestsMethod.Get,
};

export type GetMentorWidgetStatistics = ApiData<never, MentorWidgetStatistics>;

export const getMentorWidgetStatisticsV2: ApiDescription = {
  endpoint: '/mentor/mentor-widget-statistics-v2',
  method: RequestsMethod.Get,
};

export type GetMentorWidgetStatisticsV2 = ApiData<
  never,
  MentorWidgetStatistics
>;

export const getAllCoursesCanTeach: ApiDescription = {
  endpoint: '/mentor/:id/get-all-courses-can-teach',
  method: RequestsMethod.Get,
};

export type GetAllCoursesCanTeach = ApiData<never, GetAllCoursesCanTeachDTO[]>;

export const reorderCoursesCanTeach: ApiDescription = {
  endpoint: '/mentor/:id/reorder-courses-can-teach',
  method: RequestsMethod.Patch,
};

export type ReorderCoursesCanTeach = ApiData<
  {
    coursesCanTeach: Array<{
      _id: string;
      newOrder?: number;
      delete?: boolean;
    }>;
  },
  unknown
>;
