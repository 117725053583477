import { useAuthActions } from '@shared/react';
import styles from '@styles/components/header.module.scss';
import { Dropdown } from 'antd';
import DriveButton from './DriveButton';
import MenuTabs from './MenuTabs';
import ThemeButton from './ThemeButton';

type Props = {
  visible: boolean;
  children: any;
};

const MobileMenuDropdown = ({ visible, children }: Props) => {
  const { logout } = useAuthActions();

  const menuFooter = (
    <div className={styles.mobileMenuFooter}>
      <div onClick={logout} style={{ cursor: 'pointer' }}>
        <span>Log out</span>
      </div>
      <DriveButton />
      {/* <ThemeButton /> */}
    </div>
  );

  const mobileMenuItems = (
    <div className={styles.mobileMenu}>
      <div className={styles.mobileMenuBody}>
        <MenuTabs />
      </div>
      {menuFooter}
    </div>
  );

  return (
    <div>
      <Dropdown
        overlay={mobileMenuItems}
        placement="bottomLeft"
        visible={visible}
      >
        {children}
      </Dropdown>
    </div>
  );
};

export default MobileMenuDropdown;
