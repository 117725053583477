import { PlusOutlined } from '@ant-design/icons';
import {
  CountriesSelector,
  PageLayout,
  StudySubjectsSelector,
} from '@components';
import { CourseSelector } from '@components';
import { useModal } from '@hooks';
import { Button, Input, Space } from 'antd';
import { useLayoutEffect, useState } from 'react';

import MentorsTable from './MentorsTable';
import NewMentorModal from './NewMentorModal';

const { Search } = Input;

export type MentorSearchParam = {
  coursesCanTeach: string;
  countries: string[];
  studySubjects: string[];
  majors: string[];
  isMarkedInactive: boolean;
};

const TABLE_HEADER = 520;

const Mentors = () => {
  const [NewMentor, showNewMentor] = useModal(NewMentorModal);
  const [searchParams, setSearchParams] = useState<MentorSearchParam>({
    coursesCanTeach: '',
    countries: [],
    studySubjects: [],
    majors: [],
    isMarkedInactive: false,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [tableHeight, setTableHeight] = useState(
    window.innerHeight - TABLE_HEADER
  );

  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      setTableHeight(window.innerHeight - TABLE_HEADER);
    });
    return () => window.removeEventListener('resize', () => {});
  });

  return (
    <PageLayout
      headerTitle="All Mentors"
      headerExtra={
        <Button icon={<PlusOutlined />} onClick={showNewMentor}>
          Add new mentor
        </Button>
      }
      cardTitle="Mentors Table"
    >
      {NewMentor}
      <>
        <Search
          placeholder="Search by name, e-mail, phone etc."
          enterButton="Search"
          size="large"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Space style={{ margin: '10px 0' }}>
          <div>
            <span>Can teach: </span>
            <CourseSelector
              optionPlaceholder="ALL"
              onChange={(value: string) => {
                setSearchParams({
                  ...searchParams,
                  coursesCanTeach: value,
                });
              }}
            />
          </div>
          <div>
            <span>Country: </span>
            <CountriesSelector
              value={searchParams.countries}
              style={{ width: 170 }}
              onChange={(value: string[]) => {
                setSearchParams({
                  ...searchParams,
                  countries: value,
                });
              }}
            />
          </div>
          <div>
            <span>Study subjects: </span>
            <StudySubjectsSelector
              value={searchParams.studySubjects}
              style={{ width: 170 }}
              onChange={(value: string[]) => {
                setSearchParams({
                  ...searchParams,
                  studySubjects: value,
                });
              }}
            />
          </div>
          <div>
            <span>Majors: </span>
            <StudySubjectsSelector
              value={searchParams.majors}
              style={{ width: 170 }}
              onChange={(value: string[]) => {
                setSearchParams({
                  ...searchParams,
                  majors: value,
                });
              }}
            />
          </div>
          <div>
            <span>Inactive: </span>
            <Input
              type="checkbox"
              checked={searchParams.isMarkedInactive}
              onChange={(e) => {
                setSearchParams({
                  ...searchParams,
                  isMarkedInactive: e.target.checked,
                });
              }}
            />
          </div>
        </Space>
      </>
      <MentorsTable
        searchParams={searchParams}
        searchQuery={searchQuery}
        tableHeight={tableHeight < 300 ? 300 : tableHeight}
      />
    </PageLayout>
  );
};

export default Mentors;
