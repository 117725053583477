import { Dispatch, ReactNode, SetStateAction } from 'react';
import { mergeClassNames } from '../../utils/helpers';

export interface DropdownItem {
  icon: ReactNode;
  text: string;
  onClick: () => void;
}

interface DropdownProps {
  YPosition?: 'above' | 'below';
  XPosition?: 'left' | 'right';
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
  items: DropdownItem[];
}

const Dropdown = (props: DropdownProps) => {
  const {
    YPosition = 'below',
    XPosition = 'left',
    setIsDropdownOpen,
    items,
  } = props;

  const dropdownItemClickHandler = (callback: () => void) => {
    callback();
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <div
      className={mergeClassNames(
        'absolute z-10 bg-surfaceComplementary rounded-lg min-w-32 w-32 py-2',
        {
          'top-0 -translate-y-full': YPosition === 'above',
          'right-0': XPosition === 'left',
          'left-0': XPosition === 'right',
        }
      )}
    >
      {items.map((item, index) => (
        <div
          key={`${item.text} - ${index}`}
          className="flex items-center gap-2 py-2.5 pl-3 cursor-pointer hover:bg-surfaceHover "
          onClick={() => dropdownItemClickHandler(item.onClick)}
        >
          {item.icon}
          <p className="text-xs text-white">{item.text}</p>
        </div>
      ))}
    </div>
  );
};

export default Dropdown;
