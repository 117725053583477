import { QueryKey, UseQueryOptions } from 'react-query';
import { useAuthQueryWithParams } from '.';

function useAuthQuery<ResponseData>(
  query: QueryKey,
  options?: UseQueryOptions<ResponseData>
) {
  return useAuthQueryWithParams(query, undefined, options);
}

export default useAuthQuery;
