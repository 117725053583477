import React, { useEffect, useState } from 'react';
import UserPermissionsPage from '@components/V3/UserPermissionsModal/UserPermissionsPage';
import AgoraModal from '@components/V3/Utils/AgoraModal';
import './user-permissions-modal.styles.scss';
import { useEditUserPermissions, useGetUserPermissions } from '@shared/react';
import { message } from 'antd';

type Props = {
  showModal: boolean;
  handleShowModal: () => void;
  userData?: any;
  refetchUserData: () => void;
};

const UserPermissionsModal = ({
  showModal,
  handleShowModal,
  userData,
  refetchUserData,
}: Props) => {
  const { data: userPermissions, refetch: refetchUserPermissions } =
    useGetUserPermissions(userData?._id);

  const [currentPage, setCurrentPage] = useState('user-permissions');

  const handleCurrentPage = (page: string) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    refetchUserPermissions();
  }, [showModal]);

  const [editError, setEditError] = useState();

  const { mutate: editUserPermissions } = useEditUserPermissions(
    userData?._id,
    {
      onSuccess: (response: any) => {
        refetchUserData();
        message.success(response?.message);
      },
      onError: (error: any) => {
        setEditError(error?.response?.data?.error?.message);
      },
    }
  );

  return (
    <div>
      <AgoraModal
        backButton={currentPage === 'edit-permissions-details'}
        onBack={
          currentPage === 'edit-permissions-details'
            ? handleCurrentPage
            : () => null
        }
        backRoute="user-permissions"
        title={
          currentPage === 'user-permissions'
            ? `Edit Permissions`
            : currentPage === 'edit-permissions-details'
            ? 'Edit Permissions'
            : ''
        }
        title2={
          currentPage === 'user-permissions' ? `${userData?.fullName}` : ''
        }
        setModalOpen={() => handleShowModal()}
        isModalOpen={showModal}
        closable={true}
        width="58vw"
      >
        <UserPermissionsPage
          userData={userData}
          refetchUserData={refetchUserData}
          editUserPermissions={editUserPermissions}
          userPermissions={userPermissions}
          editError={editError}
        />
      </AgoraModal>
    </div>
  );
};

export default UserPermissionsModal;
