import FlagsDistributionOverTime from '@components/V3/FlagsDistributionOverTime';
import FlagsPieChart from '@components/V3/FlagsPieChart';
import TotalFlagsBanner from '@components/V3/TotalFlagsBanner';
import { useGetNoOfFlagsByFlagTypes } from '@shared/react';
import { COLORS } from '../../../v3/global';
import './flags-overview.styles.scss';

type Props = {
  search: any;
  isActive: any;
  flagType: any;
  startRange: any;
  endRange: any;
  studentId: any;
  mentorId: any;
  courseId: any;
};

const FlagsOverview = ({
  search,
  isActive,
  flagType,
  startRange,
  endRange,
  studentId,
  mentorId,
  courseId,
}: Props) => {
  const { data: noOfFlagTypes } = useGetNoOfFlagsByFlagTypes({
    search: search,
    isActive: isActive,
    flagType: flagType,
    startRange: startRange,
    endRange: endRange,
    courseId: courseId,
    studentId: studentId,
    mentorId: mentorId,
  });

  return (
    <div>
      <h3
        style={{
          color: COLORS.TEXT_PRIMARY,
          marginBottom: '24px',
          fontWeight: 'bold',
        }}
        className="flags-overview-title"
      >
        Total Flags
      </h3>
      <div style={{ marginBottom: '56px' }}>
        <TotalFlagsBanner noOfFlagTypes={noOfFlagTypes} />
      </div>
      <h3
        style={{
          color: COLORS.TEXT_PRIMARY,
          marginBottom: '24px',
          fontWeight: 'bold',
        }}
        className="flags-overview-title"
      >
        Flags Distribution Over Time
      </h3>
      <div style={{ marginBottom: '56px' }}>
        <FlagsDistributionOverTime
          search={search}
          isActive={isActive}
          flagType={flagType}
          startRange={startRange}
          endRange={endRange}
          studentId={studentId}
          mentorId={mentorId}
          courseId={courseId}
        />
      </div>
      <h3
        style={{
          color: COLORS.TEXT_PRIMARY,
          marginBottom: '24px',
          fontWeight: 'bold',
        }}
        className="flags-overview-title"
      >
        Flags procentual distribution
      </h3>
      <div style={{ marginBottom: '56px' }}>
        <FlagsPieChart noOfFlagTypes={noOfFlagTypes} />
      </div>
    </div>
  );
};

export default FlagsOverview;
