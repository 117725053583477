import moment from 'moment';
import { useContext, useMemo } from 'react';
import { StudentDetailsPageContext } from '../../StudentDetailsPage';
import PastExamsSection from './PastExamsSection/PastExamsSection';
import UpcomingExamsSection from './UpcomingExamsSection/UpcomingExamsSection';

const ExamsTab = () => {
  const { student } = useContext(StudentDetailsPageContext);

  const { upcomingExams, pastExams } = useMemo(() => {
    const upcomingExams: any[] = [];
    const pastExams: any[] = [];

    const today = moment();

    student?.languageExams?.forEach((exam: any) => {
      const examDate = moment(exam.date);

      if (examDate.isSameOrAfter(today, 'day')) {
        upcomingExams.push(exam);
      } else {
        pastExams.push(exam);
      }
    });

    return { upcomingExams, pastExams };
  }, [student?.languageExams]);

  return (
    <>
      <UpcomingExamsSection exams={upcomingExams} />
      <PastExamsSection exams={pastExams} />
    </>
  );
};

export default ExamsTab;
