import { EditOutlined } from '@ant-design/icons';
import { useDeleteChecklistTemplate } from '@shared/react';
import { Checklist } from '@shared/types';
import { Button, List, message } from 'antd';
import { Link } from 'react-router-dom';

const CommonChecklistTable = ({
  data,
  isAdmin,
  refetch,
}: {
  data: Checklist[];
  isAdmin: boolean;
  refetch?: () => void;
}) => {
  const { mutate: deleteChecklistTemplate } = useDeleteChecklistTemplate({
    onSuccess: () => {
      message.success('Checklist template deleted');
      refetch?.();
    },
  });
  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={data}
        bordered
        renderItem={(item: Checklist) => (
          <List.Item key={item._id}>
            <List.Item.Meta title={item.title} />
            <Link
              to={`/${isAdmin ? 'admin' : 'mentor'}/checklists/${
                item._id
              }/edit`}
            >
              <Button type="primary" icon={<EditOutlined />}>
                Edit
              </Button>
            </Link>
            <Button
              className="ml2"
              type="dashed"
              size={'small'}
              onClick={() => deleteChecklistTemplate({ checklistId: item._id })}
            >
              Delete
            </Button>
          </List.Item>
        )}
      />
    </div>
  );
};

export default CommonChecklistTable;
