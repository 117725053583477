import { Layout } from 'antd';
import Header from './Header';
import { useMobileState } from './MobileContext';
import { NavigationProvider } from './NavigationContext';
import Sider from './Sider';

type Props = {
  children?: any;
};

const Navigation = ({ children }: Props) => {
  const isMobile = useMobileState();
  return (
    <NavigationProvider>
      <Layout>
        {!isMobile && <Sider />}
        <Layout
          style={{
            background: 'var(--color-bg-body)',
            minHeight: 'calc(100vh - 64px)',
          }}
        >
          <Header />
          <div>{children}</div>
        </Layout>
      </Layout>
    </NavigationProvider>
  );
};

export default Navigation;
