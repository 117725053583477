import { Enrollment } from '@shared/common';
import React, { useState } from 'react';
import SessionModalSection, { ActionButton } from '../../SessionModalSection';
import EnrollmentDetails, { EnrollmentDetailsType } from './EnrollmentDetails';
import EnrollmentDetailsForm from './EnrollmentDetailsForm';

interface EnrollmentDetailsSectionProps {
  enrollment: Enrollment;
}

const EnrollmentDetailsSection = (props: EnrollmentDetailsSectionProps) => {
  const { enrollment } = props;

  const [isEdit, setIsEdit] = useState(false);

  const enrollmentDetails: EnrollmentDetailsType = {
    startDate: enrollment.startDate,
    endDate: enrollment.estimatedEndDate,
    ended: enrollment.ended,
    noOfSessions: enrollment.noOfSessions,
  };

  const buttons: ActionButton[] = [
    {
      buttonText: 'Edit',
      onClick: () => setIsEdit(true),
    },
  ];

  return isEdit ? (
    <EnrollmentDetailsForm
      defaultData={enrollment}
      onCancel={() => setIsEdit(false)}
    />
  ) : (
    <SessionModalSection
      sectionTitle={'Enrolment Details'}
      buttonsArray={buttons}
    >
      <EnrollmentDetails enrollmentDetails={enrollmentDetails} />
    </SessionModalSection>
  );
};

export default EnrollmentDetailsSection;
