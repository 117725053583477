import hubspotLoading from '@assets/lottie/congratulations.json';
import React from 'react';
import Lottie from 'react-lottie';
import { COLORS } from '../../../v3/global';
import './create-new-user.styles.scss';

const CreateNewUserConfirmation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: hubspotLoading,
    rendererSettings: {
      preserveAspectRatio: 'xSmalYMid slice',
    },
    clickToPause: false,
  };

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>

      <div
        style={{
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <div
            style={{
              marginTop: '-160px',
            }}
          >
            <Lottie
              isClickToPauseDisabled={true}
              options={defaultOptions}
              height={300}
              width={300}
              style={{ cursor: 'default' }}
            />
          </div>
          <p
            style={{
              color: COLORS.TEXT_PRIMARY,
              marginTop: '-20px',
            }}
            className="hubspot-loading-text"
          >
            User Created Successfully!
          </p>
        </div>
      </div>
    </div>
  );
};

export default CreateNewUserConfirmation;
