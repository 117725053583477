import { MENTOR_ROLE } from '@shared/constants';
import { useAuthState } from '@shared/react';

import { AccountProfile } from './Profile';

const AccountPage = () => {
  const { roles } = useAuthState();
  const isMentor = roles.includes(MENTOR_ROLE);

  return <AccountProfile isMentorProfile={isMentor} />;
};

export default AccountPage;
