import { useQueryParams } from '@hooks';
import { COURSES } from '@routes';
import { useLesson, useMarkLessonComplete } from '@shared/react';
import { Block, EdjData } from '@shared/types';
import { Button, Result } from 'antd';
import { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

type State = {
  title: string;

  content: EdjData;
  isFirstSlide: boolean;
  isLastSlide: boolean;
  slideHasQuiz: boolean;
  slidePercent: number;

  skippedAhead: boolean;

  gettingLesson: boolean;
  markingComplete: boolean;
  moduleUrl: string;
};
export const LessonStateContext = createContext<State | undefined>(undefined);

type Actions = {
  nextSlide: () => void;
  prevSlide: () => void;
  resetSlide: () => void;
  lessonDone: () => void;
};
export const LessonActionsContext = createContext<Actions | undefined>(
  undefined
);

function splitArray<T>(arr: T[], fn: (el: T) => boolean) {
  if (!arr) return [];

  const indexes: number[] = [];
  arr.forEach((el, index) => {
    if (fn(el)) indexes.push(index);
  });

  const splitArray = [arr.slice(0, indexes[0])];
  for (let i = 0; i < indexes.length - 1; i++)
    splitArray.push(arr.slice(indexes[i] + 1, indexes[i + 1]));

  splitArray.push(arr.slice(indexes[indexes.length - 1] + 1));

  return splitArray;
}

type Props = {
  children: any;
};

const LessonProvider = ({ children }: Props) => {
  const params = useQueryParams();
  const skippedAhead = !!params.get('sa');
  const chapter = params.get('m');

  const { id } = useParams<{ id: string }>();
  const { data, isLoading: gettingLesson } = useLesson(id);

  const moduleUrl = `${COURSES}/${data?.parentCourse}`;

  const { mutate: markComplete, isLoading: markingComplete } =
    useMarkLessonComplete(id);

  const [slide, setSlide] = useState(0);

  const lessonDone = () => {
    // TODO Completed logic should be changed

    // if (skippedAhead) {
    //   message.info('Lesson will be marked as complete when taken in order.');
    //   return;
    // }

    markComplete({});
    setTimeout(() => {
      // history.goBack();
      window.location.replace(`${moduleUrl}/chapters/${chapter}`);
    }, 700);
  };

  const content = data?.content || {
    blocks: [],
    time: Date.now(),
    version: '2.22.2',
  };

  const slides = splitArray(
    content.blocks,
    (block: Block) => block.type === 'delimiter'
  );

  const lastSlide = slides.length - 1;

  const currentBlocks = slides[slide];
  const slideHasQuiz = !!currentBlocks.find(
    (block) => block.type.toLowerCase() === 'simplequiz'
  );
  const isFirstSlide = slide === 0;
  const isLastSlide = slide === lastSlide;
  const slidePercent = (slide + 1) / slides.length;

  const nextSlide = () => {
    if (isLastSlide) return;

    setSlide(slide + 1);
  };

  const prevSlide = () => {
    if (isFirstSlide) return;

    setSlide(slide - 1);
  };

  const resetSlide = () => {
    setSlide(0);
  };

  const state: State = {
    title: data?.title || '',

    content: { ...content, blocks: currentBlocks },
    slideHasQuiz,
    isFirstSlide,
    isLastSlide,
    slidePercent,

    skippedAhead,

    gettingLesson,
    markingComplete,

    moduleUrl,
  };

  const actions: Actions = {
    nextSlide,
    prevSlide,
    resetSlide,
    lessonDone,
  };

  return state.content.blocks.length && actions && children ? (
    <div>
      <LessonStateContext.Provider value={state}>
        <LessonActionsContext.Provider value={actions}>
          {children}
        </LessonActionsContext.Provider>
      </LessonStateContext.Provider>
    </div>
  ) : state.gettingLesson ? (
    <div>Loading...</div>
  ) : (
    <Result
      status="warning"
      title="No lesson found."
      extra={
        <Button type="primary" key="console">
          Please contact our support team for the missing lesson.
        </Button>
      }
    />
  );
};

const useLessonState = () => {
  const context = useContext(LessonStateContext);
  if (context === undefined) {
    throw new Error('useLessonState must be used within a LessonProvider');
  }

  return context;
};

const useLessonActions = () => {
  const context = useContext(LessonActionsContext);
  if (context === undefined) {
    throw new Error('useLessonActions must be used within a LessonProvider');
  }

  return context;
};

export { LessonProvider, useLessonState, useLessonActions };
