import { MentorData } from '@shared/common';
import {
  getSymbolFromCurrency,
  mergeClassNames,
} from 'apps/agora/src/utils/helpers';
import React from 'react';

interface PaymentsTabTableProps {
  mentorData?: MentorData;
}

interface PaymentsTableItemProps {
  label: string;
  value: string;
}

const UNCOMPLETED_STRING = 'Uncompleted';

const PaymentsTableItem = (props: PaymentsTableItemProps) => {
  const { label, value } = props;
  return (
    <div className="flex justify-between">
      <p className="text-sm text-white">{label}</p>
      <p
        className={mergeClassNames('text-sm text-customGrey', {
          'text-customRed': value === UNCOMPLETED_STRING,
        })}
      >
        {value}
      </p>
    </div>
  );
};

const PaymentsTabTable = (props: PaymentsTabTableProps) => {
  const { mentorData } = props;

  const paymentsInfo = [
    {
      label: 'Pay Rate',
      value: mentorData?.rate?.amount
        ? `${mentorData?.rate?.amount}${
            getSymbolFromCurrency(mentorData?.rate?.currency ?? '') ?? '$'
          }/hour`
        : undefined,
    },
    {
      label: 'Pay Rate Currency',
      value: mentorData?.rate?.currency,
    },
    {
      label: 'Billing Currency',
      value: mentorData?.rate?.billingCurrency,
    },
    {
      label: 'VAT Payer',
      value: mentorData?.rate?.vatPayer ? 'Yes' : 'No',
    },
    {
      label: 'Company / Freelancer Name',
      value: mentorData?.company?.name,
    },
    {
      label: 'Company / Freelancer Address',
      value: mentorData?.company?.address,
    },
    {
      label: 'Fiscal registration number (or equivalent)',
      value: mentorData?.company?.CUI,
    },
    {
      label: 'IBAN',
      value: mentorData?.company?.IBAN,
    },
  ];

  return (
    <section className="flex flex-col gap-6">
      {paymentsInfo.map((info, index) => (
        <React.Fragment key={index}>
          <PaymentsTableItem
            label={info.label}
            value={info.value ?? UNCOMPLETED_STRING}
          />
          {index !== paymentsInfo.length - 1 && (
            <div className="w-full h-px bg-white" />
          )}
        </React.Fragment>
      ))}
    </section>
  );
};

export default PaymentsTabTable;
