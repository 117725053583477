import { FLAG_TYPES } from '@shared/constants';
import { useSearchFlagsForLinearChart } from '@shared/react';
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { COLORS } from '../../../v3/global';
import './flags-distribution-over-time.styles.scss';

type Props = {
  search: any;
  isActive: any;
  flagType: any;
  startRange: any;
  endRange: any;
  studentId: any;
  mentorId: any;
  courseId: any;
};

const FlagsDistributionOverTime = ({
  search,
  isActive,
  flagType,
  startRange,
  endRange,
  studentId,
  mentorId,
  courseId,
}: Props) => {
  const { data: linearChartFlags, refetch: refetchLinearChartFlags } =
    useSearchFlagsForLinearChart({
      search: search,
      isActive: isActive,
      flagType: flagType,
      startRange: startRange,
      endRange: endRange,
      courseId: courseId,
      studentId: studentId,
      mentorId: mentorId,
    });

  const flagTypes =
    linearChartFlags && linearChartFlags[0]
      ? Object.keys(linearChartFlags[0]).filter(
          (key) => key !== 'month' && key !== 'year'
        )
      : [];

  const lines = flagTypes.map((key, index) => (
    <Line
      key={key}
      type="monotone"
      dataKey={key}
      stroke={COLORS.FLAG_COLORS[index % COLORS.FLAG_COLORS.length]}
      activeDot={{ r: 8 }}
      dot={{ r: 8 }}
    />
  ));

  interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    label?: string | number | Date;
  }

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: '#B0B0B0',
            border: '1px solid #707070',
            width: '300px',
            padding: '16px 8px',
          }}
        >
          <p
            style={{
              color: COLORS.BLACK,
              marginBottom: '8px',
              textDecoration: 'underline',
            }}
            className="flags-distribution-tooltip-text"
          >
            {label}:
          </p>
          <div>
            {payload?.map((data) => (
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    minWidth: '12px',
                    maxWidth: '12px',
                    height: '12px',
                    backgroundColor: COLORS.FLAG_COLORS[data?.dataKey],
                    marginRight: '8px',
                  }}
                ></div>
                <p
                  style={{
                    color: COLORS.BLACK,
                    marginBottom: '8px',
                  }}
                  className="flags-distribution-tooltip-text"
                >
                  {FLAG_TYPES[data?.dataKey]}: {data?.value ?? 'N/A'}
                </p>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <div
        style={{
          width: '100%',
          height: '300px',
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={500} height={300} data={linearChartFlags}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip content={CustomTooltip} />
            {lines}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default FlagsDistributionOverTime;
