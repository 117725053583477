import {
  CoffeeOutlined,
  CommentOutlined,
  MailOutlined,
} from '@ant-design/icons';
import { SystemNotification } from '@shared/common';
import { useListMyNewNotifications, useReadNotification } from '@shared/react';
import styles from '@styles/components/notifications-card.module.scss';
import { Avatar, Button, Empty, Spin } from 'antd';
import moment from 'moment';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export const icons = {
  'direct-chat': <CommentOutlined className={styles.icon} />,
  'group-chat': <CoffeeOutlined className={styles.icon} />,
  default: <MailOutlined className={styles.icon} />,
};

const Wrapper = ({
  children,
  loading,
}: {
  loading?: boolean;
  children?: ReactNode;
}) => (
  <div className={styles.wrapper}>
    {loading ? (
      <div className={styles.loader}>
        <Spin />
      </div>
    ) : (
      children
    )}
  </div>
);

const NotificationsCard = ({
  onVisibleChange,
}: {
  onVisibleChange: (arg: boolean) => void;
}) => {
  const { data } = useListMyNewNotifications();
  const { mutate: readNotification } = useReadNotification();

  if (!data) return <Wrapper loading />;

  if (!data.length)
    return (
      <Wrapper>
        <Empty description="No new notifications" />{' '}
      </Wrapper>
    );

  return (
    <Wrapper>
      <div>
        {data?.slice(0, 3).map((item) => (
          <NotificationItem
            key={item._id}
            item={item}
            readNotification={() =>
              readNotification({ notificationId: item._id })
            }
          />
        ))}
        {data.length > 3 && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link
              to="/notifications"
              className={`${styles.notification} text-center`}
            >
              <div onClick={() => onVisibleChange(false)}>
                See all notifications
              </div>
            </Link>
            <Button
              type="text"
              onClick={() => {
                data.forEach((item) =>
                  readNotification({ notificationId: item._id })
                );
              }}
            >
              Clear All Notifications
            </Button>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export const NotificationItem = ({
  item,
  readNotification,
}: {
  item: SystemNotification;
  readNotification: () => void;
}) => {
  const listItem = (
    <div className={styles.notification} onClick={readNotification}>
      <Avatar
        icon={icons[item.type]}
        size="large"
        className={styles.notificationAvatar}
      />

      <div>
        <div>{item.description}</div>
        <div className={styles.notificationInfo}>
          {icons[item.type]}
          {item.title}
        </div>
      </div>

      <div className={styles.notificationDate}>
        {moment(item.createdAt).fromNow()}
      </div>
    </div>
  );

  if (item.link.startsWith('http')) {
    return (
      <a
        href={item.link}
        target="_blank"
        rel="noreferrer"
        style={{ position: 'relative' }}
      >
        {listItem}
      </a>
    );
  }

  return <Link to={item.link}>{listItem}</Link>;
};

export default NotificationsCard;
