import { Select } from 'antd';
import { CSSProperties } from 'react';

type Props = {
  value?: string[];
  onChange?: (value: string[]) => void;
  style?: CSSProperties;
};

const CountriesSelector = ({ value, onChange, style }: Props) => {
  return (
    <Select
      mode="multiple"
      allowClear
      placeholder="Countries helping with"
      value={value}
      style={style || { width: '100%' }}
      onChange={onChange}
    >
      <Select.Option value="US">US</Select.Option>
      <Select.Option value="UK">UK</Select.Option>
      <Select.Option value="EU">EU</Select.Option>
    </Select>
  );
};

export default CountriesSelector;
