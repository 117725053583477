import { SimpleQuiz as SimpleQuizType } from '@shared/types';
import { Button, message } from 'antd';
import { useEffect, useState } from 'react';
import { useLessonActions } from './LessonContext';

type Props = {
  quiz: SimpleQuizType['data'];
};

const SimpleQuiz = ({ quiz }: Props) => {
  const { nextSlide } = useLessonActions();

  const [selected, setSelected] = useState<{ [key: number]: boolean }>({});

  if (quiz.code)
    return (
      <p>
        The code{' '}
        <code style={{ backgroundColor: 'lightgray' }}>{quiz.code}</code> is
        invalid
      </p>
    );

  const isCorrect = (index: number) => index < quiz.correctAnswers;

  const correctAnswer = () => {
    message.success("That's correct!");
    nextSlide();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const wrongAnswer = () => {
    message.warning('Not quite, try again!');
  };

  const toggle = (index: number) => {
    if (quiz.correctAnswers === 1) {
      isCorrect(index) ? correctAnswer() : wrongAnswer();
      return;
    }

    if (selected[index]) {
      const newSelected = { ...selected };
      delete newSelected[index];
      setSelected(newSelected);
    } else setSelected({ ...selected, [index]: true });
  };

  const submit = () => {
    const selectedKeys = Object.keys(selected);
    const indexes = selectedKeys.map((val) => parseInt(val));
    const correct = indexes.every(isCorrect);

    correct ? correctAnswer() : wrongAnswer();
  };

  const isSelected = (index: number) => !!selected[index];

  return (
    <SimpleQuizBody
      quiz={quiz}
      toggle={toggle}
      isSelected={isSelected}
      submit={submit}
    />
  );
};

type BodyProps = {
  quiz: SimpleQuizType['data'];
  toggle?: (index: number) => void;
  isSelected?: (index: number) => boolean;
  submit?: () => void;
};

export const SimpleQuizBody = ({
  quiz,
  toggle,
  isSelected,
  submit,
}: BodyProps) => {
  if (!submit) submit = () => {};
  const range = [...Array(quiz.choices.length).keys()];
  const [shuffledOrder, setShuffleOrder] = useState<number[]>();

  useEffect(() => {
    setShuffleOrder(range.sort(() => 0.5 - Math.random()));
  }, []);

  if (quiz.code)
    return (
      <p>
        The code{' '}
        <code style={{ backgroundColor: 'lightgray' }}>{quiz.code}</code> is
        invalid
      </p>
    );

  const choices = quiz.choices.map((choice, index) => {
    return (
      <Button
        key={choice}
        onClick={() => toggle && toggle(index)}
        type={isSelected && isSelected(index) ? 'primary' : undefined}
        style={{
          width: '100%',
          marginBottom: 10,
          whiteSpace: 'inherit',
          minHeight: '32px',
          height: 'auto',
        }}
      >
        {choice}
      </Button>
    );
  });

  return (
    <div style={{ textAlign: 'center' }}>
      <h4>{quiz.title}</h4>
      {shuffledOrder && shuffledOrder.map((index) => choices[index])}
      {quiz.correctAnswers > 1 && (
        <Button type="primary" onClick={submit}>
          Submit
        </Button>
      )}
    </div>
  );
};

export default SimpleQuiz;
