import { useAuthState } from '@shared/react';
import styles from '@styles/components/header.module.scss';
import { Link } from 'react-router-dom';

const DriveButton = () => {
  const { googleDrive } = useAuthState();

  return (
    <Link
      className={styles.driveButtonContainer}
      to={{
        pathname: `https://drive.google.com/drive/folders/${googleDrive}`,
      }}
      target="_blank"
    >
      <div className={styles.driveButton}>
        <div className={styles.driveGradient}></div>
        <div className={styles.driveIcon}>
          <img src="../../assets/icons/google-drive.svg" alt="drive" />
        </div>
        <div className={styles.driveText}>My Files Drive</div>
      </div>
    </Link>
  );
};

export default DriveButton;
