import { ApiDescription, ApiData, RequestsMethod } from '../types';

export const testParentFeedbackEmail: ApiDescription = {
  method: RequestsMethod.Get,
  endpoint: '/testing/parent-feedback-email',
};
export type TestParentFeedbackEmail = ApiData<
  { studentEmail: string; startDate: string; endDate: string },
  unknown
>;

export const testMentorRatingEmail: ApiDescription = {
  method: RequestsMethod.Get,
  endpoint: '/testing/mentor-rating-email',
};
export type TestMentorRatingEmail = ApiData<{ mentorEmail: string }, unknown>;
