import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import styles from '@styles/components/buttons-v3.module.scss';
import classNames from 'classnames';
import { ButtonHTMLAttributes, ReactNode, useState } from 'react';
import { COLORS } from '../../../v3/global';

type Props = {
  buttonText?: string | ReactNode;
  icon?: string | ReactNode;
  disabled?: boolean;
  disabledIcon?: string | ReactNode;
  className?: string;
  isLoading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const PrimaryButtonV3 = ({
  buttonText,
  icon,
  disabled,
  disabledIcon,
  className,
  isLoading = false,
  style,
  ...buttonProps
}: Props) => {
  const [hovered, setHovered] = useState(false);

  return (
    <button
      {...buttonProps}
      style={{
        backgroundColor: disabled
          ? COLORS.SURFACE_BACKGROUND
          : hovered
          ? COLORS.HOVER_BACKGROUND
          : COLORS.WHITE,
        color: COLORS.DARK_WHITE,
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...style,
      }}
      disabled={disabled}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      type="button"
      className={classNames(`${styles.buttonV3}`, 'button-V3', className)}
    >
      <div className={styles.buttonV3Content}>
        {disabled && disabledIcon ? (
          typeof disabledIcon === 'string' ? (
            <img src={disabledIcon} className={styles.buttonV3CustomIcon} />
          ) : (
            <span className={styles.buttonV3AntIcon}>{disabledIcon}</span>
          )
        ) : icon ? (
          typeof icon === 'string' ? (
            <img src={icon} className={styles.buttonV3CustomIcon} />
          ) : (
            <span className={styles.buttonV3AntIcon}>{icon}</span>
          )
        ) : null}
        <span
          style={{ color: disabled ? COLORS.TEXT_SECONDARY : COLORS.BLACK }}
          className={styles.buttonV3Text}
        >
          {isLoading ? <LoadingSpinner /> : buttonText}
        </span>
      </div>
    </button>
  );
};

export default PrimaryButtonV3;
