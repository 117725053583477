import {
  ApiDescription,
  ApiData,
  RequestsMethod,
  ModuleFeedback,
} from '../common';

interface ModuleFeedbackResponse {
  current: number;
  support: number;
}

export const submitModuleFeedback: ApiDescription = {
  endpoint: '/feedback/module',
  method: RequestsMethod.Post,
};
export type SubmitModuleFeedback = ApiData<
  Partial<ModuleFeedback>,
  ModuleFeedbackResponse
>;

export const getModuleFeedback: ApiDescription = {
  endpoint: '/feedback/module',
  method: RequestsMethod.Get,
};

export type GetModuleFeedback = ApiData<
  Partial<ModuleFeedback>,
  ModuleFeedbackResponse
>;
