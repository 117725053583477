import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';
import UniversitiesSection from './UniversitiesSection/UniversitiesSection';

interface GoalsTabProps {
  userId: string;
  userProfile: Partial<UserProfileDTO>;
  isMentorViewer: boolean;
}

const GoalsTab = (props: GoalsTabProps) => {
  const { userId, userProfile, isMentorViewer } = props;

  return (
    <section className="flex flex-col gap-4">
      <UniversitiesSection
        isMentorViewer={isMentorViewer}
        userId={userId}
        userProfile={userProfile}
      />
    </section>
  );
};

export default GoalsTab;
