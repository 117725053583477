import { useContext, useMemo } from 'react';
import { StudentDetailsPageContext } from '../../StudentDetailsPage';
import SchoolSection from './SchoolSection/SchoolSection';
import UniversitySection from './UniversitySection/UniversitySection';
import WorkExperienceSection from './WorkExperienceSection/WorkExperienceSection';

const ResumeTab = () => {
  const { student } = useContext(StudentDetailsPageContext);

  const { schools, universities } = useMemo(() => {
    const schools: any[] = [];
    const universities: any[] = [];

    student?.educationalBackground?.forEach((educationItem) => {
      const institutionType = educationItem.educationalInstitution.type;

      if (institutionType === 'University') {
        universities.push(educationItem);
      } else if (institutionType === 'School') {
        schools.push(educationItem);
      }
    });

    return { schools, universities };
  }, [student?.educationalBackground]);

  return (
    <>
      <WorkExperienceSection experiences={student?.experiences} />
      <UniversitySection universities={universities} />
      <SchoolSection schools={schools} />
    </>
  );
};

export default ResumeTab;
