import arrowDown from '@assets/icons/input-arrow-expand.svg';
import { MentoringStyleType, SelectOption } from '@shared/common';
import { useMentors } from '@shared/react';
import { getRoundedNumber } from '@shared/utils/number';
import { Select } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import './agora-mentor-selector.styles.scss';
import AgoraInputError from '../AgoraInputError';
import AgoraInputLabel from '../AgoraInputLabel';

type Choice = {
  label: string;
  value: 'USER' | 'TBD' | 'ALGO' | 'TOP';
};

export type MentorSelectorValue = {
  choice?: Choice;
  user?: SelectOption;
  interests?: string[];
  mentoringStyle?: MentoringStyleType;
  coursesCanTeach?: string;
};

type Props = {
  value?: MentorSelectorValue;
  disabled?: boolean;
  onChange?: (val: MentorSelectorValue) => void;
  courseId?: string;
  className?: string;
  isValid?: boolean;
  isTouched?: boolean;
  isRequired?: boolean;
  label?: string;
  errorText?: string;
};

const NON_USER_CHOICES = ['TBD', 'ALGO', 'TOP'];

const MentorSelector = ({
  value,
  onChange,
  courseId,
  disabled = false,
  isValid,
  isTouched,
  className,
  isRequired,
  label,
  errorText,
}: Props) => {
  const [internalValue, setInternalValue] = useState<MentorSelectorValue>(
    value || {}
  );

  useEffect(() => {
    if (value) setInternalValue(value);
  }, [value]);

  const setValues = (val: MentorSelectorValue) => {
    if (onChange) onChange(val);
    if (!value) setInternalValue(val);
  };

  const { data: mentorsData, isLoading: mentorsLoading } = useMentors({
    isDezactivated: 'unchecked',
  });
  const options = (mentorsData?.users || []).map((mentor) => {
    const { mentorData } = mentor;
    const score = mentorData.averageFeedback
      ? getRoundedNumber(mentorData.averageFeedback)
      : '';
    return {
      value: mentor._id,
      label: `${mentor.fullName} ${score}`,
    };
  });

  const choice = internalValue?.choice?.value || '';

  options.unshift({
    value: 'TBD',
    label: 'TBD',
  });

  return (
    <div
      className={classNames(
        'agora-mentor-selector',
        className,
        isValid && isTouched
          ? 'agora-mentor-selector--valid'
          : !isValid && isTouched
          ? 'agora-mentor-selector--error'
          : ''
      )}
    >
      {label && (
        <AgoraInputLabel
          label={label}
          isRequired={isRequired}
          isTouched={isTouched}
          isValid={isValid}
        />
      )}

      <Select
        //   onFocus={() => setFocused(true)}
        //   onBlur={() => setFocused(false)}
        labelInValue
        showSearch
        disabled={disabled}
        optionFilterProp="label"
        options={options}
        loading={mentorsLoading}
        value={choice === 'USER' ? internalValue.user : internalValue.choice}
        showArrow={false}
        suffixIcon={
          <img
            className="placeholder-image"
            src={arrowDown}
            alt="Click to interact with the selector."
          />
        }
        onChange={(val) => {
          const newVal = { ...internalValue };
          if (NON_USER_CHOICES.includes(val.value)) {
            newVal.choice = val as Choice;
            newVal.user = undefined;
          } else {
            newVal.user = val;
            newVal.choice = { value: 'USER' } as Choice;
          }

          setValues(newVal);
        }}
      />

      {!isValid && isTouched && errorText && (
        <AgoraInputError errorText={errorText} />
      )}
    </div>
  );
};

export default MentorSelector;
