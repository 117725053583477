import { checklistApis } from '../../apis';
import {
  genericMutation,
  genericMutationWithId,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useChecklistTemplates =
  genericQueryWithParams<checklistApis.GetAllChecklistTemplates>(
    checklistApis.getAllChecklistTemplates
  );

export const useChecklistTemplatesForEnrollment =
  genericQueryWithId<checklistApis.GetAllChecklistTemplatesForEnrollment>(
    checklistApis.getAllChecklistTemplatesForEnrollment
  );

export const useImportTemplate =
  genericMutationWithId<checklistApis.ImportChecklist>(
    checklistApis.importChecklist
  );

export const useDeleteChecklistTemplate =
  genericMutation<checklistApis.DeleteChecklistTemplate>(
    checklistApis.deleteChecklistTemplate
  );

export const useChecklistForUser =
  genericQueryWithId<checklistApis.GetChecklistArrayForUser>(
    checklistApis.getChecklistArrayForUser
  );

export const useChecklistById = genericQueryWithId<checklistApis.GetChecklist>(
  checklistApis.getChecklist
);

export const useStudentChecklistArrayForMentor =
  genericQueryWithId<checklistApis.GetAllStudentsChecklistArrayForMentor>(
    checklistApis.getAllStudentsChecklistArrayForMentor
  );

export const useToggleChecklistMilestoneStatus =
  genericMutationWithId<checklistApis.ToggleChecklistItemChecked>(
    checklistApis.toggleChecklistItemChecked
  );

export const useUpdateChecklist =
  genericMutationWithId<checklistApis.UpdateChecklist>(
    checklistApis.updateChecklist
  );

export const useCreateMilestone =
  genericMutationWithId<checklistApis.CreateMilestone>(
    checklistApis.createMilestone
  );

export const useDeleteMilestone =
  genericMutationWithId<checklistApis.DeleteMilestone>(
    checklistApis.deleteMilestone
  );
