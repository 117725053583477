import axios from 'axios';

import { RequestsMethod } from '../../types';
import { useUrlState } from '../contexts';
import useHeaders from './useHeaders';

function useAuthAxios() {
  const headers = useHeaders();
  const { apiUrl } = useUrlState();

  const actionCreator = <Payload, Response>(
    endpoint: string,
    method: RequestsMethod = RequestsMethod.Post
  ) => {
    return async (data: Payload) => {
      const response = await axios({
        url: `${apiUrl}${endpoint}`,
        method,
        headers,
        data,
      });

      return response.data as Response;
    };
  };

  return [actionCreator];
}

export default useAuthAxios;
