import Button from '@components/V4/Button';
import TextArea from '@components/V4/Inputs/Textarea';
import Modal from '@components/V4/Modal/Modal';
import { PaginationContext } from '@components/V4/Modal/MultiPageModal/MultiPageModal';
import StarRating from '@components/V4/StarRating/StarRating';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import AIGeneratedFeedbackToggle from '../AIGeneratedFeedbackToggle';

export type Progress = {
  rating: number;
  feedback: string;
};

export const progressSchema = z.object({
  rating: z
    .number({ message: 'Please give a rating' })
    .min(1, 'Please give a rating'),
  feedback: z
    .string()
    .min(100, { message: 'Please enter at least 100 characters' }),
});

export type ProgressFormData = z.infer<typeof progressSchema>;

interface ProgressPageProps extends BasicProps {
  isAIFeedbackReady: boolean;
  didAIFeedbackGenerationFail?: boolean;
  isFeedbackAIGenerationEnabled: boolean;
  progressFormData: UseFormReturn<ProgressFormData, any, undefined>;
  onNextClick: (progress: Progress) => void;
  onIsFeedbackAIGenerationEnabledToggle: (isAIGenerated: boolean) => void;
}

const ProgressPage = (props: ProgressPageProps) => {
  const {
    isAIFeedbackReady,
    progressFormData,
    didAIFeedbackGenerationFail,
    isFeedbackAIGenerationEnabled,
    onNextClick,
    onIsFeedbackAIGenerationEnabledToggle,
  } = props;
  const { goBack, goToNextPage } = useContext(PaginationContext);

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    formState: { errors, touchedFields },
  } = progressFormData;

  const touchAllFields = (
    fields: Record<keyof ProgressFormData, string | number>
  ) => {
    Object.keys(fields).forEach((key) => {
      setValue(
        key as keyof ProgressFormData,
        getValues()[key as keyof ProgressFormData],
        {
          shouldTouch: true,
          shouldValidate: true,
        }
      );
    });
  };

  const onSubmit = async (data: ProgressFormData) => {
    const isValid = await trigger();
    if (isValid) {
      goToNextPage();
      onNextClick(data);
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  return (
    <Modal.Body className="tablet:px-0 gap-6">
      <div className="flex flex-col gap-2 tablet:gap-0 tablet:flex-row tablet:justify-between tablet:items-center">
        <h3 className="text-customGrey text-2xl font-semibold">Progress</h3>
        <AIGeneratedFeedbackToggle
          didAIFeedbackGenerationFail={didAIFeedbackGenerationFail}
          isDisabled={!isAIFeedbackReady}
          isChecked={isFeedbackAIGenerationEnabled && isAIFeedbackReady}
          onClick={() =>
            onIsFeedbackAIGenerationEnabledToggle(
              !isFeedbackAIGenerationEnabled
            )
          }
        />
      </div>
      <div>
        <StarRating
          {...register('rating')}
          value={getValues().rating || 0}
          onChange={(value) =>
            setValue('rating', value, { shouldValidate: true })
          }
          isTouched={touchedFields.rating}
          isValid={!errors.rating}
          errorText={errors.rating?.message}
        />
      </div>

      <div className="flex flex-col gap-2">
        <TextArea
          isRequired
          className="text-customGrey"
          id="nextTopics"
          label="Progress Feedback"
          isTouched={touchedFields.feedback}
          isValid={!errors.feedback}
          errorText={errors.feedback?.message}
          {...register('feedback')}
        />
        {isAIFeedbackReady && (
          <span className="text-xsm text-customRed">
            This feature is in beta, so please review and adjust the feedback to
            ensure accuracy.
          </span>
        )}
      </div>

      <div className="flex justify-end gap-4">
        <Button
          variant="secondary"
          className="w-24"
          size="large"
          buttonText="Back"
          onClick={goBack}
        />
        <Button
          className="w-24"
          size="large"
          buttonText="Next"
          onClick={submitHandler}
        />
      </div>
    </Modal.Body>
  );
};

export default ProgressPage;
