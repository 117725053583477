import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

const Loading: React.FC = () => {
  return (
    <div style={{ display: 'grid', placeItems: 'center' }}>
      <LoadingOutlined style={{ fontSize: 50 }} spin />
    </div>
  );
};

export default Loading;
