import styles from '@styles/components/section-header.module.scss';
import { ReactNode } from 'react';
import { COLORS } from '../../v3/global';

const SectionHeader = ({
  title,
  subtitle,
  children,
}: {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
}) => {
  return (
    <div style={{ color: COLORS.TEXT_PRIMARY }} className={styles.header}>
      <div className={styles.headerInfo}>
        <p className={styles.title}>{title}</p>
        {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      </div>

      {children && <div className={styles.action}>{children}</div>}
    </div>
  );
};

export default SectionHeader;
