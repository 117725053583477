import { enrollmentsApis, meetingsApis } from '../../apis';
import {
  genericMutation,
  genericMutationWithId,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useRescheduleMeeting =
  genericMutationWithId<meetingsApis.RescheduleMeeting>(
    meetingsApis.rescheduleMeeting,
    meetingsApis.searchMyMeetings.endpoint
  );

export const useMyMeetings =
  genericQueryWithParams<meetingsApis.SearchMyMeetings>(
    meetingsApis.searchMyMeetings
  );

export const useMentorStudentMeetings =
  genericQueryWithParams<meetingsApis.GetMentorStudentMeetings>(
    meetingsApis.getMentorStudentMeetings
  );

export const useAllMyMeetings = () => {
  return useMyMeetings(
    { status: 'all', limit: 1000 },
    { refetchInterval: 5000 }
  );
};

export const useFeedbackMeetings = () => {
  return useMyMeetings(
    {
      status: 'feedback',
    },
    { refetchInterval: 5000 }
  );
};

export const useUpcomingMeetings = () => {
  return useMyMeetings(
    {
      status: 'upcoming',
    },
    { refetchInterval: 5000 }
  );
};

export const useOngoingMeetings = () => {
  return useMyMeetings(
    {
      status: 'started',
    },
    { refetchInterval: 5000 }
  );
};

export const useMeeting = genericQueryWithId<meetingsApis.GetMeeting>(
  meetingsApis.getMeeting
);

export const useSearchAllMeetings =
  genericQueryWithParams<meetingsApis.SearchAllMeetings>(
    meetingsApis.searchAllMeetings
  );

export const useDeleteMeeting =
  genericMutationWithId<meetingsApis.DeleteMeeting>(meetingsApis.deleteMeeting);

export const useGetAIGeneratedFeedback =
  genericQueryWithId<meetingsApis.GetAIGeneratedFeedback>(
    meetingsApis.getAIGeneratedFeedback
  );

export const useRegisterMentorFeedback =
  genericMutationWithId<meetingsApis.RegisterMentorFeedback>(
    meetingsApis.registerMentorFeedback,
    enrollmentsApis.searchSessionHistory.endpoint,
    meetingsApis.noOfCompletedSessionsCurrentMonth.endpoint
  );

export const useRegisterStudentFeedback =
  genericMutationWithId<meetingsApis.RegisterStudentFeedback>(
    meetingsApis.registerStudentFeedback
  );

export const useUpdateMeeting =
  genericMutationWithId<meetingsApis.UpdateMeeting>(
    meetingsApis.updateMeeting,
    meetingsApis.getMeeting.endpoint
  );

export const useCreateMeeting = genericMutation<meetingsApis.CreateMeeting>(
  meetingsApis.createMeeting,
  enrollmentsApis.getSessionDetails.endpoint,
  enrollmentsApis.searchMentorEnrollments.endpoint
);

export const useMentorStatistics =
  genericQueryWithId<meetingsApis.GetMentorStatisticsByFeedback>(
    meetingsApis.getMentorStatisticsByFeedback
  );

export const useMentorSessionNo =
  genericQueryWithId<meetingsApis.NoOfCompletedSessionsCurrentMonth>(
    meetingsApis.noOfCompletedSessionsCurrentMonth
  );

export const useGetMeetingNotes =
  genericQueryWithId<meetingsApis.GetMeetingNotes>(
    meetingsApis.getMeetingNotes
  );

export const useUpdateMeetingNotes =
  genericMutationWithId<meetingsApis.UpdateMeetingNotes>(
    meetingsApis.updateMeetingNotes,
    meetingsApis.getMeetingNotes.endpoint,
    enrollmentsApis.getSessionDetails.endpoint
  );
