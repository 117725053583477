import EditProfileDetailsPage from '@components/V3/ProfileModalUsers/EditProfileDetailsPage';
import ProfilePageUsers from '@components/V3/ProfileModalUsers/ProfilePageUsers';
import AgoraModal from '@components/V3/Utils/AgoraModal';
import { useGetUserProfile } from '@shared/react';
import React, { useState } from 'react';
import './profile-modal-users.styles.scss';

type Props = {
  showModal: boolean;
  handleShowModal: () => void;
  userData?: any;
  refetchUserData: () => void;
};

const ProfileModalUsers = ({
  showModal,
  handleShowModal,
  userData,
  refetchUserData,
}: Props) => {
  const [page, setPage] = useState(1);
  const [isEnded, setIsEnded] = useState(false);

  const [currentPage, setCurrentPage] = useState('user-profile');

  const handleCurrentPage = (page: string) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <AgoraModal
        backButton={currentPage === 'edit-profile-details'}
        onBack={
          currentPage === 'edit-profile-details'
            ? handleCurrentPage
            : () => null
        }
        backRoute="user-profile"
        title={
          currentPage === 'user-profile'
            ? `View User Profile`
            : currentPage === 'edit-profile-details'
            ? 'Edit User Profile'
            : ''
        }
        title2={currentPage === 'user-profile' ? `${userData?.fullName}` : ''}
        setModalOpen={() => handleShowModal()}
        isModalOpen={showModal}
        closable={true}
        width="58vw"
      >
        {currentPage === 'user-profile' ? (
          <ProfilePageUsers
            userId={userData?._id}
            userData={userData}
            refetchUserData={refetchUserData}
            handleCurrentPage={handleCurrentPage}
          />
        ) : currentPage === 'edit-profile-details' ? (
          <EditProfileDetailsPage
            handleCurrentPage={handleCurrentPage}
            userId={userData?._id}
            userData={userData}
            refetchUserData={refetchUserData}
          />
        ) : null}
      </AgoraModal>
    </div>
  );
};

export default ProfileModalUsers;
