import { CloseModalProps } from '@shared/types';
import { Modal } from 'antd';
import { COLORS } from 'apps/agora/src/v3/global';
import React from 'react';
import EnrollmentDetails from './EnrollmentDetails';

type Props = CloseModalProps & {
  userId: string;
};

const EnrollmentModal: React.FC<Props> = ({ visible, hide, userId }) => {
  return (
    <Modal
      visible={visible}
      onCancel={hide}
      footer={false}
      width={900}
      bodyStyle={{ padding: 0, backgroundColor: COLORS.SECTION_BACKGROUND }}
    >
      <EnrollmentDetails userId={userId} />
    </Modal>
  );
};
export default EnrollmentModal;
