import { lmsApis } from '@shared/apis';
import { genericMutation, genericQuery } from './generics';

export const useLMSCourses = genericQuery<lmsApis.GetAllLMSCourses>(
  lmsApis.getAllLMSCourses
);

export const useLoginAndRedirectToCourse =
  genericMutation<lmsApis.LoginAndRedirectToCourse>(
    lmsApis.loginAndRedirectToCourse
  );
