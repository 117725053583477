import Button from '@components/V4/Button';
import Modal from '@components/V4/Modal/Modal';

type MeetingStatusModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onStudentCancelledClick: () => void;
  onStudentNoShowClick: () => void;
};

const MeetingStatusModal = (props: MeetingStatusModalProps) => {
  const { isOpen, onClose, onStudentCancelledClick, onStudentNoShowClick } =
    props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small">
      <Modal.Header title="Meeting Status" className="text-center" />
      <Modal.Body className="flex flex-col justify-center items-center overflow-x-hidden mb-6 text-xs leading-5 text-center">
        <p>Please let us know what happened.</p>
        <p>Did the student request to cancel, or did they not show up?</p>
      </Modal.Body>
      <Modal.Footer className="flex justify-center gap-4">
        <Button
          className="w-[180px]"
          variant="secondary"
          buttonText="The student asked to cancel"
          onClick={onStudentCancelledClick}
        />
        <Button
          className="w-[180px]"
          variant="secondary"
          buttonText="The student didn't show up"
          onClick={onStudentNoShowClick}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default MeetingStatusModal;
