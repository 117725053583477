import { PlusOutlined } from '@ant-design/icons';
import { NewUserModal } from '@components';
import { PrimaryButton } from '@components/Common/Buttons';
import SectionHeader from '@components/Common/SectionHeader';
import { useModal } from '@hooks';
import { RoleType, STUDENT_ROLE, USER_ROLE } from '@shared/constants';
import styles from '@styles/pages/admin/all-users.module.scss';
import { useLayoutEffect, useState } from 'react';
import UsersFilters from './UsersFilters';
import UsersTable from './UsersTable';

export type UserSearchParams = {
  search: string;
  roles: RoleType[];
  graduationYear?: number;
  courseId?: string;
  mentorId?: string;
  sortBy?: 'createdAt' | 'avgSatisfaction' | 'courseStartDate';
  sortDirection?: 'asc' | 'desc';
  isTakingCourse?: boolean;
  isDezactivated?: string;
  isTestUser?: string;
};

const TABLE_HEADER = 635;
const MIN_HEIGHT = 250;

export const initialSearchParams: UserSearchParams = {
  search: '',
  sortDirection: 'desc',
  roles: [USER_ROLE, STUDENT_ROLE],
  isTestUser: 'false',
};

const ManageUsersPage = () => {
  const [searchParams, setSearchParams] =
    useState<UserSearchParams>(initialSearchParams);

  const [tableHeight, setTableHeight] = useState(
    Math.max(window.innerHeight - TABLE_HEADER, MIN_HEIGHT)
  );

  useLayoutEffect(() => {
    const listener = () => {
      const height = window.innerHeight - TABLE_HEADER;
      setTableHeight(Math.max(height, MIN_HEIGHT));
    };
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  const [NewUser, showNewUser] = useModal(NewUserModal);

  return (
    <div className={styles.page}>
      {NewUser}

      <p style={{ color: '#000000', fontSize: '24px' }}>All Users</p>
      <SectionHeader>
        <PrimaryButton onClick={showNewUser}>
          <PlusOutlined /> Add new
        </PrimaryButton>
      </SectionHeader>

      <UsersFilters
        searchParams={searchParams}
        updateSearchParams={(data) =>
          setSearchParams({ ...searchParams, ...data })
        }
      />

      <UsersTable searchParams={searchParams} tableHeight={tableHeight} />
    </div>
  );
};

export default ManageUsersPage;
