import FormInput from '@components/Form/FormInput';
import { userManagementApis } from '@shared/apis';
import { mailTemplates } from '@shared/utils';
import styles from '@styles/components/sales-onboarding-plan.module.scss';
import { Input, Button, message } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import authHeader from '../../services/auth-header';

const ActivateAccountForm = ({ initialEmail }: { initialEmail?: string }) => {
  const [id, setId] = useState('');
  const [registrationToken, setRegistrationToken] = useState('');
  const [email, setEmail] = useState(initialEmail || '');
  const [isEmailLoading, setIsEmailLoading] = useState(false);

  const generateLink = async () => {
    axios
      .get('/api/auth/get-user-invitation', {
        params: { email },
        headers: authHeader(),
      })
      .then((response) => {
        setId(response.data.id);
        setRegistrationToken(response.data.registrationToken);
        (!response.data.registrationToken || !response.data.id) &&
          message.error(`Could not generate activation link`);
      })
      .catch((err) => {
        message.error(err.response?.data?.message || err.message);
      });
  };

  const sendEmail = async () => {
    setIsEmailLoading(true);
    try {
      await axios.post(
        `/api${userManagementApis.sendUserEmail.endpoint}`,
        {
          to: email,
          subject: 'Welcome to Upgrade Education Agora!',
          text: mailTemplates.welcomeStudentEmail({
            userId: id,
            registrationToken,
          }),
        },
        { headers: authHeader() }
      );
      message.success('Email sent successfully!');
    } catch (err) {
      message.error(`The message wasn't sent. Please try again later.`);
      console.error(err);
    } finally {
      setIsEmailLoading(false);
    }
  };

  return (
    <>
      <FormInput
        value={email}
        placeholder="E-mail address"
        onChange={(e) => setEmail(e.target.value.toLowerCase())}
      />

      <button
        className={styles.button}
        onClick={generateLink}
        style={{ marginTop: '1rem' }}
        type="button"
      >
        Generate Activation Link
      </button>

      {id?.length > 0 && registrationToken?.length > 0 && (
        <>
          <p style={{ marginTop: 20 }}>Send them this link:</p>
          <Input
            value={mailTemplates.welcomeStudentEmail.link({
              userId: id,
              registrationToken,
            })}
          />

          <p style={{ marginTop: 20 }}>
            Or send them an email with this content:
          </p>
          <p
            style={{ backgroundColor: '#f2f2f2', padding: '10px' }}
            dangerouslySetInnerHTML={{
              __html: mailTemplates.welcomeStudentEmail({
                userId: id,
                registrationToken,
              }),
            }}
          ></p>
          <Button
            type="primary"
            style={{ marginTop: 10 }}
            onClick={sendEmail}
            loading={isEmailLoading}
          >
            Send Email
          </Button>
        </>
      )}
    </>
  );
};

export default ActivateAccountForm;
