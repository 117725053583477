import { ReactComponent as DriveIcon } from '@assets/icons/V4/drive-icon.svg';
import { ReactComponent as ChatIcon } from '@assets/icons/video-icons/chat-icon.svg';
import { ReactComponent as MeetingNotesIcon } from '@assets/icons/video-icons/meeting-notes-icon.svg';
import { ReactComponent as ParticipantsIcon } from '@assets/icons/video-icons/participants-icon.svg';
import { ReactComponent as WifiIcon } from '@assets/icons/video-icons/wifi.svg';
import IconButton from '@components/V4/IconButton';
import Tooltip from '@components/V4/Tooltip';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import { PageNameType } from '@modules/MeetingVideo/types/PageNameType';
import { useAuthState } from '@shared/react';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  className?: string;
  activeButton?: PageNameType;
  isMentor: boolean;
  unreadCount: number;
  isNetworkWarning?: boolean;
  handleShowSidebar: (pageName: PageNameType) => void;
  setActiveButton: (pageName: PageNameType) => void;
  handleCloseSidebar: () => void;
};

const MeetingActionButtons = (props: Props) => {
  const {
    className,
    activeButton,
    unreadCount,
    isNetworkWarning,
    handleShowSidebar,
    setActiveButton,
    handleCloseSidebar,
  } = props;

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { meetingDetails } = useContext(ZoomContext);
  const { googleDrive } = meetingDetails || {};
  const { isMentor } = useAuthState();

  const videoActionButtons = [
    {
      icon: <ChatIcon className="w-full h-full" />,
      name: 'chat',
      tooltipText: {
        active: 'Hide Chat',
        inactive: 'Chat',
      },
      hasWarning: false,
    },
    {
      icon: <MeetingNotesIcon className="w-full h-full" />,
      name: 'notes',
      tooltipText: {
        active: 'Hide Notes',
        inactive: 'Notes',
      },
    },
    {
      icon: <ParticipantsIcon className="w-full h-full" />,
      tooltipText: {
        active: 'Hide Participants List',
        inactive: 'Participants List',
      },
      name: 'participants',
    },
    {
      icon: <DriveIcon className="w-full h-full" />,
      name: 'studentDrive',
      href: `https://drive.google.com/drive/folders/${googleDrive}`,
      tooltipText: {
        inactive: isMentor ? 'Open Student Drive' : 'Open Drive',
      },
    },
  ];

  if (params.get('inMeeting') === 'true') {
    videoActionButtons.unshift({
      icon: <WifiIcon className="w-6 h-6" />,
      name: 'connection',
      tooltipText: {
        active: 'Hide Connection Quality',
        inactive: 'Connection Quality',
      },
      hasWarning: !!isNetworkWarning,
    });
  }

  const [readCount, setReadCount] = useState<number>(0);

  useEffect(() => {
    if (activeButton === 'chat') {
      setReadCount((prev) => (prev = unreadCount));
    }
  }, [unreadCount, activeButton]);

  const handleClick = (buttonName: PageNameType) => {
    if (activeButton === buttonName) {
      handleCloseSidebar();
    } else {
      setActiveButton(buttonName);
      handleShowSidebar(buttonName);
    }
  };

  return (
    <div className={mergeClassNames('gap-6 flex laptop:block', className)}>
      {videoActionButtons.map((button, index) => (
        <Tooltip
          text={
            activeButton === button.name
              ? button.tooltipText?.active
              : button.tooltipText?.inactive
          }
          placement="top"
          className="relative"
          key={index}
        >
          {button.name === 'studentDrive' ? (
            !!googleDrive && (
              <a target="_blank" rel="noreferrer" href={button.href}>
                <IconButton
                  className="relative w-8 h-8 tablet:w-10 tablet:h-10"
                  icon={button.icon}
                  variant="secondary"
                  size="large"
                />
              </a>
            )
          ) : (
            <IconButton
              className="relative w-8 h-8 tablet:w-10 tablet:h-10"
              icon={button.icon}
              isActive={button.name === activeButton}
              variant="secondary"
              size="large"
              onClick={() => handleClick(button.name as PageNameType)}
              popupText={
                button.name === 'chat' &&
                activeButton !== 'chat' &&
                unreadCount !== readCount
                  ? unreadCount - readCount
                  : undefined
              }
              hasWarning={button.hasWarning}
            />
          )}
        </Tooltip>
      ))}
    </div>
  );
};

export default MeetingActionButtons;
