import { customerSuccessApis } from '@shared/apis';

import { genericQuery } from './generics';

export const useMentorPerformace =
  genericQuery<customerSuccessApis.MentorPerformance>(
    customerSuccessApis.mentorPerformance
  );

export const useMentorPerformaceTrends =
  genericQuery<customerSuccessApis.MentorPerformanceTrends>(
    customerSuccessApis.mentorPerformanceTrends
  );

export const useStudentsOverview =
  genericQuery<customerSuccessApis.StudentsOverview>(
    customerSuccessApis.studentsOverview
  );
