import { ApiDescription, RequestsMethod, ApiData, Position } from '../common';

export const createPosition: ApiDescription = {
  endpoint: '/positions',
  method: RequestsMethod.Post,
};

export type CreatePosition = ApiData<Partial<Position>, Position>;

export const getPositions: ApiDescription = {
  endpoint: '/positions',
  method: RequestsMethod.Get,
};

export type GetPositions = ApiData<unknown, Position[]>;

export const getPosition: ApiDescription = {
  endpoint: '/positions/:id',
  method: RequestsMethod.Get,
};

export type GetPosition = ApiData<unknown, Position>;

export const updatePosition: ApiDescription = {
  endpoint: '/positions/:id',
  method: RequestsMethod.Patch,
};

export type UpdatePosition = ApiData<Partial<Position>, Position>;

export const deletePosition: ApiDescription = {
  endpoint: '/positions/:id',
  method: RequestsMethod.Delete,
};

export type DeletePosition = ApiData<unknown, Position>;

export const getAllPositionTitles: ApiDescription = {
  endpoint: '/positions/titles',
  method: RequestsMethod.Get,
};

export type GetAllPositionTitles = ApiData<unknown, string[]>;
