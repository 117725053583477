import { MentorFeedback, StudentFeedback } from '@shared/common';
import { MentorAIFeedback } from '@shared/types/DTOs/EnrollmentsDTO';
import SessionModalEmptyState from '../../../SessionModalEmptyState';
import MentorAIFeedbackAccordion from './MentorAIFeedbackAccordion';
import MentorAIRecommendationAccordion from './MentorAIRecommendationAccordion';
import MentorFeedbackAccordion from './MentorFeedbackAccordion';
import StudentFeedbackAccordion from './StudentFeedbackAccordion';

interface SessionFeedbackTabProps {
  mentorFeedback?: MentorFeedback;
  mentorAIFeedback?: MentorAIFeedback;
  studentFeedback?: StudentFeedback[];
  mentorRecommendation?: string;
}

const SessionFeedbackTab = (props: SessionFeedbackTabProps) => {
  const {
    mentorFeedback,
    mentorAIFeedback,
    studentFeedback,
    mentorRecommendation,
  } = props;

  return (
    <div>
      {!mentorFeedback?.sessionSummary &&
      !studentFeedback?.length &&
      !mentorAIFeedback &&
      !mentorRecommendation ? (
        <SessionModalEmptyState />
      ) : (
        <div className="flex flex-col gap-1">
          <MentorFeedbackAccordion
            mentorFeedback={mentorFeedback}
            defaultIsAccordionOpen={!!mentorFeedback?.sessionSummary}
          />

          <StudentFeedbackAccordion
            studentFeedback={studentFeedback}
            defaultIsAccordionOpen={
              !mentorFeedback?.sessionSummary && !!studentFeedback?.[0]?.message
            }
          />

          <MentorAIFeedbackAccordion
            mentorAIFeedback={mentorAIFeedback}
            defaultIsAccordionOpen={
              !mentorFeedback?.sessionSummary &&
              !studentFeedback?.[0]?.message &&
              !!mentorAIFeedback?.createdAt
            }
          />

          <MentorAIRecommendationAccordion
            mentorRecommendation={mentorRecommendation}
            defaultIsAccordionOpen={
              !mentorFeedback?.sessionSummary &&
              !studentFeedback?.[0]?.message &&
              !mentorAIFeedback?.createdAt &&
              !!mentorRecommendation
            }
          />
        </div>
      )}
    </div>
  );
};

export default SessionFeedbackTab;
