import { useEnrollment } from '@shared/react';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';

export const DATE_FORMAT = 'YYYY-MM-DD';

const useDetails = (enrollmentId: string) => {
  const { data: enrollment } = useEnrollment(enrollmentId);

  const [startDate, setStartDate] = useState(enrollment?.startDate);
  const [estimatedEndDate, setEstimatedEndDate] = useState(
    enrollment?.estimatedEndDate
  );
  const [findBy, setFindBy] = useState(enrollment?.findBy);

  // Move query state to local state
  useEffect(() => {
    if (!enrollment) return;
    setStartDate(enrollment.startDate);
    setEstimatedEndDate(enrollment.estimatedEndDate);
    setFindBy(enrollment.findBy);
  }, [enrollment]);

  // Sync findBy
  useEffect(() => {
    const date = moment(startDate);
    if (date.toString() === 'Invalid date') {
      return;
    }
    const prevDate = date.subtract(2, 'weeks');
    setFindBy(prevDate.format(DATE_FORMAT));
  }, [startDate]);

  return {
    startDate,
    setStartDate,
    estimatedEndDate,
    setEstimatedEndDate,
    findBy,
    setFindBy,
  };
};

export default useDetails;
