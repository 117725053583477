import { MentorData } from '@shared/common';
import { useState } from 'react';
import ProfileMainSection from '../ResumeSection';
import PaymentsTabForm from './PaymentsTabForm';
import PaymentsTabTable from './PaymentsTabTable';

interface PaymentsTabProps {
  mentorData?: MentorData;
  userId: string;
}

const PaymentsTab = (props: PaymentsTabProps) => {
  const { mentorData, userId } = props;

  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <section className="flex flex-col gap-6">
      <ProfileMainSection
        title={'Payments Settings'}
        onClick={() => setIsEditMode((prev) => !prev)}
        isButtonActive={isEditMode}
        isEditMode
      >
        {isEditMode && (
          <PaymentsTabForm
            isModeAddNew={isEditMode}
            setIsEditMode={setIsEditMode}
            userId={userId}
            mentorData={mentorData}
            onCancel={() => setIsEditMode(false)}
          />
        )}
        {!isEditMode && <PaymentsTabTable mentorData={mentorData} />}
      </ProfileMainSection>
    </section>
  );
};

export default PaymentsTab;
