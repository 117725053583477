import { PlusOutlined } from '@ant-design/icons/lib';
import { Button, Form } from 'antd';
import { ReactNode } from 'react';

type Props = {
  field?: any;
  name: string;
  addNewText: string;
  renderField: (field: any, remove: () => void) => ReactNode;
};

const DynamicFormField = ({ name, renderField, addNewText, field }: Props) => {
  const formProps = field
    ? { ...field, name: [field.name, name], fieldKey: [field.fieldKey, name] }
    : { name };

  return (
    <Form.List {...formProps}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field) => renderField(field, () => remove(field.name)))}

          <Form.Item>
            <Button
              type="dashed"
              onClick={() => {
                add();
              }}
              block
            >
              <PlusOutlined /> {addNewText}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default DynamicFormField;
