import { ReactComponent as EmptyStateIcon } from '@assets/icons/V4/empty-states/happy-calendar.svg';
import SectionTitle from '@components/V3/Utils/SectionTitle';
import { useFeedbackMeetings } from '@shared/react';
import style from '@styles/views/home.module.scss';
import { Typography } from 'antd';
import React, { useState } from 'react';
import { COLORS } from '../../../../../v3/global';
import EmptyStateCard from '../../../components/EmptyStateCard';
import MeetingNeedingFeedbackStudent from './MeetingNeedingFeedbackStudent';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';

const MeetingsNeedingFeedback: React.FC = () => {
  const { data, isLoading, isError } = useFeedbackMeetings();
  const [viewAll, setViewAll] = useState(false);

  if (isLoading) {
    return <LoadingSpinner className="mb-8" />;
  }
  if (isError) {
    return (
      <Typography.Paragraph style={{ color: COLORS.TEXT_PRIMARY }}>
        An error has occurred...
      </Typography.Paragraph>
    );
  }

  if (!data?.length) {
    return null;
  }

  return (
    <>
      <div style={{ marginBottom: '32px' }}>
        <SectionTitle title="Meetings Needing Feedback" />
      </div>
      <div className={style.meetings}>
        {!data?.length ? (
          <EmptyStateCard
            icon={<EmptyStateIcon />}
            text={`You have no meetings needing feedback.
Thank you for giving us feedback about our services!`}
          />
        ) : null}

        {!viewAll &&
          data?.map((meeting: any, index) =>
            index < 5 ? (
              <div key={meeting._id}>
                <MeetingNeedingFeedbackStudent meeting={meeting} />
              </div>
            ) : null
          )}

        {viewAll &&
          data?.map((meeting: any, index) => (
            <div key={meeting._id}>
              <MeetingNeedingFeedbackStudent meeting={meeting} />
            </div>
          ))}

        {data && data?.length > 5 ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <span
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                font: 'normal normal medium 13px/16px Urbane Rounded',
                fontSize: '13px',
                color: COLORS.PRIMARY,
                alignSelf: 'flex-end',
              }}
              onClick={() => setViewAll(!viewAll)}
            >
              {viewAll ? 'View less' : 'View all'}
            </span>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default MeetingsNeedingFeedback;
