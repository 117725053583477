import { Enrollment } from '@shared/common';
import moment from 'moment';

interface EnrollmentDetailsProps {
  enrollmentDetails: EnrollmentDetailsType;
}

export interface EnrollmentDetailsType {
  startDate: string;
  endDate: string;
  ended?: boolean;
  noOfSessions?: number;
}

const EnrollmentDetails = (props: EnrollmentDetailsProps) => {
  const { enrollmentDetails } = props;
  return (
    <div className="w-full flex gap-x-8">
      <div className="flex flex-col gap-2 w-1/2">
        <div className="flex text-customGrey">
          <span className="min-w-max">Start Date:</span>
          <span className="text-white ml-2 break-words">
            {enrollmentDetails.startDate
              ? moment(enrollmentDetails.startDate).format('DD.MM.YYYY')
              : ' n/a'}
          </span>
        </div>

        <div className="flex text-customGrey">
          <span className="min-w-max">End Date:</span>
          <span className="text-white ml-2 break-words">
            {enrollmentDetails.endDate
              ? moment(enrollmentDetails.endDate).format('DD.MM.YYYY')
              : ' n/a'}
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-2 w-1/2">
        <div className="flex text-customGrey">
          <span className="min-w-max">Status:</span>
          <span className="text-white ml-2 break-words">
            {enrollmentDetails.ended ? 'Ended' : 'Active'}
          </span>
        </div>

        <div className="flex text-customGrey">
          <span className="min-w-max">Total Sessions:</span>
          <span className="text-white ml-2 break-words">
            {enrollmentDetails.noOfSessions}
          </span>
        </div>
      </div>
    </div>
  );
};

export default EnrollmentDetails;
