import {
  PopulatedTakeaway,
  ApiDescription,
  ApiData,
  RequestsMethod,
  Takeaway,
} from '../common';

export const searchMyTakeaways: ApiDescription = {
  endpoint: '/takeaways/my',
  method: RequestsMethod.Get,
};
export type SearchMyTakeaways = ApiData<
  {
    limit?: number;
    q?: string;
  },
  PopulatedTakeaway[]
>;

export const getModuleTakeaways: ApiDescription = {
  endpoint: '/takeaways/module',
  method: RequestsMethod.Get,
};
export type GetModuleTakeaways = ApiData<
  { courseId: string; moduleId: number },
  Takeaway[]
>;

export const viewTakeaways: ApiDescription = {
  endpoint: '/takeaways/view/:lessonId',
  method: RequestsMethod.Get,
};

export type ViewTakeaways = ApiData<unknown, Takeaway[]>;

export const getMyTakeaways: ApiDescription = {
  endpoint: '/knowledge/search',
  method: RequestsMethod.Get,
};

export type GetMyTakeaways = ApiData<unknown, Takeaway[]>;

export const setTakeaways: ApiDescription = {
  endpoint: '/takeaways/set/:courseId/:lessonId',
  method: RequestsMethod.Post,
};

export type SetTakeaways = ApiData<
  {
    lessonId: string;
    takeaways: string[];
  },
  unknown
>;
