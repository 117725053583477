import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  message,
  PageHeader,
  Card,
  Button,
  Form,
  Input,
  Checkbox,
  Skeleton,
  Modal,
  InputNumber,
  Popconfirm,
} from 'antd';
import axios from 'axios';
import { EditorState, ContentState, convertToRaw, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useParams, Redirect } from 'react-router-dom';

import { LessonEditorOptions } from '../../constants';
import * as ROUTES from '../../constants/routes';
import authHeader from '../../services/auth-header';
import MainTakeawaysEditor from './MainTakeawaysEditor';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const LessonEditor = () => {
  const [title, setTitle] = useState('');
  const [isFree, setIsFree] = useState(true);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  const [isRecoveryModalVisible, setIsRecoveryModalVisible] = useState(false);
  const [showDeletePopConfirm, setShowDeletePopConfirm] = useState(false);

  const pageLoaded: any = new Date();

  const recovery = {
    findBackup: (remoteLastUpdated: any, html: any) => {
      const localLessonId: any = localStorage.getItem(
        `backup-time-lesson-${lessonId}`
      );
      if (localStorage.getItem(`backup-lesson-${lessonId}`)) {
        if (new Date(localLessonId) > remoteLastUpdated) {
          if (localStorage.getItem(`backup-lesson-${lessonId}`) !== html)
            setIsRecoveryModalVisible(true);
        }
      }
    },

    recoverBackup: () => {
      const html: any = localStorage.getItem(`backup-lesson-${lessonId}`);
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
        setIsRecoveryModalVisible(false);
      }
    },

    saveBackup: () => {
      const body = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
        {},
        false,
        ({ type, data }): any => {
          //entity.data.alignment is for float using the LCR options on the image 'none' means the user clicked center
          if (type === 'IMAGE') {
            const alignment = data.alignment || 'none';
            const textAlign = alignment === 'none' ? 'center' : alignment;

            return `
                <p style="text-align:${textAlign};">
                    <img src="${data.src}" alt="${data.alt}" style="height: ${data.height};width: ${data.width}"/>
                </p>
            `;
          }
        }
      );
      localStorage.setItem(`backup-lesson-${lessonId}`, body);
      localStorage.setItem(
        `backup-time-lesson-${lessonId}`,
        new Date().toString()
      );
    },
  };

  useEffect(() => {
    if (action.includes('edit')) {
      setLoading(true);
      axios
        .get(`/api/page/${lessonId}?refType=Lesson`, { headers: authHeader() })
        .then((response) => {
          setTitle(response.data.title);
          setIsFree(response.data.isFree);
          const html = response.data.body;
          recovery.findBackup(new Date(response.data.updatedAt), html);
          const contentBlock = htmlToDraft(html);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          message.error('Error occurred - please check console.');
        });
    }
  }, []);

  useEffect(() => {
    const dateNow: any = new Date();
    if (dateNow - pageLoaded >= 10000) recovery.saveBackup();
  }, [editorState]);

  const { action, courseId, lessonId, moduleId }: any = useParams();
  if (courseId === undefined) {
    message.error('Course undefined. Access via Course Management.');
    return <Redirect to={ROUTES.MANAGE_COURSES} />;
  }

  const onSubmit = () => {
    const body = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
      {},
      false,
      ({ type, data }): any => {
        //entity.data.alignment is for float using the LCR options on the image 'none' means the user clicked center
        if (type === 'IMAGE') {
          const alignment = data.alignment || 'none';
          const textAlign = alignment === 'none' ? 'center' : alignment;

          return `
              <p style="text-align:${textAlign};">
                  <img src="${data.src}" alt="${data.alt}" style="height: ${data.height};width: ${data.width}"/>
              </p>
          `;
        }
      }
    );
    localStorage.removeItem(`backup-lesson-${lessonId}`);
    localStorage.removeItem(`backup-time-lesson-${lessonId}`);
    if (lessonId && action.includes('edit')) {
      // editPage(body);
    } else {
      // addNewPage(body);
    }
  };

  const deletePage = async () => {
    await axios.post(
      `/api/lesson/delete`,
      { lessonId, courseId, moduleId },
      { headers: authHeader() }
    );
    window.location.href = ROUTES.MANAGE_COURSES;
  };

  const onEditorStateChange = (newEditorState: any) => {
    setEditorState(newEditorState);
  };

  const headerTitle = () => {
    let message = '';
    if (action.includes('new')) message += 'Adding ';
    else if (action.includes('edit')) message += 'Editing ';
    message += `lesson`;
    return <span>{message}</span>;
  };

  return (
    <div>
      <div>
        <PageHeader
          ghost={false}
          title={headerTitle()}
          extra={[
            <Popconfirm
              title={`This action is irreversible. Are you sure you want to delete ${title}?`}
              visible={showDeletePopConfirm}
              onConfirm={deletePage}
              onCancel={() => setShowDeletePopConfirm(false)}
            >
              <Button
                key="delete-page"
                onClick={() => setShowDeletePopConfirm(true)}
              >
                Delete lesson
              </Button>
            </Popconfirm>,
            <Button
              key="0"
              onClick={() => {
                const win = window.open(`/lesson/${lessonId}`, '_blank');
                win && win.focus();
              }}
            >
              Open lesson in new tab
            </Button>,
            <Button key="1" type="primary" onClick={onSubmit}>
              Publish
            </Button>,
          ]}
        ></PageHeader>
      </div>

      <Card style={{ margin: 15, minHeight: 300 }}>
        {loading && <Skeleton active />}

        <Form
          onFinish={onSubmit}
          initialValues={{ isFree: true }}
          style={loading ? { opacity: 0 } : { marginBottom: 10 }}
        >
          <Form.Item rules={[{ required: true, message: 'Required field' }]}>
            <Input
              placeholder="Lesson Title"
              value={title}
              name="title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Item>

          <Form.Item name="isFree" noStyle>
            <Checkbox
              checked={isFree}
              onChange={(e) => setIsFree(e.target.checked)}
            >
              Available to free members
            </Checkbox>
          </Form.Item>
        </Form>

        <Editor
          wrapperStyle={
            loading ? { opacity: 0 } : { width: '100%', height: '100%' }
          }
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbar={LessonEditorOptions}
          toolbarCustomButtons={[<LessonBreakOption />, <QuizOption />]}
        />
      </Card>

      <Card title="Main Takeaways" style={{ margin: 15, minHeight: 300 }}>
        <MainTakeawaysEditor lessonId={lessonId} courseId={courseId} />
      </Card>

      <Modal
        title="Content Backup"
        visible={isRecoveryModalVisible}
        onOk={recovery.recoverBackup}
        onCancel={() => setIsRecoveryModalVisible(false)}
      >
        <p>
          You closed the editor without saving your changes. Do you want to
          restore your progress?
        </p>
      </Modal>
    </div>
  );
};

const LessonBreakOption = ({ editorState, onChange }: any) => {
  const addLessonBreak = () => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      '\n[LESSON-BREAK]\n',
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  return <Button onClick={addLessonBreak}>Add Lesson Break</Button>;
};

interface INumberCorrect {
  numberCorrect: number;
  setNumberCorrect: Dispatch<SetStateAction<number>>;
}

const QuizOption = ({ editorState, onChange }: any) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [numberCorrect, setNumberCorrect] = useState(1);

  const showModal = () => {
    setVisible(true);
  };

  const addFormResponse = (data: any) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `\n[QUIZ]${JSON.stringify(data)}[/QUIZ]\n`,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  const onFinish = (values: any) => {
    const data = {
      title,
      numberCorrect,
      values,
    };
    setConfirmLoading(true);
    setTimeout(() => {
      addFormResponse(data);
      setVisible(false);
      setConfirmLoading(false);
      cleanForm();
    }, 500);
  };

  const cleanForm = () => {
    // eslint-disable-next-line no-console
    console.log('cleanForm');
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showModal}>Add Quiz</Button>
      <Modal
        title="Add Quiz"
        visible={visible}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
      >
        <b>
          (!!) Always put the correct answer(s) first. They will be shuffled
          when displayed.
        </b>

        <Input
          placeholder="Question or title (optional)"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ width: '100%', marginBottom: '15px' }}
        />
        <p>
          The first{' '}
          <InputNumber
            min={1}
            max={10}
            value={numberCorrect}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange={setNumberCorrect}
          />{' '}
          choice{numberCorrect > 1 ? 's' : ''}{' '}
          {numberCorrect > 1 ? 'are' : 'is'} correct.
        </p>
        <Form name="dynamic_form_item" onFinish={onFinish}>
          <Form.List name="choices">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item required={false} key={field.key}>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            'Please input a choice or delete this field.',
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="choice" style={{ width: '80%' }} />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '100%' }}
                    icon={<PlusOutlined />}
                  >
                    Add choice
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LessonEditor;
