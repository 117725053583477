interface ProfileAsideInfoProps {
  label?: string;
  value: string;
}

const ProfileAsideInfo = (props: ProfileAsideInfoProps) => {
  const { label, value } = props;
  return (
    <div>
      {!!label && <p className="text-sm text-customGrey">{label}</p>}
      <p className="text-sm">{value}</p>
    </div>
  );
};

export default ProfileAsideInfo;
