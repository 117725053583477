import PageLayout from '@components/PageLayout';
import {
  useCreateUserDriveByAdmin,
  useResetUserActivationLink,
} from '@shared/react';
import { mailTemplates } from '@shared/utils';
import { Form, Input, Button, message } from 'antd';
import { useState } from 'react';

const ResetUserActivationLink = () => {
  const [form1] = Form.useForm();
  const [form3] = Form.useForm();
  const [id, setId] = useState('');
  const [registrationToken, setRegistrationToken] = useState('');
  const [driveLink, setDriveLink] = useState('');
  const [fullName, setFullName] = useState('');
  const { mutate: resetUserActivationLink } = useResetUserActivationLink({
    onSuccess: (data: any) => {
      setFullName(data?.fullName);
      setId(data.id);
      setRegistrationToken(data.registrationToken);
    },
    onError: (err: any) => {
      message.error(err.response?.data?.message || err.message);
    },
  });

  const { mutate: createUserDrive } = useCreateUserDriveByAdmin({
    onSuccess: (data: any) => {
      setDriveLink(data);
      message.success('Drive created successfully');
    },
    onError: (err: any) => {
      message.error(err.response?.data?.message || err.message);
    },
  });

  const onFinish1 = (fieldsValue: any) => {
    const values = {
      ...fieldsValue,
    };
    resetUserActivationLink({ ...values });
  };

  const onFinish3 = (fieldsValue: any) => {
    const values = {
      ...fieldsValue,
    };
    createUserDrive({ ...values });
  };

  return (
    <>
      <PageLayout
        headerTitle="Reset activation link"
        cardTitle="Deletes Username, Password, Registration Token, On-boarding"
      >
        <Form
          {...formItemLayout}
          onFinish={onFinish1}
          form={form1}
          name="reset-user-activation-link"
        >
          <Form.Item
            required
            name="email"
            label="Email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ]}
          >
            <Input placeholder="Add user e-mail address" />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Reset
            </Button>
          </Form.Item>
        </Form>
        {id.length > 0 && registrationToken.length > 0 && (
          <>
            <p style={{ marginTop: 20 }}>
              New link generated for <b>{fullName}</b>:
            </p>
            <Input
              value={mailTemplates.welcomeStudentEmail.link({
                userId: id,
                registrationToken,
              })}
            />
          </>
        )}
      </PageLayout>
      <PageLayout
        headerTitle="Create Student Drive"
        cardTitle="Creates student new drive"
      >
        <Form
          {...formItemLayout}
          onFinish={onFinish3}
          form={form3}
          name="create-student-drive"
        >
          <Form.Item
            required
            name="email"
            label="Email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ]}
          >
            <Input placeholder="Add user e-mail address" />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
        {driveLink?.length > 0 && (
          <>
            <p style={{ marginTop: 20 }}>New drive link generated:</p>
            <div>
              <a href={driveLink} target="_blank" rel="noreferrer">
                Student Drive Link
              </a>
            </div>
          </>
        )}
      </PageLayout>
    </>
  );
};

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: {
    span: 24,
  },
};

const tailFormItemLayout = {
  wrapperCol: {},
};
export default ResetUserActivationLink;
