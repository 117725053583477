import { ROLES } from '@shared/constants';
import AgoraSearchBar from '@utils/AgoraSearchbar';
import { COLORS } from '../../../v3/global';
import './users-table-header.styles.scss';
import SecondaryButton from '@utils/SecondaryButton';
import { PlusOutlined } from '@ant-design/icons';
import CreateNewUserModal from '@components/V3/CreateNewUser';
import { useState } from 'react';
import Select from '@components/V4/Select/Select';
import Checkbox from 'antd/lib/checkbox';

type Props = {
  search?: string;
  showTestUsers: 'checked' | 'unchecked' | 'indeterminate';
  handleSearch: (searchValue: string) => void;
  handleFilterBy: (status: string | undefined) => void;
  handleRoles: (role: string | undefined) => void;
  onShowTestUsersChange: (
    showTestUsers: 'checked' | 'unchecked' | 'indeterminate'
  ) => void;
};

const UsersTableHeader = ({
  search,
  showTestUsers,
  handleSearch,
  handleFilterBy,
  handleRoles,
  onShowTestUsersChange,
}: Props) => {
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);

  const roles = () => {
    const rolesArray = [];

    for (const key in ROLES) {
      const roleObject = {
        label: ROLES[key],
        value: key,
      };

      rolesArray.push(roleObject);
    }

    return rolesArray;
  };

  const handleShowCreateUserModal = () => {
    setShowCreateUserModal(!showCreateUserModal);
  };

  const checkboxChangeHandler = () => {
    switch (showTestUsers) {
      case 'checked':
        onShowTestUsersChange('unchecked');
        return;
      case 'unchecked':
        onShowTestUsersChange('indeterminate');
        return;
      case 'indeterminate':
        onShowTestUsersChange('checked');
        return;
    }
  };

  return (
    <div
      style={{
        backgroundColor: COLORS.DARK_GREY_ALT,
        padding: '24px 32px 32px 32px',
      }}
    >
      <CreateNewUserModal
        showModal={showCreateUserModal}
        handleShowModal={handleShowCreateUserModal}
      />
      <div
        style={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/*HEADER TITLE AND BUTTON*/}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '24px',
          }}
        >
          <h1 style={{ color: COLORS.TEXT_PRIMARY }} className="header-title">
            Users
          </h1>
          <SecondaryButton
            onClick={() => {
              setShowCreateUserModal(!showCreateUserModal);
            }}
            backgroundColor={COLORS.WHITE}
            height="24px"
            padding="4px 12px 4px 12px"
            margin="0px"
          >
            <PlusOutlined
              style={{
                color: COLORS.BLACK,
                fontSize: '12px',
                marginRight: '8px',
              }}
            />
            <span className="button-text" style={{ color: COLORS.BLACK }}>
              Create User
            </span>
          </SecondaryButton>
        </div>

        <div style={{ maxWidth: '1920px' }}>
          {/*SEARCHBAR*/}
          <AgoraSearchBar
            search={search}
            handleSearch={handleSearch}
            placeholder={'Search by name, email, phone number...'}
          />

          {/*FILTERS*/}
          <div className="flex flex-wrap gap-4">
            {/*STATUS*/}
            <Select
              className="max-w-64"
              variant="secondary"
              label="Status"
              onSelect={(value) => handleFilterBy(value as string)}
              options={[
                {
                  label: 'Active',
                  value: 'active',
                },
                {
                  label: 'Deactivated',
                  value: 'deactivated',
                },
                {
                  label: 'Test User',
                  value: 'test',
                },
              ]}
              onClear={() => handleFilterBy(undefined)}
            />

            {/*ROLE*/}
            <Select
              className="max-w-64"
              variant="secondary"
              label="Role"
              onSelect={(value) => handleRoles(value as string)}
              options={roles()}
              onClear={() => handleRoles(undefined)}
            />
          </div>

          <div className="flex items-center gap-2 mt-6">
            <Checkbox
              id="hideNoMeetingSessions"
              indeterminate={showTestUsers === 'indeterminate'}
              checked={showTestUsers === 'checked'}
              onChange={checkboxChangeHandler}
            />
            <label
              htmlFor="hideNoMeetingSessions"
              className="text-xsm font-semibold text-white cursor-pointer"
            >
              Show Test Users
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersTableHeader;
