import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './table-header-info-card.styles.scss';

type Props = {
  title: number | string;
  description?: string;
  margin?: string;
  handleExtraFilter?: (extraFilter: any) => void;
  extraFilter?: any;
  statuses?: any[];
  status?: string;
  handleAddStatus?: (value: string | undefined) => void;
  handleRemoveStatus?: (value: string | undefined) => void;
  handleClearStatuses?: () => void;
};

const TableHeaderInfoCard = ({
  title,
  description,
  margin,
  handleExtraFilter,
  extraFilter,
  statuses,
  status,
  handleAddStatus,
  handleRemoveStatus,
  handleClearStatuses,
}: Props) => {
  const [cardHovered, setCardHovered] = useState(false);

  const handleMouseEnterCard = () => {
    setCardHovered(true);
  };

  const handleMouseLeaveCard = () => {
    setCardHovered(false);
  };

  return (
    <div
      style={{
        width: 'fit-content',
        margin: margin ?? '',
        cursor: handleExtraFilter || statuses ? 'pointer' : 'default',
      }}
      onClick={() => {
        if (handleClearStatuses && handleExtraFilter) {
          handleClearStatuses();
          handleExtraFilter(undefined);
        }

        if (handleExtraFilter) {
          if (extraFilter === description) {
            handleExtraFilter(undefined);
          } else {
            handleExtraFilter(description);
          }
        }

        if (status && handleAddStatus && handleRemoveStatus) {
          if (statuses?.includes(status)) {
            handleRemoveStatus(status);
          } else {
            handleAddStatus(status);
          }
        }
      }}
      onMouseEnter={
        handleExtraFilter || statuses ? handleMouseEnterCard : undefined
      }
      onMouseLeave={
        handleExtraFilter || statuses ? handleMouseLeaveCard : undefined
      }
    >
      <h1
        style={{
          color:
            extraFilter === description || statuses?.includes(status)
              ? COLORS.PRIMARY
              : cardHovered
              ? COLORS.PRIMARY
              : COLORS.TEXT_SECONDARY,
          marginBottom: '12px',
        }}
        className="header-info-card-title"
      >
        {title}
      </h1>
      <h3
        style={{
          color:
            extraFilter === description || statuses?.includes(status)
              ? COLORS.PRIMARY
              : cardHovered
              ? COLORS.PRIMARY
              : COLORS.TEXT_SECONDARY,
        }}
        className="header-info-card-description"
      >
        {description}
      </h3>
    </div>
  );
};

export default TableHeaderInfoCard;
