import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

export type RouteType = RouteProps & {
  name?: string;
  path: string;
};

type Props = {
  routes: RouteType[];
  redirectPath: string;
};

const RenderRoutes = ({ routes, redirectPath }: Props) => {
  let { path: basePath } = useRouteMatch();

  if (basePath === '/') basePath = '';

  return (
    <Switch>
      {routes.map((route) => {
        const path = `${basePath}${route.path}`;

        return <Route {...route} key={path} path={path} />;
      })}
      <Redirect to={`${basePath}${redirectPath}`} />
    </Switch>
  );
};

export default RenderRoutes;
