import qs from 'qs';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type DataType = {
  [key: string]: any;
};

/**
 * This hook is used the same as useState, but it will be stored inside the URL
 */
export function useQueryParamsState<T extends DataType>(
  initialData: Partial<T>
): [T, (data: Partial<T>) => void] {
  const history = useHistory();
  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    for (const [key, value] of Object.entries(initialData))
      !searchParams[key] &&
        (value !== null
          ? (searchParams[key] = value)
          : delete searchParams[key]);

    history.replace({
      search: qs.stringify(searchParams, {
        arrayFormat: 'indices',
      }),
    });
  }, []);

  const setData = (newData: Partial<T>) => {
    for (const [key, value] of Object.entries(newData))
      value !== null ? (searchParams[key] = value) : delete searchParams[key];
    history.replace({
      search: qs.stringify(searchParams, {
        arrayFormat: 'indices',
      }),
    });
  };

  const data = { ...initialData, ...searchParams };

  return [data as T, setData];
}
