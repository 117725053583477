import React, { ReactNode, ButtonHTMLAttributes } from 'react';
import { COLORS } from '../../../../v3/global';
import './primary-button.styles.scss';

type Props = {
  children?: ReactNode;
  disabled?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  borderRadius?: string;
  borderWidth?: string;
  margin?: string;
  width?: string;
  height?: string;
  padding?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const PrimaryButton = ({
  children,
  disabled,
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  margin,
  width,
  height,
  padding,
  ...buttonProps
}: Props) => {
  return (
    <button
      {...buttonProps}
      style={{
        backgroundColor: backgroundColor
          ? backgroundColor
          : disabled
          ? COLORS.SURFACE_BACKGROUND
          : COLORS.COMPONENT_BACKGROUND,
        borderColor: borderColor ?? '',
        borderRadius: borderRadius ?? '4px',
        color: COLORS.DARK_WHITE,
        cursor: disabled ? 'not-allowed' : 'pointer',
        // opacity: disabled ? '0.5' : '',
        margin: margin ?? '',
        width: width ?? '',
        height: height ?? '',
        padding: padding ?? '5px 16px',
      }}
      type="button"
      className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 primary-button"
    >
      <span className="primary-button-text">{children}</span>
    </button>
  );
};

export default PrimaryButton;
