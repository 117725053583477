import { UserEditableField } from '@components';
import { interestsOptions } from '@shared/constants';
import { User } from '@shared/types';
import { List } from 'antd';
import ReactJson from 'react-json-view';

const ViewUserObject = ({ user }: { user: User }) => {
  return (
    <div>
      <List>
        <List.Item>
          <b>Phone number:</b>
          <UserEditableField
            userId={user._id}
            updateLocation="phoneNumber"
            initialValue={user.phoneNumber}
            type="string"
          />
        </List.Item>
        <List.Item>
          <b>Email:</b>
          <UserEditableField
            userId={user._id}
            updateLocation="email"
            initialValue={user.email}
            type="string"
          />
        </List.Item>
        <List.Item>
          <b>Interests</b>
          <UserEditableField
            userId={user._id}
            updateLocation="interests"
            initialValue={user.interests}
            type="multi"
            multiOptions={interestsOptions}
          />
        </List.Item>
        <List.Item>
          <b>Graduation year:</b>
          <UserEditableField
            userId={user?._id}
            updateLocation="graduationYear"
            initialValue={user.graduationYear}
            type="number"
          />
        </List.Item>
        <List.Item>
          <b>Parent Name:</b>
          <UserEditableField
            userId={user._id}
            updateLocation="parentData.fullName"
            initialValue={user?.parentData?.fullName}
            type="string"
          />
        </List.Item>
        <List.Item>
          <b>Parent Email:</b>
          <UserEditableField
            userId={user._id}
            updateLocation="parentData.email"
            initialValue={user?.parentData?.email}
            type="string"
          />
        </List.Item>
        <List.Item>
          <b>Parent Phone:</b>
          <UserEditableField
            userId={user._id}
            updateLocation="parentData.phoneNumber"
            initialValue={user?.parentData?.phoneNumber}
            type="string"
          />
        </List.Item>
      </List>
      <h2>User object (everything else):</h2>
      <ReactJson src={user} collapsed={true} />
    </div>
  );
};

export default ViewUserObject;
