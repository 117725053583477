import UEAvatar from '@assets/images/UE-avatar.png';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';

import { COLORS } from '../../../../v3/global';
import { formatMessage } from '../../utils/helpers';
import './ChatMessage.styles.scss';

interface ChatMessageProps {
  message: string;
  isUser: boolean;
  showPulsatingDot: boolean;
}

const ChatMessage = (props: ChatMessageProps) => {
  const { message, isUser, showPulsatingDot } = props;

  const formattedMessage = isUser ? message : formatMessage(message);

  return (
    <div
      className={mergeClassNames('w-fit max-w-[calc(100%-2rem)]', {
        'self-end': isUser,
        'self-start': !isUser,
      })}
    >
      <div
        className={mergeClassNames(
          'flex break-words whitespace-pre-wrap mb-8 rounded-2.5xl py-4 px-6',
          {
            'bg-surfaceHover': isUser,
            'bg-transparent pl-0': !isUser,
          }
        )}
      >
        {!isUser && (
          <img
            className="w-8 h-8 mr-2 laptop:h-10 laptop:w-10 laptop:mr-6"
            src={UEAvatar}
            alt="ue-avatar"
          />
        )}
        {isUser ? (
          <span
            style={{ color: COLORS.TEXT_PRIMARY }}
            className="user-message-box-text"
          >
            {message}
          </span>
        ) : (
          <span
            style={{ color: COLORS.TEXT_PRIMARY }}
            className={mergeClassNames('user-message-box-text', {
              'pulsating-dot': showPulsatingDot,
            })}
            dangerouslySetInnerHTML={{
              __html: formattedMessage,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ChatMessage;
