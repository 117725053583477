import { mergeClassNames } from 'apps/agora/src/utils/helpers';

interface TabItemProps {
  tabName: string;
  isActive: boolean;
  onClick: () => void;
}

const TabItem = (props: TabItemProps) => {
  const { tabName, isActive, onClick } = props;
  return (
    <li
      className={mergeClassNames(
        'min-w-24 py-4 flex-auto rounded-t-lg cursor-pointer text-customGrey tablet:flex-none tablet:w-28',
        {
          'bg-surfaceComplementary text-white': isActive,
        }
      )}
      onClick={onClick}
    >
      <p className="text-center text-inherit text-sbase">{tabName}</p>
    </li>
  );
};

export default TabItem;
