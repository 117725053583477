import { useLessonTakeaways } from '@shared/react';
import { Card } from 'antd';
import { useParams } from 'react-router-dom';

const LessonTakeaways = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useLessonTakeaways(id);

  if (!data?.length) return null;

  return (
    <Card
      loading={isLoading}
      title="Main Takeaways"
      className={`lesson-card visible`}
      id="lesson-takeaways"
    >
      <ul>
        {data?.map((takeaway) => (
          <li>
            <b>{takeaway.question}</b> - {takeaway.answer}
          </li>
        ))}
      </ul>
    </Card>
  );
};

export default LessonTakeaways;
