import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { CreateUserV2 } from '@shared/apis/auth.apis';
import { countryList, supportedLanguages, timezones } from '@shared/constants';
import { CreateNewUserResponse } from '@shared/types';
import AgoraBigSelector from '@utils/AgoraBigSelector';
import AgoraInput from '@utils/AgoraInput';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { UseMutateFunction } from 'react-query';
import { COLORS } from '../../../v3/global';
import './create-new-user.styles.scss';

type StudentDetails = {
  fullName: string;
  phoneNumber: string;
  parentName: string;
  parentEmail: string;
  parentPhoneNumber: string;
  city: string;
  country: string;
  highschool: string;
  graduationYear: string;
  gpa: string;
  universities: string[];
  countriesToStudyIn: string[];
  major: string;
  preferredLanguage?: string;
  timezone?: string;
};

type Props = {
  email: string;
  role: 'STUDENT' | 'MENTOR' | 'ADMIN' | undefined;
  handleCurrentPage: (page: string) => void;
  studentDetails: StudentDetails;
  handleInputChangeStudent: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  setNewUserId: (string: string | undefined) => void;
  handleCountrySelectStudent: (value: any) => void;
  handleCountriesToStudyInSelect: (value: any) => void;
  handleGraduationYearChange: (date: Moment | null) => void;
  handleUniversities: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePreferredLanguageSelectStudent: (value: any) => void;
  handleTimezoneSelectStudent: (value: any) => void;
  createUserV2: UseMutateFunction<
    CreateNewUserResponse,
    Error,
    CreateUserV2['payload']
  >;
};

const CreateNewUserStudentStepTwo = ({
  email,
  role,
  handleCurrentPage,
  studentDetails,
  handleInputChangeStudent,
  setNewUserId,
  handleCountrySelectStudent,
  handleCountriesToStudyInSelect,
  handleGraduationYearChange,
  handleUniversities,
  handlePreferredLanguageSelectStudent,
  handleTimezoneSelectStudent,
  createUserV2,
}: Props) => {
  const [touched, setTouched] = useState<Record<keyof StudentDetails, boolean>>(
    {
      fullName: false,
      phoneNumber: false,
      parentName: false,
      parentEmail: false,
      parentPhoneNumber: false,
      city: false,
      country: false,
      highschool: false,
      graduationYear: false,
      gpa: false,
      universities: false,
      countriesToStudyIn: false,
      major: false,
      preferredLanguage: false,
      timezone: false,
    }
  );

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const getColor = (name: keyof typeof studentDetails) => {
    if (!touched[name]) {
      return COLORS.TEXT_SECONDARY;
    } else {
      if (name === 'universities' || name === 'countriesToStudyIn') {
        return studentDetails[name].length ? COLORS.PRIMARY : COLORS.ERROR;
      } else {
        return studentDetails[name] ? COLORS.PRIMARY : COLORS.ERROR;
      }
    }
  };

  const areDetailsFilled = !!(
    studentDetails.city &&
    studentDetails.country &&
    studentDetails.graduationYear &&
    studentDetails.major
  );

  return (
    <div className="mt-2">
      <div className="flex flex-col"></div>

      <div className="flex flex-col justify-between h-[60vh]">
        <div>
          <h3 className="modal-page-section-title mb-6 text-customGrey">
            Educational Background
          </h3>
          <div className="flex justify-between">
            <AgoraInput
              htmlFor="city"
              isRequired
              type="text"
              name="city"
              id="city"
              placeholder=""
              label="City"
              color={COLORS.TEXT_SECONDARY}
              labelColor={COLORS.TEXT_SECONDARY}
              borderColor={COLORS.TEXT_SECONDARY}
              labelMargin="0 0 0 8px"
              value={studentDetails?.city}
              margin="0 24px 8px 0"
              onChange={handleInputChangeStudent}
              width="calc(50% - 12px)"
              // onBlur={handleBlur}
            />

            <div className="w-[calc(50%-12px)] -mt-2">
              <AgoraBigSelector
                label="Country"
                isRequired
                labelColor={COLORS.TEXT_SECONDARY}
                onSelect={(value) => {
                  handleCountrySelectStudent(value);
                }}
                onClear={() => handleCountrySelectStudent('')}
                options={countryList.map((country) => ({
                  label: country,
                  value: country,
                }))}
                labelKey="label"
                valueKey="value"
                margin="0 0 8px 0"
                height="38px"
                width="100%"
                borderRadius="8px"
                borderColor={COLORS.TEXT_SECONDARY}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-[calc(50%-12px)] -mt-2">
              <AgoraBigSelector
                label="Preferred Language"
                labelColor={COLORS.TEXT_SECONDARY}
                onSelect={(value) =>
                  handlePreferredLanguageSelectStudent(value)
                }
                onClear={() => handlePreferredLanguageSelectStudent('')}
                options={supportedLanguages.map((language) => ({
                  label: language,
                  value: language,
                }))}
                labelKey="label"
                valueKey="value"
                margin="0 0 8px 0"
                height="38px"
                width="100%"
                borderRadius="8px"
                borderColor={COLORS.TEXT_SECONDARY}
              />
            </div>
            <div className="w-[calc(50%-12px)] -mt-2">
              <AgoraBigSelector
                label="Timezone"
                labelColor={COLORS.TEXT_SECONDARY}
                onSelect={(value) => handleTimezoneSelectStudent(value)}
                onClear={() => handleTimezoneSelectStudent('')}
                options={timezones.map((timezone) => ({
                  label: timezone,
                  value: timezone,
                }))}
                labelKey="label"
                valueKey="value"
                margin="0 0 8px 0"
                height="38px"
                width="100%"
                borderRadius="8px"
                borderColor={COLORS.TEXT_SECONDARY}
              />
            </div>
          </div>
        </div>

        <div className="">
          <h3 className="modal-page-section-title mb-6 text-customGrey">
            Academic Path
          </h3>

          <div className="flex justify-between">
            <div className="w-[calc(50%-12px)]">
              <label
                style={{
                  color: getColor('graduationYear'),
                }}
                htmlFor="location"
                className="block text-sm font-medium leading-6 bg-transparent ml-2 text-customPrimary"
              >
                Graduation Year <span className="text-red-500">*</span>
              </label>

              <DatePicker
                picker="year"
                value={
                  studentDetails.graduationYear
                    ? moment(studentDetails.graduationYear, 'YYYY')
                    : null
                }
                onChange={handleGraduationYearChange}
                onClick={() =>
                  setTouched((prev) => ({
                    ...prev,
                    graduationYear: true,
                  }))
                }
                style={{
                  backgroundColor: 'transparent',
                  width: '100%',
                  height: '38px',
                  color: 'red',
                  borderColor: getColor('graduationYear'),
                }}
                clearIcon={null}
                className="custom-date-picker-secondary mt-2"
              />
            </div>

            <AgoraInput
              htmlFor="major"
              type="text"
              name="major"
              id="major"
              placeholder=""
              label="Major (Target University Programs)"
              color={getColor('major')}
              labelColor={getColor('major')}
              borderColor={getColor('major')}
              labelMargin="0 0 0 8px"
              value={studentDetails?.major}
              margin="0 0 8px 0"
              onChange={handleInputChangeStudent}
              width="calc(50% - 12px)"
              onBlur={handleBlur}
              isRequired
            />
          </div>
          <div
            onClick={() => {
              setTouched((prev) => ({
                ...prev,
                countriesToStudyIn: true,
              }));
            }}
          >
            <AgoraBigSelector
              label="Countries to study in"
              labelColor={getColor('countriesToStudyIn')}
              labelMargin="0 0 8px 0"
              onSelect={(value) => {
                handleCountriesToStudyInSelect([
                  ...studentDetails.countriesToStudyIn,
                  value,
                ]);
              }}
              onDeselect={(value) => {
                const updatedCountries =
                  studentDetails.countriesToStudyIn.filter(
                    (country) => country !== value
                  );
                handleCountriesToStudyInSelect(updatedCountries);
              }}
              options={countryList.map((country) => ({
                label: country,
                value: country,
              }))}
              onClear={() => {
                handleCountriesToStudyInSelect([]);
              }}
              labelKey="label"
              valueKey="value"
              margin="0 0 8px 0"
              // height="38px"
              minHeight="38px"
              width="100%"
              borderRadius="8px"
              borderColor={getColor('countriesToStudyIn')}
              mode="multiple"
              value={studentDetails.countriesToStudyIn}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <div className="flex justify-center items-center mb-6">
            <span className="create-new-user-modal-steps-text mr-4">
              Step 2 of 2
            </span>
            <div className="flex items-center justify-between w-8">
              <div className="create-new-user-modal-step-container">
                <div className="create-new-user-modal-step-circle"></div>
              </div>

              <div className="create-new-user-modal-step-container">
                <div className="create-new-user-modal-step-circle"></div>
              </div>
            </div>
          </div>
          <SecondaryButton
            onClick={() => {
              if (areDetailsFilled) {
                createUserV2({
                  email: email,
                  userType: role ?? '',
                  permissions:
                    role === 'STUDENT'
                      ? { student: true }
                      : role === 'MENTOR'
                      ? { mentor: true }
                      : { admin: true },
                  studentData: {
                    fullName: studentDetails.fullName,
                    phoneNumber: studentDetails.phoneNumber,
                    parentEmail: studentDetails.parentEmail,
                    parentFullName: studentDetails.parentName,
                    parentPhoneNumber: studentDetails.parentPhoneNumber,
                    preferredLanguage: studentDetails.preferredLanguage ?? '',
                    timezone: studentDetails.timezone ?? '',
                    city: studentDetails.city,
                    country: studentDetails.country,
                    highSchool: studentDetails.highschool,
                    graduationYear: Number(studentDetails.graduationYear),
                    gpa: isNaN(Number(studentDetails.gpa))
                      ? 0
                      : Number(studentDetails.gpa),
                    ecl: '',
                    standardisedTests: [],
                    universities: studentDetails.universities,
                    admissionExamPrep: [],
                    studySubjects: [],
                    subjectsStudentLikes: [],
                    subjectsStudentDoesntLike: [],
                    learningStyle: [],
                    countries: studentDetails.countriesToStudyIn,
                    interests: [],
                  },
                });
              }
            }}
            backgroundColor={COLORS.WHITE}
            height="40px"
            padding="12px"
            margin="0 0 0 0"
            width="100%"
            disabled={!areDetailsFilled}
          >
            <span className="create-new-user-modal-submit-button text-black">
              Continue
            </span>
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default CreateNewUserStudentStepTwo;
