import { UrlProvider, AuthProvider } from './contexts';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

const BaseProvider = ({ children }: Props) => {
  return (
    <UrlProvider>
      <AuthProvider>{children}</AuthProvider>
    </UrlProvider>
  );
};

export default BaseProvider;
