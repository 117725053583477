import { Card, PageHeader } from 'antd';
import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
  headerTitle: string;
  headerSubtitle?: string;
  cardTitle?: string;
  cardExtra?: ReactNode;
  isEmpty?: boolean;
  backButton?: boolean;
  backUrl?: string;
  headerExtra?: ReactNode;
  children: any;
};

const PageLayout = ({
  children,
  headerTitle,
  headerSubtitle,
  cardTitle,
  cardExtra,
  isEmpty,
  backButton,
  backUrl,
  headerExtra,
}: Props) => {
  const history = useHistory();
  const backFn = backUrl ? () => history.push(backUrl) : history.goBack;

  return (
    <div>
      <div>
        <PageHeader
          ghost={false}
          title={headerTitle}
          onBack={backButton ? backFn : undefined}
          extra={headerExtra}
          key={headerTitle}
        >
          {headerSubtitle && <p>{headerSubtitle}</p>}
        </PageHeader>
      </div>
      <div style={{ margin: 15 }}>
        {isEmpty ? (
          children
        ) : (
          <Card title={cardTitle} extra={cardExtra}>
            {children}
          </Card>
        )}
      </div>
    </div>
  );
};

export default PageLayout;
