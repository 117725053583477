import React, { ReactNode } from 'react';
import './users-table-header.styles.scss';

type Props = {
  children: ReactNode;
};

const MentorsTableHeader = ({ children }: Props) => {
  return (
    <thead style={{ width: '100%' }} className="column-text">
      {children}
    </thead>
  );
};

export default MentorsTableHeader;
