import { DOMAIN } from '@shared/frontendEnv';
import { storage } from '@shared/react/UniversalStorage';
import { useEffect, useRef, useState } from 'react';

const PING_INTERVAL = 60000; //60 seconds

const useMeetingWebSocket = (meetingId: string) => {
  const webSocketRef = useRef<WebSocket>();
  const manuallyClosed = useRef<boolean>();
  const reconnectTimeoutRef = useRef<NodeJS.Timer>();
  const elapsedTimeRef = useRef(0);
  const pingIntervalRef = useRef<NodeJS.Timer>();
  const [isSocketConnected, setIsSocketConnected] = useState(false);

  const maxReconnectTime = 120; // Maximum reconnect time in seconds (2 minutes)
  const reconnectInterval = 5000; // Reconnect every 5 seconds

  const connectWebSocket = () => {
    if (
      webSocketRef.current &&
      (webSocketRef.current.readyState === WebSocket.OPEN ||
        webSocketRef.current.readyState === WebSocket.CONNECTING)
    ) {
      console.log(
        'WebSocket already connected or in the process of connecting'
      );
      return;
    }

    const url = `wss://${DOMAIN}/api/room-activity-ws`;

    if (
      webSocketRef.current &&
      webSocketRef.current.readyState !== WebSocket.CLOSED
    ) {
      webSocketRef.current.close();
    }

    const ws = new WebSocket(url);
    manuallyClosed.current = false;

    ws.onopen = () => {
      console.log('WebSocket connected');
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      setIsSocketConnected(true);

      const token = storage.getItem('auth0token');

      pingIntervalRef.current = setInterval(() => {
        ws.send(
          JSON.stringify({
            type: 'ping',
            token: `Bearer ${token}`,
            meetingId,
          })
        );
      }, PING_INTERVAL);
      elapsedTimeRef.current = 0;
    };

    ws.onclose = () => {
      clearInterval(pingIntervalRef.current);
      console.log('WebSocket disconnected');

      if (!manuallyClosed.current) {
        setIsSocketConnected(false);
      }

      if (
        !manuallyClosed.current &&
        elapsedTimeRef.current < maxReconnectTime
      ) {
        reconnectTimeoutRef.current = setTimeout(() => {
          elapsedTimeRef.current += reconnectInterval / 1000;
          connectWebSocket();

          console.log(
            `Reconnecting in ${
              reconnectInterval / 1000
            } seconds... Total elapsed time: ${elapsedTimeRef.current} seconds`
          );
        }, reconnectInterval);
      } else if (manuallyClosed.current) {
        console.log('WebSocket closed intentionally, no reconnection.');
      } else {
        console.error(
          'Max reconnect time reached. Stopping reconnection attempts.'
        );
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error encountered:', error);
    };

    webSocketRef.current = ws;
  };

  const closeWebsocketConnection = () => {
    manuallyClosed.current = true;
    if (webSocketRef.current) {
      webSocketRef.current.close();
    }
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
    }

    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
    }
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      closeWebsocketConnection();
    };
  }, []);

  return {
    webSocket: webSocketRef.current,
    isSocketConnected,
    closeWebsocketConnection,
  };
};

export default useMeetingWebSocket;
